import React, { useEffect, useState, useCallback } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { useFormik } from "formik";
import { getEventsData, setupEventsInfo, deleteEvent, getCalendarEventsData } from '../../../services/account/action';
import EventsModal from './eventsModal';
import { eventSetupSchema } from '../../../common/validation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDatePicker from '../../../../common/CustomDatePicker';

const EventsCalendar = ({ profileId, useDynamicFields }) => {
    const { fields: eventsField, setFields: setEventsFields, addField: addEventsFields, removeField: removeEventsField, handleChange: handleEventsField } = useDynamicFields({
        title: '',
        start_date: '',
        color: '',
        end_date: '',
        start_stop_time: '',
        location: '',
        description: ''
    });

    const [eventModalShow, setEventModalShow] = useState(false);
    const [eventModalData, setEventModalData] = useState(null);
    const [eventCalendarData, setEventCalendarData] = useState(null);
    const [currentStart, setCurrentStart] = useState(null);
    const [currentEnd, setCurrentEnd] = useState(null);
    const [selectTriggered, setSelectTriggered] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadEventsData();
    }, []);

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            if (selectTriggered) {
                loadEventsCalendarData();
            }
            setShowCalendar(true);
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, [currentStart, currentEnd]);


    const loadEventsData = async () => {
        try {
            const response = await getEventsData({ "profileId": profileId });
            if (response && response.events) {
                const loadEventsData = response.events.length
                    ? response.events.map(event => ({
                        event_id: event.uevent_id,
                        title: event.uevent_title,
                        start_date: event.uevent_start_date,
                        end_date: event.uevent_end_date,
                        color: event.uevent_color,
                        backgroundColor: event.uevent_color,
                        start_stop_time: event.uevent_start_stop,
                        location: event.uevent_location,
                        description: event.uevent_description,
                    }))
                    : [];
                setEventsFields(loadEventsData);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const loadEventsCalendarData = async () => {
        try {
            const response = await getCalendarEventsData({ "profileId": profileId, "date_from": currentStart, "date_to": currentEnd });
            const transformedEvents = response.length
                ? response.map(event => ({
                    id: event.uevent_id,
                    title: event.uevent_title,
                    start: event.uevent_start_date,
                    end: event.uevent_end_date,
                    end_date: event.uevent_end_date,
                    color: event.uevent_color,
                    display: 'block',
                    backgroundColor: event.uevent_color,
                    start_stop: event.uevent_start_stop,
                    location: event.uevent_location,
                    description: event.uevent_description,
                }))
                : [];
            setEventCalendarData(transformedEvents);
        } catch (error) {
            console.log(error);
        }
    }


    const formik = useFormik({
        initialValues: {},
        validationSchema: eventSetupSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            setLoading(true);
            const filteredEventField = eventsField.filter(item => item.title !== '' || item.start_date !== '' || item.color !== '' || item.end_date !== '' || item.start_stop_time !== '' || item.location !== '' || item.description !== '');

            const eventsWithProfileId = filteredEventField.map(event => {
                return {
                    ...event,
                    profile_id: profileId
                };
            });
            try {
                const response = await setupEventsInfo(eventsWithProfileId);
                if (response === true) {
                    loadEventsCalendarData();
                    loadEventsData();
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        },
    });

    const handleDeleteEvent = async (eventId, index) => {
        if (eventId) {
            const response = await deleteEvent({ "id": eventId });
            if (response === true) {
                removeEventsField(index)
                loadEventsCalendarData();
            }
        } else {
            removeEventsField(index)
        }
    }

    const handleEventClick = (arg) => {
        setEventModalShow(true);
        setEventModalData(arg.event);
    }

    const handleSelect = useCallback((arg) => {
        const startDate = arg.startStr.split('T')[0];
        const endDate = arg.endStr.split('T')[0];
        setCurrentStart(startDate);
        setCurrentEnd(endDate);
        setSelectTriggered(true);
    }, []);

    const handleEventModalClose = () => setEventModalShow(false);

    const handleDateChange = (index, field, date) => {
        handleEventsField(index, field, date);
    };

    return (
        <>
            <h4>Events Calendar</h4>
            <div className="row bg-white-tranier mb-5">
                <form onSubmit={formik.handleSubmit}>
                    {eventsField.map((field, index) => (
                        <div key={index} className="col-lg-12 event-calendar">
                            <a className="btn btn-primary  bx bxs-down-arrow" data-bs-toggle="collapse" href={`#collapseExample${index}`}
                                role="button" aria-expanded="false" aria-controls={`collapseExample${index}`}></a>
                            <div id={`reqs_input${index}`} className="datainputs">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="form-group">
                                            <input name={`title[${index}]`} value={field.title} placeholder="Name/Type" type="text" className="name-event" onChange={(e) => handleEventsField(index, 'title', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <CustomDatePicker
                                            selectedDate={field.start_date ? new Date(field.start_date) : null}
                                            onDateChange={(date) => handleDateChange(index, 'start_date', date)}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            id=""
                                            placeholderText="Start date"
                                            minDate={new Date()}
                                        />
                                    </div>
                                    {index > 0 && (
                                        <div className="col-lg-2 required_inp">
                                            <div className="form-group">
                                                <button type="button" className="inputRemove mt-0" onClick={() => handleDeleteEvent(field.event_id, index)}>X</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="collapse" id={`collapseExample${index}`}>
                                <div className="card card-body">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="from-group">
                                                <label>Color</label>
                                                <select className="form-select custom-select" name={`color[${index}]`} value={field.color} onChange={(e) => handleEventsField(index, 'color', e.target.value)} aria-label="Default select example" style={{ backgroundColor: field.color, color: "#fff" }}>
                                                    <option value="">Event Color</option>
                                                    <option value="#2D323C" style={{ backgroundColor: "#2D323C", color: "#fff" }}>#2D323C</option>
                                                    <option value="#AB6601" style={{ backgroundColor: "#AB6601", color: "#fff" }}>#AB6601</option>
                                                    <option value="#CBA121" style={{ backgroundColor: "#CBA121", color: "#fff" }}>#CBA121</option>
                                                    <option value="#468189" style={{ backgroundColor: "#468189", color: "#fff" }}>#468189</option>
                                                    <option value="#246A73" style={{ backgroundColor: "#246A73", color: "#fff" }}>#246A73</option>
                                                    <option value="#9CA1A5" style={{ backgroundColor: "#9CA1A5", color: "#fff" }}>#9CA1A5</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="from-group">
                                                <label>End Date (optional)</label><br />
                                                <CustomDatePicker
                                                    selectedDate={field.end_date ? new Date(field.end_date) : null}
                                                    onDateChange={(date) => handleDateChange(index, 'end_date', date)}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    id=""
                                                    placeholderText="End date"
                                                    minDate={new Date()}
                                                />
                                            </div>
                                        </div>


                                        <div className="col-lg-4">
                                            <div className="from-group">
                                                <label>Times (start/stop)</label><br />
                                                <input type="text" name={`start_stop_time[${index}]`} value={field.start_stop_time} className="form-control" placeholder="Time" aria-label="Time" onChange={(e) => handleEventsField(index, 'start_stop_time', e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="from-group">
                                                <label>Location</label><br />
                                                <input type="text" name={`location[${index}]`} value={field.location} className="form-control" placeholder="Location of Events" aria-label="location" onChange={(e) => handleEventsField(index, 'location', e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="from-group">
                                                <label>Description (200 max characters)</label><br />
                                                <textarea rows="4" name={`description[${index}]`} value={field.description} placeholder="Description of Event" cols="100" maxLength="50" onChange={(e) => handleEventsField(index, 'description', e.target.value)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="add-more">
                        <button type="button" className="add_input" onClick={() => addEventsFields()}><i className='bx bx-plus'></i> Add Event</button>
                    </div>
                    <div className="col-lg-12 mb-5">
                        <div className="save-btn">
                            <button type="submit" className="btn btn-primary" disabled={loading}>Save Changes</button>
                        </div>
                    </div>
                </form>
                {showCalendar && (
                    <div id="calendar">
                        <FullCalendar
                            plugins={[dayGridPlugin]}
                            initialView="dayGridMonth"
                            datesSet={handleSelect}
                            headerToolbar={{
                                start: 'prev,next',
                                center: 'title',
                                end: 'dayGridMonth,dayGridWeek'
                            }}
                            editable={false}
                            eventClick={handleEventClick}
                            events={eventCalendarData}
                        />
                    </div>
                )}
                <EventsModal eventData={eventModalData} eventModalShow={eventModalShow} handleEventModalClose={handleEventModalClose} />
            </div>
        </>
    );
}

export default EventsCalendar;
