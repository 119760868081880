import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getDataBlogs, getBlogbyId, updateBlogStatus, deleteBlog, } from "../../services/blog/action";
import SearchBar from "./searchBar";
import Listing from "./listing";
import AddUpdateBlog from "./addUpdateBlog";
import DeleteModal from "../../common/deleteModal";
import { getPrivileges } from '../../common/adminPrivilege';
import { getAdminPaginationLimit } from "../../../common/common";
const BlogIndex = () => {
    const [blogData, setBlogData] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const limit = getAdminPaginationLimit();
    const requestBody = {
        page: currentPage,
        limit: limit,
        date_from: "",
        date_to: "",
        keyword: "",
    };
    const [formshow, setFormShow] = useState(false);
    const [filterdata, setFilterData] = useState(requestBody);
    const [deleteid, setDeleteId] = useState(null);
    const [deleteshow, setDeleteShow] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const dispatch = useDispatch();
    const { blogs, totalCount } = useSelector((state) => state.blog);
    const loading = useSelector((state) => state.blog.loading);
    const error = useSelector((state) => state.blog.error);
    const getPrivilege = getPrivileges();
    const editBlog = getPrivilege.canEditBlog();

    useEffect(() => {
        dispatch(getDataBlogs(requestBody));
    }, []);

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getBlogbyId(id);
                const result = response.data;
                setBlogData(result);
            }
        } catch (error) { }
        setFormShow(true);
    };

    const handleFormClose = () => {
        setFormShow(false);
        setBlogData('')
    };

    const setOrderIdArray = () => {
        const checkedInputs = document.querySelectorAll(
            "input[name='order_id_blog[]']:checked"
        );
        const newOrderIds = Array.from(checkedInputs, (input) => input.value);
        return newOrderIds;
    };

    const handleSelectedStatusUpdate = async (status) => {
        const data = {
            "id": setOrderIdArray(),
            "status": status,
        };
        try {
            await updateBlogStatus(data);
            dispatch(getDataBlogs(requestBody));
        } catch (error) { }
    };

    const handleStatus = async (e, id, status) => {
        if (editBlog) {
            const newStatus = status === 1 ? 0 : 1;
            blogs.map((blog) => {
                if (blog.blog_id === id) {
                    blog.blog_status = newStatus;
                }
                return blog;
            });
            const data = {
                "status": newStatus,
                "id": [id],
            };
            try {
                await updateBlogStatus(data);
                dispatch(getDataBlogs(requestBody));
            } catch (error) { }
        }
    };

    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id);
    };

    const handleDeleteClose = () => {
        setDeleteShow(false);
    };

    const handleDelete = async () => {
        try {
            let data;
            if (typeof deleteid === "number") {
                data = [deleteid];
            } else {
                data = setOrderIdArray();
            }
            await deleteBlog({ "id": data });
            dispatch(getDataBlogs(requestBody));
            handleDeleteClose();
        } catch (error) { }
    };

    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataBlogs(updatedFilterData));
        }
    };

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataBlogs(updatedFilterData));
    };

    const handleClear = () => {
        setFilterData(requestBody);
        dispatch(getDataBlogs(requestBody));
    };

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataBlogs(updatedFilterData));
    };

    const handleCheckboxChange = (event, labelId) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedCheckboxes((prevSelected) => [...prevSelected, labelId]);
        } else {
            setSelectedCheckboxes((prevSelected) =>
                prevSelected.filter((id) => id !== labelId)
            );
        }
    };

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFormShow={handleFormShow}
                        handleSelectedStatusUpdate={handleSelectedStatusUpdate}
                        handleFilterUpdate={handleFilterUpdate}
                        filterdata={filterdata}
                        handleFilter={handleFilter}
                        handleClear={handleClear}
                        selectedCheckboxes={selectedCheckboxes}
                        deleteModalOpen={deleteModalOpen}
                        editBlog={editBlog}
                    />
                    <Listing
                        error={error}
                        blogs={blogs}
                        loading={loading}
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        handlePaginate={handlePaginate}
                        handleFormShow={handleFormShow}
                        handleStatus={handleStatus}
                        deleteModalOpen={deleteModalOpen}
                        handleCheckboxChange={handleCheckboxChange}
                        setSelectedCheckboxes={setSelectedCheckboxes}
                        editBlog={editBlog}
                    />
                </div>
            </div>
            {formshow && (
                <AddUpdateBlog
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    requestBody={requestBody}
                    blogData={blogData}
                    editBlog={editBlog}
                />
            )}
            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default BlogIndex;
