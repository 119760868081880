import { getLabel } from "../../common/getLabel"
import { baseUrl } from "../../common/config";
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
const Listing = (props) => {
    const { offers, totalCount, loading, error, handleFormShow, handlePaginate, calculateSerialNumber, limit, editOffers, deleteModalOpen, offerTypes, currentPage } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <form id="blogTag-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_Profile_ID")}</th>
                            <th>{getLabel("LBL_Offer_Date")}</th>
                            <th>{getLabel("LBL_Offer_Type")}</th>
                            <th>{getLabel("LBL_Offer_College")}</th>
                            <th>{getLabel("LBL_Offer_Commit")}</th>
                            <th>{getLabel("LBL_Offer_School_Logo")}</th>
                            <th className="table-action">{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={8} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="6" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {offers && offers.length > 0 ? (
                                    offers.map((elem, index) => {
                                        const imagePath = (elem.school_image) ? elem.school_image[0] : '';
                                        let formattedDate = '';
                                        if (elem.poffer_date) {
                                            const date = new Date(elem.poffer_date);
                                            formattedDate = date.toISOString().split('T')[0];
                                        }
                                        return (
                                            <tr className="actions" key={index} >
                                                <td>{calculateSerialNumber(index)}</td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">
                                                            {elem.poffer_uprofile_id ?
                                                                `${elem["poffers.uprofile_fname"]} ${elem["poffers.uprofile_lname"]} (${elem.poffer_uprofile_id})` :
                                                                '0 (missing)'
                                                            }
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{formattedDate} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{offerTypes[elem.poffer_type]} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{elem.poffer_college} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{elem.poffer_commit ? `Yes` : 'No'}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="userimg">
                                                        {imagePath &&
                                                            <img
                                                                src={`${baseUrl}/${imagePath.afile_physical_path}`}
                                                                className="img-fluid" />
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="action">
                                                        <ul>
                                                            <li><a onClick={(() => handleFormShow(elem.poffer_id))}><i className='bx bx-pencil'></i></a></li>
                                                            {editOffers && (
                                                                <li><a><i className="bx bx-trash" onClick={() => deleteModalOpen(elem.poffer_id)} ></i></a></li>
                                                            )}
                                                        </ul>
                                                    </span>

                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="8" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {offers && offers.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    )
}

export default Listing