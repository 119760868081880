import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { isLoggedIn } from './services/authentication/action.js';
import { useSelector } from 'react-redux';

import Main from "./Main.js";
import HomePage from "./component/home";
import MemberShip from "./component/membership/index.jsx";
import FaqIndex from "./component/faq/index.jsx";
import BlogIndex from "./component/blogs/index.jsx";
import Contact from "./component/contact/index.jsx";
import Profiles from "./component/profiles/index.jsx";
import Trainers from "./component/trainers/index.jsx";
import Page404 from './common/Page404.jsx';
import BulletinBoard from './component/bulletin/index.jsx';
import PremiumOffers from './component/premium-offers/index.jsx';
import CmsPages from './component/cms/index.jsx';
import BlogView from './component/blogs/view.jsx';

import Login from "./component/auth/login.jsx";
import SignUp from "./component/auth/register.jsx";
import Logout from './component/auth/logout.jsx';
import AccountVerify from './component/auth/AccountVerify.jsx';
import ForgotPasswod from './component/auth/ForgotPasswod.jsx';
import ResetPassword from './component/auth/ResetPassword.jsx';

import UserProfile from './component/account/profile/index.jsx';
import ProfileView from './component/account/profile/view.jsx';
import AccountSetting from './component/account/account-setting/index.jsx';
import TopArchive from './component/top-archive/index.jsx';
import ProfileDetail from './component/profiles/view.jsx';
import TrainerProfileDetail from './component/trainers/view.jsx';
import Pricing from './component/membership/pricing.jsx';
import Checkout from './component/membership/checkout.jsx';
import Thankyou from './component/membership/thankyou.jsx';
import PremiumProfiles from './component/premium-profiles/index.jsx';
import { getUrlSlug } from '../common-services/url-rewriting/action.js';
import BlogCategoryIndex from './component/blog-category/index.jsx';
import BlogTagIndex from './component/blog-tag/index.jsx';
import OrderUpdate from './component/account/account-setting/order-update.jsx';
import ProtectedRoute from './ProtectedRoute.js';
// import CronIndex from './component/cron/index.jsx';
import RedirectIfLoggedIn from './common/RedirectIfLoggedIn.js';

const FrontendRoutes = () => {
    const loggedIn = useSelector(isLoggedIn);
    const [styleLoaded, setStyleLoaded] = useState(false);
    const [urlRecord, setUrlRecord] = useState('');
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    // const pathname = location.pathname;
    const pathname = location.pathname.replace(/\/$/, '');
    const parts = pathname.split('/');
    const slug = parts[parts.length - 1];

    useEffect(() => {
        import('./common/FrontendStyle')
            .then(() => {
                setStyleLoaded(true);
            })
            .catch(error => {
                console.error('Error loading frontend style:', error);
            });
        if (slug && slug.length > 0) {
            handleUrlSlug();
        }
    }, [slug]);

    const handleUrlSlug = async () => {
        try {
            const response = await getUrlSlug({ "url": slug });
            setUrlRecord(response);
        } catch (error) {
            console.error('Error fetching URL slug:', error);
            setUrlRecord(null);
        } finally {
            setLoading(false);
        }
    }

    const getComponentByType = (type) => {
        switch (type) {
            case 1:
                return ProfileDetail;
            case 2:
                return TrainerProfileDetail;
            case 3:
                return BlogView;
            case 4:
                return BlogCategoryIndex;
            case 6:
                return BlogTagIndex;
            case 5:
                return CmsPages;
        }
    };

    const RouteComponent = ({ type, ...restProps }) => {
        const Component = getComponentByType(type);
        let blogCategoryType = type === 4;
        let blogTagType = type === 6;
        return <Component {...restProps} blogCategory={blogCategoryType} blogTag={blogTagType} />;
    };

    const RoutePath = (path, type) => {
        switch (type) {
            case 1:
                return "/profile/" + path;
            case 2:
                return "/trainer-profile/" + path;
            case 4:
                return "/category/" + path;
            case 6:
                return "/tag/" + path;
            default:
                return path;
        }
    };

    if (!styleLoaded) {
        return null;
    }

    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const user_type = userDetails ? userDetails.user_type : '';

    return (
        <>
            <>
                <Routes>
                    <Route path="/" element={<Main />}>
                        <Route index element={<HomePage />} />
                        <Route exact path="log-in" element={<Login />} />
                        <Route exact path="forgot-password" element={<ForgotPasswod />} />
                        <Route exact path="reset-password/:token" element={<ResetPassword />} />
                        <Route exact path="signup" element={
                            <RedirectIfLoggedIn to="/membership">
                                <SignUp />
                            </RedirectIfLoggedIn>
                        } />
                        <Route exact path="pricing" element={
                            <RedirectIfLoggedIn to="/membership">
                                <Pricing />
                            </RedirectIfLoggedIn>
                        } />
                        <Route exact path="faqs" element={<FaqIndex />} />
                        <Route exact path="news" element={<BlogIndex />} />
                        <Route exact path="news/view/:blogId" element={<BlogView />} />
                        <Route exact path="contact" element={<Contact />} />
                        <Route exact path="qb-profiles" element={<Profiles show_top_200={false} />} />
                        <Route exact path="profile/search/:search_string" element={<Profiles show_top_200={false} />} />
                        <Route exact path="qbhl-top-150" element={<Profiles show_top_200={true} />} />
                        <Route exact path="trainers" element={<Trainers />} />
                        <Route exact path="bulletin-board" element={<BulletinBoard />} />
                        <Route exact path="top-150-archive" element={<TopArchive />} />
                        <Route exact path="premium-offers-commits" element={<PremiumOffers />} />
                        <Route exact path="qbhl-premium-profiles" element={<PremiumProfiles />} />
                        <Route exact path="account-verify/:token" element={<AccountVerify />} />
                        {urlRecord && (
                            <Route
                                exact
                                path={RoutePath(urlRecord.urlrewrite_custom, urlRecord.urlrewrite_type)}
                                element={<RouteComponent type={urlRecord.urlrewrite_type} record_id={urlRecord.urlrewrite_record_id} />}
                            />
                        )}
                        {loggedIn && (
                            <>
                                <Route exact path="membership" element={<MemberShip />} />
                                <Route exact path="checkout" element={<Checkout />} />
                                <Route exact path="thank-you" element={<Thankyou />} />
                                <Route exact path="qbhl-admin" element={
                                    <ProtectedRoute user_type={user_type}>
                                        <UserProfile />
                                    </ProtectedRoute>} />
                                <Route exact path="qbhl-admin/account" element={<AccountSetting />} />
                                <Route exact path="qbhl-admin/billing-update/:order_id" element={<OrderUpdate />} />
                                <Route exact path="qbhl-admin/profile/:profileId?" element={<ProfileView />} />
                                <Route exact path="user/logout" element={<Logout />} />
                            </>
                        )}
                        <Route exact path="*" element={<Page404 />} />
                    </Route>
                    {/* <Route exact path="/cron/:command?" element={<CronIndex />} /> */}
                </Routes>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    limit='1'
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
        </>
    );
};

export default FrontendRoutes;
