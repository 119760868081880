import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { useState } from 'react';
import { baseUrl } from '../../common/config';
import { ImageDeleteModal } from '../../common/imageDeleteModal';
import { schoolLogoSchema } from '../../common/validation';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSchoolLogos, setUpSchoolLogo, deleteSchoolLogoImage } from '../../services/manage-school-logos/action';

const AddUpdateLogos = (props) => {
    const { formshow, handleFormClose, requestBody, logoId, editSchoolLogs } = props
    const [schoolLogoImage, setSchoolLogoImage] = useState(null)
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const [imageDeleteId, setImageDeletedId] = useState()
    const schoolLogoData = useSelector(state => {
        const logos = state.logos.logos;
        if (logos && logos.length > 0) {
            return logos.find(logos => logos.sch_id === logoId);
        }
        return null;
    });
    const imageId = schoolLogoData && schoolLogoData.school_image && Object.keys(schoolLogoData.school_image).length > 0 && schoolLogoData.school_image[0].afile_record_id
    const initialValues = {
        school_id: (schoolLogoData) ? schoolLogoData.sch_id : '',
        title: (schoolLogoData) ? schoolLogoData.sch_name : '',
    };
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schoolLogoSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("logo", schoolLogoImage);
            Object.entries(values).forEach(([key, value]) => {
                formData.append(key, value);
            });

            if (editSchoolLogs) {
                try {
                    const response = await setUpSchoolLogo(formData);
                    if (response.success === true) {
                        handleFormClose();
                        dispatch(getDataSchoolLogos(requestBody))
                    }
                } catch (error) { }
            }
        },
    });

    const handleChange = (e) => {
        if (editSchoolLogs) {
            formik.handleChange(e);
        }
    };

    const handleCancelImage = () => {
        setSchoolLogoImage("")
    };

    const handleImageDeleteModalShow = (id) => {
        setImageDeletedId(id)
        setImageDeleteModalShow(true);
    }

    const handleDeleteImage = async () => {
        if (editSchoolLogs) {
            try {
                const data = { "school_id": schoolLogoData.sch_id }
                await deleteSchoolLogoImage(data)
                handleImageDeleteModalClose()
                const Name = 'image_list_' + imageDeleteId;
                document.querySelector('.' + Name).remove();
                dispatch(getDataSchoolLogos(requestBody))
            } catch (error) { }
        }
    }

    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false)
    return (
        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_School_Logo_Form")}</h5>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input"> {getLabel("LBL_School_Name*")} </label>
                                            <input
                                                type="text"
                                                id="title"
                                                name="title"
                                                onChange={handleChange}
                                                value={formik.values.title}
                                                className="form-control"
                                                placeholder={getLabel("LBL_School_Name")}
                                                disabled={!editSchoolLogs}
                                            />
                                            <div>
                                                {formik.touched.title && formik.errors.title && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.title}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input"> {getLabel("LBL_Logo")}</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                onChange={((e) => setSchoolLogoImage(e.target.files[0]))}
                                                disabled={!editSchoolLogs}
                                            />
                                        </div>
                                        <div className='main-div'>
                                            {schoolLogoImage ?
                                                (
                                                    <img src={URL.createObjectURL(schoolLogoImage)} style={{ height: "50px", width: "50px", borderRadius: "50%" }} onClick={handleCancelImage} alt="image-path ist" />
                                                ) : (
                                                    <div className="show--image">
                                                        <div className={`image_list_${imageId}`}>
                                                            {schoolLogoData && schoolLogoData.school_image && Object.keys(schoolLogoData.school_image).length > 0 &&
                                                                <div>
                                                                    <img
                                                                        src={`${baseUrl}/${schoolLogoData.school_image[0].afile_physical_path}`}
                                                                        style={{ height: "50px", width: "50px", borderRadius: "50%" }} onClick={handleCancelImage} alt="image-path" />
                                                                    {editSchoolLogs && (

                                                                        <input
                                                                            type="button"
                                                                            value="x"
                                                                            className="btn-rmv1 rmv"
                                                                            onClick={(() => handleImageDeleteModalShow(imageId))} />
                                                                    )}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {editSchoolLogs && (
                        <Modal.Footer>
                            <Button variant="secondary"
                                onClick={handleFormClose}
                            >
                                {getLabel("LBL_Close")}
                            </Button>
                            <Button variant="primary" type="submit">{getLabel("LBL_Submit")}</Button>
                        </Modal.Footer>
                    )}
                </form>
            </Modal>
            <ImageDeleteModal
                imageDeleteModalshow={imageDeleteModalshow}
                handleImageDeleteModalClose={handleImageDeleteModalClose}
                handleDeleteImage={handleDeleteImage}
            />

        </>
    )
}

export default AddUpdateLogos
