import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import JoditEditor from "jodit-react";
import { getLabel } from "../../common/getLabel";
import { blogSchema } from "../../common/validation";
import { baseUrl } from "../../common/config";
import { ImageDeleteModal } from "../../common/imageDeleteModal";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
    blogCategoryListing,
    blogTagListing,
    updateBlog,
    getDataBlogs,
    deleteBlogImage,
} from "../../services/blog/action";
import { scrollToFirstError } from "../../../common/common";
const AddUpdateBlog = (props) => {
    const { formshow, handleFormClose, requestBody, blogData, editBlog } = props;

    const editor = useRef(null);
    const [categoryList, setCategoryList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [imageDeleteId, setImageDeletedId] = useState();
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const [uploadImage, setUploadImage] = useState(null);
    const fileInputRef = useRef(null);

    const imageId = blogData && blogData.blogImage && Object.keys(blogData.blogImage).length > 0 && blogData.blogImage[0].afile_record_id
    const fileId = blogData && blogData.blogImage && Object.keys(blogData.blogImage).length > 0 && blogData.blogImage[0].afile_id;
    const afileRecordId = blogData && blogData.blogImage && Object.keys(blogData.blogImage).length > 0 && blogData.blogImage[0].afile_record_id;

    const dispatch = useDispatch();
    useEffect(() => {
        fetchCategoryListing();
        fetchTagListing();
        setSelectedCategoryInFormik();
        setSelectedTagsInFormik();
    }, []);

    const fetchCategoryListing = async () => {
        const response = await blogCategoryListing();
        setCategoryList(response.data);
    };

    const fetchTagListing = async () => {
        const response = await blogTagListing();
        setTagList(response.data.blogTags);
    };

    const setSelectedCategoryInFormik = async () => {
        const selectedOptionsa = blogData.blogCategory && Object.keys(blogData.blogCategory).length > 0 &&
            blogData.blogCategory.map((selectedCategory) => ({
                value: selectedCategory["category.bcat_id"],
                label: selectedCategory["category.bcat_title"],
            }));

        formik.setFieldValue("blogCategory", selectedOptionsa);
    }

    const setSelectedTagsInFormik = async () => {
        const selectedOptionsa = blogData.blogTags && Object.keys(blogData.blogTags).length > 0 &&
            blogData.blogTags.map((selectedTags) => ({
                value: selectedTags["btt_tag_id"],
                label: selectedTags["tags.btag_title"],
            }));

        formik.setFieldValue("blogTags", selectedOptionsa);
    }
    
    const initialValues = {
        id: (blogData) ? blogData.blog_id : '',
        blog_title: (blogData) ? blogData.blog_title : '',
        blog_status: (blogData) ? blogData.blog_status : '',
        blog_author_name: (blogData) ? blogData.blog_author_name : '',
        blog_long_description: (blogData) ? blogData.blog_long_description : '',
        blog_short_description: (blogData) ? blogData.blog_short_description : '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: blogSchema,
        validateOnChange: false,
        onSubmit: async (values) => {

            const formData = new FormData();
            formData.append("title", values.blog_title);
            formData.append("status", values.blog_status);
            formData.append("author_name", values.blog_author_name);
            formData.append("long_description", values.blog_long_description);
            formData.append("short_description", values.blog_short_description);
            formData.append("blog_img", uploadImage);

            if (values.blogCategory && values.blogCategory.length > 0) {

                values.blogCategory.forEach((category) => {
                    formData.append(
                        "blog_category[]",
                        category.value || category["category.bcat_id"]
                    );
                });
            }

            if (values.blogTags && values.blogTags.length > 0) {
                values.blogTags.forEach((tag) => {
                    formData.append("blog_tags[]", tag.value || tag["btag_id"]);
                });
            }

            if (typeof blogData.blog_id === "number") {
                formData.append("id", (blogData) ? blogData.blog_id : '');
                formData.append("afile_id", fileId);
            }
            if (editBlog) {
                try {
                    await updateBlog(formData);
                    handleFormClose();
                    dispatch(getDataBlogs(requestBody));
                } catch (error) { }
            }
        },
    });
    // 
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const handleCancelImage = () => {
        setUploadImage("");
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleImageDeleteModalShow = (id) => {
        setImageDeletedId(id);
        setImageDeleteModalShow(true);
    };

    const handleDeleteImage = async () => {
        try {
            const data = { blog_id: afileRecordId };
            await deleteBlogImage(data);
            handleImageDeleteModalClose();
            const Name = "image_list_" + imageDeleteId;
            document.querySelector("." + Name).remove();
        } catch (error) { }
    };
    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false);

    const fieldRefs = useRef({});
    useEffect(() => {
        scrollToFirstError(formik, fieldRefs);
    }, [formik.errors, formik.touched, formik.isSubmitting]);

    return (
        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">
                        {getLabel("LBL_Blog_Form")}
                    </h5>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Post_Title*")}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="blog_title"
                                                name="blog_title"
                                                onChange={handleChange}
                                                value={formik.values.blog_title}
                                                placeholder={getLabel("LBL_Post_Title")}
                                                disabled={!editBlog}
                                                ref={el => fieldRefs.current.blog_title = el}
                                            />
                                            <div>
                                                {formik.touched.blog_title && formik.errors.blog_title && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.blog_title}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Author_Name*")}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="blog_author_name"
                                                name="blog_author_name"
                                                onChange={handleChange}
                                                value={formik.values.blog_author_name}
                                                ref={el => fieldRefs.current.blog_author_name = el}
                                                placeholder={getLabel("LBL_Author_Name")}
                                                disabled={!editBlog}
                                            />
                                            <div>
                                                {formik.touched.blog_author_name && formik.errors.blog_author_name && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.blog_author_name}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">{getLabel("LBL_Select_Category*")}</label>
                                        <Select
                                            isMulti
                                            name="blogCategory"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            aria-label="Default select example"
                                            defaultValue={
                                                blogData.blogCategory && Object.keys(blogData.blogCategory).length > 0 &&
                                                blogData.blogCategory.map((selectedCategory) => ({
                                                    value: selectedCategory["category.bcat_id"],
                                                    label: selectedCategory["category.bcat_title"],
                                                }))
                                            }
                                            options={categoryList.map((category) => ({
                                                value: category.bcat_id,
                                                label: category.bcat_title,
                                            }))}
                                            onChange={(selectedOptions) => {
                                                formik.setFieldValue("blogCategory", selectedOptions);
                                            }}
                                            isDisabled={!editBlog}
                                        />
                                        <div>
                                            {formik.touched.blogCategory &&
                                                formik.errors.blogCategory && (
                                                    <p style={{ color: "#b94a48" }}>
                                                        {formik.errors.blogCategory}
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Short_Description")}
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="blog_short_description"
                                                name="blog_short_description"
                                                rows="3"
                                                value={formik.values.blog_short_description}
                                                ref={el => fieldRefs.current.blog_short_description = el}
                                                onChange={formik.handleChange}
                                                placeholder={getLabel("LBL_Describe_Short_Description_here...")}
                                                disabled={!editBlog}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        // className="input-group file col-md-6"
                                        className="col-lg-12"
                                    >
                                        <label className="custom">
                                            {getLabel("LBL_Post_Featured_Image")}
                                        </label>
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            className="form-control"
                                            id="inputGroupFile03"
                                            onChange={(e) => setUploadImage(e.target.files[0])}
                                            disabled={!editBlog}
                                        />
                                        <div className="main-div">
                                            {uploadImage ? (
                                                <img
                                                    src={URL.createObjectURL(uploadImage)}
                                                    style={{
                                                        height: "50px",
                                                        width: "50px",
                                                        borderRadius: "50%",
                                                    }}
                                                    onClick={handleCancelImage}
                                                    alt="image-path"
                                                />
                                            ) : (
                                                <div className="show--image">
                                                    <div className={`image_list_${imageId}`}>
                                                        {blogData && blogData.blogImage && Object.keys(blogData.blogImage).length > 0 && (
                                                            <div>
                                                                <img
                                                                    src={`${baseUrl}/${blogData.blogImage[0].afile_physical_path}`}
                                                                    style={{
                                                                        height: "50px",
                                                                        width: "50px",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                    onClick={handleCancelImage}
                                                                    alt="image-path"
                                                                />
                                                                {editBlog && (
                                                                    <input
                                                                        type="button"
                                                                        value="x"
                                                                        className="btn-rmv1 rmv"
                                                                        onClick={() =>
                                                                            handleImageDeleteModalShow(imageId)
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">{getLabel("LBL_Select_Tags*")}</label>
                                        <Select
                                            isMulti
                                            name="blogTags"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            aria-label="Default select example"
                                            defaultValue={
                                                blogData.blogTags && Object.keys(blogData.blogTags).length > 0 &&
                                                blogData.blogTags.map((selectedTags) => ({
                                                    value: selectedTags["btt_tag_id"],
                                                    label: selectedTags["tags.btag_title"],
                                                }))
                                            }
                                            options={tagList && tagList.map((tag) => ({
                                                value: tag.btag_id,
                                                label: tag.btag_title,
                                            }))}
                                            onChange={(selectedOptions) => {
                                                formik.setFieldValue("blogTags", selectedOptions);
                                            }}
                                            isDisabled={!editBlog}
                                        />
                                        <div>
                                            {formik.touched.blogTags && formik.errors.blogTags && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.blogTags}</p>
                                            )}
                                        </div>
                                    </div>
                                    <label className="form-label" htmlFor="basic-default-message">
                                        {getLabel("LBL_Description")}
                                    </label>
                                    <JoditEditor
                                        ref={editor}
                                        value={formik.values.blog_long_description}
                                        config={{
                                            readonly: editBlog ? false : true,
                                            toolbarAdaptive: false,
                                            uploader: {
                                                insertImageAsBase64URI: true,
                                                imagesExtensions: ["jpg", "jpeg", "png", "gif"],
                                                filesVariableName: "blockImages",
                                                path: baseUrl,
                                                prepareData: (formdata) => {
                                                    formdata.append("additionalParam", "value");
                                                    return formdata;
                                                },
                                            },
                                        }}
                                        tabIndex={1}
                                        onBlur={(newContent) => { formik.handleChange({ target: { name: "blog_long_description", value: newContent } }); }}
                                        onChange={newContent => { }}
                                    />
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">
                                            {getLabel("LBL_Status*")}
                                        </label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="blog_status"
                                            name="blog_status"
                                            onChange={handleChange}
                                            value={formik.values.blog_status}
                                            ref={el => fieldRefs.current.blog_status = el}
                                            disabled={!editBlog}
                                        >
                                            <option value="">{getLabel("LBL_Choose")}</option>
                                            <option value="1">{getLabel("LBL_Active")}</option>
                                            <option value="0">{getLabel("LBL_InActive")}</option>
                                        </select>
                                        <div>
                                            {formik.touched.blog_status && formik.errors.blog_status && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.blog_status}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {editBlog && (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFormClose}>
                                {getLabel("LBL_Close")}
                            </Button>
                            <Button variant="primary" type="submit">
                                {getLabel("LBL_Submit_Blog")}
                            </Button>
                        </Modal.Footer>
                    )}
                </form>
            </Modal>
            <ImageDeleteModal
                imageDeleteModalshow={imageDeleteModalshow}
                handleImageDeleteModalClose={handleImageDeleteModalClose}
                handleDeleteImage={handleDeleteImage}
            />
        </>
    );
};

export default AddUpdateBlog;
