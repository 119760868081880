import React from 'react'
import Pagination from '../../../common/reactPaginate';
import { getLabel } from '../../../common/getLabel';
import { getUserProfileStyles } from '../../../common/common';
import ArchiveSkeletonLoading from '../../common/skeleton-loading/archive-skeleton-loading';

const ArchiveListing = (props) => {
    const { error, archives, loading, totalCount, handlePaginate, currentPage, limit, handleArchiveData } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <div className="container-fluid custom-width">
            <div className="row archive">
                {loading ? (
                    <ArchiveSkeletonLoading length={12} />
                ) : error ? (
                    <h4 style={{ textAlign: "center" }}>
                        {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
                    </h4>
                ) : (
                    <>
                        {archives && archives.length > 0 ? (
                            archives.map((archive, index) => {
                                const archiveData = {
                                    style: archive.rank_style,
                                    class: archive.rank_grad_class,
                                    year: archive.rank_year,
                                    month: archive.rank_month
                                }
                                return (
                                    <div className="col-lg-3 col-sm-6 my-5" key={index}>
                                        <div className="archive-box">
                                            <a onClick={() => handleArchiveData(archiveData)}>
                                                <div className="archive-box-title">QBHL TOP 200</div>
                                                <div className="archive-box-text">Class of</div>
                                                <div className="articles-box-year">{archive.rank_grad_class}</div>
                                                <div className="dual-threat">{getUserProfileStyles[archive.rank_style]}</div>
                                                <p>Updated {archive.rank_month}/{archive.rank_year}</p>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <h4 className="my-4" style={{ textAlign: "center" }}>
                                {getLabel("LBL_No_result_found")}
                            </h4>
                        )}
                    </>
                )}
                {archives && archives.length > 0 && (
                    <>
                        <div className="col-lg-12">
                            <div className="custom-pagination">
                                <nav aria-label="...">
                                    <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
                                </nav>

                            </div>
                        </div>
                    </>
                )}

            </div>

        </div>
    )
}

export default ArchiveListing
