import SearchBar from "./searchBar";
import UpadteProfile from "./updateProfile";
import { getProfilebyId } from "../../services/profile/action";
import { useState, useEffect } from "react";
import UpdateProfilePassword from "./updateProfilePassword";
import { LoadingIndicator } from "../../../common/loadingIndicator";
const ProfileIndex = () => {
    const storedAdminDetails = localStorage.getItem('adminDetails');
    const [loading, setLoading] = useState(true);
    const parsedAdminDetails = JSON.parse(storedAdminDetails);
    const admin_id = parsedAdminDetails ? parsedAdminDetails.admin_id : null;
    const [initialValues, setInitialValues] = useState({
        "admin_name": "",
        "admin_email": "",
        "Images": "",
    });
    useEffect(() => {
        getProfile();
    }, []);
    const [formshow, setFormShow] = useState(false);
    const handleFormShow = async () => {
        setFormShow(true);
    };

    const getProfile = async (setLocalStoreItems = false) => {
        try {
            const response = await getProfilebyId(admin_id);
            const profileImg = response.data.attachment ? response.data.attachment : "";
            setInitialValues({
                "admin_id": response.data.admin_id,
                "admin_name": response.data.admin_name,
                "admin_email": response.data.admin_email,
                "Images": profileImg,
            });
            setLoading(false);
            if (setLocalStoreItems) {
                const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
                adminDetails.admin_name = response.data.admin_name;
                if (profileImg && Object.keys(profileImg).length > 0) {
                    adminDetails.admin_image = profileImg.afile_physical_path;
                }
                localStorage.setItem('adminDetails', JSON.stringify(adminDetails));
            }
        } catch (error) { }
    };

    const keysLength = Object.keys(initialValues).length;

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar handleFormShow={handleFormShow} />
                    {loading ? (
                        <LoadingIndicator />
                    ) : (
                        <>
                            {keysLength == 4 && (
                                <UpadteProfile
                                    initialValues={initialValues}
                                    refetch={getProfile}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
            {formshow && (
                <UpdateProfilePassword
                    formshow={formshow}
                    setFormShow={setFormShow}
                    admin_id={admin_id}
                />
            )}
        </>
    );
};

export default ProfileIndex;
