import { EVENTS_LOADING, EVENTS_ERROR, EVENTS_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingEvents = (payload) => ({
    type: EVENTS_LOADING,
    payload,
});

const handleErrorEvents = () => ({
    type: EVENTS_ERROR,
});

const handleSuccessEvents = (payload) => ({
    type: EVENTS_SUCCESS,
    payload,
});

const getDataEvents = (data) => async (dispatch) => {
    dispatch(handleLoadingEvents());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/event-search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessEvents(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in User Profile listing", error);
        dispatch(handleErrorEvents());
    }
};

const setUpEvents = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/event-setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};



const deleteEvent = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/event-delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export {
    getDataEvents,
    setUpEvents,
    deleteEvent
};
