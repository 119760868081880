import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getLabel } from "../../common/getLabel";
import { getDataMetaTags, getMetaTagbyId } from "../../services/meta-tag/action.jsx";
import DefaultListing from "./defaultListing.jsx";
import MetaTagListing from "./metaTagListing.jsx";
import UpdateMetaTag from "./updateMetaTag.jsx";
import { getPrivileges } from "../../common/adminPrivilege";
import { Link } from "react-router-dom";
import {
    HOME_PAGE_META_TYPE,
    CMS_PAGE_META_TYPE,
    PROFILE_PAGE_META_TYPE,
    TRAINER_PAGE_META_TYPE,
    BLOG_PAGE_META_TYPE,
    BLOG_DETAIL_PAGE_META_TYPE,
    BLOG_CATEGORY_PAGE_META_TYPE,
    BLOG_TAG_PAGE_META_TYPE
} from "../../../frontend/common/common.js";
import { getAdminPaginationLimit } from "../../../common/common.js";


const MetaTagIndex = () => {
    const dispatch = useDispatch();
    const [metaData, setMetaData] = useState('');
    const [advanceTags, setAdvanceTags] = useState(0);
    const [activePage, setActivePage] = useState(HOME_PAGE_META_TYPE);
    const { metaTagListing, totalCount } = useSelector((state) => state.metaTag);
    const loading = useSelector((state) => state.metaTag.loading);
    const error = useSelector((state) => state.metaTag.error);
    const getPrivilege = getPrivileges();
    const editMetaTags = getPrivilege.canEditMetaTags();
    const limit = getAdminPaginationLimit();
    const [currentPage, setCurrentPage] = useState(1);
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: "",
        metaType: activePage,
        advance_tag: advanceTags
    };
    const [filterdata, setFilterData] = useState(requestBody);
    useEffect(() => {
        dispatch(getDataMetaTags(requestBody));
    }, [activePage]);
    const [formshow, setFormShow] = useState(false);
    const handleFormClose = () => {
        setFormShow(false);
        setMetaData('');
    };
    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getMetaTagbyId(id);
                const result = response.data;
                setMetaData(result);
            }
        } catch (error) { }
        setFormShow(true);
    };

    const handleAdvancedTags = () => {
        setAdvanceTags(1);
        setActivePage(0);
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            metaType: 0,
            advance_tag: 1
        };
        setFilterData(updatedFilterData);
    }

    const handleMetaType = (type) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            metaType: type,
            advance_tag: 0
        };
        setFilterData(updatedFilterData);
        setActivePage(type);
    }

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataMetaTags(updatedFilterData));
    };

    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataMetaTags(updatedFilterData));
        }
    };

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataMetaTags(updatedFilterData));
    };

    return (
        <>
            <div className="bg-whites mt-4 meta--section">
                <div className="container-fluid inner-section">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 col-12">
                            <div className="card flex-fill">
                                <div className="card-iner-text-notification">
                                    <h3>{getLabel("LBL_Meta_Tags")}</h3>
                                    <div className="notification-detail">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/admin/dashboard">{getLabel("LBL_Home")} </Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                {getLabel("LBL_Meta_Tags")}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card flex-fills-user mt-4">
                                        <div className="new-ab-custom">
                                            <ul className="nav nav-tabs mb-3 " id="ex1" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link active"
                                                        id="default_tags"
                                                        data-bs-toggle="tab"
                                                        href="#default_tags_tabs"
                                                        role="tab"
                                                        aria-controls="default_tags_tabs"
                                                        aria-selected="true"
                                                        onClick={() => handleMetaType(HOME_PAGE_META_TYPE)}
                                                    >
                                                        {getLabel("LBL_Default")}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="cms_tags"
                                                        data-bs-toggle="tab"
                                                        href="#cms_tags_tabs"
                                                        role="tab"
                                                        aria-controls="cms_tags_tabs"
                                                        aria-selected="false"
                                                        onClick={() => handleMetaType(CMS_PAGE_META_TYPE)}
                                                    >
                                                        {getLabel("LBL_CMS_Page")}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="profile_tags"
                                                        data-bs-toggle="tab"
                                                        href="#profile_tags_tabs"
                                                        role="tab"
                                                        aria-controls="profile_tags_tabs"
                                                        aria-selected="false"
                                                        onClick={() => handleMetaType(PROFILE_PAGE_META_TYPE)}
                                                    >
                                                        {getLabel("LBL_Profile_Page")}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="trainer_tags"
                                                        data-bs-toggle="tab"
                                                        href="#trainer_tags_tabs"
                                                        role="tab"
                                                        aria-controls="trainer_tags_tabs"
                                                        aria-selected="false"
                                                        onClick={() => handleMetaType(TRAINER_PAGE_META_TYPE)}
                                                    >
                                                        {getLabel("LBL_Trainer_Page")}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="blog_tags"
                                                        data-bs-toggle="tab"
                                                        href="#blog_tags_tabs"
                                                        role="tab"
                                                        aria-controls="blog_tags_tabs"
                                                        aria-selected="false"
                                                        onClick={() => handleMetaType(BLOG_PAGE_META_TYPE)}
                                                    >
                                                        {getLabel("LBL_Blog_Page")}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="blog_post_tags"
                                                        data-bs-toggle="tab"
                                                        href="#blog_post_tags_tabs"
                                                        role="tab"
                                                        aria-controls="blog_post_tags_tabs"
                                                        aria-selected="false"
                                                        onClick={() => handleMetaType(BLOG_DETAIL_PAGE_META_TYPE)}
                                                    >
                                                        {getLabel("LBL_Blog_Detail")}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="blog_cat_tags"
                                                        data-bs-toggle="tab"
                                                        href="#blog_cat_tags_tabs"
                                                        role="tab"
                                                        aria-controls="blog_cat_tags_tabs"
                                                        aria-selected="false"
                                                        onClick={() => handleMetaType(BLOG_CATEGORY_PAGE_META_TYPE)}
                                                    >
                                                        {getLabel("LBL_Blog_Category")}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="blog_tag_tags"
                                                        data-bs-toggle="tab"
                                                        href="#blog_tag_tags_tabs"
                                                        role="tab"
                                                        aria-controls="blog_tag_tags_tabs"
                                                        aria-selected="false"
                                                        onClick={() => handleMetaType(BLOG_TAG_PAGE_META_TYPE)}
                                                    >
                                                        {getLabel("LBL_Blog_Tag")}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="other_tags"
                                                        data-bs-toggle="tab"
                                                        href="#other_tags_tabs"
                                                        role="tab"
                                                        aria-controls="other_tags_tabs"
                                                        aria-selected="false"
                                                        onClick={() => handleAdvancedTags()}
                                                    >
                                                        {getLabel("LBL_Other_Tag")}
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="ex1-content">
                                                <div className="tab-pane fade active show" id="default_tags_tabs" role="tabpanel" aria-labelledby="default_tags" >
                                                    <DefaultListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        handleFormClose={handleFormClose}
                                                        formshow={formshow}
                                                        activePage={activePage}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="cms_tags_tabs" role="tabpanel" aria-labelledby="cms_tags" >
                                                    <MetaTagListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        totalCount={totalCount}
                                                        limit={limit}
                                                        handlePaginate={handlePaginate}
                                                        activePage={activePage}
                                                        handleFilterUpdate={handleFilterUpdate}
                                                        handleFilter={handleFilter}
                                                        filterdata={filterdata}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="profile_tags_tabs" role="tabpanel" aria-labelledby="profile_tags" >
                                                    <DefaultListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        handleFormClose={handleFormClose}
                                                        formshow={formshow}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="trainer_tags_tabs" role="tabpanel" aria-labelledby="trainer_tags" >
                                                    <DefaultListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        handleFormClose={handleFormClose}
                                                        formshow={formshow}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="blog_tags_tabs" role="tabpanel" aria-labelledby="blog_tags" >
                                                    <DefaultListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        handleFormClose={handleFormClose}
                                                        formshow={formshow}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="blog_post_tags_tabs" role="tabpanel" aria-labelledby="blog_post_tags" >
                                                    <MetaTagListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        totalCount={totalCount}
                                                        limit={limit}
                                                        handlePaginate={handlePaginate}
                                                        activePage={activePage}
                                                        handleFilterUpdate={handleFilterUpdate}
                                                        handleFilter={handleFilter}
                                                        filterdata={filterdata}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="blog_cat_tags_tabs" role="tabpanel" aria-labelledby="blog_cat_tags" >
                                                    <MetaTagListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        totalCount={totalCount}
                                                        limit={limit}
                                                        handlePaginate={handlePaginate}
                                                        activePage={activePage}
                                                        handleFilterUpdate={handleFilterUpdate}
                                                        handleFilter={handleFilter}
                                                        filterdata={filterdata}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="blog_tag_tags_tabs" role="tabpanel" aria-labelledby="blog_tag_tags" >
                                                    <MetaTagListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        totalCount={totalCount}
                                                        limit={limit}
                                                        handlePaginate={handlePaginate}
                                                        activePage={activePage}
                                                        handleFilterUpdate={handleFilterUpdate}
                                                        handleFilter={handleFilter}
                                                        filterdata={filterdata}
                                                    />
                                                </div>
                                                <div className="tab-pane fade" id="other_tags_tabs" role="tabpanel" aria-labelledby="other_tags" >
                                                    <DefaultListing
                                                        metaTagListing={metaTagListing}
                                                        currentPage={currentPage}
                                                        loading={loading}
                                                        error={error}
                                                        handleFormShow={handleFormShow}
                                                        handleFormClose={handleFormClose}
                                                        formshow={formshow}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {formshow && (
                <UpdateMetaTag
                    handleFormClose={handleFormClose}
                    formshow={formshow}
                    metaData={metaData}
                    activePage={activePage}
                    editMetaTags={editMetaTags}
                />
            )}
        </>
    );
};

export default MetaTagIndex;
