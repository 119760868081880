import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { faqCategorySchema } from '../../common/validation';
import { addUpdateFaqCategory, getDataFaqCategories } from '../../services/faq-category/action';
import { useDispatch } from 'react-redux';

const AddUpdateFaqCategory = (props) => {
  const { formshow, handleFormClose, editFaq, faqCategoryData } = props;
    const dispatch = useDispatch()

    const initialValues = {
        id: (faqCategoryData)?faqCategoryData.fcat_id:'',
        title: (faqCategoryData)?faqCategoryData.fcat_title:'',
        status: (faqCategoryData)?faqCategoryData.fcat_status:'',
    };
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: faqCategorySchema,
        validateOnChange:false,
        onSubmit: async (values) => {
            try {
                const response = await addUpdateFaqCategory(values);
                if (response === true) {
                    dispatch(getDataFaqCategories())
                    handleFormClose();
                }
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    return (

        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Faq_Form")}</h5>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Faq_Category_Title*")}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="title"
                                                name="title"
                                                onChange={handleChange}
                                                value={formik.values.title}
                                                placeholder={getLabel("LBL_Faq_Category_Title")}
                                                disabled={!editFaq}
                                            />
                                            <div>
                                                {formik.touched.title && formik.errors.title && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.title}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">{getLabel("LBL_Faq_Category_Status*")}</label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="status"
                                            name="status"
                                            onChange={handleChange}
                                            value={formik.values.status}
                                            disabled={!editFaq}
                                        >
                                            <option value="" >
                                                {getLabel("LBL_Choose")}
                                            </option>
                                            <option value="1">{getLabel("LBL_Active")}</option>
                                            <option value="0">{getLabel("LBL_InActive")}</option>
                                        </select>
                                        <div>
                                            {formik.touched.status && formik.errors.status && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.status}</p>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFormClose}>
                            {getLabel("LBL_Close")}
                        </Button>
                        {editFaq && (
                            <Button variant="primary" type="submit">{getLabel("LBL_Submit_Faq_Category")}</Button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AddUpdateFaqCategory
