import React from 'react'
import { useFormik } from "formik";
import { newletterSchema } from '../../common/validation';
import { getLabel } from '../../../common/getLabel';

const Newsletter = () => {
    const initialValues = {
        FNAME: "",
        EMAIL: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: newletterSchema,
        validateOnChange: true,
        onSubmit: (values) => {
            document.getElementById("mc-embedded-subscribe-form").submit();
        },
    })
    const handleChange = (e) => {
        formik.handleChange(e)
    };
    return (
        <>
            <div className="news-letter">
                <form onSubmit={formik.handleSubmit} action="https://qbhitlist.us19.list-manage.com/subscribe/post?u=3a6b82f1ce98fb3573cb419a2&amp;id=50ad233f00" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">
                    <div className="news-letter-title">{getLabel('LBL_INBOX_UPDATES')}</div>
                    <div className="news-letter-content">
                        <p>{getLabel('LBL_Subscribe_to_our_e-newsletter_today_and_never_miss_a_moment!')} </p>
                        <div className="form-group">
                            <input type="text" name="FNAME" onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} className="form-control" placeholder="First Name" onChange={handleChange} />
                            {formik.touched.FNAME && formik.errors.FNAME && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.FNAME}</span>
                            )}
                        </div>
                        <div className="form-group email">
                            <input type="text" name="EMAIL" className="form-control" placeholder="Email Address" onChange={handleChange} />
                            {formik.touched.EMAIL && formik.errors.EMAIL && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.EMAIL}</span>
                            )}
                        </div>
                        <div className="clear">
                            <input type="submit" value="SIGN UP!" name="subscribe" className="button" />
                        </div>
                        <p className="disclaimer">{getLabel('LBL_NEWSLETTER_BOTTOM_TEXT.')}</p>
                    </div>
                </form >
            </div>
        </>
    )
}

export default Newsletter
