// ProtectedRoute.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children, user_type }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (user_type == 4) {
      navigate('/qbhl-admin/account'); // Redirect to account settings
    }
  }, [user_type, navigate]);

  return user_type !== 4 ? children : null; // Render children only if user_type is not 4
};

export default ProtectedRoute;
