import {
  MANAGE_USER_ERROR,
  MANAGE_USER_SUCCESS,
  MANAGE_USER_LOADING,
} from "./type";

const initState = { loading: true, error: false, manageUsers: [] };

const manageUserReducer = (state = initState, action) => {
  switch (action.type) {
    case MANAGE_USER_LOADING:
      return { ...state, loading: true };
    case MANAGE_USER_ERROR:
      return { ...state, loading: false, error: true };
    case MANAGE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        manageUsers: action.payload.usersDetails,
        totalCount: action.payload.totoalRecords
        ,   
      };
    default:
      return state;
  }
};

export { manageUserReducer };
