import { getLabel } from "../../common/getLabel";
import { useDelayedSearch } from "../../common/common";
import { useState } from "react";
import CustomDatePicker from "../../../common/CustomDatePicker";
import { getUserTypes, handleTimeZoneFormat } from "../../../common/common";
const SearchBar = (props) => {
    const {
        handleSelectedStatusUpdate,
        handleFilterUpdate,
        filterdata,
        handleFilter,
        handleClear,
        selectedCheckboxes,
        deleteModalOpen,
        handleExportFormShow,
        editUsers
    } = props;
    const [searchItem, setSearchItem] = useState("");
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate('keyword', event.target.value, false);
    };

    const [startDate, setStartDate] = useState(filterdata.date_from ? new Date(filterdata.date_from) : null);
    const [endDate, setEndDate] = useState(filterdata.date_to ? new Date(filterdata.date_to) : null);

    const handleStartDateChange = date => {
        setStartDate(date);
        handleFilterUpdate('date_from', handleTimeZoneFormat(date), false);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
        handleFilterUpdate('date_to', handleTimeZoneFormat(date), false);
    };

    const handleCancel = () => {
        setSearchItem("");
        setStartDate(null);
        setEndDate(null);
        handleFilterUpdate('date_from', '', false);
        handleFilterUpdate('date_to', '', false);
        handleClear();
    };
    const userStatus = filterdata.status !== undefined ? filterdata.status : "";
    const userType = filterdata.user_type !== undefined ? filterdata.user_type : "";
    return (
        <div className="row pb-5">
            <div className="col leftspace">
                <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
                    <input className="search-input" name="keyword" placeholder={getLabel("LBL_Search_by_Keyword...")} style={{ width: "20%" }} value={searchItem} onChange={(e) => { handleInputChange(e) }} />
                    <a
                        className="btn btn-primary"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        href="#collapseExample"
                    >
                        <i className="bx bx-chevrons-down"></i>
                    </a>
                    <div className="card-button">
                        <ul>
                            <li>
                                <button
                                    type="submit"
                                    name="btn_submit"
                                    className="btn btn-brand btn-wide btn-search"
                                    onClick={()=>handleFilter}
                                >
                                    {getLabel("LBL_Search")}{" "}
                                </button>
                                <button
                                    type="button"
                                    name="btn_clear"
                                    className="btn btn-link btn_clear"
                                    onClick={(e) => {
                                        handleCancel();
                                    }}
                                >
                                    {getLabel("LBL_Clear")}
                                </button>
                            </li>
                            {editUsers && (
                                <>
                                    <li>
                                        <a
                                            className="btntoltip"
                                            title=""
                                            data-bs-toggle="popover"
                                            data-bs-placement="top"
                                            data-bs-original-title="Mark as active"
                                            onClick={() => handleSelectedStatusUpdate(1)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                style={{ fill: "#ccc", transform: "", msFilter: "" }}
                                            >
                                                <path d="M8 9c-1.628 0-3 1.372-3 3s1.372 3 3 3 3-1.372 3-3-1.372-3-3-3z"></path>
                                                <path d="M16 6H8c-3.3 0-5.989 2.689-6 6v.016A6.01 6.01 0 0 0 8 18h8a6.01 6.01 0 0 0 6-5.994V12c-.009-3.309-2.699-6-6-6zm0 10H8a4.006 4.006 0 0 1-4-3.99C4.004 9.799 5.798 8 8 8h8c2.202 0 3.996 1.799 4 4.006A4.007 4.007 0 0 1 16 16zm4-3.984.443-.004.557.004h-1z"></path>
                                            </svg>
                                            {getLabel("LBL_Activate")}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="btntoltip"
                                            title=""
                                            data-bs-toggle="popover"
                                            data-bs-placement="top"
                                            data-bs-original-title="Mark as active"
                                            onClick={() => handleSelectedStatusUpdate(0)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                style={{ fill: "#ccc", transform: "", msFilter: "" }}
                                            >
                                                <path d="M8 9c-1.628 0-3 1.372-3 3s1.372 3 3 3 3-1.372 3-3-1.372-3-3-3z"></path>
                                                <path d="M16 6H8c-3.3 0-5.989 2.689-6 6v.016A6.01 6.01 0 0 0 8 18h8a6.01 6.01 0 0 0 6-5.994V12c-.009-3.309-2.699-6-6-6zm0 10H8a4.006 4.006 0 0 1-4-3.99C4.004 9.799 5.798 8 8 8h8c2.202 0 3.996 1.799 4 4.006A4.007 4.007 0 0 1 16 16zm4-3.984.443-.004.557.004h-1z"></path>
                                            </svg>
                                            {getLabel("LBL_Deactivate")}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className={`btntoltip ${selectedCheckboxes.length === 0 ? "btn-disable" : ""
                                                }`}
                                            title="Mark as active"
                                            data-bs-toggle="popover"
                                            data-bs-placement="top"
                                            onClick={deleteModalOpen}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                style={{ fill: "#ccc", transform: "", msFilter: "" }}
                                            >
                                                <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                                                <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                                            </svg>
                                            {getLabel("LBL_Delete")}
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                            <div className="container-fluid inner-section">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-3 col-12">
                                        <div className="form-group">
                                            <label className="label">
                                                {getLabel("LBL_User's_status")}
                                            </label>
                                            <br></br>
                                            <select
                                                className="form-select user"
                                                aria-label="Default select example"
                                                value={userStatus}
                                                name="status"
                                                onChange={(e) =>
                                                    handleFilterUpdate(
                                                        e.target.name,
                                                        parseInt(e.target.value, 10)
                                                    )
                                                }
                                            >
                                                <option value="" disabled>
                                                    {getLabel("LBL_Choose")}
                                                </option>
                                                <option value="1">{getLabel("LBL_Active")} </option>
                                                <option value="0">{getLabel("LBL_InActive")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-3 col-12">
                                        <div className="form-group">
                                            <label className="label">
                                                {getLabel("LBL_User_Type")}
                                            </label>
                                            <br></br>
                                            <select
                                                className="form-select user"
                                                aria-label="Default select example"
                                                value={userType}
                                                name="user_type"
                                                onChange={(e) =>
                                                    handleFilterUpdate(
                                                        e.target.name,
                                                        parseInt(e.target.value, 10)
                                                    )
                                                }
                                            >
                                                <option value="" disabled>
                                                    {getLabel("LBL_Choose")}
                                                </option>
                                                {Object.keys(getUserTypes).map((key) => (
                                                    <option key={key} value={key}>
                                                        {getUserTypes[key]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-3 col-12">
                                        <div className="form-group">
                                            <label className="label">
                                                {getLabel("LBL_Reg._date_from")}
                                            </label>
                                            <br></br>
                                            <CustomDatePicker
                                                selectedDate={startDate}
                                                onDateChange={handleStartDateChange}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                id="birthday"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-3 col-12">
                                        <div className="form-group">
                                            <label className="label">
                                                {getLabel("LBL_Reg._date_to")}
                                            </label>
                                            <br></br>
                                            <CustomDatePicker
                                                selectedDate={endDate}
                                                onDateChange={handleEndDateChange}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                id="birthday"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {editUsers && (
                <div className="col-md-4 header--action">
                    <button type="button" className="btn btn-primary float-right" onClick={handleExportFormShow} >
                        <i className="bx bx-export"></i> {getLabel("LBL_Export_Users")}
                    </button>
                </div>
            )}
        </div>
    );
};

export default SearchBar;
