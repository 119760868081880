import UpdateOffer from './updateOffer'
import Listing from './listing'
import SearchBar from './SearchBar'
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getPrivileges } from '../../common/adminPrivilege';
import DeleteModal from "../../common/deleteModal";
import { getDataOffers, deleteOffer } from '../../services/manage-offers/action'
import { getAdminPaginationLimit } from '../../../common/common'
import { update } from 'lodash'

const OfferIndex = () => {
    const [offerId, setOfferId] = useState(false);
    const [formshow, setFormShow] = useState(false);
    const [deleteid, setDeleteId] = useState(null);
    const [deleteshow, setDeleteShow] = useState(false);
    const getPrivilege = getPrivileges();
    const editOffers = getPrivilege.canEditOffers();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = getAdminPaginationLimit();
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: ""
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch()
    const { offers, totalCount } = useSelector((state) => state.offers)
    const loading = useSelector((state) => state.offers.loading)
    const error = useSelector((state) => state.offers.error)

    useEffect(() => {
        dispatch(getDataOffers(requestBody))
    }, []);

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                setOfferId(id);
            }
        } catch (error) { }
        setFormShow(true);
    }
    const handleFormClose = () => {
        setOfferId(false);
        setFormShow(false);
    }
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataOffers(updatedFilterData))
    };
    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };
    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
            page: 1
        };
        if (name == 'keyword') {
            updatedFilterData.offer_college = "";
        }
        if (name == 'offer_college') {
            updatedFilterData.keyword = "";
        }
        setCurrentPage(1);
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataOffers(updatedFilterData))
        }
    };

    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id);
    };

    const handleDeleteClose = () => {
        setDeleteShow(false);
    };

    const handleDelete = async () => {
        try {
            let data;
            if (typeof deleteid === "number") {
                data = [deleteid];
            }
            await deleteOffer({ "offer_id": data });
            dispatch(getDataOffers(requestBody));
            handleDeleteClose();
        } catch (error) { }
    };

    const offerTypes = {
        "1": "Offer",
        "2": "Offer / Preferred Walk-On",
    }

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataOffers(updatedFilterData));
    };
    const handleClear = () => {
        let updatedFilterData = {
            ...requestBody,
            page: 1,
        }
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        dispatch(getDataOffers(updatedFilterData));
    };

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFilterUpdate={handleFilterUpdate}
                        handleFormShow={handleFormShow}
                        editOffers={editOffers}
                        offerTypes={offerTypes}
                        handleFilter={handleFilter}
                        handleClear={handleClear}
                    />
                    <Listing
                        offers={offers}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        handleFormShow={handleFormShow}
                        handlePaginate={handlePaginate}
                        calculateSerialNumber={calculateSerialNumber}
                        limit={limit}
                        editOffers={editOffers}
                        deleteModalOpen={deleteModalOpen}
                        offerTypes={offerTypes}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            {formshow &&
                <UpdateOffer
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    requestBody={requestBody}
                    offerId={offerId}
                    editOffers={editOffers}
                    offerTypes={offerTypes}
                />
            }
            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
        </>
    )
}

export default OfferIndex
