import { legacy_createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { authReducer } from "./services/authentication/reducer";
import { labelReducer } from "./services/label/reducer";
import { bannerReducer } from "./services/manage-banner/reducer";
import { faqReducer } from "./services/faq/reducer";
import { contentBlockReducer } from "./services/content-block/reducer";
import { contentPageReducer } from "./services/content-page/reducer";
import { generalSettingReducer } from "./services/general-settings/reducer";
import { blogCategoryReducer } from "./services/blog-category/reducer";
import { blogTagReducer } from "./services/blog-tag/reducer";
import { blogReducer } from "./services/blog/reducer";
import { navigationMenuReducer } from "./services/navigation/reducer";
import { manageUserReducer } from "./services/manage-users/reducer";
import { manageSubAdminReducer } from "./services/manage-subadmin/reducer";
import { orderCancelReducer } from "./services/manage-order-cancel/reducer";
import { orderReturnReducer } from "./services/manage-order-return/reducer";
import { seoUrlReducer } from "./services/url-rewriting/reducer";
import { metaTagReducer } from "./services/meta-tag/reducer";
import loadLabelReducer from "../common-services/labels/reducer";
import { faqCategoryReducer } from "./services/faq-category/reducer";
import { userProfileReducer } from "./services/manage-user-profiles/reducer";
import { schoolLogosReducer } from "./services/manage-school-logos/reducer";
import { offersReducer } from "./services/manage-offers/reducer";
import { eventsReducer } from "./services/manage-events/reducer";
import { rankingReducer } from "./services/manage-rankings/reducer";
import { orderReducer } from "./services/manage-orders/reducer";
import loadOrderStatus from "../common-services/order-status/reducer";
import { dashboardReducer } from "./services/dashboard/reducer";
import { couponReducer } from "./services/manage-coupons/reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    label: labelReducer,
    loadLabel: loadLabelReducer,
    banner: bannerReducer,
    faqCategory: faqCategoryReducer,
    faq: faqReducer,
    contentBlock: contentBlockReducer,
    contentPage: contentPageReducer,
    generalSetting: generalSettingReducer,
    blogCategory: blogCategoryReducer,
    blogTag: blogTagReducer,
    blog: blogReducer,
    navigationMenu: navigationMenuReducer,
    manageUser: manageUserReducer,
    manageSubAdmin: manageSubAdminReducer,
    orderCancelReason: orderCancelReducer,
    orderReturnReason: orderReturnReducer,
    seoUrl: seoUrlReducer,
    metaTag: metaTagReducer,
    profiles: userProfileReducer,
    logos: schoolLogosReducer,
    offers: offersReducer,
    events: eventsReducer,
    rankings: rankingReducer,
    orders: orderReducer,
    orderStatus: loadOrderStatus,
    dashboardData: dashboardReducer,
    coupons: couponReducer,
})


const store = legacy_createStore(rootReducer, applyMiddleware(thunk))
export { store } 