import { getLabel } from "../../common/getLabel";
import { useFormik } from "formik";
import { useState, useRef } from "react";
import { updateProfile } from "../../services/profile/action";
import { profileSchema } from "../../common/validation";
import { baseUrl } from "../../common/config";
import { ImageDeleteModal } from "../../common/imageDeleteModal";
import { deleteSubAdminImage } from "../../services/manage-subadmin/action";
const UpadteProfile = (props) => {
    const { initialValues, refetch } = props;
    const [profileImage, setProfileImage] = useState(null);
    const fileInputRef = useRef(null);
    const [imageDeleteId, setImageDeletedId] = useState();
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const fileId = initialValues.Images && Object.keys(initialValues.Images).length > 0 && initialValues.Images.afile_id;
    const imageId = initialValues.Images && Object.keys(initialValues.Images).length > 0 && initialValues.Images.afile_record_id;

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: profileSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("admin_id", values.admin_id);
            formData.append("admin_name", values.admin_name);
            formData.append("admin_image", profileImage);
            formData.append("afile_id", fileId);
            try {
                await updateProfile(formData);
                setProfileImage(null);
                refetch(true);
            } catch (error) { }
        },
    });

    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const handleCancelImage = () => {
        setProfileImage("");
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleImageDeleteModalShow = (id) => {
        setImageDeletedId(id);
        setImageDeleteModalShow(true);
    };

    const handleDeleteImage = async () => {
        try {
            const data = {
                admin_id: imageId,
                afile_id: fileId,
                afile_record_id: imageId,
            };
            await deleteSubAdminImage(data);
            handleImageDeleteModalClose();
            const Name = "image_list_" + imageDeleteId;
            document.querySelector("." + Name).remove();
            initialValues.Images = "";
            const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
            delete adminDetails.admin_image;
            localStorage.setItem('adminDetails', JSON.stringify(adminDetails));
        } catch (error) { }
    };

    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false);

    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group col-md-12 pb-3">
                        <label htmlFor="input">{getLabel("LBL_Name*")}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Name")}
                            id="admin_name"
                            name="admin_name"
                            value={formik.values.admin_name}
                            onChange={handleChange}
                        />
                        <div>
                            {formik.touched.admin_name && formik.errors.admin_name && (
                                <p style={{ color: "#b94a48" }}>{formik.errors.admin_name}</p>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-12 pb-3">
                        <label htmlFor="input">{getLabel("LBL_Email")}</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder={getLabel("LBL_Email")}
                            id="admin_email"
                            name="admin_email"
                            value={formik.values.admin_email}
                            onChange={handleChange}
                            disabled={true}
                        />
                        <div>
                            {formik.touched.admin_email && formik.errors.admin_email && (
                                <p style={{ color: "#b94a48" }}>{formik.errors.admin_email}</p>
                            )}
                        </div>
                    </div>
                    <div className="input-group file col-md-6">
                        <label className="custom">{getLabel("LBL_User_Image")}</label>
                        <input
                            ref={fileInputRef}
                            className="form-control"
                            type="file"
                            onChange={(e) => setProfileImage(e.target.files[0])}
                        />
                        <label className="input-group-text" htmlFor="inputGroupFile03">
                            {getLabel("LBL_Upload")}
                        </label>
                    </div>
                    <div className="main-div">
                        {profileImage ? (
                            <img
                                src={URL.createObjectURL(profileImage)}
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "50%",
                                }}
                                onClick={handleCancelImage}
                                alt="image-path-broked"
                            />
                        ) : (
                            <div className="show--image">
                                <div className={`image_list_${imageId} mt-2`}>
                                    {initialValues.Images && Object.keys(initialValues.Images).length > 0 ? (
                                        <div>
                                            <img
                                                src={`${baseUrl}/${initialValues.Images.afile_physical_path}`}
                                                style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    borderRadius: "50%",
                                                }}
                                                onClick={handleCancelImage}
                                            />
                                            <input
                                                type="button"
                                                value="x"
                                                className="btn-rmv1 rmv"
                                                onClick={() => handleImageDeleteModalShow(imageId)}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <img
                                                src="/images/man.png"
                                                style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="form-group col-md-12 mt-4">
                        <button className="btn btn-sm btn-secondary" type="submit">
                            {getLabel("LBL_Submit")}
                        </button>
                    </div>
                </form>
            </div>
            <ImageDeleteModal
                imageDeleteModalshow={imageDeleteModalshow}
                handleImageDeleteModalClose={handleImageDeleteModalClose}
                handleDeleteImage={handleDeleteImage}
            />
        </>
    );
};

export default UpadteProfile;
