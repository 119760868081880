import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { subUserSchema } from "../../common/validation";
import { useFormik } from "formik";
import { useState } from "react";
import { updatePassword } from "../../services/manage-users/action";
const ChangePasswordModal = (props) => {
  const { creadientFormShow, userId , setCreadientalFormShow} = props;
  const [initialValues, setInitialValues] = useState({
    user_password: "",
    confirm_password: "",
  });
   const formik = useFormik({
    initialValues: initialValues,
    validationSchema: subUserSchema,
    onSubmit: async (values , { resetForm }) => {
      const data = {
        new_password: values.user_password,
        confirm_password: values.confirm_password,
        id: userId,
      };
      try {
        await updatePassword(data);
        handleCreadientalFormClose();
        resetForm();
      } catch (error) {}
    },
  });
  const handleChange = (e) => {
    formik.handleChange(e);
  };
  const handleCreadientalFormClose = () => {
    setCreadientalFormShow(false);
     formik.resetForm();
  };
  return (
    <>
      <Modal
        show={creadientFormShow}
        onHide={handleCreadientalFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Change_Password")}
          </h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_New_Password*")}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        id="user_password"
                        name="user_password"
                        onChange={handleChange}
                        value={formik.values.user_password}
                        autoComplete="off"
                      />
                      <div>
                        {formik.touched.user_password &&
                          formik.errors.user_password && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.user_password}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_Confirm_Password*")}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        onChange={handleChange}
                        value={formik.values.confirm_password}
                        autoComplete="off"
                      />
                      <div>
                        {formik.touched.confirm_password &&
                          formik.errors.confirm_password && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.confirm_password}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCreadientalFormClose}>
              {getLabel("LBL_Cancel")} 
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Update")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
