import { BANNER_LOADING, BANNER_ERROR, BANNER_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";
const handleLoadingBanner = (payload) => ({
    type: BANNER_LOADING,
    payload,
});

const handleErrorBanner = () => ({
    type: BANNER_ERROR,
});

const handleSuccessBanner = (payload) => ({
    type: BANNER_SUCCESS,
    payload,
});

const getDataBanners = (data) => async (dispatch) => {
    dispatch(handleLoadingBanner());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/banner/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessBanner(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in tag listing", error);
        dispatch(handleErrorBanner());
    }
};

const getBannerbyId = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios
            .get(`/banner/${id}`, { headers: headers() })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};


const updateBanner = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/banner/setup", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteBannerImage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post("/banner/delete/image", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateBannerStatus = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/banner/status-update", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteBanner = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/banner/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};


export {
    getDataBanners,
    getBannerbyId,
    updateBanner,
    deleteBannerImage,
    updateBannerStatus,
    deleteBanner
};
