import * as Yup from "yup";
import { getLabel } from "../../common/getLabel";
export const loginSchema = Yup.object().shape({
    username: Yup.string().required(() => getLabel('LBL_Required_user_name')),
    userPassword: Yup.string().required(() => getLabel('LBL_Required_password'))
});
const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const signUpSchema = Yup.object().shape({
    first_name: Yup.string().required(() => getLabel('LBL_Required_First_Name')),
    last_name: Yup.string().required(() => getLabel('LBL_Required_last_name')),
    user_email: Yup.string()
        .matches(emailRegex, () => getLabel("LBL_Invalid_Email_Address"))
        .email(() => getLabel("LBL_Invalid_Email_Address"))
        .required(() => getLabel("LBL_E-Mail_Address_Is_Required")),
    username: Yup.string().required(() => getLabel('LBL_Required_Username')),
    user_type: Yup.string().required(() => getLabel('LBL_Required_User_Type')),
    user_password: Yup.string().min(8, () => getLabel('LBL_Password must be 8 characters long'))
        .matches(/[0-9]/, () => getLabel('LBL_Password_requires_a_number'))
        .matches(/[a-z]/, () => getLabel('LBL_Password_requires_a_lowercase_letter'))
        .matches(/[A-Z]/, () => getLabel('LBL_Password_requires_an_uppercase_letter'))
        .matches(/[^\w]/, () => getLabel('LBL_Password_requires_a_symbol'))
        .required(() => getLabel('LBL_Required_Password')),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('user_password'), null], () => getLabel('LBL_Must_match_password_field_value'))
        .required(() => getLabel('LBL_Required_confirm_Password')),
    terms_condition: Yup.bool().oneOf([true], getLabel('LBL_Please_accept_Terms_and_Condition')).required(getLabel('LBL_Please_accept_Terms_and_Condition'))
});

export const forgotPasswordSchema = Yup.object().shape({
    user_email: Yup.string().email(() => getLabel("LBL_Invalid_email_Address")).required(() => getLabel("LBL_Email_Address_Is_Required")),
})
export const contactUsSchema = Yup.object().shape({
    first_name: Yup.string().required(() => getLabel('LBL_Required_First_Name')),
    last_name: Yup.string().required(() => getLabel('LBL_Required_last_name')),
    contact_email: Yup.string().matches(emailRegex, () => getLabel("LBL_Invalid_Email_Address")).email(() => getLabel("LBL_Invalid_email_Address")).required(() => getLabel("LBL_E-Mail_Address_Is_Required")),
    contact_comment: Yup.string().required(() => getLabel('LBL_Required_Comment')),
})
export const changePasswordSchema = Yup.object().shape({
    new_password: Yup.string().min(8, () => getLabel('LBL_Password must be 8 characters long'))
        .matches(/[0-9]/, () => getLabel('LBL_Password_requires_a_number'))
        .matches(/[a-z]/, () => getLabel('LBL_Password_requires_a_lowercase_letter'))
        .matches(/[A-Z]/, () => getLabel('LBL_Password_requires_an_uppercase_letter'))
        .matches(/[^\w]/, () => getLabel('LBL_Password_requires_a_symbol'))
        .required(() => getLabel('LBL_Required_Password')),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], () => getLabel('LBL_Password_is_not_matched'))
        .required(() => getLabel('LBL_Required_confirm_Password')),
})
export const profileSchema = Yup.object().shape({
    first_name: Yup.string().required(() => getLabel('LBL_Required_First_Name')),
    last_name: Yup.string().required(() => getLabel('LBL_Required_last_name')),
    style: Yup.string().required(() => getLabel('LBL_Required_Position')),
    hs_name: Yup.string().required(() => getLabel('LBL_Required_School_name')),
    hs_city: Yup.string().required(() => getLabel('LBL_Required_City')),
    hs_state: Yup.string().required(() => getLabel('LBL_Required_State')),
    grad_class: Yup.string().required(() => getLabel('LBL_Required_Graduation_Class')),
    email: Yup.string().email(() => getLabel("LBL_Invalid_E-Mail")),
    weight: Yup.number()
        .min(75, getLabel("LBL_This_value_should_be_between_75_and_400."))
        .max(400, getLabel("LBL_This_value_should_be_between_75_and_400."))
        .required(() => getLabel('LBL_Required_Weight')),
    height_feet: Yup.number().required(() => getLabel('LBL_Required_Height_feet')),
    height_inch: Yup.number().required(() => getLabel('LBL_Required_Height_inch')),
    act_score: Yup.number()
        .typeError(getLabel("LBL_Please_enter_a_valid_number."))
        .min(1, getLabel("LBL_This_value_should_be_between_1_and_36."))
        .max(36, getLabel("LBL_This_value_should_be_between_1_and_36.")),
    sat_score: Yup.number()
        .typeError(getLabel("LBL_Please_enter_a_valid_number."))
        .min(400, getLabel("LBL_This_value_should_be_between_400_and_1600."))
        .max(1600, getLabel("LBL_This_value_should_be_between_400_and_1600.")),
    gpa_score: Yup.number()
        .typeError(getLabel("LBL_Please_enter_a_valid_number."))
        .min(0, getLabel("LBL_This_value_should_be_between_0_and_5."))
        .max(5, getLabel("LBL_This_value_should_be_between_0_and_5.")),
})
export const trainerProfileSchema = Yup.object().shape({
    first_name: Yup.string().required(() => getLabel('LBL_Required_First_Name')),
    last_name: Yup.string().required(() => getLabel('LBL_Required_last_name')),
    company_name: Yup.string().required(() => getLabel('LBL_Required_Business_Name')),
    address: Yup.string().required(() => getLabel('LBL_Required_Address')),
    city: Yup.string().required(() => getLabel('LBL_Required_City')),
    state_code: Yup.string().required(() => getLabel('LBL_Required_State')),
    zip: Yup.string().required(() => getLabel('LBL_Required_Zip')),
    phone_no: Yup.string().required(() => getLabel('LBL_Required_Phone')),
    email: Yup.string().email(() => getLabel("LBL_Invalid_E-Mail")).required(() => getLabel("LBL_E-Mail_Address_Is_Required")),
})
export const trainerAdSchema = Yup.object().shape({
    title: Yup.string().required(() => getLabel('LBL_Required_Title')),
    link: Yup.string().required(() => getLabel('LBL_Required_Link')),
})
export const accountSettingSchema = Yup.object().shape({
    user_email: Yup.string().email(() => getLabel("LBL_Invalid_E-Mail")).required(() => getLabel("LBL_E-Mail_Address_Is_Required")),
    user_password: Yup.string().min(8, () => getLabel('LBL_Password must be 8 characters long'))
        .matches(/[0-9]/, () => getLabel('LBL_Password_requires_a_number'))
        .matches(/[a-z]/, () => getLabel('LBL_Password_requires_a_lowercase_letter'))
        .matches(/[A-Z]/, () => getLabel('LBL_Password_requires_an_uppercase_letter'))
        .matches(/[^\w]/, () => getLabel('LBL_Password_requires_a_symbol'))
})
export const memberShipSchema = Yup.object().shape({
    addressOne: Yup.string().required(() => getLabel("LBL_address_is_required")),
    city: Yup.string().required(() => getLabel("LBL_city_is_required")),
    state: Yup.string().required(() => getLabel("LBL_state_is_required")),
    zip: Yup.number().required(() => getLabel("LBL_zip_is_required")),
    phoneNumber: Yup.string().required(() => getLabel("LBL_number_is_required")),
    cardName: Yup.string().required(() => getLabel("LBL_card_name_is_required")),
    cardNumber: Yup.number().required(() => getLabel("LBL_card_number_is_required")),
    expire: Yup.string().required(() => getLabel("LBL_card_expire_is_required")),
    securityCode: Yup.string().required(() => getLabel("LBL_security_code_is_required")),
    promoCode: Yup.string(),
    subscriptionType: Yup.string().required(() => getLabel("LBL_sub_type_is_required")),
    amount: Yup.string().required(() => getLabel("LBL_amount_is_required")),
    profileId: Yup.number()
})

export const updateBillingSchema = Yup.object().shape({
    addressOne: Yup.string().required(() => getLabel("LBL_address_is_required")),
    city: Yup.string().required(() => getLabel("LBL_city_is_required")),
    state: Yup.string().required(() => getLabel("LBL_state_is_required")),
    zip: Yup.number().required(() => getLabel("LBL_zip_is_required")),
    cardName: Yup.string().required(() => getLabel("LBL_card_name_is_required")),
    cardNumber: Yup.number().required(() => getLabel("LBL_card_number_is_required")),
    expire: Yup.string().required(() => getLabel("LBL_card_expire_is_required")),
    securityCode: Yup.string().required(() => getLabel("LBL_security_code_is_required")),
    amount: Yup.string().required(() => getLabel("LBL_amount_is_required")),
})

export const newletterSchema = Yup.object().shape({
    FNAME: Yup.string().required(() => getLabel('LBL_Required_Name')),
    EMAIL: Yup.string().email(() => getLabel("LBL_Invalid_email_Address")).required(() => getLabel("LBL_Email_Address_Is_Required")),
})

export const eventSetupSchema = Yup.array().of(Yup.object({
    profile_id: Yup.number().required(() => getLabel("LBL_PROFILE_ID_IS_REQUIRED")),
    title: Yup.string().required(() => getLabel("LBL_Title_is_required")),
    start_date: Yup.date(() => getLabel("LBL_INVALID_DATE_FORMAT")).required(() => getLabel("LBL_start_date_is_required")),
}));