import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "./type";
import { errorMessage, successMessage, displayProcessing } from "../../common/message";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";

const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    payload: token,
});

const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
});

const logout = () => ({
    type: LOGOUT,
});

export const login = (data) => async (dispatch) => {
    const device_id = data.device_id;
    displayProcessing();
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/login", data)
                .then(async (res) => {
                    if (res.data.success === true) {
                        const token = res.data.data.token;
                        const user_id = res.data.data.user_id;
                        const user_fname = res.data.data.user_fname;
                        const user_type = res.data.data.user_type;
                        let actionTime = new Date().getTime();
                        const ttl = 24 * 60 * 60 * 1000;
                        const userDetails = {
                            tokenTime: actionTime,
                            token: token,
                            user_id: user_id,
                            user_fname: user_fname,
                            user_type: user_type,
                            device_id: device_id,
                            isLogged: true,
                            expiry: actionTime + ttl,
                        }
                        localStorage.setItem('userDetails', JSON.stringify(userDetails));

                        dispatch(loginSuccess(token));
                        successMessage(resolve, res);
                        return;
                    }
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });

    } catch (error) {
        dispatch(loginFailure());
    }
};

export const signup = async (data) => {
    displayProcessing();
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/signup", data)
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export const accountVerify = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/verify-account", data)
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export const forgotPassword = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/forgot-password", data)
            .then((res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export const changePassword = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/reset-password", data)
            .then((res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export const doLogout = () => (dispatch) => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {
        const data = { id: userDetails.user_id, device_id: userDetails.device_id }
        try {
            return new Promise((resolve, reject) => {
                myAxios
                    .post("/user/logout", data, { headers: headers() })
                    .then(async (res) => {

                        if (res.data.success === true) {
                            localStorage.removeItem('userDetails');
                            dispatch(logout());
                            return;
                        }
                        successMessage(resolve, res);
                    })
                    .catch((error) => {
                        localStorage.removeItem('userDetails');
                        dispatch(logout());
                        errorMessage(error, reject);
                    });
            });

        } catch (error) {
            dispatch(loginFailure());
        }
    }
};

export const isLoggedIn = () => {
    try {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        let token = userDetails ? userDetails.token : null;
        let tokenTime = userDetails ? userDetails.tokenTime : null;
        if (token && tokenTime) {
            return true;
        }
        return false;
    } catch (error) { }
}