import React from 'react'
import { Link } from "react-router-dom"
import Pagination from '../../../common/reactPaginate';
import { getLabel } from '../../../common/getLabel';
import { formatDate, truncateText } from '../../../common/common';
import { baseUrl } from '../../common/config';
import BlogSkeletonLoading from '../../common/skeleton-loading/blog-skeleton-loading';

const Search = (props) => {
    const { error, blogs, loading, totalCount, handlePaginate, currentPage, limit, defaultBlogImg } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <div className="row news-detail-space">
                {loading ? (
                    <BlogSkeletonLoading length={12} />
                ) : error ? (
                    <h3 style={{ textAlign: "center" }}>
                        {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
                    </h3>
                ) : (
                    <>
                        {blogs && blogs.length > 0 ? (
                            blogs.map((blog, index) => (
                                <div key={index} className="col-lg-4 col-sm-6">
                                    <div className="news-listing">
                                        <div className="new-image">
                                            <figure>
                                                <Link to={`/${blog["blogUrl.urlrewrite_custom"]}`}>
                                                    <img
                                                        src={`${baseUrl}/${(blog.images?.[0]?.afile_physical_path || defaultBlogImg[0].afile_physical_path)}`}
                                                        className="img-fluid"
                                                        alt={blog.images && blog.images[0] && blog.images[0].afile_name ? blog.images[0].afile_name : blog.blog_title}
                                                    />
                                                </Link>
                                            </figure>
                                        </div>
                                        <h5><Link to={`/${blog["blogUrl.urlrewrite_custom"]}`}>{blog.blog_title}</Link></h5>
                                        <time dateTime={formatDate(blog.blog_added_at)} pubdate=""><i className='bx bx-calendar'></i> {formatDate(blog.blog_added_at)}</time>
                                        {blog.blog_short_description && (
                                            <p><span>Written by {blog.blog_author_name}</span> {truncateText(blog.blog_short_description, 20)}</p>
                                        )}
                                        <Link to={`/${blog["blogUrl.urlrewrite_custom"]}`} className="btn btn-primary ">Read More</Link>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" style={{ textAlign: "center" }}>
                                    {getLabel("LBL_No_result_found")}
                                </td>
                            </tr>
                        )}
                        {blogs && blogs.length > 0 && (
                            <>
                                <div className="custom-pagination new-listing">
                                    <nav>
                                        <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
                                    </nav>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default Search
