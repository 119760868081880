import { Link } from "react-router-dom"
import { getLabel } from "../../common/getLabel";
import { getDasboardData } from "../../services/dashboard/action";
import { useEffect, useState } from "react";
import { formatDate, getUserProfileStyles, getUserTypes } from "../../../common/common";
import TableSkeleton from "../../common/TableSkeleton";
import getPrivileges from "../../common/adminPrivilege";

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        handleDashboardData();
    }, []);
    const handleDashboardData = async () => {
        const response = await getDasboardData();
        setDashboardData(response);
        setLoading(false);
    }
    const profiles = dashboardData ? dashboardData.profiles : [];
    const premium = dashboardData ? dashboardData.premium : [];
    const tariners = dashboardData ? dashboardData.tariners : [];
    const users = dashboardData ? dashboardData.users : [];
    const profileCount = dashboardData ? dashboardData.profileCount : 0;
    const PremiumProfileCount = dashboardData ? dashboardData.PremiumProfileCount : 0;
    const trainerCount = dashboardData ? dashboardData.trainerCount : 0;
    const userCount = dashboardData ? dashboardData.userCount : 0;
    const getPrivilege = getPrivileges();
    const viewUserProfile = getPrivilege.canViewUserProfiles();
    const viewUsers = getPrivilege.canViewUser();
    return (
        <>
            <div className="container-fluid inner-section mt-4 dashboard-section">
                <div className="row">
                    {viewUserProfile && (
                        <>
                            <div className="col-lg-3 col-sm-6 col-12">
                                <div className="dash-count">
                                    <div className="dash-counts">
                                        <h4>{profileCount}</h4>
                                        <h5>{getLabel("LBL_profiles")}</h5>
                                    </div>
                                    <div className="dash-imgs">
                                        <i className='bx bx-user'></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12">
                                <div className="dash-count das1">
                                    <div className="dash-counts">
                                        <h4>{PremiumProfileCount}</h4>
                                        <h5>{getLabel("LBL_Premium_Profiles")}</h5>
                                    </div>
                                    <div className="dash-imgs">
                                        <i className='bx bx-user'></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12">
                                <div className="dash-count das2">
                                    <div className="dash-counts">
                                        <h4>{trainerCount}</h4>
                                        <h5>{getLabel("LBL_Trainers")}</h5>
                                    </div>
                                    <div className="dash-imgs">
                                        <i className='bx bx-user'></i>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {viewUsers && (
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="dash-count das3">
                                <div className="dash-counts">
                                    <h4>{userCount}</h4>
                                    <h5>{getLabel("LBL_Users")}</h5>
                                </div>
                                <div className="dash-imgs">
                                    <i className='bx bx-user'></i>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/*start ecommmerce inner section  */}

            <div className="container-fluid inner-section dashboard-section">
                <div className="row">
                    {viewUserProfile && (
                        <>
                            <div className="col-md-12 mt-4">
                                <div className="card flex-fill">
                                    <div className="card-iner-text">
                                        <h3>{getLabel("LBL_Recent_Profiles")}</h3>
                                        <Link className="btn btn-success dashboard--btn" to="/admin/user-profile">View All</Link>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><strong>{getLabel("LBL_First_Name")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Last_Name")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Email")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Class")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Style")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Created_at")}</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? (
                                                    <>
                                                        <TableSkeleton cards={10} count={6} />
                                                    </>
                                                ) : (
                                                    <>
                                                        {profiles && profiles.length > 0 && profiles.map((elem, index) => (
                                                            <tr key={index}>
                                                                <td>{elem.uprofile_fname}</td>
                                                                <td>{elem.uprofile_lname}</td>
                                                                <td>{elem.uprofile_email}</td>
                                                                <td>{elem.uprofile_grad_class}</td>
                                                                <td>{getUserProfileStyles[elem.uprofile_style]}</td>
                                                                <td>{formatDate(elem.uprofile_added_at)}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-4 dashboard-section">
                                <div className="card flex-fill">
                                    <div className="card-iner-text">
                                        <h3>{getLabel("LBL_Premium_Profiles")}</h3>
                                        <Link className="btn btn-success dashboard--btn" to="/admin/user-profile?premium=1">View All</Link>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><strong>{getLabel("LBL_Order_id")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_First_Name")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Last_Name")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Email")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Class")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Style")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Created_at")}</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? (
                                                    <>
                                                        <TableSkeleton cards={10} count={7} />
                                                    </>
                                                ) : (
                                                    <>
                                                        {premium && premium.length > 0 && premium.map((elem, index) => (
                                                            <tr key={index}>
                                                                <td>#{elem["orderProfile.order_invoice_number"]}</td>
                                                                <td>{elem.uprofile_fname}</td>
                                                                <td>{elem.uprofile_lname}</td>
                                                                <td>{elem.uprofile_email}</td>
                                                                <td>{elem.uprofile_grad_class}</td>
                                                                <td>{getUserProfileStyles[elem.uprofile_style]}</td>
                                                                <td>{formatDate(elem.uprofile_added_at)}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-4 dashboard-section">
                                <div className="card flex-fill">
                                    <div className="card-iner-text">
                                        <h3>{getLabel("LBL_Trainers")}</h3>
                                        <Link className="btn btn-success dashboard--btn" to="/admin/trainers">View All</Link>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><strong>{getLabel("LBL_First_Name")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Last_Name")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Email")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Company")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Website")}</strong></th>
                                                    <th scope="col"><strong>{getLabel("LBL_Created_at")}</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? (
                                                    <>
                                                        <TableSkeleton cards={10} count={6} />
                                                    </>
                                                ) : (
                                                    <>
                                                        {tariners && tariners.length > 0 && tariners.map((elem, index) => (
                                                            <tr key={index}>
                                                                <td>{elem.uprofile_fname}</td>
                                                                <td>{elem.uprofile_lname}</td>
                                                                <td>{elem.uprofile_email}</td>
                                                                <td>{elem.uprofile_company_name}</td>
                                                                <td>{elem.uprofile_website}</td>
                                                                <td>{formatDate(elem.uprofile_added_at)}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {viewUsers && (
                        <div className="col-md-12 mt-4">
                            <div className="card flex-fill">
                                <div className="card-iner-text">
                                    <h3>{getLabel("LBL_Users")}</h3>
                                    <Link className="btn btn-success dashboard--btn" to="/admin/users">View All</Link>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"><strong>{getLabel("LBL_Username")}</strong></th>
                                                <th scope="col"><strong>{getLabel("LBL_First_Name")}</strong></th>
                                                <th scope="col"><strong>{getLabel("LBL_Last_Name")}</strong></th>
                                                <th scope="col"><strong>{getLabel("LBL_Type")}</strong></th>
                                                <th scope="col"><strong>{getLabel("LBL_Email")}</strong></th>
                                                <th scope="col"><strong>{getLabel("LBL_Verified_Status")}</strong></th>
                                                <th scope="col"><strong>{getLabel("LBL_Added_at")}</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <>
                                                    <TableSkeleton cards={10} count={7} />
                                                </>
                                            ) : (
                                                <>
                                                    {users && users.length > 0 && users.map((elem, index) => (
                                                        <tr key={index}>
                                                            <td>{elem.user_username}</td>
                                                            <td>{elem.user_fname}</td>
                                                            <td>{elem.user_lname}</td>
                                                            <td>{getUserTypes[elem.user_type]}</td>
                                                            <td>{elem.user_email}</td>
                                                            <td><span className={`badge badge-success ${elem.user_is_active == 1 ? 'badge0' : 'badge2'}`}>{elem.user_is_active == 1 ? 'Active' : 'In Active'}</span></td>
                                                            <td>{formatDate(elem.user_added_at)}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}



export default Dashboard