import {
  NAVIGATION_MENU_ERROR,
  NAVIGATION_MENU_LOADING,
  NAVIGATION_MENU_SUCCESS,
  NAVIGATION_SUB_MENU_SUCCESS,
} from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";

const handleLoadingNavigationMenu = (payload) => ({
  type: NAVIGATION_MENU_LOADING,
  payload,
});
const handleErrorNavigationMenu = () => ({
  type: NAVIGATION_MENU_ERROR,
});
const handleSuccessNavigationMenu = (payload) => ({
  type: NAVIGATION_MENU_SUCCESS,
  payload,
});

const handleSuccessSubNavigationMenu = (payload) => ({
  type: NAVIGATION_SUB_MENU_SUCCESS,
  payload,
});

const getDataNavigationMenues = (data) => async (dispatch) => {
  dispatch(handleLoadingNavigationMenu());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/navigation/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessNavigationMenu(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    dispatch(handleErrorNavigationMenu());
  }
};

const getNavigationbyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/navigations/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateNavigationMenu = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/navigation/setup", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const getNavigationDescriptionbyId = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post(`/navigation/link-search`, data, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const getSubNavigationByNavId = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post(`/navigation/link-search`, data, { headers: headers() })
      .then((res) => {
        dispatch(handleSuccessSubNavigationMenu(res.data.data.navLinkMenu));
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const getSingleMenubyId = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post(`/navigation/link`, data, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteMenubyId = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/navigation/delete-link", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateMenuStatus = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/navigation/status", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const updateNavigationLink = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/navigation/link-setup", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const productManufactureListing = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/product/manufacturer`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
export {
  getDataNavigationMenues,
  getNavigationbyId,
  updateNavigationMenu,
  getNavigationDescriptionbyId,
  getSingleMenubyId,
  deleteMenubyId,
  updateMenuStatus,
  updateNavigationLink,
  productManufactureListing,
  getSubNavigationByNavId
};
