import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getDataGeneralSettings } from "../../services/general-settings/action";
import GeneralPage from "./generalPage.jsx";
import AdddressPage from "./addressPage.jsx";
import MediaPage from "./mediaPage.jsx";
import ThirdPartyPage from "./thirdPartyPage.jsx";
import SocialMediaPage from "./socialMediaPage.jsx";
import EmailConfigrationPage from "./emailConfigrationPage.jsx";
import { getLabel } from "../../common/getLabel.js";
import { getPrivileges } from '../../common/adminPrivilege';
import { Link } from "react-router-dom"

const GeneralSettingIndex = () => {
    const dispatch = useDispatch();
    const getPrivilege = getPrivileges();
    const editSetting = getPrivilege.canEditConfiguration();

    const { generalSettings } = useSelector(
        (state) => state.generalSetting
    );
    useEffect(() => {
        const data = { "attach_images": true }
        dispatch(getDataGeneralSettings(data));
    }, [dispatch]);
    const keysLength = Object.keys(generalSettings).length;
    return (
        <>
            <div className="container-fluid inner-section mt-4">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-12">
                        <div className="card flex-fill">
                            <div className="card-iner-text-notification">
                                <h3>{getLabel("LBL_General_Settings")}</h3>
                                <div className="notification-detail">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/admin/dashboard">{getLabel("LBL_Home")} </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            {getLabel("LBL_General_Settings")}
                                        </li>
                                    </ul>
                                </div>
                                <div className="card flex-fills-user mt-4">
                                    <div className="new-ab-custom">
                                        <ul className="nav nav-tabs mb-3 " id="ex1" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link active"
                                                    id="ex1-tab-1"
                                                    data-bs-toggle="tab"
                                                    href="#ex1-tabs-1"
                                                    role="tab"
                                                    aria-controls="ex1-tabs-1"
                                                    aria-selected="true"
                                                >
                                                    {getLabel("LBL_General")}
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="ex1-tab-2"
                                                    data-bs-toggle="tab"
                                                    href="#ex1-tabs-2"
                                                    role="tab"
                                                    aria-controls="ex1-tabs-2"
                                                    aria-selected="false"
                                                >
                                                    {getLabel("LBL_Address")}
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="ex1-tab-3"
                                                    data-bs-toggle="tab"
                                                    href="#ex1-tabs-3"
                                                    role="tab"
                                                    aria-controls="ex1-tabs-3"
                                                    aria-selected="false"
                                                >
                                                    {getLabel("LBL_Media")}
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="ex1-tab-4"
                                                    data-bs-toggle="tab"
                                                    href="#ex1-tabs-4"
                                                    role="tab"
                                                    aria-controls="ex1-tabs-4"
                                                    aria-selected="false"
                                                >
                                                    {getLabel("LBL_Third Party_API")}
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="ex1-tab-5"
                                                    data-bs-toggle="tab"
                                                    href="#ex1-tabs-5"
                                                    role="tab"
                                                    aria-controls="ex1-tabs-5"
                                                    aria-selected="false"
                                                >
                                                    {getLabel("LBL_Social_Media")}
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="ex1-tab-7"
                                                    data-bs-toggle="tab"
                                                    href="#ex1-tabs-7"
                                                    role="tab"
                                                    aria-controls="ex1-tabs-7"
                                                    aria-selected="false"
                                                >
                                                    {getLabel("LBL_Email_Configuration")}
                                                </a>
                                            </li>

                                        </ul>
                                        <div className="tab-content" id="ex1-content">
                                            <div
                                                className="tab-pane fade active show"
                                                id="ex1-tabs-1"
                                                role="tabpanel"
                                                aria-labelledby="ex1-tab-1"
                                            >
                                                {keysLength > 0 && (
                                                    <GeneralPage generalSettings={generalSettings} editSetting={editSetting} />
                                                )}
                                            </div>

                                            <div
                                                className="tab-pane fade"
                                                id="ex1-tabs-2"
                                                role="tabpanel"
                                                aria-labelledby="ex1-tab-2"
                                            >
                                                {keysLength > 0 && (
                                                    <AdddressPage generalSettings={generalSettings} editSetting={editSetting} />
                                                )}
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="ex1-tabs-3"
                                                role="tabpanel"
                                                aria-labelledby="ex1-tab-3"
                                            >
                                                {keysLength > 0 && (
                                                    <MediaPage generalSettings={generalSettings} editSetting={editSetting} />
                                                )}
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="ex1-tabs-4"
                                                role="tabpanel"
                                                aria-labelledby="ex1-tab-4"
                                            >
                                                {keysLength > 0 && (
                                                    <ThirdPartyPage generalSettings={generalSettings} editSetting={editSetting} />
                                                )}
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="ex1-tabs-5"
                                                role="tabpanel"
                                                aria-labelledby="ex1-tab-5"
                                            >
                                                {keysLength > 0 && (
                                                    <SocialMediaPage generalSettings={generalSettings} editSetting={editSetting} />
                                                )}
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="ex1-tabs-7"
                                                role="tabpanel"
                                                aria-labelledby="ex1-tab-7"
                                            >
                                                {keysLength > 0 && (
                                                    <EmailConfigrationPage
                                                        generalSettings={generalSettings}
                                                        editSetting={editSetting}
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="ex1-tabs-6"
                                                role="tabpanel"
                                                aria-labelledby="ex1-tab-6"
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GeneralSettingIndex;
