import { OFFERS_LOADING, OFFERS_ERROR, OFFERS_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingOffer = (payload) => ({
    type: OFFERS_LOADING,
    payload,
});

const handleErrorOffer = () => ({
    type: OFFERS_ERROR,
});

const handleSuccessOffer = (payload) => ({
    type: OFFERS_SUCCESS,
    payload,
});

const getDataOffers = (data) => async (dispatch) => {
    dispatch(handleLoadingOffer());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/get-offers", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessOffer(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in User Profile listing", error);
        dispatch(handleErrorOffer());
    }
};

const setUpOffer = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/setup-offers", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};



const deleteOffer = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/delete-offers", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export {
    getDataOffers,
    setUpOffer,
    deleteOffer
};
