import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { navigationMenuSchema } from "../../common/validation";
import { useFormik } from "formik";
import { useDispatch,useSelector } from "react-redux";
import {updateNavigationMenu,getDataNavigationMenues} from "../../services/navigation/action";
const AddUpdateNavigationMenu = (props) => {
  const { formshow, handleFormClose,  requestBody, navId } = props; 
  const navData = useSelector(state =>
    state.navigationMenu.navigationMenues.find(navData => navData.nav_id === navId)
  )
  const dispatch = useDispatch();
  const initialValues = {
    nav_id:(navData)?navData.nav_id:'0',
    nav_title: (navData)?navData.nav_title:'',
    nav_active: (navData)?navData.nav_active:'',        
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: navigationMenuSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        const response = await updateNavigationMenu(values);
        if (response === true) {
          dispatch(getDataNavigationMenues(requestBody));
          handleFormClose();
        }
      } catch (error) {}
    },
  });
  
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>
      <Modal
        show={formshow}
        onHide={handleFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Update_Navigation_Form")}
          </h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="input">{getLabel("LBL_Title*")}</label>
                      <input
                        className="form-control"
                        type="text"
                        id="nav_title"
                        name="nav_title"
                        onChange={handleChange}
                        value={formik.values.nav_title}
                        placeholder={getLabel("LBL_Title")}
                      />
                      <div>
                        {formik.touched.nav_title && formik.errors.nav_title && (
                          <p style={{ color: "#b94a48" }}>{formik.errors.nav_title}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputState">
                      {getLabel("LBL_Status*")}
                    </label>
                    <select
                      as="select"
                      className="form-select"
                      aria-label="Default select example"
                      id="nav_active"
                      name="nav_active"
                      onChange={handleChange}
                      value={formik.values.nav_active}
                    >
                      <option value="">{getLabel("LBL_Choose")}</option>
                      <option value="1">{getLabel("LBL_Active")}</option>
                      <option value="0">{getLabel("LBL_InActive")}</option>
                    </select>
                    <div>
                      {formik.touched.nav_active && formik.errors.nav_active && (
                        <p style={{ color: "#b94a48" }}>{formik.errors.nav_active}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFormClose}>
              {getLabel("LBL_Close")}
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Submit")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateNavigationMenu;
