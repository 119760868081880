import { RANKING_LOADING, RANKING_ERROR, RANKING_SUCCESS } from "./type";
const initState = {
    loading: true,
    error: false,
    profiles: [],
};

const rankingReducer = (state = initState, action) => {
    switch (action.type) {
        case RANKING_LOADING:
            return { ...state, loading: true };
        case RANKING_ERROR:
            return { ...state, loading: false, error: true };
        case RANKING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                rankings: action.payload.rows,
                totalCount: action.payload.count,
            };
        default:
            return state;
    }
};

export { rankingReducer };