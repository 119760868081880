import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BulletinSkeletonLoading = ({ length }) => {
    const baseColor = "#ebebeb";
    const highlightColor = "#cfcfcfab";
    return (
        Array.from({ length: length }, (_, i) => (
            <React.Fragment key={i}>
                <div className="col-lg-4 col-sm-4 mt-3">
                    <div className="buttetin-image">
                        <Skeleton baseColor={baseColor} highlightColor={highlightColor} height={228} style={{ lineHeight: "unset" }} />
                        <h5 className="mt-2"><Skeleton baseColor={baseColor} highlightColor={highlightColor} height={40} /></h5>
                    </div>
                </div>
            </React.Fragment>
        ))
    )
}

export default BulletinSkeletonLoading
