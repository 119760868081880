import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPrivileges } from '../../common/adminPrivilege';
import SearchBar from "./SearchBar";
import Listing from "./listing";
import { getDataRankings } from "../../services/manage-rankings/action";

const RankingIndex = () => {
    const getPrivilege = getPrivileges();
    const editRankings = getPrivilege.canEditRankings();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 200;
    const requestBody = {
        page: currentPage,
        limit: limit,
        top_ranking:true,
        style: "",
        grad_class: ""
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch()
    const { rankings, totalCount } = useSelector((state) => state.rankings)
    const loading = useSelector((state) => state.rankings.loading)
    const error = useSelector((state) => state.rankings.error)

    useEffect(() => {
        dispatch(getDataRankings(requestBody))
    }, []);

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataRankings(updatedFilterData))
    };
    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };
    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
            page: 1,
        };
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataRankings(updatedFilterData))
        }
    };

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataRankings(updatedFilterData));
    };

    return (
        <div className="bg-whites mt-4 pt-5">
            <div className="container-fluid inner-section">
                <SearchBar
                    handleFilterUpdate={handleFilterUpdate}
                    handleFilter={handleFilter}
                    editRankings={editRankings}
                    filterdata={filterdata}
                    rankings={rankings}
                />
                <Listing
                    rankings={rankings}
                    totalCount={totalCount}
                    loading={loading}
                    error={error}
                    handlePaginate={handlePaginate}
                    calculateSerialNumber={calculateSerialNumber}
                    limit={limit}
                    editRankings={editRankings}
                    currentPage={currentPage}
                />
            </div>
        </div>
    )
}

export default RankingIndex
