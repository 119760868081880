import { useDelayedSearch } from "../../common/common"
import { useState } from "react";
import { getLabel } from "../../common/getLabel"

const SearchBar = (props) => {
    const { handleFilterUpdate, handleFilter, handleClear } = props;
    const [searchItem, setSearchItem] = useState("")
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate('keyword', event.target.value, false);
    };

    const handleCancel = () => {
        setSearchItem("");
        handleClear();
    };

    return (
        <>
            <div className="row">
                <div className="col leftspace pb-5">
                    <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
                        <input className="search-input mx-4" placeholder={getLabel("LBL_Search_by_Label_Caption's")} name="keyword"
                            value={searchItem}
                            onChange={(e) => {
                                handleInputChange(e)
                            }}
                        />
                        <div className="card-button">
                            <ul>
                                <li>
                                    <button
                                        type="submit"
                                        name="btn_submit"
                                        className="btn btn-brand btn-wide btn-search"
                                        onClick={() => handleFilter}
                                    >
                                        {getLabel("LBL_Search")}{" "}
                                    </button>
                                    <button
                                        type="button"
                                        name="btn_clear"
                                        className="btn btn-link btn_clear"
                                        onClick={(e) => {
                                            handleCancel();
                                        }}
                                    >
                                        {getLabel("LBL_Clear")}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div >

            </div>
        </>
    )
}
export default SearchBar