import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { baseUrl } from '../../common/config';
import JoditEditor from "jodit-react";
import { useRef, useState, useEffect } from 'react';
import { updateContentPage, getDataContentPages, deleteContentPageImage } from '../../services/content-page/action';
import { useDispatch } from 'react-redux';
import { contentPageSchema } from '../../common/validation';
import { ImageDeleteModal } from '../../common/imageDeleteModal';

const AddUpdateContentPage = (props) => {
    const { pageContent, formshow, handleFormClose, editContentPages } = props
    const [cpImages, setCpImages] = useState(null)
    const editor = useRef(null);
    const fileInputRef = useRef(null);
    const [textEditors, setTextEditors] = useState([]);
    const [imageDeleteId, setImageDeletedId] = useState()
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);

    const imageId = pageContent && pageContent.attachments && Object.keys(pageContent.attachments).length > 0 && pageContent.attachments[0].afile_record_id
    const afileType = pageContent && pageContent.attachments && Object.keys(pageContent.attachments).length > 0 && pageContent.attachments[0].afile_type;
    const fileId = pageContent && pageContent.attachments && Object.keys(pageContent.attachments).length > 0 && pageContent.attachments[0].afile_id;

    const dispatch = useDispatch()
    useEffect(() => {
        if (pageContent && pageContent.pageBlocks.length > 0) {
            setTextEditors(pageContent.pageBlocks.map((block, index) => (
                getJodEditors(index, block.cpblock_text)
            )));
        } else {
            handleAddTextEditor();
        }
    }, [pageContent]);
    const initialValues = {
        id: (pageContent) ? pageContent.cpage_id : '',
        title: (pageContent) ? pageContent.cpage_title : '',
        banner_title: (pageContent) ? pageContent.cpage_banner_title : '',
        banner_content: (pageContent) ? pageContent.cpage_banner_content : '',
        banner_button_url: (pageContent) ? pageContent.cpage_banner_button_url : '',
        banner_button_target: (pageContent) ? pageContent.cpage_banner_button_target : '',
        banner_button: (pageContent) ? pageContent.cpage_banner_button : '',
    };



    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: contentPageSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("banner_button", values.banner_button);
            formData.append("banner_button_target", values.banner_button_target)
            formData.append("banner_button_url", values.banner_button_url)
            formData.append("title", values.title);
            formData.append("banner_title", values.banner_title);
            formData.append("banner_content", values.banner_content)
            const pageBlocksArray = values.pageBlocks ? values.pageBlocks : [];
            let isEditorHaveContent = false;
            pageBlocksArray.forEach((block, index) => {
                if (block !== '' && block !== undefined) {
                    formData.append(`content_block[${index}]`, block);
                    isEditorHaveContent = true;
                }
            });

            if (pageBlocksArray.length == 0 || isEditorHaveContent == false) {
                formData.append(`content_block[]`, '');
            }
            formData.append("cp_image", cpImages)
            if (typeof initialValues.id === "number") {
                formData.append("id", initialValues.id)
                formData.append("afile_id", fileId)
                formData.append("afileType", afileType)
            }
            if (editContentPages) {
                try {

                    await updateContentPage(formData)
                    handleFormClose()
                    dispatch(getDataContentPages())
                } catch (error) { }
            }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };
    const handleCancelImage = () => {
        setCpImages("")
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };
    const handleImageDeleteModalShow = (id) => {
        setImageDeletedId(id)
        setImageDeleteModalShow(true);
    }
    const handleDeleteImage = async () => {
        try {
            const data = { "afile_id": fileId }
            await deleteContentPageImage(data)
            handleImageDeleteModalClose()
            const Name = 'image_list_' + imageDeleteId;
            document.querySelector('.' + Name).remove();
        } catch (error) { }
    }
    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false)
    const handleAddTextEditor = () => {
        setTextEditors([...textEditors,
        getJodEditors(textEditors.length, '')
        ]);
    };

    const getJodEditors = (key, content) => {
        return <JoditEditor
            ref={editor}
            tabIndex={key}
            value={content}
            onChange={(value) =>
                formik.setFieldValue("pageBlocks[" + key + "]", value)
            }
            key={key}
            config={{
                readonly: editContentPages ? false : true,
                toolbarAdaptive: false,
                uploader: {
                    insertImageAsBase64URI: true,
                    imagesExtensions: ['jpg', 'jpeg', 'png', 'gif'],
                    filesVariableName: 'blockImages',
                    path: baseUrl,
                    prepareData: (formdata) => {
                        formdata.append('additionalParam', 'value');
                        return formdata;
                    },
                },
            }}
        />
    }

    const handleRemoveTextEditor = (event) => {
        if (textEditors.length > 0) {
            const keyToRemove = Number(event.target.getAttribute('data-key'));
            formik.setFieldValue("pageBlocks[" + keyToRemove + "]", '');
            const editor = 'text_editor_' + keyToRemove;
            document.querySelector('.' + editor).remove();
        }
    };

    return (

        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Add_Update_Page_Form")}</h5>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">

                                <div className="row">

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Title*")}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="title"
                                                onChange={handleChange}
                                                value={formik.values.title}
                                                placeholder={getLabel("LBL_Title")}
                                                disabled={!editContentPages}
                                            />
                                            <div>{formik.touched.title && formik.errors.title && <p style={{ color: "#b94a48" }}>{formik.errors.title}</p>}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input"> {getLabel("LBL_Banner_Title")} </label>
                                            <input
                                                type="text"
                                                name="banner_title"
                                                onChange={handleChange}
                                                value={formik.values.banner_title}
                                                className="form-control"
                                                placeholder={getLabel("LBL_Banner_Title")}
                                                disabled={!editContentPages}
                                            />
                                            <div>{formik.touched.banner_title && formik.errors.banner_title && <p style={{ color: "#b94a48" }}>{formik.errors.banner_title}</p>}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Button_Url")}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="banner_button_url"
                                                onChange={handleChange}
                                                value={formik.values.banner_button_url}
                                                placeholder={getLabel("LBL_Button_Url")}
                                                disabled={!editContentPages}
                                            />
                                            <div>{formik.touched.banner_button_url && formik.errors.banner_button_url && <p style={{ color: "#b94a48" }}>{formik.errors.banner_button_url}</p>}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Button_Label*")} </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="banner_button"
                                                onChange={handleChange}
                                                value={formik.values.banner_button}
                                                placeholder={getLabel("LBL_Button_Label")}
                                                disabled={!editContentPages}
                                            />
                                            <div>{formik.touched.banner_button && formik.errors.banner_button && <p style={{ color: "#b94a48" }}>{formik.errors.banner_button}</p>}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Background_image_description")}</label>
                                            <textarea
                                                className="form-control"
                                                name="banner_content"
                                                rows="3"
                                                required=""
                                                value={formik.values.banner_content}
                                                onChange={handleChange}
                                                placeholder={getLabel("LBL_Describe_Short_Description_here...")}
                                                disabled={!editContentPages}
                                            ></textarea>
                                            <div>{formik.touched.banner_content && formik.errors.banner_content && <p style={{ color: "#b94a48" }}>{formik.errors.banner_content}</p>}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input"> {getLabel("LBL_Background_Image")}</label>
                                            <input
                                                className="form-control"
                                                ref={fileInputRef}
                                                type="file"
                                                id="cpImages"
                                                name="cpImages"
                                                onChange={((e) => setCpImages(e.target.files[0]))}
                                                disabled={!editContentPages}
                                            />
                                        </div>
                                        <div className='main-div'>
                                            {cpImages ?
                                                (
                                                    <img src={URL.createObjectURL(cpImages)} style={{ height: "50px", width: "50px", borderRadius: "50%" }} onClick={handleCancelImage} alt="image-path ist" />
                                                ) : (
                                                    <div className={`image_list_${imageId}`}>
                                                        {pageContent.attachments
                                                            && pageContent.attachments
                                                                .length > 0 &&
                                                            <div>
                                                                <img
                                                                    src={`${baseUrl}/${pageContent.attachments[0].afile_physical_path}`}
                                                                    style={{ height: "50px", width: "50px", borderRadius: "50%" }} onClick={handleCancelImage} alt="image-path" />
                                                                {editContentPages && (
                                                                    <input
                                                                        type="button"
                                                                        value="x"
                                                                        className="btn-rmv1 rmv"
                                                                        onClick={(() => handleImageDeleteModalShow(imageId))} />
                                                                )}
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Open_in")}</label>
                                            <select
                                                as="select"
                                                className="form-select"
                                                aria-label="Default select example"
                                                name="banner_button_target"
                                                onChange={handleChange}
                                                disabled={!editContentPages}
                                                value={formik.values.banner_button_target}>
                                                <option value="">{getLabel("LBL_Choose")}</option>
                                                <option value="_self">{getLabel("LBL_Current_Window")}</option>
                                                <option value="_blank">{getLabel("LBL_New_Window")}</option>
                                            </select>
                                            <div>{formik.touched.banner_button_target && formik.errors.banner_button_target && <p style={{ color: "#b94a48" }}>{formik.errors.banner_button_target}</p>}</div>
                                        </div>
                                    </div>
                                    {/* text editor */}


                                    <label className="form-label" htmlFor="basic-default-message">
                                        {getLabel("LBL_Page_Content")}
                                    </label>

                                    {textEditors.map((editor, index) => (
                                        <div className={`text_editor_${index} mt-4`} key={index}>
                                            <div>{editor}</div>
                                            {editContentPages && (
                                                <Button variant="secondary" className="float-right mt-4" data-key={index} onClick={handleRemoveTextEditor} >
                                                    {getLabel("LBL_Remove_Content_Block")}
                                                </Button>
                                            )}
                                        </div>
                                    ))}
                                    {editContentPages && (
                                        <Button variant="primary" className="w-auto" onClick={handleAddTextEditor}>{getLabel("LBL_Add_Content_Block")}</Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFormClose}> {getLabel("LBL_Close")}</Button>
                        {editContentPages && (
                            <Button variant="primary" type="submit">{getLabel("LBL_Submit")}</Button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
            <ImageDeleteModal
                imageDeleteModalshow={imageDeleteModalshow}
                handleImageDeleteModalClose={handleImageDeleteModalClose}
                handleDeleteImage={handleDeleteImage} />
        </>
    )
}
export default AddUpdateContentPage