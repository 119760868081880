import React, { useEffect, useState } from 'react'
import { defineContentBlockKey } from '../../common/common'
import { getContentBlockbyKey } from '../../services/content-block/action'
import { renderHTML } from '../../../common/common'
import { LoadingIndicator } from '../../../common/loadingIndicator'

const Thankyou = () => {
    const [loading, setLoading] = useState(true);
    const contentBlockKey = defineContentBlockKey();
    const [thankYouContent, setThankYouContent] = useState(null);
    useEffect(() => {
        getContentBlocks();
    }, []);

    const getContentBlocks = async () => {
        const response = await getContentBlockbyKey({ "block_key": [contentBlockKey.thankYouKey] });
        if (response && response.length > 0) {
            setThankYouContent(response[0].cb_content);
        }
        setLoading(false);
    }

    return (
        <>
            {loading ? (
                <div className="membership membership-info thank-you">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 cms-skeleton">
                                <LoadingIndicator />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p dangerouslySetInnerHTML={renderHTML(thankYouContent)} />
            )}
        </>
    )
}

export default Thankyou
