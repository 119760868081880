import React, { useState, useEffect } from 'react';
import { LoadingIndicator } from '../../../common/loadingIndicator';
import { handleCronSchedule } from '../../services/cron/action';
import { useParams } from 'react-router-dom'

const CronIndex = () => {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState([]);
    const param = useParams();
    const command = param.command;
    useEffect(() => {
        const timer = setTimeout(() => {
            handleCronEvents();
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleCronEvents = async () => {
        const response = await handleCronSchedule({ command: command });
        setResponse(response.data);
        setLoading(false);
    }

    return (
        <>
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12 mx-auto">
                        <p className="lost">Cron Scheduler</p>
                        {loading ? (
                            <div className="col-md-12">
                                <LoadingIndicator />
                            </div>
                        ) : (
                            <>
                                {response && response.length > 0 && response.map((elem, index) => (
                                    <p key={index}>{elem.message ?? 'No Cron Function'} for {elem.endPoint}</p>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CronIndex;
