import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { orderCancelReasonSchema } from "../../common/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {updateOrderCancelReason,getDataOrderCancelReasons} from "../../services/manage-order-cancel/action";
const AddUpdateOrderCancel = (props) => {
  const { defineNames, initialValues, handleFormClose, formshow  , requestBody} = props;
  const { title } = defineNames;
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: orderCancelReasonSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateOrderCancelReason(values);
        if (response === true) {
          dispatch(getDataOrderCancelReasons(requestBody));
          handleFormClose();
        }
      } catch (error) {}
    },
  });
  const handleChange = (e) => {
    formik.handleChange(e);
  };
  return (
    <>
      <Modal
        show={formshow}
        onHide={handleFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Add_Update_Reason")}
          </h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_Reason_Name*")}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id={title}
                        name={title}
                        onChange={handleChange}
                        value={formik.values[title]}
                        placeholder={getLabel("LBL_Reason")}
                      />
                      <div>
                        {formik.touched[title] && formik.errors[title] && (
                          <p style={{ color: "#b94a48" }}>{formik.errors[title]}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFormClose}>
              {getLabel("LBL_Close")}
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Submit")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
export default AddUpdateOrderCancel;
