import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import SearchBar from './SearchBar'
import Listing from './listing'
import { getProfiles } from '../../services/profiles/action'
import { loadMetaTags, SITE_TITLE, TRAINER_PAGE_META_TYPE } from '../../common/common'

const Trainers = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 20;
    const requestBody = {
        page: currentPage,
        limit: limit,
        display_trainer: true,
        keyword: ""
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch();
    const { profiles, totalCount } = useSelector((state) => state.profiles);
    const loading = useSelector((state) => state.profiles.loading);
    const error = useSelector((state) => state.profiles.error);
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getProfiles(updatedFilterData));
    };

    const handleFilter = () => {
        const updatedFilterData = {
            ...filterdata,
        };
        dispatch(getProfiles(updatedFilterData));
    };

    const handleFilterUpdate = async (name, value) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
    };

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const metaTags = {
        meta_type: TRAINER_PAGE_META_TYPE,
        meta_title: `${site_title} | Promote Training To The Top QBs In The Country`,
        meta_record_id: 0
    }

    useEffect(() => {
        loadMetaTags(metaTags);
        dispatch(getProfiles(requestBody));
        // const delaySearch = setTimeout(() => {
        // }, 1000);
        // return () => clearTimeout(delaySearch);
    }, []);
    return (
        <>
            <div className="player-list-tranier">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <h2>Trainers</h2>
                            </div>
                        </div>

                        <SearchBar
                            handleFilterUpdate={handleFilterUpdate}
                            handleFilter={handleFilter}
                            filterdata={filterdata}
                        />

                        <Listing
                            error={error}
                            profiles={profiles}
                            loading={loading}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            limit={limit}
                            handlePaginate={handlePaginate}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Trainers
