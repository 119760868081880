import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { useDispatch, useSelector } from 'react-redux';
import { getDataOffers, setUpOffer } from '../../services/manage-offers/action'
import CustomDatePicker from '../../../common/CustomDatePicker';
import { useState } from 'react';
import { handleTimeZoneFormat } from '../../../common/common';

const UpdateOffer = (props) => {
    const { formshow, handleFormClose, requestBody, offerId, editOffers, offerTypes } = props
    const offerData = useSelector(state => {
        const offers = state.offers.offers;
        if (offers && offers.length > 0) {
            return offers.find(offers => offers.poffer_id === offerId);
        }
        return null;
    });
    const dispatch = useDispatch();

    let formattedDate = '';
    if(offerData && offerData.poffer_date){
        const date = new Date(offerData.poffer_date);
        formattedDate = date.toISOString().split('T')[0];
    }

    const initialValues = {
        poffer_id: (offerData) ? offerData.poffer_id : '',
        poffer_date: formattedDate,
        poffer_college: (offerData) ? offerData.poffer_college : '',
        poffer_commit: (offerData) ? offerData.poffer_commit : '',
        poffer_type: (offerData) ? offerData.poffer_type : '',
        profile_id: (offerData) ? offerData.poffer_uprofile_id : '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: schoolLogoSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const postedData = {
                poffer_id: values.poffer_id,
                profile_id: values.profile_id,
                poffer_date: offerDate,
                poffer_type: values.poffer_type,
                poffer_college: values.poffer_college,
                poffer_commit: values.poffer_commit
            }
            if (editOffers) {
                try {
                    const response = await setUpOffer(postedData);
                    if (response.success === true) {
                        handleFormClose();
                        dispatch(getDataOffers(requestBody))
                    }
                } catch (error) { }
            }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const [offerDate, setOfferDate] = useState(initialValues.poffer_date ? new Date(initialValues.poffer_date) : null);

    const handleOfferDateChange = date => {
        setOfferDate(handleTimeZoneFormat(date));
    };
    return (
        <Modal
            show={formshow}
            onHide={handleFormClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_UPDATE_AN_ENTRY_IN_OFFERS")}</h5>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_OFFER_DATE")}</label>
                                        <CustomDatePicker
                                            selectedDate={offerDate}
                                            onDateChange={handleOfferDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            id="poffer_date"
                                            placeholder={getLabel("LBL_OFFER_DATE")}
                                            disabled={!editOffers}
                                        />
                                        <div>
                                            {/* {formik.touched.title && formik.errors.title && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.title}</p>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputState">{getLabel("LBL_OFFER_TYPE")}</label>
                                    <select
                                        as="select"
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="poffer_type"
                                        name="poffer_type"
                                        onChange={handleChange}
                                        value={formik.values.poffer_type}
                                        disabled={!editOffers}
                                    >
                                        <option value="" >
                                            {getLabel("LBL_Select_One")}
                                        </option>
                                        {Object.keys(offerTypes).map((key) => (
                                            <option key={key} value={key}>
                                                {offerTypes[key]}
                                            </option>
                                        ))}
                                    </select>
                                    <div>
                                        {/* {formik.touched.status && formik.errors.status && (
                                            <p style={{ color: "#b94a48" }}>{formik.errors.status}</p>
                                        )} */}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_OFFER_COLLEGE")}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="poffer_college"
                                            name="poffer_college"
                                            onChange={handleChange}
                                            value={formik.values.poffer_college}
                                            placeholder={getLabel("LBL_OFFER_COLLEGE")}
                                            disabled={!editOffers}
                                        />
                                        <div>
                                            {/* {formik.touched.title && formik.errors.title && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.title}</p>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputState">{getLabel("LBL_OFFER_COMMIT")}</label>
                                    <select
                                        as="select"
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="poffer_commit"
                                        name="poffer_commit"
                                        onChange={handleChange}
                                        value={formik.values.poffer_commit}
                                        disabled={!editOffers}
                                    >
                                        <option value="" >
                                            {getLabel("LBL_Select_One")}
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <div>
                                        {/* {formik.touched.status && formik.errors.status && (
                                            <p style={{ color: "#b94a48" }}>{formik.errors.status}</p>
                                        )} */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFormClose}>
                        {getLabel("LBL_Close")}
                    </Button>
                    {editOffers && (
                        <Button variant="primary" type="submit">{getLabel("LBL_Submit")}</Button>
                    )}
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default UpdateOffer
