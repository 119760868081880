import { META_TAGS_SUCCESS } from "./type";
const initState = { loading: true, error: false, metaTags: [] };
const metaTagsReducer = (state = initState, action) => {
    switch (action.type) {
        case META_TAGS_SUCCESS:
            return {
                ...state,
                metaTags: action.payload,
            };
        default:
            return state;
    }
};

export { metaTagsReducer };
