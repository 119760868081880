import { getLabel } from "../../common/getLabel";
import { useFormik } from "formik";
import { updateGeneralSetting } from "../../services/general-settings/action";
import { getDataGeneralSettings } from "../../services/general-settings/action";
import { useDispatch } from "react-redux";

const EmailConfigrationPage = (props) => {
    const { generalSettings, editSetting } = props;
    const dispatch = useDispatch();

    const initialValues = {
        CONF_FROM_EMAIL: (generalSettings) ? generalSettings.CONF_FROM_EMAIL : '',
        CONF_REPLY_TO_EMAIL: (generalSettings) ? generalSettings.CONF_REPLY_TO_EMAIL : '',
        CONF_SMTP_HOST: (generalSettings) ? generalSettings.CONF_SMTP_HOST : '',
        CONF_SMTP_PORT: (generalSettings) ? generalSettings.CONF_SMTP_PORT : '',
        CONF_SMTP_USERNAME: (generalSettings) ? generalSettings.CONF_SMTP_USERNAME : '',
        CONF_SMTP_PASSWORD: (generalSettings) ? generalSettings.CONF_SMTP_PASSWORD : '',
        CONF_ADDITIONAL_ALERT_EMAILS: (generalSettings) ? generalSettings.CONF_ADDITIONAL_ALERT_EMAILS : '',
        CONF_SEND_SMTP_EMAIL: (generalSettings) ? generalSettings.CONF_SEND_SMTP_EMAIL : '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validateOnChange: false,
        onSubmit: async (values) => {

            const data = Object.entries(values).map(([key, value]) => ({
                conf_name: key,
                conf_value: value,
            }));

            try {
                await updateGeneralSetting(data);
                dispatch(getDataGeneralSettings());
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        if (editSetting) {
            formik.handleChange(e);
        }
    };

    var disable = "disabled";
    if (editSetting) {
        disable = "";
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row formcustom">
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_From_Email*")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_FROM_EMAIL"
                            value={formik.values.CONF_FROM_EMAIL}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_From_Email")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Reply_To_Email_Address*")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_REPLY_TO_EMAIL"
                            value={formik.values.CONF_REPLY_TO_EMAIL}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Reply_To_Email_Address")}
                            disabled={disable}
                        />
                    </div>


                    <fieldset>
                        <label htmlFor="input">{getLabel("LBL_Send_SMTP_Email")}</label>
                        <div className="option--mode">
                            <input
                                type="radio"
                                id="CONF_SEND_SMTP_EMAIL"
                                name="CONF_SEND_SMTP_EMAIL"
                                value="1"
                                checked={formik.values.CONF_SEND_SMTP_EMAIL === "1"}
                                disabled={disable}
                                onChange={handleChange}
                            />
                            <label htmlFor="contactChoice1">Yes</label>
                            <input
                                type="radio"
                                name="CONF_SEND_SMTP_EMAIL"
                                value="0"
                                checked={formik.values.CONF_SEND_SMTP_EMAIL === "0"}
                                disabled={disable}
                                onChange={handleChange}
                            />
                            <label htmlFor="contactChoice2">No</label>
                        </div>
                    </fieldset>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_SMTP_Host")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_SMTP_HOST"
                            value={formik.values.CONF_SMTP_HOST}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_SMTP_Host")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_SMTP_Port")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_SMTP_PORT"
                            value={formik.values.CONF_SMTP_PORT}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_SMTP_Port")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_SMTP_Username")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_SMTP_USERNAME"
                            value={formik.values.CONF_SMTP_USERNAME}
                            onChange={handleChange}
                            placeholder={getLabel("LBL__SMTP_Username")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_SMTP_Password")}</label>
                        <input
                            type="password"
                            className="form-control"
                            name="CONF_SMTP_PASSWORD"
                            value={formik.values.CONF_SMTP_PASSWORD}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_SMTP_Password")}
                            disabled={disable}
                        />
                    </div>
                </div>
                <br></br>
                {editSetting && (
                    <div className="form-group col-md-6">
                        <button className="btn btn-sm btn-secondary" type="submit">
                            {getLabel("LBL_Submit")}
                        </button>
                    </div>
                )}
            </form>
        </>
    );
};
export default EmailConfigrationPage;
