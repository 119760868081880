import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ selectedDate, onDateChange, dateFormat, className, id, ...otherAttributes }) => {
    return (
        <DatePicker
            selected={selectedDate}
            onChange={onDateChange}
            dateFormat={dateFormat}
            className={className}
            id={id}
            onKeyDown={(e) => e.preventDefault()}
            isClearable={true}
            {...otherAttributes}
        />
    );
};

export default CustomDatePicker;
