import { USER_PROFILE_LOADING, USER_PROFILE_ERROR, USER_PROFILE_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { displayProcessing, errorMessage, successMessage } from "../../common/message";
import { toast } from "react-toastify";
import { getLabel } from "../../common/getLabel";

const handleLoadingUserProfile = (payload) => ({
    type: USER_PROFILE_LOADING,
    payload,
});

const handleErrorUserProfile = () => ({
    type: USER_PROFILE_ERROR,
});

const handleSuccessUserProfile = (payload) => ({
    type: USER_PROFILE_SUCCESS,
    payload,
});

const getDataUserProfiles = (data) => async (dispatch) => {
    dispatch(handleLoadingUserProfile());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/search-profiles", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessUserProfile(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in User Profile listing", error);
        dispatch(handleErrorUserProfile());
    }
};

const getProfileData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/profile-data", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getTestimonials = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/testimonial/search", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getTrainingStatsData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/training-profile-data", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getEvaluationData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/evaluation-profile-data", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getAdsData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/ads-search", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const setupProfileData = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user-profile/setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const setupAdsData = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/ads-setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    return;
                }
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const setupTestimonials = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/testimonial/add", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    successMessage(resolve, res);
                    return;
                }
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const uploadProfileImage = (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/profile-image", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const uploadProfileCoverImage = (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/profile-cover-image", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const deleteProfileImage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/delete-image", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
                return;
            });
    });
};

const deleteAdImage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/delete-ads-image", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
                return;
            });
    });
};

const setupTrainerProfileInfo = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user-training-info/setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const setupProfileEvaluationInfo = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user-evaluation-info/setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteProfile = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user-profile/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteTestimonial = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/testimonial/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const getuserListingForReAssign = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/assign-profile-users", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const reAssignProfile = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/assign-profile", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const exportProfiles = async (data) => {
    const export_type = data.export_type;
    displayProcessing();
    const arg = {
        headers: headers()
    }
    if (export_type == 1) {
        arg.responseType = 'blob';
    }
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/exports-profiles", data, arg)
            .then((res) => {
                if(export_type == 1){
                    resolve(res.data);
                    toast.dismiss()
                }else{
                    successMessage(resolve, res);
                }
            })
            .catch((error) => {
                toast.error(getLabel('LBL_No_Record_Found'));
                errorMessage(error, reject);
            });
    });
};

export {
    getDataUserProfiles,
    getProfileData,
    getTrainingStatsData,
    getEvaluationData,
    getAdsData,
    setupProfileData,
    uploadProfileImage,
    uploadProfileCoverImage,
    deleteProfileImage,
    setupTrainerProfileInfo,
    deleteProfile,
    setupProfileEvaluationInfo,
    setupAdsData,
    deleteAdImage,
    getuserListingForReAssign,
    reAssignProfile,
    getTestimonials,
    setupTestimonials,
    deleteTestimonial,
    exportProfiles
};
