import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";
import { BLOG_TAGS_LOADING, BLOG_TAGS_ERROR, BLOG_TAGS_SUCCESS } from "./types";

const handleLoadingBlogTags = (payload) => ({
    type: BLOG_TAGS_LOADING,
    payload,
});
const handleErrorBlogTags = () => ({
    type: BLOG_TAGS_ERROR,
});
const handleSuccessBlogTags = (payload) => ({
    type: BLOG_TAGS_SUCCESS,
    payload,
});

const getBlogsTags = () => async (dispatch) => {
    dispatch(handleLoadingBlogTags());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .get("/blog/tags")
                .then((res) => {
                    dispatch(handleSuccessBlogTags(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorBlogTags());
    }
};

export { getBlogsTags };