import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BannerSkeleton = () => {
    const baseColor = "#ebebeb";
    const highlightColor="#cfcfcfab";
    return (
        <>
            <div className="slider-custom">
                <Skeleton baseColor={baseColor} highlightColor={highlightColor} height={600} style={{lineHeight:"unset"}} />
            </div>
        </>
    )
}

export default BannerSkeleton
