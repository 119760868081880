import { LOAD_LABELS } from "./type";
import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";
export const loadLabelsData = (data) => {
  return {
    type: LOAD_LABELS,
    payload: data,
  };
};

export const loadLabels = () => (dispatch) => {
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/common-labels",'',{ adminURL: false })
        .then((res) => {
          dispatch(loadLabelsData(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {

  }
};