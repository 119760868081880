import { getLabel } from "../../common/getLabel";
import { useDelayedSearch } from "../../common/common";
import { useState } from "react";
const SearchBar = (props) => {
  const { handleFormShow, defineNames, handleFilterUpdate } = props;
  const { title } = defineNames;
  const [searchItem, setSearchItem] = useState("");
  const handleInputChange = (event) => {
    setSearchItem(event.target.value);
  };
  useDelayedSearch(title, searchItem, handleFilterUpdate);
 

  return (
    <>
      <div className="row">
        <div className="col leftspace pb-5">
          <input
            className="search-input"
            placeholder={getLabel("LBL_Search_by_Keyword...")}
            name={title}
            value={searchItem}
            onChange={(e) => {
              handleInputChange(e);
            }}
          />
          <div className="card-button">
            <ul>
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleFormShow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ fill: "#fff", transform: "", msFilter: "" }}
                  >
                    <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z"></path>
                    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                  </svg>
                  {getLabel("LBL_Add_Reason")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
