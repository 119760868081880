import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { changePasswordSchema } from '../../common/validation';
import { changePassword, isLoggedIn } from '../../services/authentication/action';
import { getLabel } from '../../../common/getLabel';

const ResetPassword = () => {
    const param = useParams();
    const token = param.token;
    const navigate = useNavigate("")
    useEffect(() => {
        if (isLoggedIn() === true) {
            navigate('/');
            return;
        }
        if (token === undefined || token === '' || token === null) {
            navigate('/');
            return;
        }
    }, [navigate, token]);

    const initialValues = {
        new_password: "",
        confirm_password: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: changePasswordSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const postData = {
                new_password: values.new_password,
                confirm_password: values.confirm_password,
                token: token
            };
            try {
                const response = await changePassword(postData);
                if (response === true) {
                    navigate('/log-in');
                }
            } catch (error) { }
        }
    })
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 mx-auto">
                        <div className="login-bg">
                            <div className="row">
                                <h2>{getLabel('LBL_Reset_Password')}</h2>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="col-lg-12">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_New_Password')}</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="new_password"
                                                onChange={formik.handleChange}
                                                placeholder={getLabel('LBL_*Enter_your_password')}
                                            />
                                            {formik.touched.new_password && formik.errors.new_password && (
                                                <span style={{ color: "#b94a48" }}>{formik.errors.new_password}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Confirm_Password')}</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="confirm_password"
                                                onChange={formik.handleChange}
                                                placeholder={getLabel('LBL_*Confirm_your_password')}
                                            />
                                            {formik.touched.confirm_password && formik.errors.confirm_password && (
                                                <span style={{ color: "#b94a48" }}>{formik.errors.confirm_password}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn btn-primary">{getLabel('LBL_Submit')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="return">
                            <Link to="/log-in"><strong><i className='bx bx-arrow-back'></i> {getLabel('LBL_RETURN_TO_LOGIN_PAGE')}</strong></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword
