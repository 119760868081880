import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";

const getDataBannersByKey = async(data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/banners", data)
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getPremiumProfiles = async(data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/premium-profiles", data)
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getPremiumOffers = async(data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/premium-offers", data)
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

export { getDataBannersByKey, getPremiumProfiles, getPremiumOffers };