import { PROFILE_LOADING, PROFILE_ERROR, PROFILE_SUCCESS } from "./type";
const initState = { loading: true, error: false, profiles: [] };
const profileReducer = (state = initState, action) => {
    switch (action.type) {
        case PROFILE_LOADING:
            return { ...state, loading: true };
        case PROFILE_ERROR:
            return { ...state, loading: false, error: true };
        case PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                profiles: action.payload.rows,
                totalCount: action.payload.count,
            };
        default:
            return state;
    }
};

export { profileReducer };
