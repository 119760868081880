import { ARCHIVE_LOADING, ARCHIVE_ERROR, ARCHIVE_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";

const handleLoadingArchives = (payload) => ({
    type: ARCHIVE_LOADING,
    payload,
});
const handleErrorArchives = () => ({
    type: ARCHIVE_ERROR,
});
const handleSuccessArchives = (payload) => ({
    type: ARCHIVE_SUCCESS,
    payload,
});

const getArchiveProfiles = (data) => async (dispatch) => {
    dispatch(handleLoadingArchives());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/archive-profile", data)
                .then((res) => {
                    dispatch(handleSuccessArchives(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorArchives());
    }
};

export { getArchiveProfiles };