import React from 'react'
import { Helmet } from 'react-helmet'
import { getDefaultMetaTags, isEmpty } from '../../common/common';
import { baseUrl } from './config';
const MetaTags = ({ record }) => {
    const defaultMetaTags = getDefaultMetaTags();
    if (isEmpty(record) == true) {
        if (isEmpty(defaultMetaTags) === false) {
            record = defaultMetaTags.tag;
        }
    }
    if (isEmpty(record) == true) {
        return null;
    }

    const currentUrl = window.location.href;

    const parseMetaTags = (metaTagsString) => {
        if (isEmpty(metaTagsString) == true) {
            return null;
        }
        const parser = new DOMParser();
        const doc = parser.parseFromString(metaTagsString, 'text/html');
        const metaElements = doc.head.querySelectorAll('meta');
        return Array.from(metaElements).map((meta, index) => {
            const name = meta.getAttribute('name');
            const property = meta.getAttribute('property');
            const content = meta.getAttribute('content');
            if (name) {
                return <meta key={index} name={name} content={content} />;
            } else if (property) {
                return <meta key={index} property={property} content={content} />;
            }
            return null;
        });
    };

    return (
        <>
            <Helmet>
                <title>{record.meta_title}</title>
                <meta name="application-name" content={record.meta_title} />
                <meta name="apple-mobile-web-app-title" content={record.title} />
                <meta name="description" content={record.meta_description} />
                <meta name="keywords" content={record.meta_keywords} />
                {parseMetaTags(record.meta_other_meta_tags)}
                <link rel="icon" type="image/png" href={defaultMetaTags?.fav?.afile_physical_path ? `${baseUrl}/${defaultMetaTags.fav.afile_physical_path}` : "/images/favicon.ico"} sizes="16x16" />
                <link rel="canonical" href={currentUrl} />
                {record.meta_schema && (
                    <script type="application/ld+json">
                        {record.meta_schema}
                    </script>
                )}
            </Helmet>
        </>
    )
}

export default MetaTags
