import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "./type";
import { myAxios } from "../../common/myAxios";
import { displayProcessing, errorMessage, successMessage } from "../../common/message";
import { setPermissionsByUserId } from '../manage-subadmin/action';
import { getProfilebyId } from "../../services/profile/action";
import { headers } from "../../common/common";

const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    payload: token,
});

const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
});

const logout = () => ({
    type: LOGOUT,
});

export const login = (data) => async (dispatch) => {
    displayProcessing();
    const admin_device_id = data.user_device_id;
    try {

        return new Promise((resolve, reject) => {
            myAxios
                .post("/login", data)
                .then(async (res) => {

                    if (res.data.success === true) {
                        const token = res.data.data.token;
                        const admin_id = res.data.data.admin_id;
                        const admin_name = res.data.data.admin_name;
                        let actionTime = new Date().getTime();
                        const ttl = 24 * 60 * 60 * 1000;
                        const adminDetails = {
                            tokenTime: actionTime,
                            token: token,
                            admin_id: admin_id,
                            admin_name: admin_name,
                            admin_device_id: admin_device_id,
                            isLogged: true,
                            expiry: actionTime + ttl,
                        }
                        localStorage.setItem('adminDetails', JSON.stringify(adminDetails));
                        let admin_image = '';
                        const data = { admin_id: admin_id, attach_images: true };
                        const response = await getProfilebyId(admin_id);
                        if (response == true && data.length > 0) {
                            const profileImg = response.data.attachment ? response.data.attachment : "";
                            if (profileImg && Object.keys(profileImg).length > 0) {
                                admin_image = profileImg.afile_physical_path;
                            }
                        }
                        adminDetails.admin_image = admin_image;
                        localStorage.setItem('adminDetails', JSON.stringify(adminDetails));

                        dispatch(loginSuccess(token));
                        dispatch(setPermissionsByUserId(admin_id));
                        successMessage(resolve, res);
                        return;
                    }
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });

    } catch (error) {
        dispatch(loginFailure());
    }
};

export const forgotPassword = (data) => async (dispatch) => {
    displayProcessing();
    try {

        return new Promise((resolve, reject) => {
            myAxios
                .post("/forgot/password", data)
                .then((res) => {
                    if (res.data.success === true) {
                        successMessage(resolve, res);
                        return;
                    }
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });

    } catch (error) {
        dispatch(loginFailure());
    }
};

export const changePassword = (data) => async (dispatch) => {
    try {

        return new Promise((resolve, reject) => {
            myAxios
                .post("/create/password", data)
                .then((res) => {
                    if (res.data.success === true) {
                        successMessage(resolve, res);
                    }
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });

    } catch (error) {
        dispatch(loginFailure());
    }
};

export const doLogout = () => (dispatch) => {
    const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    if (adminDetails) {
        const data = { admin_id: adminDetails.admin_id, device_id: adminDetails.admin_device_id }
        try {
            return new Promise((resolve, reject) => {
                myAxios
                    .post("/logout", data, { headers: headers() })
                    .then(async (res) => {

                        if (res.data.success === true) {
                            localStorage.removeItem('adminDetails');
                            dispatch(logout());
                            return;
                        }
                        successMessage(resolve, res);
                    })
                    .catch((error) => {
                        localStorage.removeItem('adminDetails');
                        dispatch(logout());
                        errorMessage(error, reject);
                    });
            });

        } catch (error) {
            dispatch(loginFailure());
        }
    }
};

export const isLoggedIn = () => {
    try {
        const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
        let token = adminDetails ? adminDetails.token : null;
        let tokenTime = adminDetails ? adminDetails.tokenTime : null;
        if (token && tokenTime) {
            return true;
        }
        return false;
    } catch (error) { }
}