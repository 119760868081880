import { useState, useEffect } from 'react';

function useCookie(cookieName) {
    const [cookieValue, setCookieValue] = useState(null);

    useEffect(() => {
        const getCookie = () => {
            const nameEQ = cookieName + "=";
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1, cookie.length);
                }
                if (cookie.indexOf(nameEQ) === 0) {
                    return cookie.substring(nameEQ.length, cookie.length);
                }
            }
            return null;
        };

        const cookie = getCookie();
        setCookieValue(cookie);
    }, [cookieName]);

    const setCookie = (value, days) => {
        const expires = new Date();
        expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
        const cookieValue = encodeURIComponent(value) + (days ? `; expires=${expires.toUTCString()}` : '');
        document.cookie = `${cookieName}=${cookieValue}; path=/`;
        setCookieValue(value);
    };

    return [cookieValue, setCookie];
}

export default useCookie;
