import { ARCHIVE_LOADING, ARCHIVE_ERROR, ARCHIVE_SUCCESS } from "./type";
const initState = { loading: true, error: false, archives: [] };
const archiveReducer = (state = initState, action) => {
    switch (action.type) {
        case ARCHIVE_LOADING:
            return { ...state, loading: true };
        case ARCHIVE_ERROR:
            return { ...state, loading: false, error: true };
        case ARCHIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                archives: action.payload.rows,
                totalCount: action.payload.count,
            };
        default:
            return state;
    }
};

export { archiveReducer };
