import { MEMBERSHIP_LOADING, MEMBERSHIP_ERROR, MEMBERSHIP_SUCCESS } from "./types";
const initState = { loading: true, error: false, membership: [] };
const membershipReducer = (state = initState, action) => {
    switch (action.type) {
        case MEMBERSHIP_LOADING:
            return { ...state, loading: true };
        case MEMBERSHIP_ERROR:
            return { ...state, loading: false, error: true };
        case MEMBERSHIP_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                membership: action.payload,
            };
        default:
            return state;
    }
};

export { membershipReducer };
