import { getLabel } from "../../common/getLabel";
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
const Listing = (props) => {
    const {
        blogTags,
        totalCount,
        loading,
        error,
        handlePaginate,
        currentPage,
        limit,
        handleFormShow,
        deleteModalOpen,
        handleCheckboxChange,
        handleStatus,
        editBlog
    } = props;

    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const calculateSerialNumber = (index) =>
        (currentPage - 1) * limit + index + 1;
    const totalPages = Math.ceil(totalCount / limit);

    return (
        <>
            <form id="faq-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            {editBlog && (
                                <th className="col-check">
                                    <input type="checkbox" className="selectAll-js" onChange={(e) => handleCheckboxChange(e, "selectedlavel")} />
                                </th>
                            )}
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_Blog_Tag_Name")}</th>
                            <th>{getLabel("LBL_Status")}</th>
                            {editBlog && (<th className="table-action">{getLabel("LBL_Action")}</th>)}
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={4} checkbox={true} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="5" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {blogTags && blogTags.length > 0 ? (
                                    blogTags.map((elem, index) => (
                                        <tr className="actions" key={index}>
                                            {editBlog && (
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        name="order_id_blogTag[]"
                                                        value={elem.btag_id}
                                                        className="selectItem--js"
                                                        onChange={(e) => handleCheckboxChange(e, elem.btag_id)}
                                                    />
                                                </th>
                                            )}
                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem.btag_title}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={elem.btag_status ? 1 : 0}
                                                        onChange={(e) =>
                                                            handleStatus(e, elem.btag_id, elem.btag_status)
                                                        }
                                                        disabled={!editBlog}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                            {editBlog && (
                                                <td>
                                                    <span className="action">
                                                        <ul>
                                                            <li>
                                                                <a onClick={() => handleFormShow(elem.btag_id)}>
                                                                    <i
                                                                        className="bx bx-pencil"
                                                                        variant="primary"
                                                                    ></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a>
                                                                    <i
                                                                        className="bx bx-trash"
                                                                        onClick={() => deleteModalOpen(elem.btag_id)}
                                                                    ></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="5" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {blogTags && blogTags.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    );
};

export default Listing;
