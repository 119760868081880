const MANAGE_SUBADMIN_LOADING = "MANAGE_SUBADMIN_LOADING";
const MANAGE_SUBADMIN_SUCCESS = "MANAGE_SUBADMIN_SUCCESS";
const MANAGE_SUBADMIN_ERROR = "MANAGE_SUBADMIN_ERROR";
const MANAGE_SUBADMIN_MODULE_PERMISSIONS = "MANAGE_SUBADMIN_MODULE_PERMISSIONS";
export {
  MANAGE_SUBADMIN_LOADING,
  MANAGE_SUBADMIN_SUCCESS,
  MANAGE_SUBADMIN_ERROR,
  MANAGE_SUBADMIN_MODULE_PERMISSIONS,
};
