import { CONTENT_BLOCK_LOADING, CONTENT_BLOCK_ERROR, CONTENT_BLOCK_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";

const handleLoadingContentBlock = (payload) => ({
    type: CONTENT_BLOCK_LOADING,
    payload,
});

const handleErrorContentBlock = () => ({
    type: CONTENT_BLOCK_ERROR,
});

const handleSuccessContentBlock = (payload) => ({
    type: CONTENT_BLOCK_SUCCESS,
    payload,
});

const getDataContentBlocks = (data) => async (dispatch) => {
    dispatch(handleLoadingContentBlock());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/block/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessContentBlock(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorContentBlock());
    }
};

const getContentBlockbyId = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios
            .get(`/block/${id}`, { headers: headers() })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateContentBlock = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/block/update", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteContentBlockImage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post("/block/delete/image", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateBlockContentStatus = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/block/update-status", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};


export { getDataContentBlocks, getContentBlockbyId, updateContentBlock, deleteContentBlockImage, updateBlockContentStatus }


