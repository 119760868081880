import { errorMessage } from "../../common/message";
import { myAxios } from "../../common/myAxios";

const handleCronSchedule = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/cron", data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in Cron", error);
    }
};
export { handleCronSchedule };
