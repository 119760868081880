const CONTENT_BLOCK_LOADING = "CONTENT_BLOCK_LOADING";
const CONTENT_BLOCK_ERROR = "CONTENT_BLOCK_ERROR";
const CONTENT_BLOCK_SUCCESS = "CONTENT_BLOCK_SUCCESS";


export {
    CONTENT_BLOCK_LOADING,
    CONTENT_BLOCK_ERROR,
    CONTENT_BLOCK_SUCCESS,
   
};