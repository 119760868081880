import { useNavigate } from "react-router-dom"
import { handleTimeZoneFormat } from "../../../common/common"
import CustomDatePicker from "../../../common/CustomDatePicker"
import { getLabel } from "../../common/getLabel"
import { useState } from "react"
const SearchBar = (props) => {
    const { handleFormShow, handleFilterUpdate, handleFilter, filterdata, handleClear, editUserProfile, handleExportFormShow } = props

    const [searchItem, setSearchItem] = useState("")
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate('keyword', event.target.value, false);
    };

    const [startDate, setStartDate] = useState(filterdata.date_from ? new Date(filterdata.date_from) : null);
    const [endDate, setEndDate] = useState(filterdata.date_to ? new Date(filterdata.date_to) : null);

    const handleStartDateChange = date => {
        setStartDate(date);
        handleFilterUpdate('date_from', handleTimeZoneFormat(date), false);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
        handleFilterUpdate('date_to', handleTimeZoneFormat(date), false);
    };

    const navigate = useNavigate();

    const handleCancel = () => {
        setSearchItem("");
        setStartDate(null);
        setEndDate(null);
        handleFilterUpdate('date_from', '', false);
        handleFilterUpdate('date_to', '', false);
        handleFilterUpdate('premium_profile', '', false);
        handleClear();
        navigate('/admin/user-profile');
    };
    const premiumProfileValue = filterdata.premium_profile !== undefined ? filterdata.premium_profile : "";

    return (
        <div className="row pb-5">
            <div className="col leftspace">
                <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
                    <input className="search-input" placeholder={getLabel("LBL_Search_by_Keyword...")} name="keyword"
                        value={searchItem}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                    />
                    <a className="btn btn-primary" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample" href="#collapseExample">
                        <i className="bx bx-chevrons-down"></i>
                    </a>
                    <div className="card-button">
                        <ul>
                            <li>
                                <button
                                    type="submit"
                                    name="btn_submit"
                                    className="btn btn-brand btn-wide btn-search"
                                    onClick={() => handleFilter}
                                >
                                    {getLabel("LBL_Search")}{" "}
                                </button>
                                <button
                                    type="button"
                                    name="btn_clear"
                                    className="btn btn-link btn_clear"
                                    onClick={(e) => {
                                        handleCancel();
                                    }}
                                >
                                    {getLabel("LBL_Clear")}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                            <div className="container-fluid inner-section">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-3 col-12">
                                        <div className="form-group">
                                            <label className="label">{getLabel("LBL_Premium_Profile")}</label>
                                            <br></br>
                                            <select
                                                onChange={(e) => handleFilterUpdate(e.target.name, parseInt(e.target.value, 10))}
                                                value={premiumProfileValue}
                                                name="premium_profile"
                                                className="form-select user"
                                                aria-label="Default select example"
                                            >
                                                <option value="">{getLabel("LBL_Choose")}</option>
                                                <option value="1">{getLabel("LBL_Yes")}</option>
                                                <option value="0">{getLabel("LBL_No")}</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-3 col-12">
                                        <div className="form-group">
                                            <label className="label">{getLabel("LBL_Date_from")}</label>
                                            <br></br>
                                            <CustomDatePicker
                                                selectedDate={startDate}
                                                onDateChange={handleStartDateChange}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                id="birthday"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-3 col-12">
                                        <div className="form-group">
                                            <label className="label">{getLabel("LBL_Date_to")}</label>
                                            <br></br>
                                            <CustomDatePicker
                                                selectedDate={endDate}
                                                onDateChange={handleEndDateChange}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                id="birthday"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
            <div className="col-md-4 header--action">
                {editUserProfile && (
                    <>
                        <button type="button" className="btn btn-primary" onClick={handleFormShow}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: '#fff', transform: '', msFilter: '' }}><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z"></path><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path></svg>
                            {getLabel("LBL_Add_New_Profile")}
                        </button>
                        <button type="button" className="btn btn-primary float-right" onClick={handleExportFormShow} >
                            <i className="bx bx-export"></i> {getLabel("LBL_Export_Profiles")}
                        </button>
                    </>
                )}
            </div>

        </div>
    )
}
export default SearchBar