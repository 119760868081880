import { LOAD_LABELS } from "./type";
import { myAxios } from "../../common/myAxios";
export const loadLabelsData = (data) => {
  return {
    type: LOAD_LABELS,
    payload: data,
  };
};

export const loadLabels = () => (dispatch) => {
  try {
    return new Promise(() => {
      myAxios
        .post("/common-labels",'',{ adminURL: false })
        .then((res) => {
          dispatch(loadLabelsData(res.data.data));
        })
        .catch(() => {
        });
    });
  } catch (error) {

  }
};

export const getLabelByKey = async (data) => {
  return new Promise(() => {
    myAxios
      .post("/labels/find-by-key", data,{ adminURL: false })
      .then(() => {
      })
      .catch(() => {
      });
  });
};