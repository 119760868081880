import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import { updateSubAdminPermission, getPermissionsByUserId, getModuleList } from "../../services/manage-subadmin/action";
import { getLabel } from "../../common/getLabel";
import { getPrivileges, getPermissionTypes } from '../../common/adminPrivilege';
import { useParams } from "react-router-dom";
import { LoadingIndicator } from "../../common/loadingIndicator";
const UpdatePermissionListing = () => {
    const { id } = useParams();
    const [getPermissions, setPermissions] = useState([]);
    const [moduleListing, setModuleListing] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await getPermissionData();
            await getModuleData();
        };
        fetchData();
    }, []);

    const permissionType = getPermissionTypes();
    const getPrivilege = getPrivileges();
    const editPermission = getPrivilege.canEditAdminPermission();


    const getPermissionData = async () => {
        try {
            const data = await getPermissionsByUserId(id);
            setPermissions(data);
            setLoading(false);
        } catch (error) { }
    };

    const getModuleData = async () => {
        try {
            const data = await getModuleList();
            setModuleListing(data);
        } catch (error) { }
    };



    const getPermission = (module_id) => {
        if (getPermissions && getPermissions.length !== 0) {
            const foundValue = getPermissions[module_id];
            return foundValue;
        }
    };

    const handleSelectChange = async (e, moduleId) => {
        const { value } = e.target;
        const data = [{ admin_id: id, module_id: moduleId, permission: value }];
        try {
            await updateSubAdminPermission(data);
        } catch (error) { }

        let updatedPermissionData;
        updatedPermissionData = {
            ...getPermissions,
            [moduleId]: value,
        };
        setPermissions(updatedPermissionData);
    };

    let size = getPermissions && Object.keys(getPermissions).length;

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="row">
                    <div className="col leftspace pb-5">
                        <Link className="btn btn-success btn-sm float-right" to="/admin/sub-admin">{getLabel("LBL_Back")}</Link>
                    </div>
                </div>


                <div className="container-fluid inner-section">
                    {loading ? (
                        <>
                        <LoadingIndicator />
                        </>
                    ) : moduleListing ? (
                        <table
                            className="table table-striped table-bordered"
                            cellSpacing="0"
                            width="100%"
                        >
                            <thead>
                                <tr>

                                    <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                                    <th>{getLabel("LBL_Section")}</th>
                                    <th>{getLabel("LBL_Value")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {moduleListing && moduleListing.map((elem, index) => {
                                    return (
                                        <tr className="actions" key={index}>

                                            <td>{index + 1}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem.module_caption}
                                                    </span>
                                                </div>
                                            </td>

                                            <td>
                                                {editPermission ? (
                                                    <select
                                                        as="select"
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                        style={{ width: "80%" }}
                                                        id={elem["module_id"]}
                                                        onChange={(e) =>
                                                            handleSelectChange(e, elem["module_id"])
                                                        }
                                                        value={getPermission(elem["module_id"])}
                                                        data-val={getPermission(elem["module_id"])}
                                                    >
                                                        <option value={0}>None</option>
                                                        <option value={1}>Read Only</option>
                                                        <option value={2}>Read and Write</option>
                                                    </select>
                                                ) : (
                                                    <h6>{permissionType[getPermissions[elem["module_id"]]]}</h6>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <h4 style={{ textAlign: "center" }}>
                            {getLabel("LBL_No_result_found")}
                        </h4>
                    )}
                </div>
            </div >
        </>
    );
};
export default UpdatePermissionListing;
