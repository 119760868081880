import React, { useEffect, useState } from 'react';
import { getDataFaqs } from '../../services/faq/action';
import { LoadingIndicator } from '../../../common/loadingIndicator';
import { getLabel } from '../../../common/getLabel';
import { FAQ_PAGE_META_TYPE, loadMetaTags, SITE_TITLE } from '../../common/common';
import { useSelector } from 'react-redux';

const FaqIndex = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const metaTags = {
        meta_type: FAQ_PAGE_META_TYPE,
        meta_title: `${site_title} | Frequently Asked Questions | QB Recruiting Services`,
        meta_record_id: 0,
        meta_advance_tag: 1
    }

    useEffect(() => {
        loadMetaTags(metaTags);
        const delaySearch = setTimeout(() => {
            getAllFaqs();
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, []);

    // Separate FAQs by category
    const faqsByCategory = {};
    if (faqs && Array.isArray(faqs)) {
        faqs.forEach(faq => {
            const category = faq.category_name;
            if (!faqsByCategory[category]) {
                faqsByCategory[category] = [];
            }
            faqsByCategory[category].push(faq);
        });
    }

    const getAllFaqs = async () => {
        const response = await getDataFaqs();
        setLoading(false);
        setFaqs(response);
    }

    return (
        <>
            <div className="player-list-bulletin-board">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <h2>FAQS</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading ? (
                <LoadingIndicator />
            ) :
                (
                    <>
                        {faqs && faqs.length > 0 ? (
                            <div className="container">
                                <div className="row">
                                    <div className="custom-tab-faqs my-5">
                                        <div className="col-lg-12">
                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                {Object.keys(faqsByCategory).map((category, index) => (
                                                    <li className="nav-item" key={index} role="presentation">
                                                        <button className={`nav-link ${index === 0 ? 'active' : ''}`} id={`pills-${index}-tab`}
                                                            data-bs-toggle="pill" data-bs-target={`#pills-${index}`} type="button" role="tab"
                                                            aria-controls={`pills-${index}`} aria-selected={index === 0 ? 'true' : 'false'}>
                                                            {category}
                                                        </button>
                                                    </li>
                                                ))}

                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                {Object.entries(faqsByCategory).map(([category, faqs], index) => (
                                                    <div key={category} className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                                        id={`pills-${index}`} role="tabpanel" aria-labelledby={`pills-${index}-tab`} tabIndex={0}>
                                                        <div className="accordion" id={`accordion-${index}`}>
                                                            {faqs.map((faq, faqIndex) => (
                                                                <div key={faqIndex} className="accordion-item">
                                                                    <h2 className="accordion-header">
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapse-${index}-${faqIndex}`} aria-expanded="true"
                                                                            aria-controls={`collapse-${index}-${faqIndex}`}>
                                                                            {faq.faq_title}
                                                                        </button>
                                                                    </h2>
                                                                    <div id={`collapse-${index}-${faqIndex}`} className="accordion-collapse collapse"
                                                                        aria-labelledby={`heading-${index}-${faqIndex}`} data-bs-parent={`#accordion-${index}`}>
                                                                        <div className="accordion-body">
                                                                            <p>{faq.faq_content}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            (
                                <h4 style={{ textAlign: "center" }}>{getLabel("LBL_No_result_found")}</h4>
                            )
                        }
                    </>
                )}
        </>
    )
}

export default FaqIndex
