import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from "./admin/store";
import { frontendstore } from './frontend/store';
import AdminRoutes from './admin/routes';
import FrontendRoutes from './frontend/routes';
import { loadLabels } from './common-services/labels/action';
import { CommonStore } from './CommonStore';
import { SkeletonTheme } from 'react-loading-skeleton';
import { getOrderStatus } from './common-services/order-status/action';
import { getDefaultMeta } from './common-services/default-meta/action';
import CronIndex from './frontend/component/cron';

CommonStore.dispatch(loadLabels());
CommonStore.dispatch(getOrderStatus());
CommonStore.dispatch(getDefaultMeta());

function App() {
  return (
    <SkeletonTheme>
      <BrowserRouter>
        <Routes>
          <Route path="/admin/*" element={<Provider store={store}><AdminRoutes /></Provider>} />
          <Route path="/*" element={<Provider store={frontendstore}><FrontendRoutes /></Provider>} />
          <Route exact path="/cron/:command?" element={<CronIndex />} />
        </Routes>
      </BrowserRouter>
    </SkeletonTheme>
  );
}

export default App;
