import { DEFAULT_META_TAGS_SUCCESS } from "./type";

const initialState = {
    defaultMetaTags: null,
};

const loadDefaultMetaTags = (state = initialState, action) => {
    switch (action.type) {
        case DEFAULT_META_TAGS_SUCCESS:
            return {
                ...state,
                defaultMetaTags: action.payload,
            };
        default:
            return state;
    }
};

export default loadDefaultMetaTags;
