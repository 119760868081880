import {
  NAVIGATION_MENU_ERROR,
  NAVIGATION_MENU_LOADING,
  NAVIGATION_MENU_SUCCESS,
  NAVIGATION_SUB_MENU_SUCCESS,
} from "./type";

const initState = { loading: true, error: false, navigationMenues: [] };

const navigationMenuReducer = (state = initState, action) => {
  switch (action.type) {
    case NAVIGATION_MENU_LOADING:
      return { ...state, loading: true };
    case NAVIGATION_MENU_ERROR:
      return { ...state, loading: false, error: true };
    case NAVIGATION_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        navigationMenues: action.payload.navigations,
        totalCount: action.payload.totalRecords,
      };
    case NAVIGATION_SUB_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        subNavigationMenues: action.payload,
      };
    default:
      return state;
  }
};

export { navigationMenuReducer };
