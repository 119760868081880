import { myAxios } from "../../common/myAxios";

export const getUrlSlug = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("get-url", data)
            .then((res) => {
                if(res.data.success === true){
                    resolve(res.data.data);
                }
            })
            .catch((error) => {
            });
    });
};