import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ContentBlockSkeleton = () => {
    const marginStyle = { margin: "10px 0" };
    const baseColor = "#ebebeb";
    const highlightColor = "#cfcfcfab";
    return (
        <>
            <section className="home--about-us">
                <div className="container-fluid">
                    <div className="row column-reverse">
                        <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6 col-xxl-6 p-0">
                            <Skeleton baseColor={baseColor} highlightColor={highlightColor} height={700} />
                        </div>

                        <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6 col-xxl-6">
                            <div className="qbh-right-section">
                                <div>
                                    <Skeleton baseColor={baseColor} highlightColor={highlightColor} width={270} height={90} style={marginStyle} />
                                </div>
                                <p><Skeleton count={5} baseColor={baseColor} highlightColor={highlightColor} height={25} /></p>

                                <p><Skeleton count={2} baseColor={baseColor} highlightColor={highlightColor} height={25} /></p>
                                <div className="wr-logo">
                                    <Skeleton width={270} height={45} baseColor={baseColor} highlightColor={highlightColor} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home--account py-5">
                <div className="container-fluid custom-width">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="my-5">
                                <h2><Skeleton width={270} height={45} baseColor={baseColor} highlightColor={highlightColor} /></h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xxl-4">
                            <div className="parent-heading"><Skeleton width={150} baseColor={baseColor} highlightColor={highlightColor} height={25} /></div>
                            <p><Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} height={25} /></p>
                            <p className="text-center"><Skeleton width={150} height={45} baseColor={baseColor} highlightColor={highlightColor} /></p>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xxl-4">
                            <div className="parent-heading"><Skeleton width={150} baseColor={baseColor} highlightColor={highlightColor} height={25} /></div>
                            <p><Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} height={25} /></p>
                            <p className="text-center"><Skeleton width={150} height={45} baseColor={baseColor} highlightColor={highlightColor} /></p>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xxl-4">
                            <div className="parent-heading"><Skeleton width={150} baseColor={baseColor} highlightColor={highlightColor} height={25} /></div>
                            <p><Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} height={25} /></p>
                            <p className="text-center"><Skeleton width={150} height={45} baseColor={baseColor} highlightColor={highlightColor} /></p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="">
                <div className="container-fluid custom-width">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12 col-md-12 col-xl-7 col-xxl-7">
                            <p><Skeleton height={70} baseColor={baseColor} highlightColor={highlightColor} /></p>
                        </div>
                        <div className="col-lg-5 col-sm-12 col-md-12 col-xl-5 col-xxl-5">
                            <p><Skeleton height={70} baseColor={baseColor} highlightColor={highlightColor} /></p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="home--feature">
                <div className="pb-5">
                    <div className="container-fluid custom-width">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="my-5">
                                    <h2><Skeleton width={200} height={45} baseColor={baseColor} highlightColor={highlightColor} /> </h2>
                                </div>
                            </div>

                            <div className="col-lg-7 col-sm-7 col-md-7 col-xl-7 col-xxl-7">
                                <Skeleton baseColor={baseColor} highlightColor={highlightColor} height={700} />
                            </div>

                            <div className="col-lg-5 col-sm-5 col-md-5 col-xl-5 col-xxl-5 space-features">
                                <div className="features-heading mb-4">
                                    <h4><Skeleton width={200} height={35} baseColor={baseColor} highlightColor={highlightColor} /></h4>
                                    <p><Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} height={25} /></p>
                                </div>
                                <div className="features-heading mb-4">
                                    <h4><Skeleton width={200} height={35} baseColor={baseColor} highlightColor={highlightColor} /></h4>
                                    <p><Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} height={25} /></p>
                                </div>
                                <div className="features-heading mb-4">
                                    <h4><Skeleton width={200} height={35} baseColor={baseColor} highlightColor={highlightColor} /></h4>
                                    <p><Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} height={25} /></p>
                                </div>
                                <Skeleton width={150} height={45} baseColor={baseColor} highlightColor={highlightColor} />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContentBlockSkeleton;
