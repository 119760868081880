import { COUPON_LOADING, COUPON_ERROR, COUPON_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingCoupon = (payload) => ({
    type: COUPON_LOADING,
    payload,
});

const handleErrorCoupon = () => ({
    type: COUPON_ERROR,
});

const handleSuccessCoupon = (payload) => ({
    type: COUPON_SUCCESS,
    payload,
});

const getDataCoupons = (data) => async (dispatch) => {
    dispatch(handleLoadingCoupon());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/coupon/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessCoupon(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in coupon listing", error);
        dispatch(handleErrorCoupon());
    }
};

const getCouponbyId = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios
            .get(`/coupon/${id}`, { headers: headers() })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};


const addUpdateCoupon = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/coupon/setup", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const deleteCoupon = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/coupon/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateCouponStatus = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/coupon/update-status", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export {
    getDataCoupons,
    getCouponbyId,
    addUpdateCoupon,
    deleteCoupon,
    updateCouponStatus
};
