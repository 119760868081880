import React, { useEffect, useState } from 'react'
import PlayerInformation from './playerInfo'
import TrainingStats from './trainingStats'
import Evaluation from './evaluation'
import { Link } from 'react-router-dom'
import { getLabel } from '../../../../common/getLabel'
import { checkUserHasPremium } from '../../../services/account/action'
import { isEmpty } from '../../../../common/common'

const ParentStudentProfile = (props) => {
    const { profileData, profileId, useDynamicFields, stateListing } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const [userIsPremium, setUserIsPremium] = useState(false);
    const [profileUpgrade, setProfileUpgrade] = useState(true);
    const [maxNumVideoFields, setMaxNumVideoFields] = useState(1);
    const handleTab = (tab) => {
        setCurrentTab(tab);
    }
    useEffect(() => {
        userHasPremium();
    }, []);

    const userHasPremium = async () => {
        const response = await checkUserHasPremium({ "profileId": profileId });
        setUserIsPremium(response);
        if (((profileData && (profileData.uprofile_premium_overide == 1 || profileData.uprofile_is_premium == 1)) || (response && isEmpty(response.order_id) === false))) {
            setProfileUpgrade(false);
            setMaxNumVideoFields(4);
        }
    }
    return (
        <>
            <div className="player-info">
                <div className="container-fluid custom-width">
                    <div className="row">
                        <div className="col-lg-4 col-sm-4">
                            {profileData ? (
                                <h4>{profileData.uprofile_fname} {profileData.uprofile_lname}</h4>
                            ) : (
                                <h4>{getLabel('LBL_New_Profile')}</h4>
                            )}
                            <div className="d-flex align-items-start">
                                <div className="left-sidebar-trainer">
                                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link active" id="v-pills-home-tab" onClick={() => handleTab(1)} data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" > {getLabel('LBL_Player_Information')}</button>
                                        <button className={`nav-link`} onClick={() => handleTab(2)} id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" disabled={(!profileId || profileUpgrade == true) ? true : false}>
                                            {getLabel('LBL_Training_and_Stats')}
                                            {profileUpgrade === true && (
                                                <>
                                                    <i className='bx bx-lock'></i><Link to={profileId ? `/membership/?upgrade=${profileId}` : `/membership`} target="_blank" className="btn-defaulti-upgrade">UPGRADE</Link>
                                                </>
                                            )}
                                        </button>
                                        <button className={`nav-link`} onClick={() => handleTab(3)} id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false" disabled={(!profileId || profileUpgrade == true) ? true : false}>
                                            {getLabel('LBL_Evaluation')}
                                            {profileUpgrade === true && (
                                                <>
                                                    <i className='bx bx-lock'></i> <Link to={profileId ? `/membership/?upgrade=${profileId}` : `/membership`} target='_blank' className="btn-defaulti-upgrade">UPGRADE</Link>
                                                </>
                                            )}
                                        </button>
                                        {profileData && (
                                            <div className="view-pro-btn">
                                                <Link to={`/profile/${profileData.slug}`}><button type="button" className="btn btn-primary">{getLabel('LBL_VIEW_PROFILE')}</button></Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-sm-8 rightsidebar">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                    <PlayerInformation profileData={profileData} profileId={profileId} userIsPremium={userIsPremium} useDynamicFields={useDynamicFields} stateListing={stateListing} maxNumVideoFields={maxNumVideoFields} />
                                </div>
                                {profileId && (
                                    <>
                                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                            {currentTab == 2 && (
                                                <TrainingStats profileId={profileId} useDynamicFields={useDynamicFields} />
                                            )}
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                            {currentTab == 3 && (
                                                <Evaluation profileId={profileId} profileData={profileData} />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ParentStudentProfile
