import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getDataSeoUrls } from "../../services/url-rewriting/action";
// import { pageLimit } from "../../common/common";
import Listing from "./listing";
import SearchBar from "./searchBar";
import UpdateSeoUrlRewrite from "./updateSeoUrl";
import { getPrivileges } from "../../common/adminPrivilege";
import { getAdminPaginationLimit } from "../../../common/common";
const UrlReWritingIndex = () => {
  const defineNames = {
    id: "urlrewrite_id",
    orginalName: "urlrewrite_original",
    customName: "urlrewrite_custom",
  };
  const [urlId, setUrlId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = getAdminPaginationLimit();
  const requestBody = {
    page: currentPage,
    limit: pageLimit,
    RegDate: "",
    toDate: "",
  };
  const [filterdata, setFilterData] = useState(requestBody);
  const dispatch = useDispatch();
  const [formshow, setFormShow] = useState(false);
  useEffect(() => {
    dispatch(getDataSeoUrls(requestBody));
  }, []);
  const { seoUrlListing, totalCount } = useSelector((state) => state.seoUrl);
  const loading = useSelector((state) => state.seoUrl.loading);
  const error = useSelector((state) => state.seoUrl.error);
  const getPrivilege = getPrivileges();
  const editUrlRewrite = getPrivilege.canEditUrlRewrite();


  const handleFormShow = async (id) => {
    try {
      if (typeof id === "number") {
        setUrlId(id);
      }
    } catch (error) { }
    setFormShow(true);
  };
  const handleFormClose = () => {
    setFormShow(false);
    setUrlId(false);
  };
  const handlePaginate = (selectedPage) => {
    const updatedFilterData = {
      ...filterdata,
      page: selectedPage.selected + 1,
    };
    setCurrentPage(selectedPage.selected + 1);
    setFilterData(updatedFilterData);
    dispatch(getDataSeoUrls(updatedFilterData));
  };
  const handleFilterUpdate = async (name, value, boolen) => {
    let updatedFilterData;
    updatedFilterData = {
      ...filterdata,
      [name]: value,
      page: 1
    };
    setCurrentPage(1);
    setFilterData(updatedFilterData);
    if (boolen == true) {
      dispatch(getDataSeoUrls(updatedFilterData));
    }
  };

  const handleFilter = () => {
    let updatedFilterData = {
      ...filterdata,
      page: 1,
    }
    setCurrentPage(1)
    dispatch(getDataSeoUrls(updatedFilterData));
  };
  const handleClear = () => {
    let updatedFilterData = {
      ...requestBody,
      page: 1,
    }
    setCurrentPage(1)
    setFilterData(updatedFilterData);
    dispatch(getDataSeoUrls(updatedFilterData));
  };

  return (
    <>
      <div className="bg-whites mt-4 pt-5">
        <div className="container-fluid inner-section">
          <SearchBar
            handleFilterUpdate={handleFilterUpdate}
            handleFilter={handleFilter}
            handleClear={handleClear}
          />
          <Listing
            seoUrlListing={seoUrlListing}
            loading={loading}
            error={error}
            totalCount={totalCount}
            handlePaginate={handlePaginate}
            currentPage={currentPage}
            limit={pageLimit}
            handleFormShow={handleFormShow}
            editUrlRewrite={editUrlRewrite}
          />
        </div>
      </div>

      {formshow && (
        <UpdateSeoUrlRewrite
          formshow={formshow}
          handleFormClose={handleFormClose}
          requestBody={requestBody}
          urlId={urlId}
        />
      )}
    </>
  );
};

export default UrlReWritingIndex;
