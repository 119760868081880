import { COUPON_LOADING, COUPON_ERROR, COUPON_SUCCESS } from "./type";
const initState = { loading: true, error: false, coupons: [] };
const couponReducer = (state = initState, action) => {
    switch (action.type) {
        case COUPON_LOADING:
            return { ...state, loading: true };
        case COUPON_ERROR:
            return { ...state, loading: false, error: true };
        case COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                coupons: action.payload.coupons,
                totalCount: action.payload.totalRecords,
            };
        default:
            return state;
    }
};

export { couponReducer };
