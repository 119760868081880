import { CommonStore } from "../CommonStore";

export const formatDate = (dateString) => {
    if (dateString === '') {
        return false;
    }
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
};

export const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.split(' ').splice(0, maxLength).join(' ') + '...';
    }
    return text;
};

export const renderHTML = (htmlString) => {
    return { __html: htmlString };
};

export const getUniqueId = function (length = 10) {
    return Math.random().toString(36).substring(2, length + 2);
};

export const formatCalendarDate = (dateString) => {
    if (dateString === '') {
        return false;
    }

    var date = new Date(dateString);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    return (month < 10 ? '0' : '') + month + '/' + (day < 10 ? '0' : '') + day + '/' + year;
};

export const sanitizeInput = (input) => {
    if (input.trim() === '') {
        return '';
    }
    return input.replace(/\s+/g, '');
};

export const formatExpiryDate = (expiryString) => {
    if (!expiryString || typeof expiryString !== 'string') {
        return '';
    }
    const [month, year] = expiryString.split('/');
    return `20${year}-${month}`;
};

export const formatOfferDate = (date) => {
    if (date) {
        const start_date = new Date(date);
        return start_date.toISOString().split('T')[0];
    }
    return false;
}

export const stateListing = () => {
    return ["AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY", "AB", "BC", "ON", "QC"];
}

export const getFilteredYear = () => {
    return [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032];
}

export const getUserTypes = {
    "1": "Parent",
    "2": "Student",
    "3": "Trainer",
    "4": "Coach/Recruiters",
}

export const getUserProfileStyles = {
    "1": "Pro Style",
    "2": "Dual Threat",
    "3": "Wide Receiver",
    "4": "Tight End",
}

export const getOfferTypes = {
    "1": "Offer",
    "2": "Offer / Preferred Walk-On",
}

export const getOrderStatus = (id) => {
    const orderStatus = CommonStore.getState().orderStatus.orderStatus;
    const status = orderStatus.find(status => status.orderstatus_id === id);
    return status ? status.orderstatus_title : '';
};

export const getDefaultMetaTags = () => {
    return CommonStore.getState().defaultMetaTags.defaultMetaTags;
};

export const getAdminPaginationLimit = () => {
    const records = getDefaultMetaTags();
    return records?.adminLimit ? Number(records.adminLimit) : 25;
};

export const isCardExpired = (date) => {
    const [year, month] = date.split('-');
    const expires = new Date(year, parseInt(month, 10) - 1);
    const now = new Date();
    return expires < now;
}

export const isCardAboutExpired = (date) => {
    const [year, month] = date.split('-');
    const expires = new Date(year, parseInt(month, 10) - 1);
    const now = new Date();
    now.setMonth(now.getMonth() + 1);
    return expires < now;
}

export const isEmpty = (obj) => {
    if (obj == null)
        return true;
    if (typeof obj === 'undefined') {
        return true;
    }
    if (Array.isArray(obj) || typeof obj === 'string') {
        return obj.length === 0;
    }
    if (typeof obj === 'object') {
        return Object.keys(obj).length === 0;
    }
    return false;
};

export const handleNameChange = (e, setName) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
    setName(filteredValue);
};


export const scrollToFirstError = (formik, fieldRefs) => {
    if (formik.isSubmitting) {
        for (const key in formik.errors) {
            if (formik.errors[key] && formik.touched[key] && fieldRefs.current[key]) {
                fieldRefs.current[key].scrollIntoView({ behavior: 'smooth', block: 'center' });
                break;
            }
        }
    }
};

export const handleKeyDown = (e) => {
    const allowedPattern = /^[a-z0-9.]$/;
    const allowedKeys = [
        'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'Escape'
    ];
    if (allowedKeys.includes(e.key) || allowedPattern.test(e.key)) {
        return;
    }
    e.preventDefault();
};

export const handleTimeZoneFormat = (date) => {
    if (!date) {
        return null;
    }
    const offset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - offset);
    return localDate.toISOString().split('T')[0]
}