import { PREMIUM_OFFERS_LOADING, PREMIUM_OFFERS_ERROR, PREMIUM_OFFERS_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";

const handleLoadingPremiumProfilesOffer = (payload) => ({
    type: PREMIUM_OFFERS_LOADING,
    payload,
});
const handleErrorPremiumProfilesOffer = () => ({
    type: PREMIUM_OFFERS_ERROR,
});
const handleSuccessPremiumProfilesOffer = (payload) => ({
    type: PREMIUM_OFFERS_SUCCESS,
    payload,
});

const getPremiumProfilesOffers = (data) => async (dispatch) => {
    dispatch(handleLoadingPremiumProfilesOffer());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/search-premium-offers", data)
                .then((res) => {
                    dispatch(handleSuccessPremiumProfilesOffer(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorPremiumProfilesOffer());
    }
};

export { getPremiumProfilesOffers };