import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getContentPagebyId } from '../../services/content-page/action';
import { renderHTML } from '../../../common/common';
import { CMS_PAGE_META_TYPE, loadMetaTags } from '../../common/common';
import { LoadingIndicator } from '../../../common/loadingIndicator';

const CmsPages = ({ record_id }) => {
    const navigate = useNavigate();
    const [contentPageData, setContentPageData] = useState('');
    const [CmsLoading, setCmsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            if (!record_id) {
                navigate('/');
                return;
            }
            const response = await getContentPagebyId({ page_id: record_id });
            if (response.success) {
                setContentPageData(response.data);
                const metaTags = {
                    meta_type: CMS_PAGE_META_TYPE,
                    meta_title: response.data ? response.data.cpage_title : 'CMS Page',
                    meta_record_id: record_id
                }
                loadMetaTags(metaTags);
            }
            setCmsLoading(false);
        }
        fetchData();
    }, [record_id, navigate]);
    return (
        <>
            {CmsLoading ? (
                <>
                    <div className="container cms-skeleton">
                        <LoadingIndicator />
                    </div>
                </>
            ) : (
                <>
                    {contentPageData && contentPageData.pageBlocks.map((block, index) => (
                        <section key={index} dangerouslySetInnerHTML={renderHTML(block.cpblock_text)} />
                    ))}
                </>
            )}
        </>
    );
};

export default CmsPages;
