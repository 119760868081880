import { GENERAL_SETTING_LOADING, GENERAL_SETTING_ERROR, GENERAL_SETTING_SUCCESS } from "./type";

const initState = {
    loading: true,
    error: false,
    generalSettings: [],
};

const generalSettingReducer = (state = initState, action) => {
    switch (action.type) {
        case GENERAL_SETTING_LOADING:
            return { ...state, loading: true };
        case GENERAL_SETTING_ERROR:
            return { ...state, loading: false, error: true };
        case GENERAL_SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                generalSettings: action.payload,
                totalCount: action.payload.totalRecords,
            };
        default:
            return state;
    }
};

export { generalSettingReducer };
