import { InputMask } from '@react-input/mask';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from "formik";
import { formatExpiryDate, sanitizeInput, stateListing } from '../../../../common/common';
import { getLabel } from '../../../../common/getLabel';
import { updateBillingSchema } from '../../../common/validation';
import { getOrderData, updateSubscription } from '../../../services/account/action';
import { LoadingIndicator } from '../../../../common/loadingIndicator';
import { getPlanDetails } from '../../../services/membership/action';
import { addOverlay } from '../../../common/common';

const OrderUpdate = () => {
    const { order_id } = useParams();
    const [orderInfo, setOrderInfo] = useState(null);
    const navigate = useNavigate();
    let payAmount = ''
    let type = '';
    useEffect(() => {
        const getAccountData = async () => {
            const response = await getOrderData({ orderId: order_id });
            if (response.length === 0) {
                navigate('/qbhl-admin/account');
                return;
            }

            setOrderInfo(response);

            const { order_plan_id } = response;
            const plans = {
                2: { type: 'yearly' },
                3: { type: 'monthly' },
                5: { type: 'tmonthly' },
                6: { type: 'tyearly' }
            };
            const { type } = plans[order_plan_id] || {};
            const planDetail = await getPlanDetails({ plan_id: order_plan_id });
            const amount = planDetail ? planDetail.splan_price : '';
            formik.setValues({ subscriptionType: type, amount });
        };

        getAccountData();
    }, [order_id]);


    const initialValues = {
        addressOne: "",
        addressTwo: "",
        city: "",
        state: "",
        zip: "",
        cardName: "",
        cardNumber: "",
        expire: "",
        securityCode: "",
        subscriptionType: "",
        amount: "",
        orderId: order_id
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: updateBillingSchema,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            addOverlay();
            const postData = {
                addressOne: values.addressOne,
                addressTwo: values.addressTwo,
                city: values.city,
                state: values.state,
                zip: values.zip,
                cardName: values.cardName,
                cardNumber: sanitizeInput(values.cardNumber),
                expire: formatExpiryDate(values.expire),
                securityCode: values.securityCode,
                subscriptionType: values.subscriptionType,
                amount: values.amount,
                orderId: order_id,
            }
            try {
                const response = await updateSubscription(postData);
                resetForm();
                if (response === true) {
                    navigate('/qbhl-admin/account');
                }
            } catch (error) { }
        }
    })
    const uprofile_fname = orderInfo?.profileData?.uprofile_fname ?? '';
    const uprofile_lname = orderInfo?.profileData?.uprofile_lname ?? '';

    const handleChange = (e) => {
        formik.handleChange(e)
    };

    if (!orderInfo) {
        return <LoadingIndicator />;
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 mx-auto mb-5">
                        <div className="login-bg mb-5">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <h2>{getLabel('LBL_Update_Billing_for')} {uprofile_fname} {uprofile_lname}</h2>
                                    <div className="col-lg-12 sign-up-label">
                                        <label className="label-text">{getLabel('LBL_BILLING_ADDRESS')}</label>
                                    </div>


                                    <div className="col-md-12 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Address_1')}*</label>
                                            <input type="text" className="form-control" name="addressOne" value={formik.values.addressOne} onChange={handleChange} placeholder="Address 1" aria-label="Address" />
                                        </div>
                                        {formik.touched.addressOne && formik.errors.addressOne && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.addressOne}</span>
                                        )}
                                    </div>

                                    <div className="col-md-12 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Address_2')}</label>
                                            <input type="text" className="form-control" name="addressTwo" value={formik.values.addressTwo} onChange={handleChange} placeholder="Address 2" aria-label="*Address1" />
                                        </div>
                                    </div>

                                    <div className="col-md-4 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_City')}*</label>
                                            <input type="text" className="form-control" name="city" value={formik.values.city} onChange={handleChange} placeholder="City" aria-label="user name" />
                                        </div>
                                        {formik.touched.city && formik.errors.city && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.city}</span>
                                        )}
                                    </div>

                                    <div className="col-md-4 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_State')}*</label>
                                            <select className="form-select" name="state" value={formik.values.state} onChange={handleChange} aria-label="Default select example">
                                                <option value="">State</option>
                                                {stateListing && stateListing().map((state, index) => (
                                                    <option key={index} value={state}>{state}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {formik.touched.state && formik.errors.state && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.state}</span>
                                        )}
                                    </div>



                                    <div className="col-md-4 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Zip')}*</label>
                                            <input type="text" className="form-control" name="zip" value={formik.values.zip} onChange={handleChange} placeholder="ZIP" aria-label="password" />
                                        </div>
                                        {formik.touched.zip && formik.errors.zip && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.zip}</span>
                                        )}
                                    </div>


                                    <div className="col-lg-12 sign-up-label mb-4">
                                        <label className="label-text">{getLabel('LBL_PAYMENT')}</label>
                                        <figure>
                                            <img src="/images/cards.png" className="img-fluid" />
                                        </figure>
                                    </div>


                                    <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Name_on_Card')}*</label>
                                            <input type="text" className="form-control" name="cardName" value={formik.values.cardName} onChange={handleChange} placeholder="Name on Card" aria-label="name on card" />
                                        </div>
                                        {formik.touched.cardName && formik.errors.cardName && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.cardName}</span>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Card_Number')}*</label>
                                            <InputMask type="text" className="form-control" mask="____ ____ ____ ____" replacement={{ _: /\d/ }} name="cardNumber" value={formik.values.cardNumber} onChange={handleChange} placeholder="Card Number" aria-label="*card number" />
                                        </div>
                                        {formik.touched.cardNumber && formik.errors.cardNumber && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.cardNumber}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-6 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Expiration_MM/YY')}*</label>
                                            <InputMask type="text" className="form-control" mask="__/__" replacement={{ _: /\d/ }} name="expire" value={formik.values.expire} onChange={handleChange} placeholder="Expiration MM/YY" aria-label="Expiration MM/YY" />
                                        </div>
                                        {formik.touched.expire && formik.errors.expire && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.expire}</span>
                                        )}
                                    </div>


                                    <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Security_Code')}*</label>
                                            <InputMask type="text" className="form-control" mask="___" replacement={{ _: /\d/ }} name="securityCode" value={formik.values.securityCode} onChange={handleChange} placeholder="Security Code" aria-label="Security Code" />
                                        </div>
                                        {formik.touched.securityCode && formik.errors.securityCode && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.securityCode}</span>
                                        )}
                                    </div>
                                    <p className="text-center"><Link to="/terms-conditions" target="_blank">{getLabel('LBL_Membership_Terms')}</Link></p>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn btn-primary"><i className='bx bx-lock' ></i> {getLabel('LBL_RENEW_BILLING_AT')} ${formik.values.amount}</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderUpdate
