import {
  MANAGE_SUBADMIN_ERROR,
  MANAGE_SUBADMIN_LOADING,
  MANAGE_SUBADMIN_SUCCESS,
  MANAGE_SUBADMIN_MODULE_PERMISSIONS,
} from "./type";
const initState = { loading: false, error: false, subAdminListing: [] };
const manageSubAdminReducer = (state = initState, action) => {
  switch (action.type) {
    case MANAGE_SUBADMIN_LOADING:
      return { ...state, loading: true };
    case MANAGE_SUBADMIN_ERROR:
      return { ...state, loading: false, error: true };
    case MANAGE_SUBADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        subAdminListing: action.payload.admins,
        totalCount: action.payload.totalRecords,

      };
    case MANAGE_SUBADMIN_MODULE_PERMISSIONS:
      return {
        ...state,
        loading: false,
        error: false,
        adminModulePermissions: action.payload,        
      };
    default:
      return state;
  }
};

export { manageSubAdminReducer };
