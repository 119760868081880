import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateMetaTag, getDataMetaTags } from "../../services/meta-tag/action";
import { metaTagSchema } from "../../common/validation";
const UpdateMetaTag = (props) => {
    const { handleFormClose, formshow, metaData, activePage, editMetaTags } = props;
    const dispatch = useDispatch();
    const initialValues = {
        meta_id: (metaData) ? metaData.meta_id : '',
        meta_title: (metaData) ? metaData.meta_title : '',
        meta_keywords: (metaData) ? metaData.meta_keywords : '',
        meta_description: (metaData) ? metaData.meta_description : '',
        meta_other_meta_tags: (metaData) ? metaData.meta_other_meta_tags : '',
        meta_schema: (metaData) ? metaData.meta_schema : '',
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: metaTagSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                const response = editMetaTags && (await updateMetaTag(values));
                if (response === true) {
                    dispatch(getDataMetaTags({ metaType: activePage }));
                    handleFormClose();
                }
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };
    return (
        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">
                        {getLabel("LBL_Update_Meta_Tag")}
                    </h5>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label htmlFor="input">{getLabel("LBL_Meta_Title*")}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="meta_title"
                                            name="meta_title"
                                            onChange={handleChange}
                                            value={formik.values.meta_title}
                                            placeholder={getLabel("LBL_Meta_Title")}
                                            disabled={!editMetaTags}
                                        />
                                        <div>
                                            {formik.touched.meta_title && formik.errors.meta_title && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.meta_title}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Meta_Keywords")}
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="meta_keywords"
                                                name="meta_keywords"
                                                rows="3"
                                                required=""
                                                value={formik.values.meta_keywords}
                                                onChange={handleChange}
                                                placeholder={getLabel("LBL_Meta_Keywords")}
                                                disabled={!editMetaTags}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Meta_Description")}
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="meta_description"
                                                name="meta_description"
                                                rows="3"
                                                required=""
                                                value={formik.values.meta_description}
                                                onChange={handleChange}
                                                placeholder={getLabel("LBL_Meta_Description")}
                                                disabled={!editMetaTags}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Add_Schema")}
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="meta_schema"
                                                name="meta_schema"
                                                rows="3"
                                                required=""
                                                value={formik.values.meta_schema}
                                                onChange={handleChange}
                                                placeholder={getLabel("LBL_Add_Schema")}
                                                disabled={!editMetaTags}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Other_Meta_Tags")}
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="meta_other_meta_tags"
                                                name="meta_other_meta_tags"
                                                rows="3"
                                                required=""
                                                value={formik.values.meta_other_meta_tags}
                                                onChange={handleChange}
                                                placeholder={getLabel("LBL_Other_Meta_Tags")}
                                                disabled={!editMetaTags}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFormClose}>
                            {getLabel("LBL_Close")}
                        </Button>
                        {editMetaTags && (
                            <Button variant="primary" type="submit">
                                {getLabel("LBL_Update")}
                            </Button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default UpdateMetaTag;
