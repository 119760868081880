import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPrivileges } from '../../common/adminPrivilege';
import DeleteModal from "../../common/deleteModal"
import { getDataUserProfiles, getProfileData, getTrainingStatsData, getEvaluationData, deleteProfile } from "../../services/manage-user-profiles/action";
import Listing from "./listing";
import SearchBar from "./SearchBar";
import AddUpdateProfile from "./addUpdateProfile";
import ReAssignProfile from "./ReAssignProfile";
import ExportProfile from "./exportProfiles";
import { getAdminPaginationLimit } from "../../../common/common";
import { useLocation } from "react-router-dom";

const UserProfilesIndex = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [userName, setUserName] = useState(1);
    const [profileData, setProfileData] = useState('');
    const [trainingStatsData, setTrainingStatsData] = useState('');
    const [evaluationData, setEvaluationData] = useState('');
    const limit = getAdminPaginationLimit();
    const [formshow, setFormShow] = useState(false);
    const [assignProfileFormShow, setAssignProfileFormShow] = useState(false);
    const [deleteshow, setDeleteShow] = useState(false);
    const [deleteid, setDeleteId] = useState(null)
    const [exportFormShow, setExportFormShow] = useState(false);
    const { profiles, totalCount } = useSelector((state) => state.profiles)
    const loading = useSelector((state) => state.profiles.loading)
    const error = useSelector((state) => state.profiles.error)
    const dispatch = useDispatch();
    const search = useLocation().search;
    const isPremium = new URLSearchParams(search).get('premium');
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: "",
        date_from: "",
        date_to: ""
    };
    if(isPremium){
        requestBody.premium_profile = isPremium
    }
    const [filterdata, setFilterData] = useState(requestBody);
    const getPrivilege = getPrivileges();
    const editUserProfile = getPrivilege.canEditUserProfiles();

    useEffect(() => {
        dispatch(getDataUserProfiles(requestBody));
    }, [isPremium]);

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataUserProfiles(updatedFilterData));
    };

    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        if(name == "keyword"){
            updatedFilterData.page = 1;
            setCurrentPage(1);
        }
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataUserProfiles(updatedFilterData));
        }
    };

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataUserProfiles(updatedFilterData))
    };

    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };

    const loadTrainingStatsData = async (id) => {
        const response = await getTrainingStatsData({ "profile_id": id });
        setTrainingStatsData(response);
    }

    const loadEvaluationData = async (id) => {
        const response = await getEvaluationData({ "profile_id": id });
        setEvaluationData(response);
    }

    const handleProfileViewClose = () => {
        setFormShow(false);
        setAssignProfileFormShow(false);
        setProfileData('');
        setTrainingStatsData('');
        setEvaluationData('');
    }

    const handleAssignProfileData = async (id, username) => {
        const response = await getProfileData({ "profile_id": id });
        setProfileData(response);
        setUserName(username);
        setAssignProfileFormShow(true);
    }

    const handleFormShow = async (id) => {
        await loadProfileData(id);
        setFormShow(true);
    }

    const loadProfileData = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getProfileData({ "profile_id": id });
                setProfileData(response);
                await loadTrainingStatsData(id);
                await loadEvaluationData(id);
            }
        } catch (error) { }
    }

    const handleClear = () => {
        let updatedFilterData = {
            ...requestBody,
            page: 1,
        }
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        dispatch(getDataUserProfiles(updatedFilterData));
    };

    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id);
    };

    const handleDeleteClose = () => {
        setDeleteShow(false);
    };

    const handleDelete = async () => {
        try {
            let data;
            if (typeof deleteid === "number") {
                data = [deleteid];
            }
            await deleteProfile({ "profile_id": data });
            dispatch(getDataUserProfiles(requestBody));
            handleDeleteClose();
        } catch (error) { }
    };

    const handleExportFormShow = () => {
        setExportFormShow(true);
    }

    const handleExportFormClose = () => {
        setExportFormShow(false);
    };

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFormShow={handleFormShow}
                        handleFilterUpdate={handleFilterUpdate}
                        handleFilter={handleFilter}
                        filterdata={filterdata}
                        handleClear={handleClear}
                        editUserProfile={editUserProfile}
                        handleExportFormShow={handleExportFormShow}
                    />
                    <Listing
                        profiles={profiles}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        limit={limit}
                        handlePaginate={handlePaginate}
                        calculateSerialNumber={calculateSerialNumber}
                        editUserProfile={editUserProfile}
                        handleFormShow={handleFormShow}
                        deleteModalOpen={deleteModalOpen}
                        handleAssignProfileData={handleAssignProfileData}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            {formshow &&
                <AddUpdateProfile
                    formshow={formshow}
                    handleProfileViewClose={handleProfileViewClose}
                    profileData={profileData}
                    trainingStatsData={trainingStatsData}
                    evaluationData={evaluationData}
                    requestBody={requestBody}
                    editUserProfile={editUserProfile} />
            }
            {assignProfileFormShow &&
                <ReAssignProfile
                    assignProfileFormShow={assignProfileFormShow}
                    handleProfileViewClose={handleProfileViewClose}
                    profileData={profileData}
                    userName={userName}
                    requestBody={requestBody} />
            }
            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
            {exportFormShow && (
                <ExportProfile
                    exportFormShow={exportFormShow}
                    handleExportFormClose={handleExportFormClose}
                    requestBody={requestBody}
                />
            )}
        </>
    )
}

export default UserProfilesIndex
