import Modal from 'react-bootstrap/Modal';
import { useFormik } from "formik";
import { Button } from 'react-bootstrap';
import { updateLabel, getDataLabels } from '../../services/label/action';
import { useDispatch,useSelector } from 'react-redux';
import { getLabel } from "../../common/getLabel"

const AddUpdateLavel = (props) => {
    const { formshow, handleFormClose, requestBody, labelId } = props;

    const labelData = useSelector(state =>
        state.label.labels.find(labelData => labelData.label_id === labelId)
    )
    const dispatch = useDispatch()
    const initialValues = {
        label_id:(labelData)?labelData.label_id:'0',
        label_key: (labelData)?labelData.label_key:'',
        label_caption: (labelData)?labelData.label_caption:'',        
    };
    const formik = useFormik({
        initialValues: initialValues,
        validateOnChange:false,
        onSubmit: async (values) => {
            try {
                const response = await updateLabel(values);
                if (response === true) {
                    dispatch(getDataLabels(requestBody))
                    handleFormClose();
                }
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    return (
        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Label_Form")}</h5>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">{getLabel("LBL_Label_Caption*")}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="label_caption"
                                            name="label_caption"
                                            onChange={handleChange}
                                            value={formik.values.label_caption}
                                            placeholder={getLabel("LBL_Enter_Label_Caption")}
                                        />
                                        <div>{formik.errors.label_caption && <p style={{ color: "#b94a48" }}>{formik.errors.label_caption}</p>}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFormClose}>
                            {getLabel("LBL_Close")}
                        </Button>
                        <Button variant="primary" type="submit">{getLabel("LBL_Submit_Label")}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
export default AddUpdateLavel