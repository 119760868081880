import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProfileSkeletonFrontSideLoading = ({length}) => {
    const baseColor = "#ebebeb";
    const highlightColor = "#cfcfcfab";
    return (
        Array.from({ length: length }, (_, i) => (
            <div className="col-lg-12 col-sm-6" key={i}>
                <div className="qbh-player-list-box" style={{ padding: "35px", height: "unset", marginBottom: "30px" }}>
                    <div className="row">
                        <div className="col-lg-4 d-flex" style={{ justifyContent : "center" }}>
                            <div className="player-profile-pics">
                                <Skeleton circle={true} baseColor={baseColor} highlightColor={highlightColor} height={150} width={150} style={{ lineHeight: "unset" }} />
                                <div className="player-pro-name mt-4"><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={150} height={30} /></div>
                            </div>
                        </div>

                        <div className="col col-lg-4 col-sm-4 box-padding-left">
                            <div className="school-list" style={{ marginTop: "0" }}>
                                <ul className="border-dashd-right">
                                    <li className="bold"><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                    <li className="bold"> <Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                    <li className="bold"><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /> </li>
                                    <li className="bold"><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /> </li>
                                </ul>
                            </div>
                        </div>


                        <div className="col col-lg-4 col-sm-4 box-padding-right">
                            <div className="school-list" style={{ marginTop: "0" }}>
                                <ul>
                                    <li><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                    <li><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                    <li><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    )
}

export default ProfileSkeletonFrontSideLoading
