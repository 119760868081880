import React, { useRef, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

const CustomReCAPTCHA = ({ onChange, reset }) => {
    const { headerFooter } = useSelector((state) => state.headerFooter);
    const generalConfigurations = headerFooter ? headerFooter.info : [];
    const recaptchaRef = useRef(null);
    const [token, setToken] = useState(null);

    const handleRecaptchaChange = (value) => {
        setToken(value);
        onChange(value);
    };

    const resetRecaptcha = () => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    };

    useEffect(() => {
        if (reset) {
            resetRecaptcha();
        }
    }, [reset]);


    return (
        <div className="recaptcha-container">
            {generalConfigurations.CONF_V2_RECAPTCHA_SITE_KEY && generalConfigurations.CONF_V2_RECAPTCHA_SECRET_KEY && (
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={generalConfigurations.CONF_V2_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                />
            )}
        </div>
    );
};


export default CustomReCAPTCHA
