import {
  BlOG_CATEGORY_LOADING,
  BlOG_CATEGORY_ERROR,
  BlOG_CATEGORY_SUCCESS,
} from "./type";

const initState = { loading: true, error: false, blogCategories: [] };
const blogCategoryReducer = (state = initState, action) => {
  switch (action.type) {
    case BlOG_CATEGORY_LOADING:
      return { ...state, loading: true };
    case BlOG_CATEGORY_ERROR:
      return { ...state, loading: false, error: true };
    case BlOG_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        blogCategories: action.payload.blogCategories,
        totalCount: action.payload.totalRecords,
      };
    default:
      return state;
  }
};

export { blogCategoryReducer };
