import {
  MANAGE_ORDER_RETURN_LOADING,
  MANAGE_ORDER_RETURN_ERROR,
  MANAGE_ORDER_RETURN_SUCCESS,
} from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingOrderReturn = (payload) => ({
  type: MANAGE_ORDER_RETURN_LOADING,
  payload,
});
const handleErrorOrderReturn = () => ({
  type: MANAGE_ORDER_RETURN_ERROR,
});
const handleSuccessOrderReturn = (payload) => ({
  type: MANAGE_ORDER_RETURN_SUCCESS,
  payload,
});
const getDataOrderReturnReasons = (data) => async (dispatch) => {
  dispatch(handleLoadingOrderReturn());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/return/reason/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessOrderReturn(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    console.error("Error in manufacture listing", error);
    dispatch(handleErrorOrderReturn());
  }
};
const getOrderReturnbyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/return/reason/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateOrderReturnReason = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/return/reason/add", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteReturOrderbyId = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/return/reason/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
export {
  getDataOrderReturnReasons,
  getOrderReturnbyId,
  updateOrderReturnReason,
  deleteReturOrderbyId,
};
