import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { doLogout } from '../../services/authentication/action';


export default function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(doLogout());
        navigate('/');
        return;
    }, [dispatch,navigate])
}
