import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Listing from "./listing"
import { getDataFaqCategories, getFaqCategorybyId, deleteFaqCategory, updateFaqCategoryStatus } from "../../services/faq-category/action"
import AddUpdateFaqCategory from "./addUpdateFaqCategory"
import DeleteModal from "../../common/deleteModal"
import { getPrivileges } from '../../common/adminPrivilege';
import { SearchBar } from "./SearchBar"
import { getAdminPaginationLimit } from "../../../common/common"

const FaqCategoryIndex = () => {
    const [faqCategoryData, setFaqCategoryData] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const limit = getAdminPaginationLimit();
    const dispatch = useDispatch()
    const [formshow, setFormShow] = useState(false);
    const [deleteshow, setDeleteShow] = useState(false);
    const [deleteid, setDeleteId] = useState(null)
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const { faqCategory, totalCount } = useSelector((state) => state.faqCategory)
    const loading = useSelector((state) => state.faqCategory.loading)
    const error = useSelector((state) => state.faqCategory.error)
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: "",
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const getPrivilege = getPrivileges();
    const editFaq = getPrivilege.canEditFaq();

    useEffect(() => {
        dispatch(getDataFaqCategories(requestBody))
    }, []);

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getFaqCategorybyId(id);
                const result = response.data
                setFaqCategoryData(result);
            }
        } catch (error) { }
        setFormShow(true);
    }

    const handleFormClose = () => {
        setFormShow(false);
        setFaqCategoryData('')
    }

    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id)
    }
    const handleDeleteClose = () => {
        setDeleteShow(false);
    }

    const setOrderIdArray = () => {
        const checkedInputs = document.querySelectorAll("input[name='order_id_faq[]']:checked");
        const newOrderIds = Array.from(checkedInputs, input => input.value);
        return newOrderIds
    }

    const handleDelete = async () => {
        try {
            let id;
            if (typeof deleteid === "number") {
                id = [deleteid];
            } else {
                id = setOrderIdArray();
            }
            const data = { id };
            await deleteFaqCategory(data)
            let updatedFilterData = requestBody;
            if (faqCategory.length <= 1 && currentPage > 1) {
                updatedFilterData = {
                    ...filterdata,
                    page: currentPage - 1,
                };
                setCurrentPage(currentPage - 1);
                setFilterData(updatedFilterData);
            }
            dispatch(getDataFaqCategories(updatedFilterData))
            handleDeleteClose();
        } catch (error) { }
    };

    const handleSelectedStatusUpdate = async (status) => {
        const data = {
            "id": setOrderIdArray(),
            "status": status
        }
        try {
            const response = await updateFaqCategoryStatus(data)
            if (response === true) {
                dispatch(getDataFaqCategories(requestBody))
            }
        } catch (error) {
        }
    }

    const handleStatus = async (e, id, status) => {
        let fcat_id = [id]
        const newStatus = status === 1 ? 0 : 1;
        faqCategory.map((faq) => {
            if (faq.fcat_id === id) {
                faq.fcat_status = newStatus;
            }
            return faq;
        });
        const data = {
            "status": newStatus,
            "id": [id],
        }
        try {
            await updateFaqCategoryStatus(data)
            dispatch(getDataFaqCategories(requestBody))
        } catch (error) {
        }
    };

    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataFaqCategories(updatedFilterData))
        }
    };

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataFaqCategories(updatedFilterData))
    }

    const handleClear = () => {
        setFilterData(requestBody);
        dispatch(getDataFaqCategories(requestBody))
    }

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataFaqCategories(updatedFilterData))
    };

    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };

    const handleCheckboxChange = (event, labelId) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedCheckboxes((prevSelected) => [...prevSelected, labelId]);
        } else {
            setSelectedCheckboxes((prevSelected) =>
                prevSelected.filter((id) => id !== labelId)
            );
        }
    };


    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">

                    <SearchBar
                        handleFormShow={handleFormShow}
                        selectedCheckboxes={selectedCheckboxes}
                        deleteModalOpen={deleteModalOpen}
                        handleFilterUpdate={handleFilterUpdate}
                        handleSelectedStatusUpdate={handleSelectedStatusUpdate}
                        handleFilter={handleFilter}
                        filterdata={filterdata}
                        handleClear={handleClear}
                        editFaq={editFaq}
                    />

                    <Listing
                        faqCategory={faqCategory}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        limit={limit}
                        handlePaginate={handlePaginate}
                        handleFormShow={handleFormShow}
                        calculateSerialNumber={calculateSerialNumber}
                        deleteModalOpen={deleteModalOpen}
                        handleCheckboxChange={handleCheckboxChange}
                        setSelectedCheckboxes={setSelectedCheckboxes}
                        handleStatus={handleStatus}
                        editFaq={editFaq}
                        currentPage={currentPage}
                    />
                </div>
            </div>

            {formshow &&
                <AddUpdateFaqCategory
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    editFaq={editFaq}
                    faqCategoryData={faqCategoryData} />
            }
            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
        </>
    )
}

export default FaqCategoryIndex
