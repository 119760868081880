import { useState } from "react";
import { getLabel } from "../../common/getLabel";
const SearchBar = (props) => {
    const { handleFilterUpdate, handleFormShow, editOffers, offerTypes, handleFilter, handleClear } = props;
    const [searchItem, setSearchItem] = useState("")
    const [searchKey, setSearchKey] = useState("keyword")
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate(searchKey, event.target.value, false);
    };
    const handleSelectChange = (event) => {
        handleFilterUpdate('offer_college', '', false);
        handleFilterUpdate('keyword', '', false);
        console.log(event.target.value)
        setSearchKey(event.target.value);
        handleFilterUpdate(event.target.value, searchItem, false);
    };
    const handleFilterChange = (event) => {
        handleFilterUpdate(event.target.name, event.target.value, false);
    };

    const handleCancel = () => {
        setSearchItem("");
        setSearchKey("keyword");
        handleFilterUpdate('offer_type', '', false);
        handleFilterUpdate('offer_commit', '', false);
        handleClear();
    };
    return (
        <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
            <div className="row search-bar-filter">
                <div className="col leftspace pb-5">
                    <select name="filter_key" className="form-select" id="filter_key" value={searchKey} style={{ width: "20%" }} onChange={(e) => { handleSelectChange(e) }}>
                        <option value="keyword">Name</option>
                        <option value="offer_college">Offer College</option>
                    </select>
                    <input className="search-input" name="filter_value" value={searchItem} onChange={(e) => { handleInputChange(e) }} />
                    <select name="offer_type" className="form-select" id="offer_type" style={{ width: "20%" }} onChange={(e) => { handleFilterChange(e) }}>
                        <option value="">Select Offer Type</option>
                        {Object.keys(offerTypes).map((key) => (
                            <option key={key} value={key}>
                                {offerTypes[key]}
                            </option>
                        ))}
                    </select>
                    <select name="offer_commit" className="form-select" id="offer_commit" style={{ width: "20%" }} onChange={(e) => { handleFilterChange(e) }}>
                        <option value="">Select Offer Commit</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <div className="card-button">
                        <ul>
                            <li>
                                <button
                                    type="submit"
                                    name="btn_submit"
                                    className="btn btn-brand btn-wide btn-search"
                                    onClick={() => handleFilter}
                                >
                                    {getLabel("LBL_Search")}{" "}
                                </button>
                                <button
                                    type="button"
                                    name="btn_clear"
                                    className="btn btn-link btn_clear"
                                    onClick={(e) => {
                                        handleCancel();
                                    }}
                                >
                                    {getLabel("LBL_Clear")}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default SearchBar