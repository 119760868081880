import AddUpdateContentPage from "./addUpdateContentPage"
import Listing from "./listing"
import SearchBar from "./searchbar"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getDataContentPages, getContentPagebyId, deleteContentPage } from "../../services/content-page/action"
import DeleteModal from "../../common/deleteModal"
import { getPrivileges } from '../../common/adminPrivilege';
import { getAdminPaginationLimit } from "../../../common/common"
const ContentPageIndex = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageContent, setPageContent] = useState('');
    const limit = getAdminPaginationLimit();
    const [formshow, setFormShow] = useState(false);
    const [deleteshow, setDeleteShow] = useState(false);
    const [deleteid, setDeleteId] = useState(null)
    const [initialValues, setInitialValues] = useState({
        "cpage_id": "",
        "cpage_background_button_label": "",
        "cpage_background_button_target": "",
        "cpage_background_button_url": "",
        "cpage_title": "",
        "cpage_background_image_content": "",
        "pageBlocks": [],
        "cpImages": "",
    });
    const requestBody = {
        "page": currentPage,
        "limit": limit,
        "cpage_title": ""
    };

    const getPrivilege = getPrivileges();
    const editContentPages = getPrivilege.canEditContentPage();

    const [filterdata, setFilterData] = useState(requestBody)
    const dispatch = useDispatch()
    const { contentPages, totalCount } = useSelector((state) => state.contentPage)
    const loading = useSelector((state) => state.contentPage.loading)
    const error = useSelector((state) => state.contentPage.error)

    useEffect(() => {
        dispatch(getDataContentPages(requestBody))
    }, []);

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getContentPagebyId(id);
                const result = response.data
                setPageContent(result);
                const blockPageImg = result.attachments ? result.attachments : "";
                const pageBlock = result.pageBlocks.length > 0 ? result.pageBlocks : []

            }
        } catch (error) { }
        setFormShow(true)
    }
    const handleFormClose = () => {
        setFormShow(false);
        setPageContent('');
    }
    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id)
    }
    const handleDeleteClose = () => {
        setDeleteShow(false);
    }
    const handleDelete = async () => {
        const data = { "id": deleteid }
        try {
            await deleteContentPage(data)
            dispatch(getDataContentPages(requestBody))
            handleDeleteClose();
        } catch (error) { }
    };
    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataContentPages(updatedFilterData))
        }
    };
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataContentPages(updatedFilterData))
    };

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataContentPages(updatedFilterData));
    };
    const handleClear = () => {
        let updatedFilterData = {
            ...requestBody,
            page: 1,
        }
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        dispatch(getDataContentPages(updatedFilterData));
    };
    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFormShow={handleFormShow}
                        handleFilterUpdate={handleFilterUpdate}
                        editContentPages={editContentPages}
                        handleFilter={handleFilter}
                        handleClear={handleClear}
                    />

                    <Listing
                        contentPages={contentPages}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        currentPage={currentPage}
                        limit={limit}
                        handlePaginate={handlePaginate}
                        handleFormShow={handleFormShow}
                        deleteModalOpen={deleteModalOpen}
                        editContentPages={editContentPages}
                    />
                </div>
            </div>
            {formshow &&
                <AddUpdateContentPage

                    pageContent={pageContent}
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    editContentPages={editContentPages}
                />}

            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
        </>
    )
}
export default ContentPageIndex