import { DASHBOARD_LOADING, DASHBOARD_ERROR, DASHBOARD_SUCCESS } from "./type";

const initState = {
    loading: true,
    error: false,
    dashboardData: [],
};

const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case DASHBOARD_LOADING:
            return { ...state, loading: true };
        case DASHBOARD_ERROR:
            return { ...state, loading: false, error: true };
        case DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                dashboardData: action.payload,
                // totalCount: action.payload.totalRecords,
            };
        default:
            return state;
    }
};

export { dashboardReducer };