import { BLOG_ERROR, BLOG_SUCCESS, BLOG_LOADING } from "./types";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";

const handleLoadingBlog = (payload) => ({
  type: BLOG_LOADING,
  payload,
});
const handleErrorBlog = () => ({
  type: BLOG_ERROR,
});
const handleSuccessBlog = (payload) => ({
  type: BLOG_SUCCESS,
  payload,
});

const getDataBlogs = (data) => async (dispatch) => {
  dispatch(handleLoadingBlog());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/blog/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessBlog(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    dispatch(handleErrorBlog());
  }
};
const getBlogbyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/blog/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const blogCategoryListing = async () => {
  return new Promise((resolve, reject) => {
    myAxios
      .post(`/blog/category`, "data", { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const blogTagListing = async () => {
  return new Promise((resolve, reject) => {
    myAxios
      .post(`/blog/tag/search`, "data", { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateBlog = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/setup", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateBlogStatus = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/status-update", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteBlog = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteBlogImage = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/image/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

export {
  getDataBlogs,
  getBlogbyId,
  updateBlog,
  updateBlogStatus,
  deleteBlog,
  blogCategoryListing,
  blogTagListing,
  deleteBlogImage
};
