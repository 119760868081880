import ReactPaginate from "react-paginate";


const Pagination = ({ totalPages, handlePageClick, currentPage = 0 }) => {
    return (

        <>
            <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                previousLabel="Previous"
                pageCount={totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                forcePage={currentPage ? currentPage - 1 : false}
            />
        </>
    )
}
export default Pagination