import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MemberShipSkeleton = ({ cards = 2, count = 3 }) => {
    const marginStyle = {margin: "10px 0"};
    return (
        Array.from({ length: cards }, (_, i) => (
            <div className="row skelton-loader-card" key={i}>
                <div key={i} className="col-lg-12">
                    <div className="player-heading">
                        <h2><Skeleton baseColor="#ebebeb" highlightColor="#cfcfcfab" width={400} height={40} style={marginStyle} /></h2>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        {Array.from({ length: count }, (_, j) => (
                            <div className="col-lg-4 col-sm-6 mx-auto mb-5" key={j}>
                                <div className="basic-price">
                                    <div className="basic-price-title"><Skeleton baseColor="#ebebeb" highlightColor="#cfcfcfab" height={40} style={marginStyle} /></div>
                                    <div className="dashed-border-basic"></div>
                                    <Skeleton count={8} baseColor="#ebebeb" highlightColor="#cfcfcfab" height={20} style={marginStyle} />
                                    <div className="dashed-border-basic"></div>
                                    <div className="free">
                                        <Skeleton baseColor="#ebebeb" highlightColor="#cfcfcfab" width={150} height={35} style={marginStyle} />
                                    </div>
                                    <Skeleton baseColor="#ebebeb" highlightColor="#cfcfcfab" height={35} style={marginStyle} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ))
    );
}

export default MemberShipSkeleton;
