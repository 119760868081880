import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { getDataBlogs } from '../../services/blog/action';
import { getBlogsCategory } from '../../services/blog-category/action';
import { getBlogsTags } from '../../services/blog-tags/action';
import { BLOG_CATEGORY_PAGE_META_TYPE, loadMetaTags, SITE_TITLE } from '../../common/common';
import Newsletter from '../blogs/Newsletter';
import BlogTags from '../blogs/tags';
import Search from '../blogs/Search';
import BlogCategory from '../blogs/category';
const BlogCategoryIndex = ({ record_id }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const limit = 20;
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: "",
        bcatId: record_id
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch();
    const { blogs, totalCount, defaultBlogImg, category } = useSelector((state) => state.blog);
    // const loading = useSelector((state) => state.blog.loading);
    const error = useSelector((state) => state.blog.error);

    const { blogCateogries } = useSelector((state) => state.blogCategory);
    const categoryLoading = useSelector((state) => state.blogCategory.loading);
    const categoryError = useSelector((state) => state.blogCategory.error);

    const { blogTags } = useSelector((state) => state.blogTags);
    const tagLoading = useSelector((state) => state.blogTags.loading);
    const tagError = useSelector((state) => state.blogTags.error);

    useEffect(() => {
        dispatch(getDataBlogs(requestBody));
        const delaySearch = setTimeout(() => {
            if (!blogCateogries || blogCateogries.length === 0) {
                dispatch(getBlogsCategory());
            }
            if (!blogTags || blogTags.length === 0) {
                dispatch(getBlogsTags());
            }
            setLoading(false);
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, []);

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    useEffect(() => {
        if (category && category.bcat_title) {
            const metaTags = {
                meta_type: BLOG_CATEGORY_PAGE_META_TYPE,
                meta_title: `${category.bcat_title} Archives - ${site_title}`,
                meta_record_id: record_id
            }
            loadMetaTags(metaTags);
        }
    }, [blogs, dispatch]);

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataBlogs(updatedFilterData));
    };

    return (
        <>
            <div className="player-list-bulletin-board">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <p>CATEGORY</p>
                                <h2>{category && category.bcat_title && (category.bcat_title)}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid custom-width mt-5 news-qb">
                <div className="row">
                    <div className="col-lg-8 mb-5">
                        <div className="row news-details">
                            <Search
                                error={error}
                                blogs={blogs}
                                loading={loading}
                                totalCount={totalCount}
                                currentPage={currentPage}
                                limit={limit}
                                handlePaginate={handlePaginate}
                                defaultBlogImg={defaultBlogImg}
                            />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="right-sidebar">
                            <Newsletter />
                            <BlogCategory categoryLoading={categoryLoading} categoryError={categoryError} blogCateogries={blogCateogries} />
                            <BlogTags tagLoading={tagLoading} tagError={tagError} blogTags={blogTags} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogCategoryIndex
