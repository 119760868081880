import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { faqSchema } from '../../common/validation';
import { addUpdateFaq, getDataFaqs, faqCategoryListing } from '../../services/faq/action';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
const AddUpdateFaq = (props) => {
    const { formshow, handleFormClose, editFaq, faqData } = props;
    const [categoryList, setCategoryList] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        fetchFaqCategoryListing();
    }, []);

    const fetchFaqCategoryListing = async() => {
        const response = await faqCategoryListing();;
        setCategoryList(response.data);
    };

    const initialValues = {
        id: (faqData) ? faqData.faq_id : '',
        title: (faqData) ? faqData.faq_title : '',
        status: (faqData) ? faqData.faq_status : '',
        content: (faqData) ? faqData.faq_content : '',
        display_order: (faqData) ? faqData.faq_display_order : '',
        category_id: (faqData) ? faqData["fCat.fcat_id"] : '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: faqSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                const response = await addUpdateFaq(values);
                if (response === true) {
                    dispatch(getDataFaqs())
                    handleFormClose();
                }
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    return (

        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Faq_Form")}</h5>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Faq_Title*")}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="title"
                                                name="title"
                                                onChange={handleChange}
                                                value={formik.values.title}
                                                placeholder={getLabel("LBL_Enter_Faq_Title")}
                                                disabled={!editFaq}
                                            />
                                            <div>
                                                {formik.touched.title && formik.errors.title && (
                                                    <p style={{ color: '#b94a48' }}>{formik.errors.title}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Display_Order*")}</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                id="display_order"
                                                name="display_order"
                                                onChange={handleChange}
                                                value={formik.values.display_order}
                                                placeholder={getLabel("LBL_Enter_Display_Order")}
                                                disabled={!editFaq}
                                            />
                                            <div>
                                                {formik.touched.display_order && formik.errors.display_order && (
                                                    <p style={{ color: '#b94a48' }}>{formik.errors.display_order}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 pb-4">
                                        <label className="form-label" htmlFor="basic-default-message">
                                            {getLabel("LBL_Faq_Content*")}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="content"
                                            name="content"
                                            rows="3"
                                            required=""
                                            value={formik.values.content}
                                            onChange={handleChange}
                                            placeholder={getLabel("LBL_Describe_Short_Description_here...")}
                                            disabled={!editFaq}
                                        ></textarea>
                                        <div>
                                            {formik.touched.content && formik.errors.content && (
                                                <p style={{ color: '#b94a48' }}>{formik.errors.content}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12 pb-4">
                                        <label className="form-label" htmlFor="basic-default-message">
                                            {getLabel("LBL_Faq_Cateogry*")}
                                        </label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="category_id"
                                            name="category_id"
                                            onChange={handleChange}
                                            value={formik.values.category_id}
                                            disabled={!editFaq}
                                        >
                                            <option value="" >
                                                {getLabel("LBL_Choose")}
                                            </option>
                                            {categoryList && categoryList.length > 0 && categoryList.map((elem, index) =>(
                                                <option value={elem.fcat_id} >{elem.fcat_title}</option>
                                            ))}
                                        </select>
                                        <div>
                                            {formik.touched.category_id && formik.errors.category_id && (
                                                <p style={{ color: '#b94a48' }}>{formik.errors.category_id}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">{getLabel("LBL_Faq_Status*")}</label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="status"
                                            name="status"
                                            onChange={handleChange}
                                            value={formik.values.status}
                                            disabled={!editFaq}
                                        >
                                            <option value="" >
                                                {getLabel("LBL_Choose")}
                                            </option>
                                            <option value="1">{getLabel("LBL_Active")}</option>
                                            <option value="0">{getLabel("LBL_InActive")}</option>
                                        </select>
                                        <div>
                                            {formik.touched.status && formik.errors.status && (
                                                <p style={{ color: '#b94a48' }}>{formik.errors.status}</p>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFormClose}>
                            {getLabel("LBL_Close")}
                        </Button>
                        {editFaq && (
                            <Button variant="primary" type="submit">{getLabel("LBL_Submit_Faq")}</Button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
export default AddUpdateFaq