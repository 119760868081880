import { OFFERS_LOADING, OFFERS_ERROR, OFFERS_SUCCESS } from "./type";
const initState = {
    loading: true,
    error: false,
    profiles: [],
};

const offersReducer = (state = initState, action) => {
    switch (action.type) {
        case OFFERS_LOADING:
            return { ...state, loading: true };
        case OFFERS_ERROR:
            return { ...state, loading: false, error: true };
        case OFFERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                offers: action.payload.rows,
                totalCount: action.payload.count,
            };
        default:
            return state;
    }
};

export { offersReducer };