import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { subAdminCredentialSchema } from "../../common/validation";
import { useFormik } from "formik";
import { updateSubAdminPassword } from "../../services/manage-subadmin/action";

const ChangePassword = (props) => {
  const { creadientFormShow, subadminId, setCreadientalFormShow } = props;
  const initialValues = {
    id: subadminId,
    new_password: "",
    confirm_password: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: subAdminCredentialSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      values.id = subadminId;
      try {
        await updateSubAdminPassword(values);
        handleCreadientalFormClose();
        resetForm();
      } catch (error) { }
    },
  });
  const handleChange = (e) => {
    formik.handleChange(e);
  };
  const handleCreadientalFormClose = () => {
    formik.resetForm()
    setCreadientalFormShow(false);

  };

  return (
    <>
      <Modal
        show={creadientFormShow}
        onHide={handleCreadientalFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Change_Password")}
          </h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_New_Password*")}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        id="new_password"
                        name="new_password"
                        onChange={handleChange}
                        value={formik.values.new_password}
                        autoComplete="off"
                      />
                      <div>
                        {formik.touched.new_password &&
                          formik.errors.new_password && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.new_password}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_Confirm_Password*")}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        onChange={handleChange}
                        value={formik.values.confirm_password}
                        autoComplete="off"
                      />
                      <div>
                        {formik.touched.confirm_password &&
                          formik.errors.confirm_password && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.confirm_password}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCreadientalFormClose}>
              {getLabel("LBL_Cancel")}
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Update")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ChangePassword;
