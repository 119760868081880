import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getProfileDetails } from '../../services/profiles/action';
import { renderHTML } from '../../../common/common';
import { isLoggedIn } from '../../services/authentication/action';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import EventsModal from './eventsModal';
import { getLabel } from '../../../common/getLabel';
import { baseUrl } from '../../common/config';
import { writeMetaTags } from '../../services/meta-tags/action';
import { useSelector, useDispatch } from "react-redux"
import { convertYouTubeURL, SITE_TITLE } from '../../common/common';

const TrainerProfileDetail = ({ record_id }) => {
    const [profileData, setProfileData] = useState('');
    const [eventModalShow, setEventModalShow] = useState(false);
    const [eventModalData, setEventModalData] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const defaultMetaTags = headerFooter?.header?.defaultMeta || [];

    useEffect(() => {
        async function fetchData() {
            if (!record_id) {
                navigate('/profiles');
                return;
            }
            const response = await getProfileDetails({ profile_id: record_id });
            if (response.success) {
                setProfileData(response.data);
            } else {
                navigate('/profiles');
                return;
            }
        }
        fetchData();
    }, [record_id]);

    const profile_image = (profileData && profileData.profile_image) ? profileData.profile_image : '';
    const ogImage = profile_image && profile_image[0] && profile_image[0].afile_physical_path ? `${baseUrl}/${profile_image[0].afile_physical_path}` : "/images/qbh-hit.jpg";
    useEffect(() => {
        if (profileData) {
            const metaTags = { ...defaultMetaTags };
            metaTags.meta_title = `${profileData.uprofile_fname} ${profileData.uprofile_lname} - ${site_title}`;
            metaTags.meta_other_meta_tags = `<meta property="og:image" content="${ogImage}" />`;
            dispatch(writeMetaTags(metaTags));
        }
    }, [profileData, dispatch]);

    const about = (profileData && profileData.about) ? JSON.parse(profileData.about) : '';
    const video_info = (profileData && profileData.video) ? JSON.parse(profileData.video) : '';
    const social_media = (profileData && profileData.social_media) ? JSON.parse(profileData.social_media) : '';
    const trainer_services_info = (profileData && profileData.trainer_services) ? JSON.parse(profileData.trainer_services) : '';
    const trainer_testimonials_info = (profileData && profileData.trainer_testimonials) ? JSON.parse(profileData.trainer_testimonials) : '';
    const trainer_client_info = (profileData && profileData.client) ? JSON.parse(profileData.client) : '';

    const social_media_facebook = (social_media && social_media.facebook) ? social_media.facebook : '';
    const social_media_instagram = (social_media && social_media.instagram) ? social_media.instagram : '';
    const social_media_twitter = (social_media && social_media.twitter) ? social_media.twitter : '';
    const social_media_snapchat = (social_media && social_media.snapchat) ? social_media.snapchat : '';
    const testimonial = (profileData && profileData.testimonial) ? profileData.testimonial : '';
    const cover_image = (profileData && profileData.cover_image) ? profileData.cover_image : '';
    const handleEventClick = (arg) => {
        setEventModalShow(true);
        setEventModalData(arg.event);
    }

    const handleEventModalClose = () => setEventModalShow(false);

    let transformedEvents = [];
    if (profileData.events && profileData.events.length > 0) {
        transformedEvents = profileData.events.length
            ? profileData.events.map(event => ({
                id: event.uevent_id,
                title: event.uevent_title,
                start: event.uevent_start_date,
                end: event.uevent_end_date,
                end_date: event.uevent_end_date,
                display: 'block',
                backgroundColor: event.uevent_color,
                start_stop: event.uevent_start_stop,
                location: event.uevent_location,
                description: event.uevent_description,
            }))
            : [];
    }

    const cleanUrlString = (url) => {
        return url.replace(/\\'/g, "'").replace(/\\\\/g, '\\');
    };
    let elemStr;
    let isHudl;
    let isIframe;
    let iframeSrc;
    if (video_info.url) {
        elemStr = String(cleanUrlString(video_info.url)).trim();
        isHudl = elemStr.includes('hudl.com/video');
        isIframe = elemStr.startsWith('<iframe');
        iframeSrc = isHudl ? elemStr : convertYouTubeURL(elemStr);
    }
    const location = useLocation();
    const pathname = location.pathname;
    const handleLoginNavigation = () => {
        navigate("/log-in", { state: { from: pathname } });
    };

    return (
        <>
            <div className="profile-details-header-banner">
                <figure>
                    <img src={cover_image && cover_image[0] && cover_image[0].afile_physical_path ? `${baseUrl}/${cover_image[0].afile_physical_path}` : "/images/profile-detail-image.jpg"} className="img-fluid" />
                </figure>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="row trainer-bg">
                            <div className="col-lg-6 col-sm-6">
                                <div className="profile-detail-img">
                                    <figure>
                                        <img src={profile_image && profile_image[0] && profile_image[0].afile_physical_path ? `${baseUrl}/${profile_image[0].afile_physical_path}` : "/images/qbh-hit.jpg"} className="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="derek-laway">
                                    <h6>{profileData.uprofile_company_name}</h6>
                                    <p>{profileData.uprofile_fname} {profileData.uprofile_lname}</p>
                                    <p>{profileData.uprofile_city} , {profileData.uprofile_state_code} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {profileData.uprofile_website && (
                        <div className="col-lg-7">
                            <div className="premium-btn-visit">
                                <a href={profileData.uprofile_website} className="btn btn-primary" target="_blank"><i className='bx bx-globe'></i> {getLabel('LBL_VISIT_WEBSITE')}</a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mb-5">
                        <div className="social-media-profile">
                            <ul>
                                {social_media_twitter && (
                                    <li><a href={`https://www.twitter.com/${social_media_twitter}`} target="_blank"><img src="/images/twiter.png" className="img-fluid" />{social_media_twitter}</a></li>
                                )}
                                {social_media_instagram && (
                                    <li><a href={`https://www.instagram.com/${social_media_instagram}`} target="_blank"><img src="/images/instagram.png" className="img-fluid" />{social_media_instagram}</a></li>
                                )}
                                {social_media_facebook && (
                                    <li><a href={`https://www.facebook.com/${social_media_facebook}`} target="_blank"><img src="/images/facebook.png" className="img-fluid" />{social_media_facebook}</a></li>
                                )}
                                {social_media_snapchat && (
                                    <li><a href={`https://www.snapchat.com/add/${social_media_snapchat}`} target="_blank"><img src="/images/snapchat-logo-24.png" className="img-fluid" />{social_media_snapchat}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                    {about && (
                        <div className="col-lg-12">
                            <blockquote>
                                {about}
                            </blockquote>
                        </div>
                    )}
                </div>
            </div>
            <div className="service-trainer service-trainer-info">
                <div className="container">
                    <div className="row">
                        {trainer_services_info && (
                            <div className="col-lg-6">
                                <div className="service-box-trainer">
                                    <div className="service-inner-box">
                                        <div className="service-title">{getLabel('LBL_SERVICES')}</div>
                                    </div>
                                    <div className="trainer-service-info" dangerouslySetInnerHTML={renderHTML(trainer_services_info)} />
                                </div>
                            </div>
                        )}
                        <div className="col-lg-6">
                            {trainer_client_info && trainer_client_info.length > 0 && (
                                <div className="accordion client-info" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                {getLabel('LBL_CLIENTS')}
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>{getLabel('LBL_Name')}</th>
                                                                <th>{getLabel('LBL_Link')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {trainer_client_info && trainer_client_info.map((client, index) => (
                                                                <tr key={index}>
                                                                    <td className="bold">{client.client_name}</td>
                                                                    <td><a href={client.client_link} target="_blank">{client.client_link}</a></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isLoggedIn() === true && (profileData.uprofile_email || profileData.uprofile_address || profileData.uprofile_phone_no) ? (
                                <div className="evalution-scores-contact mb-5">
                                    <div className="inner-scouting-box">{getLabel('LBL_CONTACT:')}</div>
                                    <div className="address-list">
                                        <ul>
                                            {profileData.uprofile_company_name && (
                                                <li><i className="bx bx-user" style={{ color: "#ab6601" }}></i> {profileData.uprofile_company_name}</li>
                                            )}
                                            {profileData.uprofile_email && (
                                                <li><i className="bx bx-envelope-open" style={{ color: "#ab6601" }}></i><a href={`mailto:${profileData.uprofile_email}`}>{profileData.uprofile_email}</a></li>
                                            )}
                                            {profileData.uprofile_phone_no && (
                                                <li><i className="bx bx-phone-call" style={{ color: "#ab6601" }}></i><a href={`tel:${profileData.uprofile_phone_no}`}>{profileData.uprofile_phone_no}</a></li>
                                            )}
                                            {profileData.uprofile_address && (
                                                <li><i className="bx bx-map" style={{ color: "#ab6601" }}></i>{profileData.uprofile_address} {profileData.uprofile_city}, {profileData.uprofile_state_code} {profileData.uprofile_zip}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {isLoggedIn() === false && (
                                        <div className="click-here mb-5">
                                            <p>Coach? <a onClick={handleLoginNavigation} className="click-btn">Click Here </a> to view all Profile Information</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {transformedEvents && transformedEvents.length > 0 && (
                <div className="container trainer-event-info mt-4" id="calendar">
                    <div className="row">
                        <div className="col-lg-12 twitter-qbh">
                            <h2>EVENTS</h2>
                        </div>
                        <div className="col-lg-12">
                            <FullCalendar
                                plugins={[dayGridPlugin]}
                                initialView="dayGridMonth"
                                headerToolbar={{
                                    start: 'prev,next',
                                    center: 'title',
                                    end: 'dayGridMonth,dayGridWeek'
                                }}
                                editable={false}
                                eventClick={handleEventClick}
                                events={transformedEvents}
                            />
                        </div>
                    </div>
                    <EventsModal eventData={eventModalData} eventModalShow={eventModalShow} handleEventModalClose={handleEventModalClose} />
                </div>
            )}
            {testimonial && testimonial.length > 0 && (
                <div className="container trainer-testimonial-info mt-4">
                    <div className="row">
                        <div className="col-lg-12 twitter-qbh">
                            <h2 className="text-center">{getLabel('LBL_TESTIMONIALS')}</h2>
                        </div>
                        <div className="testmonail-slider">
                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    {testimonial && testimonial.length > 0 && testimonial.map((elem, index) => (
                                        <div className={`carousel-item ${index == 0 ? 'active' : ''}`}>
                                            {elem.images && Object.keys(elem.images).length > 0 && (
                                                <div>
                                                    <img
                                                        src={`${baseUrl}/${elem.images[0].afile_physical_path}`}
                                                        style={{
                                                            height: "100px",
                                                            width: "100px",
                                                            borderRadius: "50%",
                                                        }}
                                                        className="d-block img-fluid"
                                                        alt="image-path"
                                                    />
                                                </div>
                                            )}
                                            {elem.pt_username && (
                                                <p>- {elem.pt_username}</p>
                                            )}
                                            {elem.pt_content && (
                                                <p className="inner-text-test">{elem.pt_content}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                {testimonial && testimonial.length > 1 && (
                                    <>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                                            data-bs-slide="prev">
                                            <span className="left-arrow"><i className="bx bxs-chevron-left"></i></span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                                            data-bs-slide="next">
                                            <span className="left-arrow"><i className="bx bxs-chevron-right"></i></span>
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {video_info && video_info.url && (
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-lg-12 twitter-qbh">
                            <h2>{getLabel('LBL_VIDEO')}</h2>
                        </div>

                        <div className="custom-tab profiles-highlight">
                            <div className="inner-text-tab">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade active show" id="video1" role="tabpanel" aria-labelledby="video1-tab">
                                        {isIframe ? (
                                            <div dangerouslySetInnerHTML={{ __html: elemStr }} />
                                        ) : (
                                            <iframe
                                                src={iframeSrc}
                                                allowFullScreen
                                                width="100%"
                                                height="315"
                                                style={{ width: '100%', height: '315px' }}
                                                frameBorder="0"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default TrainerProfileDetail