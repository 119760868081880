import Listing from "./listing";
import SearchBar from "./searchBar";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import DeleteModal from "../../common/deleteModal";
import AddUpdateBlogTag from "./addUpdateBlogTag";
import { getDataBlogTags, deleteBlogTag, updateBlogStatus } from "../../services/blog-tag/action";
import { getPrivileges } from '../../common/adminPrivilege';
import { getAdminPaginationLimit } from "../../../common/common";

const BlogTagIndex = () => {

  const [tagId, setTagId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = getAdminPaginationLimit();
  const requestBody = {
    page: currentPage,
    limit: limit,
    RegDate: "",
    toDate: "",
    keyword: "",
  };

  const [formshow, setFormShow] = useState(false);
  const [filterdata, setFilterData] = useState(requestBody);
  const [deleteid, setDeleteId] = useState(null);
  const [deleteshow, setDeleteShow] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const dispatch = useDispatch();
  const { blogTags, totalCount } = useSelector((state) => state.blogTag);
  const loading = useSelector((state) => state.blogTag.loading);
  const error = useSelector((state) => state.blogTag.error);
  const getPrivilege = getPrivileges();
  const editBlog = getPrivilege.canEditBlog();

  useEffect(() => {
    dispatch(getDataBlogTags(requestBody));
  }, []);

  const handleFormClose = () => {
    setFormShow(false);
    setTagId(false);
  };

  const handleFormShow = async (id) => {
    try {
      if (typeof id === "number") {
        setTagId(id);
      }
    } catch (error) { }
    setFormShow(true);
  };

  const handleDeleteClose = () => {
    setDeleteShow(false);
  };

  const deleteModalOpen = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  const setOrderIdArray = () => {
    const checkedInputs = document.querySelectorAll(
      "input[name='order_id_blogTag[]']:checked"
    );
    const newOrderIds = Array.from(checkedInputs, (input) => input.value);
    return newOrderIds;
  };

  const handleDelete = async () => {
    try {
      let data;
      if (typeof deleteid === "number") {
        data = [deleteid];
      } else {
        data = setOrderIdArray();
      }
      await deleteBlogTag({ "id": data });
      dispatch(getDataBlogTags(requestBody));
      handleDeleteClose();
    } catch (error) { }
  };

  const handleStatus = async (e, id, status) => {
    if (editBlog) {
      const newStatus = status === 1 ? 0 : 1;
      blogTags.map((blogtag) => {
        if (blogtag.btag_id === id) {
          blogtag.btag_status = newStatus;
        }
        return blogtag;
      });
      const data = {
        "status": newStatus,
        "id": [id],
      };
      try {
        await updateBlogStatus(data);
        dispatch(getDataBlogTags(requestBody));
      } catch (error) { }
    }
  };

  const handleSelectedStatusUpdate = async (status) => {
    const data = {
      "id": setOrderIdArray(),
      "status": status,
    };
    try {
      await updateBlogStatus(data);
      dispatch(getDataBlogTags(requestBody));
    } catch (error) { }
  };

  const handlePaginate = (selectedPage) => {
    const updatedFilterData = {
      ...filterdata,
      page: selectedPage.selected + 1,
    };
    setCurrentPage(selectedPage.selected + 1);
    setFilterData(updatedFilterData);
    dispatch(getDataBlogTags(updatedFilterData));
  };

  const handleCheckboxChange = (event, labelId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, labelId]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((id) => id !== labelId)
      );
    }
  };

  const handleFilterUpdate = async (name, value, boolen) => {
    let updatedFilterData;
    updatedFilterData = {
      ...filterdata,
      [name]: value,
    };
    setFilterData(updatedFilterData);
    if (boolen === true) {
      dispatch(getDataBlogTags(updatedFilterData));
    }
  };

  const handleFilter = () => {
    let updatedFilterData = {
      ...filterdata,
      page: 1,
  }
  setCurrentPage(1)
    dispatch(getDataBlogTags(updatedFilterData));
  };

  const handleClear = () => {
    setFilterData(requestBody);
    dispatch(getDataBlogTags(requestBody));
  };

  return (
    <>
      <div className="bg-whites mt-4 pt-5">
        <div className="container-fluid inner-section">
          <SearchBar
            handleFormShow={handleFormShow}
            deleteModalOpen={deleteModalOpen}
            selectedCheckboxes={selectedCheckboxes}
            handleSelectedStatusUpdate={handleSelectedStatusUpdate}
            handleFilterUpdate={handleFilterUpdate}
            filterdata={filterdata}
            handleFilter={handleFilter}
            handleClear={handleClear}
            editBlog={editBlog}
          />
          <Listing
            blogTags={blogTags}
            totalCount={totalCount}
            loading={loading}
            error={error}
            handlePaginate={handlePaginate}
            currentPage={currentPage}
            limit={limit}
            handleFormShow={handleFormShow}
            deleteModalOpen={deleteModalOpen}
            handleCheckboxChange={handleCheckboxChange}
            setSelectedCheckboxes={setSelectedCheckboxes}
            handleStatus={handleStatus}
            editBlog={editBlog}
          />
        </div>
      </div>
      {formshow && (
        <AddUpdateBlogTag
          formshow={formshow}
          handleFormClose={handleFormClose}
          requestBody={requestBody}
          tagId={tagId}
        />
      )}
      <DeleteModal
        show={deleteshow}
        deleteModalOpen={deleteModalOpen}
        handleDeleteClose={handleDeleteClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default BlogTagIndex;
