import React from 'react'
import { getFilteredYear, getUserProfileStyles, stateListing } from '../../../common/common';

const SearchBar = (props) => {
    const { handleFilterUpdate, handleFilter, filterdata, search_string, handleClear } = props
    const handleSubmit = (event) => {
        event.preventDefault();
        handleFilter();
    };
    return (
        <>
            <div className="col-lg-12 mb-4">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="filter">
                                <h4>FILTER BY:</h4>
                            </div>
                        </div>

                        <div className="col form-group ">
                            <select className="form-select" name="grad_class" onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)} value={filterdata.grad_class} aria-label="Default select example">
                                <option value="">Class</option>
                                {getFilteredYear && getFilteredYear().map((year, index) => (
                                    <option key={index} value={year}>Class of {year}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col form-group">
                            <select className="form-select" name="style" onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)} value={filterdata.style} aria-label="Default select example">
                                <option value="">Position</option>
                                {Object.keys(getUserProfileStyles).map((key) => (
                                    <option key={key} value={key}>
                                        {getUserProfileStyles[key]}
                                    </option>
                                ))}

                            </select>
                        </div>
                        <div className="col form-group state-select">
                            <select className="form-select" name="state_code" onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)} value={filterdata.state_code} aria-label="Default select example">
                                <option value="">State</option>
                                {stateListing && stateListing().map((state, index) => (
                                    <option key={index} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input type="text" className="form-control" name="keyword" onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)} value={filterdata.keyword} placeholder="Player Search" />
                            </div>
                        </div>

                        <div className="col">
                            <div className="go-btn">
                                <button type="submit" name="btn_submit" className="btn btn-primary profile--filter-btn" onClick={()=>handleFilter}>GO</button>
                                <button type="button" name="btn_clear" className="clear--filters" onClick={(e) => {handleClear();}} >Clear</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SearchBar
