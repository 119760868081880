import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {getSingleMenubyId,deleteMenubyId,getSubNavigationByNavId} from "../../services/navigation/action";
import SubNavigationListing from "./subNavigationListing";
import SearchBar from "./searchBar";
import AddUpdateSubNavigation from "./subNavigationAddUpdate";
import DeleteModal from "../../common/deleteModal";
import { getPrivileges } from '../../common/adminPrivilege';

const SubNavigationIndex = () => {

  const dispatch = useDispatch();
  const { id } = useParams();
  const [subNavigation, setSubNavigation] = useState('');
  const [formshow, setFormShow] = useState(false);
  const subNavListing = useSelector((state) => state.navigationMenu.subNavigationMenues);
  const loading = useSelector((state) => state.navigationMenu.loading);
  const error = useSelector((state) => state.navigationMenu.error);
  const [deleteid, setDeleteId] = useState(null);
  const [deleteshow, setDeleteShow] = useState(false);
  const getPrivilege = getPrivileges();
  const editNavigation = getPrivilege.canEditNavigation();
  useEffect(() => {
    handleNavigationList();
  }, [id]);

  const handleNavigationList = async () => {
    try {
      const data = { nav_id: id };
      dispatch(getSubNavigationByNavId(data));
      } catch (error) {}
  };

  const handleFormClose = () => {
    setFormShow(false);
    setSubNavigation('');
  };
  const handleFormShow = async (link_id) => {
    try {
      if (typeof link_id === "number") {
        const data = { link_id: link_id, nav_id: id};
        const response = await getSingleMenubyId(data);
        const result = response.data;
        setSubNavigation(result);
      }
    } catch (error) {}
    setFormShow(true);
  };

  const handleDeleteClose = () => {
    setDeleteShow(false);
  };
  const deleteModalOpen = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };
  const handleDelete = async () => {
    try {
      let data = { "nlink_id": deleteid };
      await deleteMenubyId(data);
      handleNavigationList();
      handleDeleteClose();
    } catch (error) {}
  };
  return (
    <>
      <div className="bg-whites mt-4 pt-5">
        <div className="container-fluid inner-section">
          <SearchBar 
          handleFormShow={handleFormShow} 
          editNavigation={editNavigation}
          />
          <SubNavigationListing
            subNavListing={subNavListing}
            loading={loading}
            error={error}
            handleFormShow={handleFormShow}
            deleteModalOpen={deleteModalOpen}
            editNavigation={editNavigation}
          />
        </div>
      </div>

      {formshow && (
        <AddUpdateSubNavigation
          formshow={formshow}
          handleFormClose={handleFormClose}
          handleNavigationList={handleNavigationList}
          paramsId={id}
          subNavigation={subNavigation}
          subNavListing={subNavListing}
        />
      )}

      <DeleteModal
        show={deleteshow}
        deleteModalOpen={deleteModalOpen}
        handleDeleteClose={handleDeleteClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default SubNavigationIndex;
