import { useEffect, useState } from "react"
import Listing from './listing'
import SearchBar from './SearchBar'
import { useDispatch, useSelector } from "react-redux"
import { getDataOrders } from "../../services/manage-orders/action"
import ExportOrders from "./exportOrders"
import { getAdminPaginationLimit } from "../../../common/common"
import getPrivileges from "../../common/adminPrivilege"

const OrderIndex = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [exportFormShow, setExportFormShow] = useState(false);
    const limit = getAdminPaginationLimit();
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: ""
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch()
    const { orders, totalCount } = useSelector((state) => state.orders)
    const loading = useSelector((state) => state.orders.loading)
    const error = useSelector((state) => state.orders.error)
    const getPrivilege = getPrivileges();
    const editOrder = getPrivilege.canEditOrders();

    useEffect(() => {
        dispatch(getDataOrders(requestBody))
    }, []);

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataOrders(updatedFilterData))
    };

    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };

    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
            page: 1,
        };
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataOrders(updatedFilterData))
        }
    };

    const handleExportFormShow = () => {
        setExportFormShow(true);
    }

    const handleExportFormClose = () => {
        setExportFormShow(false);
    };

    const handleClear = () => {
        let updatedFilterData = {
            ...requestBody,
            page: 1,
        }
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        dispatch(getDataOrders(updatedFilterData));
    };

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataOrders(updatedFilterData));
    };

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFilterUpdate={handleFilterUpdate}
                        handleFilter={handleFilter}
                        handleExportFormShow={handleExportFormShow}
                        handleClear={handleClear}
                        editOrder={editOrder}
                    />
                    <Listing
                        orders={orders}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        handlePaginate={handlePaginate}
                        calculateSerialNumber={calculateSerialNumber}
                        limit={limit}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            {exportFormShow && (
                <ExportOrders
                    exportFormShow={exportFormShow}
                    handleExportFormClose={handleExportFormClose}
                    requestBody={requestBody}
                />
            )}
        </>
    )
}

export default OrderIndex
