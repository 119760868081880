import { getMetaTags, writeMetaTags } from "../services/meta-tags/action";
import { frontendstore } from "../store";
export const defineContentPageId = () => {
    const definePageid = {
        aboutPage: 1,
        termsConditionPge: 2,
        provacyPolicy: 3
    };
    return definePageid;
};

export const defineContentBlockKey = () => {
    const defineBlockKeys = {
        homeAboutKey: "home_about_us",
        homeAccountKey: "home_account_info",
        homeFeatureKey: "home_features",
        thankYouKey: "thank_you_page",
    };
    return defineBlockKeys;
};

export const headers = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    let token = userDetails ? userDetails.token : null;
    const header = {
        'Authorization': 'Bearer ' + token,
    }
    return header;
}

export const loadMetaTags = async (data) => {
    const response = await getMetaTags(data);
    frontendstore.dispatch(writeMetaTags(response.data));
}

export const validateRecaptcha = async (recaptchaToken) => {
    if (!recaptchaToken) {
        throw new Error('Please complete the CAPTCHA');
    }
};

export const convertYouTubeURL = (url) => {
    const shortUrlRegex = /youtu.be\/([a-zA-Z0-9_]+)\??/i;
    const longUrlRegex = /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?\/|.*[?&]v=)|youtu\.be\/))([^"&?/\s]{11})/i;

    let youtubeId = null;

    if (shortUrlRegex.test(url)) {
        const match = url.match(shortUrlRegex);
        youtubeId = match ? match[1] : null;
    } else if (longUrlRegex.test(url)) {
        const match = url.match(longUrlRegex);
        youtubeId = match ? match[1] : null;
    }

    return youtubeId ? `https://www.youtube.com/embed/${youtubeId}` : url;
};

export const addOverlay = () => {
    document.getElementById('overlay--loader').style.display = 'block';
    document.body.classList.add("body--loader");
}

export const removeOverlay = () => {
    document.getElementById('overlay--loader').style.display = 'none';
    document.body.classList.remove("body--loader");
}



export const SITE_TITLE = 'QB Hit List';

export const HOME_PAGE_META_TYPE = 1;
export const CMS_PAGE_META_TYPE = 2;
export const PROFILE_PAGE_META_TYPE = 3;
export const TRAINER_PAGE_META_TYPE = 4;
export const PROFILE_DETAIL_PAGE_META_TYPE = 5;
export const TRAINER_DETAIL_PAGE_META_TYPE = 6;
export const BLOG_PAGE_META_TYPE = 7;
export const BLOG_DETAIL_PAGE_META_TYPE = 8;
export const BLOG_CATEGORY_PAGE_META_TYPE = 9;
export const BLOG_TAG_PAGE_META_TYPE = 10;
export const PREMIUM_PROFILE_PAGE_META_TYPE = 11;
export const PREMIUM_OFFERS_PAGE_META_TYPE = 12;
export const ARCHIVE_PAGE_META_TYPE = 13;
export const TOP_RANKING_PAGE_META_TYPE = 14;
export const MEMBERSHIP_PAGE_META_TYPE = 15;
export const CONTACT_PAGE_META_TYPE = 16;
export const FAQ_PAGE_META_TYPE = 17;
export const BULLETIN_PAGE_META_TYPE = 18;