import Listing from "./listing";
import SearchBar from "./searchbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataContentBlocks, getContentBlockbyId, updateBlockContentStatus } from "../../services/content-block/action";
import AddUpdateContentBlock from "./addUpdateContentBlock";
import { getPrivileges } from '../../common/adminPrivilege';
import { getAdminPaginationLimit } from "../../../common/common";

const ContentBlockIndex = () => {
  const [contentBlockData, setContentBlockData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const limit = getAdminPaginationLimit();
  const [formshow, setFormShow] = useState(false);
  const requestBody = {
    page: currentPage,
    limit: limit,
    "keyword": "",
  };
  const [filterdata, setFilterData] = useState(requestBody);
  const dispatch = useDispatch();
  const { contentBlocks, totalCount } = useSelector((state) => state.contentBlock);
  const loading = useSelector((state) => state.contentBlock.loading);
  const error = useSelector((state) => state.contentBlock.error);
  const getPrivilege = getPrivileges();
  const editContentBlock = getPrivilege.canEditContentBlock();

  useEffect(() => {
    dispatch(getDataContentBlocks(requestBody));
  }, []);

  const handleFormClose = () => {
    setFormShow(false);
    setContentBlockData('')
  };

  const handleFormShow = async (id) => {
    try {
      if (typeof id === "number") {
        const response = await getContentBlockbyId(id);
        const result = response.data;
        setContentBlockData(result);
      }
    } catch (error) { }
    setFormShow(true);
  };

  const handleStatus = async (e, id, status) => {
    if (editContentBlock) {
      const newStatus = status === 1 ? 0 : 1;
      contentBlocks.map((block) => {
        if (block.cb_id === id) {
          block.cb_status = newStatus;
        }
        return block;
      });
      const data = {
        "status": newStatus,
        "id": [id],
      };
      try {
        await updateBlockContentStatus(data);
        dispatch(getDataContentBlocks(requestBody));
      } catch (error) { }
    }
  };

  const handleFilterUpdate = async (name, value, boolen) => {
    let updatedFilterData;
    updatedFilterData = {
      ...filterdata,
      [name]: value,
    };
    setFilterData(updatedFilterData);
    if (boolen === true) {
      dispatch(getDataContentBlocks(updatedFilterData));
    }
  };

  const handlePaginate = (selectedPage) => {
    const updatedFilterData = {
      ...filterdata,
      page: selectedPage.selected + 1,
    };
    setCurrentPage(selectedPage.selected + 1);
    setFilterData(updatedFilterData);
    dispatch(getDataContentBlocks(updatedFilterData));
  };

  const handleFilter = () => {
    let updatedFilterData = {
      ...filterdata,
      page: 1,
    }
    setCurrentPage(1)
    dispatch(getDataContentBlocks(updatedFilterData));
  };
  const handleClear = () => {
    let updatedFilterData = {
      ...requestBody,
      page: 1,
    }
    setCurrentPage(1)
    setFilterData(updatedFilterData);
    dispatch(getDataContentBlocks(updatedFilterData));
  };

  return (
    <>
      <div className="bg-whites mt-4 pt-5">
        <div className="container-fluid inner-section">
          <SearchBar
            handleFilterUpdate={handleFilterUpdate}
            handleFilter={handleFilter}
            handleClear={handleClear}
          />

          <Listing
            contentBlocks={contentBlocks}
            loading={loading}
            error={error}
            currentPage={currentPage}
            handlePaginate={handlePaginate}
            totalCount={totalCount}
            limit={limit}
            handleFormShow={handleFormShow}
            handleStatus={handleStatus}
            editContentBlock={editContentBlock}
          />
        </div>
      </div>

      {formshow && (
        <AddUpdateContentBlock
          formshow={formshow}
          handleFormClose={handleFormClose}
          contentBlockData={contentBlockData}
          editContentBlock={editContentBlock}
        />
      )}
    </>
  );
};
export default ContentBlockIndex;
