import React from 'react'
import { Link } from 'react-router-dom'

const BlogCategory = (props) => {
    const {categoryLoading, categoryError, blogCateogries} = props;
    return (
        <>
            {blogCateogries && blogCateogries.length > 0 && (
                <>
                    <div className="news-letter-title my-4">CATEGORIES</div>
                    <div className="categories">
                        <ul>
                            {blogCateogries && blogCateogries.length > 0 && blogCateogries.map((category, index) => (
                                <li key={index}><i className='bx bxs-chevrons-right'></i><Link to={`/category/${category["bcatUrl.urlrewrite_custom"]}`}> {category.bcat_title}</Link></li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </>
    )
}

export default BlogCategory
