import { getLabel } from "../../common/getLabel";
import { useFormik } from "formik";
import { updateGeneralSetting } from "../../services/general-settings/action";
import { getDataGeneralSettings } from "../../services/general-settings/action";
import { useDispatch } from "react-redux";
const ThirdPartyPage = (props) => {
    const { generalSettings, editSetting } = props;
    const dispatch = useDispatch();

    const initialValues = {
        CONF_GOOGLE_MAP_API: (generalSettings) ? generalSettings.CONF_GOOGLE_MAP_API : '',
        CONF_MAILCHIP_KEY: (generalSettings) ? generalSettings.CONF_MAILCHIP_KEY : '',
        CONF_MAILCHIMP_LIST_ID: (generalSettings) ? generalSettings.CONF_MAILCHIMP_LIST_ID : '',
        CONF_MAILCHIMP_SERVER_PREFIX: (generalSettings) ? generalSettings.CONF_MAILCHIMP_SERVER_PREFIX : '',
        CONF_V2_RECAPTCHA_SITE_KEY: (generalSettings) ? generalSettings.CONF_V2_RECAPTCHA_SITE_KEY : '',
        CONF_V2_RECAPTCHA_SECRET_KEY: (generalSettings) ? generalSettings.CONF_V2_RECAPTCHA_SECRET_KEY : '',
        CONF_V3_RECAPTCHA_SITE_KEY: (generalSettings) ? generalSettings.CONF_V3_RECAPTCHA_SITE_KEY : '',
        CONF_V3_RECAPTCHA_SECRET_KEY: (generalSettings) ? generalSettings.CONF_V3_RECAPTCHA_SECRET_KEY : '',
        CONF_STRIPE_PUBLISHED_KEY: (generalSettings) ? generalSettings.CONF_STRIPE_PUBLISHED_KEY : '',
        CONF_STRIPE_SECRET_KEY: (generalSettings) ? generalSettings.CONF_STRIPE_SECRET_KEY : '',
        CONF_MERCHANT_TRANSACTION_KEY: (generalSettings) ? generalSettings.CONF_MERCHANT_TRANSACTION_KEY : '',
        CONF_MERCHANT_LOGIN_ID: (generalSettings) ? generalSettings.CONF_MERCHANT_LOGIN_ID : '',
        CONF_ENABLE_LIVE_AUTHORIZE_NET_PRODUCTION_KEY: (generalSettings) ? generalSettings.CONF_ENABLE_LIVE_AUTHORIZE_NET_PRODUCTION_KEY : '',
        CONF_HEAD_SCRIPT: (generalSettings) ? generalSettings.CONF_HEAD_SCRIPT : '',
        CONF_BODY_SCRIPT: (generalSettings) ? generalSettings.CONF_BODY_SCRIPT : '',
        CONF_FOOTER_SCRIPT: (generalSettings) ? generalSettings.CONF_FOOTER_SCRIPT : '',
        CONF_LIVE_CHAT_CODE: (generalSettings) ? generalSettings.CONF_LIVE_CHAT_CODE : '',
        CONF_GOOGLE_MAP_IFRAME: (generalSettings) ? generalSettings.CONF_GOOGLE_MAP_IFRAME : '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (values) => {
            const data = Object.entries(values).map(([key, value]) => ({
                conf_name: key,
                conf_value: value ? value : '',
            }));
            try {
                await updateGeneralSetting(data);
                dispatch(getDataGeneralSettings());
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        if (editSetting) {
            formik.handleChange(e);
        }
    };

    var disable = "disabled";
    if (editSetting) {
        disable = "";
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row formcustom">
                    <div className="form-group col-md-12">
                        <label htmlFor="input">{getLabel("LBL_Google_Map_API")}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Google_Map_API")}
                            name="CONF_GOOGLE_MAP_API"
                            value={formik.values.CONF_GOOGLE_MAP_API}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="input">{getLabel("LBL_Mailchimp_Key")}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Mailchimp_Key")}
                            name="CONF_MAILCHIP_KEY"
                            value={formik.values.CONF_MAILCHIP_KEY}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="input">{getLabel("LBL_Mailchimp_List_Id")}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Mailchimp_List_Id")}
                            name="CONF_MAILCHIMP_LIST_ID"
                            value={formik.values.CONF_MAILCHIMP_LIST_ID}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="input">{getLabel("LBL_Mailchimp_Server_Prefix")}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Mailchimp_Server_Prefix")}
                            name="CONF_MAILCHIMP_SERVER_PREFIX"
                            value={formik.values.CONF_MAILCHIMP_SERVER_PREFIX}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Google_V2_Recaptcha_Site_Key")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Google_V2_Recaptcha_Site_Key")}
                            name="CONF_V2_RECAPTCHA_SITE_KEY"
                            value={formik.values.CONF_V2_RECAPTCHA_SITE_KEY}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Google_V2_Recaptcha_Secret_Key")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Google_V2_Recaptcha_Secret_Key")}
                            name="CONF_V2_RECAPTCHA_SECRET_KEY"
                            value={formik.values.CONF_V2_RECAPTCHA_SECRET_KEY}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Google_V3_Recaptcha_Site_Key")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Google_V3_Recaptcha_Site_Key")}
                            name="CONF_V3_RECAPTCHA_SITE_KEY"
                            value={formik.values.CONF_V3_RECAPTCHA_SITE_KEY}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Google_V3_Recaptcha_Secret_Key")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Google_V3_Recaptcha_Secret_Key")}
                            name="CONF_V3_RECAPTCHA_SECRET_KEY"
                            value={formik.values.CONF_V3_RECAPTCHA_SECRET_KEY}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <h3>{getLabel("LBL_Authorize_Net_API")}</h3>
                    <fieldset>
                        <label htmlFor="input">
                            {getLabel("LBL_Enable_Authorize_Net_Production_Key ")}
                        </label>
                        <div className="option--mode">
                            <input
                                type="radio"
                                name="CONF_ENABLE_LIVE_AUTHORIZE_NET_PRODUCTION_KEY"
                                value="1"
                                checked={formik.values.CONF_ENABLE_LIVE_AUTHORIZE_NET_PRODUCTION_KEY === "1"}
                                disabled={disable}
                                onChange={handleChange}
                            />
                            <label htmlFor="contactChoice1">Yes</label>
                            <input
                                type="radio"
                                name="CONF_ENABLE_LIVE_AUTHORIZE_NET_PRODUCTION_KEY"
                                value="0"
                                checked={formik.values.CONF_ENABLE_LIVE_AUTHORIZE_NET_PRODUCTION_KEY === "0"}
                                disabled={disable}
                                onChange={handleChange}
                            />
                            <label htmlFor="contactChoice2">No</label>
                        </div>
                    </fieldset>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Metchant_Login_Id")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Metchant_Login_Id")}
                            name="CONF_MERCHANT_LOGIN_ID"
                            value={formik.values.CONF_MERCHANT_LOGIN_ID}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_Merchant_Transaction_Key")}</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={getLabel("LBL_Merchant_Transaction_Key")}
                            name="CONF_MERCHANT_TRANSACTION_KEY"
                            value={formik.values.CONF_MERCHANT_TRANSACTION_KEY}
                            onChange={handleChange}
                            disabled={disable}
                        />
                    </div>
                    <h3>{getLabel("LBL_Google_Tag_Manager")}</h3>
                    <div className="form-group col-md-12">
                        <label htmlFor="input">{getLabel("LBL_Head_Script")}</label>
                        <textarea
                            className="form-control"
                            name="CONF_HEAD_SCRIPT"
                            value={formik.values.CONF_HEAD_SCRIPT}
                            onChange={handleChange}
                            rows="3"
                            required=""
                            disabled={disable}
                        ></textarea>
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="input">{getLabel("LBL_Body_Script")}</label>
                        <textarea
                            className="form-control"
                            name="CONF_BODY_SCRIPT"
                            value={formik.values.CONF_BODY_SCRIPT}
                            onChange={handleChange}
                            rows="3"
                            required=""
                            disabled={disable}
                        ></textarea>
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="input">{getLabel("LBL_Footer_Script")}</label>
                        <textarea
                            className="form-control"
                            name="CONF_FOOTER_SCRIPT"
                            value={formik.values.CONF_FOOTER_SCRIPT}
                            onChange={handleChange}
                            rows="3"
                            required=""
                            disabled={disable}
                        ></textarea>
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="input">{getLabel("LBL_Live_Chat_Code")}</label>
                        <textarea
                            className="form-control"
                            name="CONF_LIVE_CHAT_CODE"
                            value={formik.values.CONF_LIVE_CHAT_CODE}
                            onChange={handleChange}
                            rows="3"
                            required=""
                            disabled={disable}
                        ></textarea>
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="input">{getLabel("LBL_Google_Map_Iframe")}</label>
                        <textarea
                            className="form-control"
                            name="CONF_GOOGLE_MAP_IFRAME"
                            value={formik.values.CONF_GOOGLE_MAP_IFRAME}
                            onChange={handleChange}
                            rows="3"
                            required=""
                            disabled={disable}
                        ></textarea>
                    </div>
                </div>
                <br></br>
                {editSetting && (
                    <div className="form-group col-md-6">
                        <button className="btn btn-sm btn-secondary" type="submit">
                            {getLabel("LBL_Submit")}
                        </button>
                    </div>
                )}
            </form>
        </>
    );
};
export default ThirdPartyPage;
