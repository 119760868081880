import {
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
  } from "./type";
  
  const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
  const token = adminDetails ? adminDetails.token : null;
  const isLoggedIn = adminDetails ? adminDetails.isLoggedIn : null;
  
  const initialState = {
    token: token || null,
    error: null,
    isLoggedIn: isLoggedIn || false,
  };
  
  
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_SUCCESS:
        return {
          ...state,
          token: action.payload,
          error: null,
          isLoggedIn: true,
        };
      case LOGIN_FAILURE:
        return {
          ...state,
          token: null,
          error: action.payload,
        };
      case LOGOUT:
        return {
          ...state,
          token: null,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export { authReducer };