import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";

const getContentBlockbyKey = async(data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post(`/content-block`, data)
            .then((res) => {
                resolve(res.data.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export { getContentBlockbyKey };