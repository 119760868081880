import { getLabel } from "../../common/getLabel"
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
const Listing = (props) => {
    const { events, totalCount, loading, error, handleFormShow, handlePaginate, calculateSerialNumber, limit, editEvents, deleteModalOpen, currentPage } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    const formatEventDate = (date) => {
        if (date) {
            const start_date = new Date(date);
            return start_date.toISOString().split('T')[0];
        }
        return false;
    }
    return (
        <>
            <form id="blogTag-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_Profile_ID")}</th>
                            <th>{getLabel("LBL_Event_Name")}</th>
                            <th>{getLabel("LBL_Event_Start")}</th>
                            <th>{getLabel("LBL_Event_End")}</th>
                            <th className="table-action">{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={6} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="6" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {events && events.length > 0 ? (
                                    events.map((elem, index) => {
                                        return (
                                            <tr className="actions" key={index} >
                                                <td>{calculateSerialNumber(index)}</td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">
                                                            {elem.uevent_uprofile_id ?
                                                                `${elem["pevent.uprofile_fname"]} ${elem["pevent.uprofile_lname"]} (${elem.uevent_uprofile_id})` :
                                                                '0 (missing)'
                                                            }
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{elem.uevent_title} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{formatEventDate(elem.uevent_start_date)} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{formatEventDate(elem.uevent_end_date)} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="action">
                                                        <ul>
                                                            <li><a onClick={(() => handleFormShow(elem.uevent_id))}><i className='bx bx-pencil'></i></a></li>
                                                            {editEvents && (
                                                                <li><a><i className="bx bx-trash" onClick={() => deleteModalOpen(elem.uevent_id)} ></i></a></li>
                                                            )}
                                                        </ul>
                                                    </span>

                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="6" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {events && events.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    )
}

export default Listing