import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPrivileges } from '../../common/adminPrivilege';
import DeleteModal from "../../common/deleteModal"
import { getDataUserProfiles, getProfileData, deleteProfile, getAdsData, getTestimonials } from "../../services/manage-user-profiles/action";
import Listing from "./listing";
import SearchBar from "./SearchBar";
import AddUpdateTrainer from "./addUpdateTrainer";
import Testimonials from "./testimonials";
import ExportTrainer from "./exportTrainer";
import { getAdminPaginationLimit } from "../../../common/common";

const TrainerIndex = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [profileData, setProfileData] = useState('');
    const [testimonialData, setTestimonialData] = useState('');
    const [profileId, setProfileId] = useState('');
    const [adData, setAdData] = useState('');
    const limit = getAdminPaginationLimit();
    const [formshow, setFormShow] = useState(false);
    const [testimonialFormshow, setTestimonialFormShow] = useState(false);
    const [deleteshow, setDeleteShow] = useState(false);
    const [deleteid, setDeleteId] = useState(null)
    const [exportFormShow, setExportFormShow] = useState(false);
    const { profiles, totalCount } = useSelector((state) => state.profiles)
    const loading = useSelector((state) => state.profiles.loading)
    const error = useSelector((state) => state.profiles.error)
    const dispatch = useDispatch();
    const requestBody = {
        page: currentPage,
        limit: limit,
        is_trainer: "1",
        keyword: "",
        date_from: "",
        date_to: "",
        is_trainer: "1"
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const getPrivilege = getPrivileges();
    const editUserProfile = getPrivilege.canEditUserProfiles();

    useEffect(() => {
        dispatch(getDataUserProfiles(requestBody));
    }, []);

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataUserProfiles(updatedFilterData));
    };

    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        if(name == "keyword"){
            updatedFilterData.page = 1;
            setCurrentPage(1);
        }
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataUserProfiles(updatedFilterData));
        }
    };

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataUserProfiles(updatedFilterData))
    };

    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };

    const loadAdsData = async (id) => {
        const response = await getAdsData({ "profile_id": id });
        setAdData(response);
    }

    const handleProfileViewClose = () => {
        setFormShow(false);
        setProfileData('');
        setAdData('');
    }

    const handleTestimonialClose = () => {
        setTestimonialFormShow(false);
        setProfileData('');
    }

    const handleFormShow = async (id) => {
        await loadProfileData(id);
        setFormShow(true);
    }

    const handlesTestimonialFormShow = async (id) => {
        await loadTestimonialData(id);
        setTestimonialFormShow(true);
    }

    const loadProfileData = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getProfileData({ "profile_id": id });
                setProfileData(response);
                await loadAdsData(id);
                setProfileId(id);
            }
        } catch (error) { }
    }

    const loadTestimonialData = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getTestimonials({ "profileId": id });
                setTestimonialData(response);
                setProfileId(id);
            }
        } catch (error) { }
    }

    const handleClear = () => {
        let updatedFilterData = {
            ...requestBody,
            page: 1,
        }
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        dispatch(getDataUserProfiles(updatedFilterData));
    };

    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id);
    };

    const handleDeleteClose = () => {
        setDeleteShow(false);
    };

    const handleDelete = async () => {
        try {
            let data;
            if (typeof deleteid === "number") {
                data = [deleteid];
            }
            await deleteProfile({ "profile_id": data });
            dispatch(getDataUserProfiles(requestBody));
            handleDeleteClose();
        } catch (error) { }
    };

    const handleExportFormShow = () => {
        setExportFormShow(true);
    }

    const handleExportFormClose = () => {
        setExportFormShow(false);
    };

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFormShow={handleFormShow}
                        handleFilterUpdate={handleFilterUpdate}
                        handleFilter={handleFilter}
                        filterdata={filterdata}
                        handleClear={handleClear}
                        editUserProfile={editUserProfile}
                        handleExportFormShow={handleExportFormShow}
                    />
                    <Listing
                        profiles={profiles}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        limit={limit}
                        handlePaginate={handlePaginate}
                        calculateSerialNumber={calculateSerialNumber}
                        editUserProfile={editUserProfile}
                        handleFormShow={handleFormShow}
                        deleteModalOpen={deleteModalOpen}
                        handlesTestimonialFormShow={handlesTestimonialFormShow}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            {formshow &&
                <AddUpdateTrainer
                    profileId={profileId}
                    formshow={formshow}
                    handleProfileViewClose={handleProfileViewClose}
                    profileData={profileData}
                    adData={adData}
                    editUserProfile={editUserProfile}
                    requestBody={requestBody} />
            }
            {testimonialFormshow &&
                <Testimonials
                    testimonialData={testimonialData}
                    profileId={profileId}
                    testimonialFormshow={testimonialFormshow}
                    handleTestimonialClose={handleTestimonialClose}
                    editUserProfile={editUserProfile}
                    requestBody={requestBody} />
            }
            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />

            {exportFormShow && (
                <ExportTrainer
                    exportFormShow={exportFormShow}
                    handleExportFormClose={handleExportFormClose}
                    requestBody={requestBody}
                />
            )}
        </>
    )
}

export default TrainerIndex
