import { getLabel } from "../../common/getLabel";
import { useState } from "react";
const SearchBar = (props) => {
    const { handleFilterUpdate, handleFilter, handleClear } = props;
    const [searchItem, setSearchItem] = useState("")
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate('custom_url', event.target.value, false);
    };
    const handleCancel = () => {
        setSearchItem("");
        handleClear();
    };
    return (
        <>
            <div className="row pb-5">
                <div className="col leftspace">
                    <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
                        <input
                            className="search-input mx-4"
                            placeholder={getLabel("LBL_Search_by_Orginal_Name")}
                            name="custom_url"
                            value={searchItem}
                            onChange={(e) => {
                                handleInputChange(e);
                            }}
                        />
                        <div className="card-button">
                            <ul>
                                <li>
                                    <button
                                        type="submit"
                                        name="btn_submit"
                                        className="btn btn-brand btn-wide btn-search"
                                        onClick={() => handleFilter}
                                    >
                                        {getLabel("LBL_Search")}{" "}
                                    </button>
                                    <button
                                        type="button"
                                        name="btn_clear"
                                        className="btn btn-link btn_clear"
                                        onClick={(e) => {
                                            handleCancel();
                                        }}
                                    >
                                        {getLabel("LBL_Clear")}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default SearchBar;
