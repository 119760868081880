import { baseUrl } from "../../common/config";
import { getLabel } from "../../common/getLabel";
import Pagination from "../../common/reactPaginate";
import { formatDate, getUserProfileStyles } from "../../../common/common";
import TableSkeleton from "../../common/TableSkeleton";
import { Link } from "react-router-dom";
import { loginUserProfile } from "../../services/manage-users/action";
const Listing = (props) => {
    const { profiles, totalCount, loading, error, limit, handlePaginate, calculateSerialNumber, editUserProfile, handleFormShow, deleteModalOpen, handleAssignProfileData, currentPage } = props;
    const totalPages = Math.ceil(totalCount / limit);
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };

    const loginUser = async (id) => {
        try {
            const response = await loginUserProfile({ userId: id });
            if (response === true) {
                window.open('/qbhl-admin/account', '_blank');
            }
        } catch (error) { }
    };

    return (
        <>
            <form id="faq-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_FIRST")}</th>
                            <th>{getLabel("LBL_LAST")}</th>
                            <th>{getLabel("LBL_USERNAME(ID)")}</th>
                            <th>{getLabel("LBL_PROFILE_IMAGE")}</th>
                            <th>{getLabel("LBL_HS_STATE")}</th>
                            <th>{getLabel("LBL_CLASS")}</th>
                            <th>{getLabel("LBL_STYLE")}</th>
                            <th>{getLabel("LBL_Reg_Date")}</th>
                            <th>{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={10} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="10" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {profiles && profiles.length > 0 ? (
                                    profiles.map((elem, index) => (
                                        <tr className="actions" key={index}>
                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_fname}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_lname}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title profile_user_premium">
                                                        {elem.uprofile_user_id ? `${elem.user_username}(${elem.uprofile_user_id})` : 'NA'}
                                                        {(elem.uprofile_is_premium == 1 || elem.uprofile_premium_overide == 1) ? <i className="bx bx-trophy"></i> : ''}
                                                    </span>

                                                </div>
                                            </td>
                                            <td>
                                                <span className="userimg">
                                                    {elem.profile_image && elem.profile_image[0] &&
                                                        <img
                                                            src={`${baseUrl}/${elem.profile_image[0].afile_physical_path}`}
                                                            className="img-fluid"
                                                            style={{ width: "50px" }}
                                                        />
                                                    }
                                                </span>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_state_code}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_grad_class}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{getUserProfileStyles[elem.uprofile_style]}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{formatDate(elem.uprofile_added_at)}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="action">
                                                    <ul>
                                                        <li>
                                                            <a onClick={() => handleFormShow(elem.uprofile_id)}>
                                                                <i className='bx bx-pencil' variant="primary"></i>
                                                            </a>
                                                        </li>
                                                        {editUserProfile && (
                                                            <li>
                                                                <a>
                                                                    <i className="bx bx-trash" onClick={() => deleteModalOpen(elem.uprofile_id)}></i>
                                                                </a>
                                                            </li>
                                                        )}

                                                        <li>
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn btn-secondary dropdown-toggle dropdownMenuButton1"
                                                                    type="button"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                    key={index}
                                                                >
                                                                    <a href="#">
                                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                                    </a>
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    {editUserProfile && (
                                                                        <>
                                                                            <li>
                                                                                <span className="dropdown-item pointer" onClick={() => handleAssignProfileData(elem.uprofile_id, elem.user_username)}>
                                                                                    <i className='bx bxs-user-account'></i>{getLabel('LBL_Re-assign_profile')}
                                                                                </span>
                                                                            </li>
                                                                            {elem.uprofile_user_id ? (
                                                                                <li>
                                                                                    <span className="dropdown-item pointer" onClick={() => loginUser(elem.uprofile_user_id)}>
                                                                                        <i className="bx bx-user"></i>{getLabel('LBL_Log_into_User_Profile')}
                                                                                    </span>
                                                                                </li>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    <li>
                                                                        <Link className="dropdown-item pointer" to={`/profile/${elem["profileUrl.urlrewrite_custom"]}`} target="_blank">
                                                                            <i className="bx bx-show"></i>{getLabel('LBL_View_Profile')}
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="10" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {profiles && profiles.length > 0 && (
                <Pagination
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                    currentPage={currentPage} />
            )}
        </>
    )
}


export default Listing