import {
  MANAGE_SUBADMIN_ERROR,
  MANAGE_SUBADMIN_LOADING,
  MANAGE_SUBADMIN_SUCCESS,
  MANAGE_SUBADMIN_MODULE_PERMISSIONS,
} from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";
import { doLogout } from "../authentication/action";


const handleLoadingSubAdmin = (payload) => ({
  type: MANAGE_SUBADMIN_LOADING,
  payload,
});
const handleErrorSubAdmin = () => ({
  type: MANAGE_SUBADMIN_ERROR,
});
const handleSuccessSubAdmin = (payload) => ({
  type: MANAGE_SUBADMIN_SUCCESS,
  payload,
});
const handleSubAdminPermission = (payload) => ({
  type: MANAGE_SUBADMIN_MODULE_PERMISSIONS,
  payload,
});
const getDataSubAdmins = (data) => async (dispatch) => {
  dispatch(handleLoadingSubAdmin());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/subusers/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessSubAdmin(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    dispatch(handleErrorSubAdmin());
  }
};
// const getSubAdminbyId = async (id) => {
const getSubAdminbyId = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    myAxios
      // .get(`/admin/${id}`, { headers: headers() })
      .post("/subusers/search", data, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateSubAdmin = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/subusers/setup", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteSubAdminImage = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/image/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateSubAdminStatus = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/status", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updatePassword = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/password/update", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteSubAdmin = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const subAdminPermissionListing = async () => {
  return new Promise((resolve, reject) => {
    myAxios
      .post(`/modules`, "data", { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const setPermissionsByUserId = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/permission/${id}`, { headers: headers() })
      .then((res) => {
          dispatch(handleSubAdminPermission(res.data.data));
      })
      .catch((error) => {
        errorMessage(error, reject);
        if(error.response.status == 401){
          dispatch(doLogout());
        }
      });
  });
};

const getPermissionsByUserId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/permission/${id}`, { headers: headers() })
      .then((res) => {
        if (res.data.success === true) {
          resolve(res.data.data);
        }
        // return res.data;
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const getModuleList = async () => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/modules-list/`, { headers: headers() })
      .then((res) => {
        resolve(res.data.data);
        // return res.data;
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const updateSubAdminPermission = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/set-permission", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const updateSubAdminPassword = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/sub-user-password/update", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

export {
  getDataSubAdmins,
  getSubAdminbyId,
  updateSubAdmin,
  deleteSubAdminImage,
  updateSubAdminStatus,
  updatePassword,
  deleteSubAdmin,
  subAdminPermissionListing,
  setPermissionsByUserId,
  updateSubAdminPermission,
  getPermissionsByUserId,
  getModuleList,
  updateSubAdminPassword
};
