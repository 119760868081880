import { getLabel } from "../../common/getLabel";
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
const Listing = (props) => {
    const { error, coupons, loading, totalCount, handlePaginate, currentPage, limit, handleFormShow, handleStatus, deleteModalOpen, handleCheckboxChange, editCoupons, userTypes, subscriptionTypes } = props;

    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const calculateSerialNumber = (index) => (currentPage - 1) * limit + index + 1;
    const totalPages = Math.ceil(totalCount / limit);

    return (
        <>
            <form id="blogTag-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            {editCoupons && (
                                <th className="col-check">
                                    <input type="checkbox" className="selectAll-js" onChange={(e) => handleCheckboxChange(e, "selectedlavel")} />
                                </th>
                            )}
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_Coupon_code")}</th>
                            <th>{getLabel("LBL_Price")}</th>
                            <th>{getLabel("LBL_User_type")}</th>
                            <th>{getLabel("LBL_Subscription_type")}</th>
                            <th>{getLabel("LBL_Status")}</th>
                            <th className="table-action">{getLabel("LBL_Action")}</th>

                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={7} checkbox={true} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="8" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {coupons && coupons.length > 0 ? (
                                    coupons.map((elem, index) => (
                                        <tr className="actions" key={index}>
                                            {editCoupons && (
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        name="coupon_array[]"
                                                        value={elem.coupon_id}
                                                        className="selectItem--js"
                                                        onChange={(e) =>
                                                            handleCheckboxChange(e, elem.coupon_id)
                                                        }
                                                    />
                                                </th>
                                            )}
                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem.coupon_code}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem.coupon_price}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {userTypes[elem.coupon_user_type]}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {subscriptionTypes[elem.coupon_subscription_type]}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={elem.coupon_status ? 1 : 0}
                                                        onChange={(e) =>
                                                            handleStatus(e, elem.coupon_id, elem.coupon_status)
                                                        }
                                                        disabled={!editCoupons}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <span className="action">
                                                    <ul>

                                                        <li>
                                                            <button type="button" onClick={() => handleFormShow(elem.coupon_id)}>
                                                                <i className="bx bx-pencil" variant="primary" ></i>
                                                            </button>
                                                        </li>

                                                        {editCoupons && (
                                                            <li>
                                                                <button type="button" onClick={() => deleteModalOpen(elem.coupon_id)}>
                                                                    <i className="bx bx-trash" ></i>
                                                                </button>
                                                            </li>
                                                        )}

                                                    </ul>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="78" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {coupons && coupons.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    );
};

export default Listing;
