import { getLabel } from "../../common/getLabel";
import Pagination from "../../common/reactPaginate";
import ChangePasswordModal from "./changePassword";
import { useState } from "react";
import { loginUserProfile, sendAccountVerifyEmail, verifiedUser } from "../../services/manage-users/action";
import { useDispatch } from "react-redux";
import { getDataManageUsers } from "../../services/manage-users/action";
import { getUserTypes } from "../../../common/common";
import TableSkeleton from "../../common/TableSkeleton";
const Listing = (props) => {
    const {
        manageUsers,
        totalCount,
        loading,
        error,
        currentPage,
        limit,
        handlePaginate,
        handleStatus,
        deleteModalOpen,
        handleCheckboxChange,
        handleFormShow,
        requestBody,
        editUsers
    } = props;
    const [userId, setUserId] = useState(null);
    const dispatch = useDispatch();
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const calculateSerialNumber = (index) =>
        (currentPage - 1) * limit + index + 1;
    const totalPages = Math.ceil(totalCount / limit);
    const [creadientFormShow, setCreadientalFormShow] = useState(false);
    const handleCreadientalFormShow = async (id) => {
        setUserId(id);
        setCreadientalFormShow(true);
    };
    const handleVerifiedUser = async (id) => {
        try {
            await verifiedUser({ userId: id });
            dispatch(getDataManageUsers(requestBody));
        } catch (error) { }
    };
    const loginUser = async (id) => {
        try {
            const response = await loginUserProfile({ userId: id });
            if (response === true) {
                window.open('/qbhl-admin/account', '_blank');
            }
        } catch (error) { }
    };
    const handleAccountVerifyEmail = async (id) => {
        try {
            const response = await sendAccountVerifyEmail({ userId: id });
            if (response === true) {
                dispatch(getDataManageUsers(requestBody));
            }
        } catch (error) { }
    };
    return (
        <>
            <form id="faq-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="col-check">
                                <input
                                    type="checkbox"
                                    className="selectAll-js"
                                    onChange={(e) => handleCheckboxChange(e, "selectedlavel")}
                                />
                            </th>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_User_Name")}</th>
                            <th>{getLabel("LBL_User_Type")}</th>
                            <th>{getLabel("LBL_First_Name")}</th>
                            <th>{getLabel("LBL_Last_Name")}</th>
                            <th>{getLabel("LBL_Email")}</th>
                            <th>{getLabel("LBL_Reg_Date")}</th>
                            <th>{getLabel("LBL_Status")}</th>
                            <th>{getLabel("LBL_Verified")}</th>
                            <th className="table-action">{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={10} checkbox={true} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="11" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {manageUsers && manageUsers.length > 0 ? (
                                    manageUsers.map((elem, index) => {
                                        let formattedDate = '';
                                        if (elem['user_added_at']) {
                                            const date = new Date(elem['user_added_at']);
                                            formattedDate = date.toISOString().split('T')[0];
                                        }
                                        return (
                                            <tr className="actions" key={index}>
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        name="order_id_user[]"
                                                        value={elem["user_id"]}
                                                        className="selectItem--js"
                                                        onChange={(e) => handleCheckboxChange(e, elem["user_id"])}
                                                    />
                                                </th>
                                                <td>{calculateSerialNumber(index)}</td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">
                                                            {elem["user_username"]}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">
                                                            {getUserTypes[elem["user_type"]]}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">
                                                            {elem["user_fname"]}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">
                                                            {elem["user_lname"]}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">
                                                            {elem["user_email"]}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{formattedDate}</td>
                                                <td>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={elem["user_is_active"] ? 1 : 0}
                                                            onChange={(e) => handleStatus(e, elem["user_id"], elem["user_is_active"])}
                                                            disabled={!editUsers}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">
                                                            {elem["user_is_verified"] == 1 ? "Yes" : "No"}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="action">
                                                        <ul>
                                                            <li>
                                                                <a onClick={() => handleFormShow(elem["user_id"])}>
                                                                    <i className="bx bx-pencil" variant="primary" ></i>
                                                                </a>
                                                            </li>
                                                            {editUsers && (
                                                                <>
                                                                    <li>
                                                                        <a>
                                                                            <i className="bx bx-trash" onClick={() => deleteModalOpen(elem["user_id"])}></i>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <div className="dropdown">
                                                                            <button
                                                                                className="btn btn-secondary dropdown-toggle dropdownMenuButton1"
                                                                                type="button"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                key={index}
                                                                            >
                                                                                <a href="#">
                                                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                                                </a>
                                                                            </button>
                                                                            <ul
                                                                                className="dropdown-menu"
                                                                                aria-labelledby="dropdownMenuButton1"
                                                                            >
                                                                                <li>
                                                                                    <span className="dropdown-item pointer" onClick={() => handleCreadientalFormShow(elem["user_id"])}>
                                                                                        <i className="bx bx-lock-alt" />
                                                                                        Change Password
                                                                                    </span>
                                                                                </li>
                                                                                <li>
                                                                                    <span className="dropdown-item pointer" onClick={() => loginUser(elem["user_id"])}>
                                                                                        <i className="bx bx-user"></i>Log into User Profile
                                                                                    </span>
                                                                                </li>

                                                                                {elem["user_is_verified"] == 0 && (
                                                                                    <>
                                                                                        <li>
                                                                                            <span className="dropdown-item pointer" onClick={() => handleAccountVerifyEmail(elem["user_id"])} >
                                                                                                <i className="bx bx-mail-send"></i>
                                                                                                {getLabel(
                                                                                                    "LBL_Send_Account_Verification_Email"
                                                                                                )}
                                                                                            </span>
                                                                                        </li>
                                                                                        <li>
                                                                                            <span className="dropdown-item pointer" onClick={() => handleVerifiedUser(elem["user_id"])} >
                                                                                                <i className="bx bx-transfer"></i>
                                                                                                {getLabel("LBL_Verify_User")}
                                                                                            </span>
                                                                                        </li>
                                                                                    </>
                                                                                )}
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )}
                                                        </ul>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="11" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {manageUsers && manageUsers.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
            <ChangePasswordModal
                creadientFormShow={creadientFormShow}
                setCreadientalFormShow={setCreadientalFormShow}
                userId={userId}
            />
        </>
    );
};
export default Listing;
