import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { getProfileListing } from '../../../services/account/action';
import ProfileSkeletonLoading from '../../../common/skeleton-loading/profile-skeleton-loading';
import { getLabel } from '../../../../common/getLabel';
import { baseUrl } from '../../../common/config';
import { isEmpty } from '../../../../common/common';

const UserProfile = () => {
    const [profileListing, setProfileListing] = useState([]);
    const [userHasPremium, setUserHasPremium] = useState(false);
    const [freeProfileLimit, setFreeProfileLimit] = useState(0);
    const [freeProfileCount, setFreeProfileCount] = useState(0);
    const [isTrainerCreateProfile, setIsTrainerCreateProfile] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const delaySearch = setTimeout(() => {
            getProfileData();
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, []);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const user_type = userDetails ? userDetails.user_type : '';
    const getProfileData = async () => {
        const response = await getProfileListing();
        setLoading(false);
        setProfileListing(response.profiles);
        setUserHasPremium(response.userHasPremium);
        if (user_type != 3) {
            setFreeProfileCount(response.freeProfile);
            setFreeProfileLimit(response.freeProfileLimit);
        }
        if (user_type == 3 && isEmpty(response.profiles) === true) {
            setIsTrainerCreateProfile(true);
        }
    }
    return (
        <>
            <div className="player-list-bulletin-board">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <h2>{user_type == 3 ? getLabel('LBL_Trainer_Profile') : getLabel('LBL_My_Profiles')}</h2>
                            </div>
                        </div>
                        {loading ? (
                            <ProfileSkeletonLoading />
                        ) : (
                            <>
                                {profileListing && profileListing.length > 0 && profileListing.map((profile, index) => (
                                    <div key={index} className="col-lg-3 col-sm-6 mb-5">
                                        <div className="border-trainer">
                                            <div className="trainer-pro-info profile--info">
                                                {(profile.uprofile_is_premium || profile.uprofile_premium_overide) ? (
                                                    <img src="/images/premium-image.png" className="img-fluid premium-img profile-premium-img" />
                                                ) : (
                                                    <></>
                                                )}
                                                {profile.images ? (
                                                    <img src={`${baseUrl}/${profile.images[0].afile_physical_path}`} alt="Profile" className="img-fluid" />
                                                ) : (
                                                    <img src="/images/qbh-hit.jpg" alt="Profile" className="img-fluid" />
                                                )}
                                                <div className="overlay"></div>
                                                <div className="button">
                                                    <Link to={`/qbhl-admin/profile/${profile.uprofile_id}`}> {getLabel('LBL_EDIT_PROFILE')} </Link>
                                                    {!(profile.uprofile_is_premium || profile.uprofile_premium_overide) && user_type != 3 && (
                                                        <Link to={`/membership/?upgrade=${profile.uprofile_id}`}> {getLabel('LBL_UPGRADE_PROFILE')} </Link>
                                                    )}
                                                </div>
                                            </div>
                                            <p className="joe m-0">{user_type == 3 ? profile.uprofile_company_name : profile.uprofile_fname + " " + profile.uprofile_lname}</p>
                                            <p>{user_type == 3 ? profile.uprofile_state_code : profile.uprofile_grad_class}</p>
                                        </div>
                                    </div>
                                ))}
                                {userHasPremium ? (
                                    <div className="col-lg-3 col-sm-6 mb-5">
                                        <div className="border-trainer profile">
                                            <div className="trainer-pro-info">
                                                <img src="/images/premium-image.png" className="img-fluid premium-img" />
                                                <div className="overlay bg"></div>
                                                <div className="button">
                                                    <Link to={`/qbhl-admin/profile`} className="new--profile"><i className="bx bx-plus-medical"></i></Link>
                                                </div>
                                            </div>
                                            <p className="joe">
                                                {getLabel('LBL_CREATE_YOUR_PREMIUM_PROFILE')}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {(freeProfileCount < freeProfileLimit || (isTrainerCreateProfile === true)) && (
                                            <div className="col-lg-3 col-sm-6 mb-5">
                                                <div className="border-trainer profile">
                                                    <div className="trainer-pro-info">
                                                        <div className="overlay bg"></div>
                                                        <div className="button">
                                                            <Link to={`/qbhl-admin/profile`} className="new--profile"><i className="bx bx-plus-medical"></i></Link>
                                                        </div>
                                                    </div>
                                                    <p className="joe">
                                                        {getLabel('LBL_CREATE_YOUR_PROFILE')}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfile
