import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { rewriteUrlSchema } from "../../common/validation";
import { useFormik } from "formik";
import { useDispatch,useSelector } from "react-redux";
import {updateSeoUrl,getDataSeoUrls} from "../../services/url-rewriting/action";
const UpdateSeoUrlRewrite = (props) => {
  const { formshow, handleFormClose, requestBody,urlId } = props;
  const dispatch = useDispatch();

  const seoData = useSelector(state =>
    state.seoUrl.seoUrlListing.find(seoData => seoData.urlrewrite_id === urlId)
  )
  const initialValues = {
    urlrewrite_id:(seoData)?seoData.urlrewrite_id:'0',
    urlrewrite_original: (seoData)?seoData.urlrewrite_original:'',
    urlrewrite_custom: (seoData)?seoData.urlrewrite_custom:'',        
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: rewriteUrlSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        const response = await updateSeoUrl(values);
        if (response === true) {
          dispatch(getDataSeoUrls(requestBody));
          handleFormClose();
        }
      } catch (error) {}
    },
  });
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>
      <Modal
        show={formshow}
        onHide={handleFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Update_Url")}
          </h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">{getLabel("LBL_Original*")}</label>
                      <input
                        className="form-control"
                        type="text"
                        id="urlrewrite_original"
                        name="urlrewrite_original"
                        onChange={handleChange}
                        value={formik.values.urlrewrite_original}
                        placeholder={getLabel("LBL_Orginal")}
                        disabled={true}
                      />
                      <div>
                        {formik.touched.urlrewrite_original &&
                          formik.errors.urlrewrite_original && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.urlrewrite_original}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="inputState">
                      {getLabel("LBL_Custom*")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="urlrewrite_custom"
                      name="urlrewrite_custom"
                      onChange={handleChange}
                      value={formik.values.urlrewrite_custom}
                      placeholder={getLabel("LBL_Custom")}
                    />
                    <div>
                      {formik.touched.urlrewrite_custom &&
                        formik.errors.urlrewrite_custom && (
                          <p style={{ color: "#b94a48" }}>
                            {formik.errors.urlrewrite_custom}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFormClose}>
              {getLabel("LBL_Close")}
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Submit")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
export default UpdateSeoUrlRewrite;
