import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { updateNavigationLink } from "../../services/navigation/action";
import { navigationMenuDescriptionSchema } from "../../common/validation";
import { getDataContentPages } from "../../services/content-page/action";
import { useDispatch, useSelector } from "react-redux";

const AddUpdateSubNavigation = (props) => {
  const { formshow, handleFormClose, handleNavigationList, paramsId, subNavigation, subNavListing } = props;
  const filterSubNavListing = subNavListing ? subNavListing.filter(item => item.nlink_id !== subNavigation.nlink_id) : [];
  const [selecttedType, setSelectedType] = useState(subNavigation.nlink_type);
  const dispatch = useDispatch();
  const { contentPages } = useSelector((state) => state.contentPage)
  useEffect(() => {
    dispatch(getDataContentPages())
  }, [dispatch]);

  const initialValues = {
    id: (subNavigation) ? subNavigation.nlink_id : '',
    nav_id: paramsId,
    title: (subNavigation) ? subNavigation.nlink_title : '',
    type: (subNavigation) ? subNavigation.nlink_type : '',
    target: (subNavigation) ? subNavigation.nlink_target : '',
    display_order: (subNavigation) ? subNavigation.nlink_display_order : '',
    record_id: (subNavigation) ? subNavigation.nlink_record_id : '0',
    parent_id: (subNavigation) ? subNavigation.nlink_parent_id : '0',
    url: (subNavigation) ? subNavigation.nlink_url : '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: navigationMenuDescriptionSchema,
    onSubmit: async (values) => {
      try {
        await updateNavigationLink(values);
        handleNavigationList();
        handleFormClose();
      } catch (error) { }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "type") {
      setSelectedType(value);
    }
    formik.handleChange(e);
  };

  return (
    <>
      <Modal
        show={formshow}
        onHide={handleFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Navigation_Menu_Form")}
          </h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="input">{getLabel("LBL_Title*")}</label>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      onChange={handleChange}
                      value={formik.values.title}
                      placeholder={getLabel("LBL_Title")}
                    />
                    <div>
                      {formik.touched.title && formik.errors.title && (
                        <p style={{ color: "#b94a48" }}>{formik.errors.title}</p>
                      )}
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="inputState">{getLabel("LBL_Type*")}</label>
                    <select as="select" className="form-select" aria-label="Default select example" name="type" onChange={handleChange} value={formik.values.type} >
                      <option value="">{getLabel("LBL_Choose")}</option>
                      <option value="1">{getLabel("LBL_CMS_Page")}</option>
                      <option value="2">{getLabel("LBL_External_Link")}</option>
                    </select>
                    <div>
                      {formik.touched.type && formik.errors.type && (
                        <p style={{ color: "#b94a48" }}>{formik.errors.type}</p>
                      )}
                    </div>
                  </div>

                  {/* link */}
                  <div className="form-group col-md-12">
                    <label htmlFor="inputState">
                      {getLabel("LBL_Link_Target*")}
                    </label>
                    <select as="select" className="form-select" aria-label="Default select example" name="target" onChange={handleChange} value={formik.values.target}>
                      <option value="">{getLabel("LBL_Choose")}</option>
                      <option value="_self">{getLabel("LBL_Current_Window")}</option>
                      <option value="_blank">{getLabel("LBL_New_Window")}</option>
                    </select>
                    <div>
                      {formik.touched.target && formik.errors.target && (
                        <p style={{ color: "#b94a48" }}>
                          {formik.errors.target}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="inputState">
                      {getLabel("LBL_Parent_Menu")}
                    </label>
                    <select as="select" className="form-select" aria-label="Default select example" name="parent_id" onChange={handleChange} value={formik.values.parent_id}>
                      <option value="0">{getLabel("LBL_Choose")}</option>
                      {filterSubNavListing && filterSubNavListing.map((elem, index) => (
                        <option key={index} value={elem.nlink_id}>{elem.nlink_title}</option>
                      ))};
                    </select>
                  </div>
                  {/* dispalay order */}

                  <div className="col-lg-12">
                    <label htmlFor="input">
                      {getLabel("LBL_Display_Order*")}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="display_order"
                      onChange={handleChange}
                      value={formik.values.display_order}
                      placeholder={getLabel("LBL_Display_Order")}
                    />
                    <div>
                      {formik.touched.display_order && formik.errors.display_order && (
                        <p style={{ color: "#b94a48" }}>
                          {formik.errors.display_order}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* display ordeer */}

                  {/* cms page */}
                  {selecttedType == 1 && (
                    <div className="form-group col-md-12">
                      <label htmlFor="inputState">
                        {getLabel("LBL_Link_To_CMS_Page*")}
                      </label>
                      <select
                        as="select"
                        className="form-select"
                        aria-label="Default select example"
                        name="record_id"
                        onChange={handleChange}
                        value={formik.values.record_id}
                      >
                        <option value="">{getLabel("LBL_Choose")}</option>
                        {contentPages.length > 0 && contentPages.map((cpage, index) => (
                          <option key={index} value={cpage.cpage_id}>{cpage.cpage_title}</option>
                        ))}
                      </select>
                      <div>
                        {formik.touched.record_id &&
                          formik.errors.record_id && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.record_id}
                            </p>
                          )}
                      </div>
                    </div>
                  )}

                  {/* eternal link div*/}
                  {selecttedType == 2 && (
                    <div className="col-lg-12">
                      <label htmlFor="input">
                        {getLabel("LBL_External_Link*")}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="url"
                        onChange={handleChange}
                        value={formik.values.url}
                        placeholder={getLabel("LBL_External_Link")}
                      />
                      <div>
                        {formik.touched.url && formik.errors.url && (
                          <p style={{ color: "#b94a48" }}>
                            {formik.errors.url}
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFormClose}>
              {getLabel("LBL_Close")}
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Submit")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateSubNavigation;
