import React, { useState, useEffect } from 'react';
import HomeSlides from './slides';
import ContentBlocks from './content';
import { getContentBlockbyKey } from '../../services/content-block/action';
import { defineContentBlockKey, HOME_PAGE_META_TYPE, loadMetaTags } from '../../common/common';
import ContentBlockSkeleton from '../../common/skeleton-loading/content-skelton-loading';
import BannerSkeleton from '../../common/skeleton-loading/banner-skeleton-loading';
import { getDataBannersByKey, getPremiumProfiles, getPremiumOffers } from '../../services/home/action';
import PremiumProfiles from './premium-profiles';
import PremiumOffers from './premium-offers';
import HomeProfielSkeletonLoading from '../../common/skeleton-loading/home-profile-skeleton-loading';
import BlogPosts from '../../common/BlogPosts';
const HomePage = () => {
    var sliderSetting = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        autoplay: false,
        arrows: true,
        responsive: [
            { breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1, dots: false } },
            { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1, dots: false } },
            { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        ]
    };

    const contentBlockKey = defineContentBlockKey();

    const [banners, setBanners] = useState([]);
    const [contentBlocks, setContentBlocks] = useState([]);
    const [premiumProfiles, setPremiumProfiles] = useState([]);
    const [premiumOffers, setPremiumOffers] = useState([]);
    const [contentBlocksLoading, setContentBlocksLoading] = useState(true);
    const [bannersLoading, setBannersLoading] = useState(true);
    const [premiumProfileLoading, setPremiumProfileLoading] = useState(true);
    const [premiumOffersLoading, setPremiumOffersLoading] = useState(true);

    const metaTags = {
        meta_type: HOME_PAGE_META_TYPE,
        meta_title: 'Home',
        meta_record_id: 0
    }

    useEffect(() => {
        getHomePageBanners();
        loadMetaTags(metaTags);
        const delaySearch = setTimeout(() => {
            getContentBlocks();
            loadPremiumProfiles();
            loadPremiumOffers();
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, []);

    const getHomePageBanners = async () => {
        const response = await getDataBannersByKey({ "key": "home-slider" });
        setBannersLoading(false);
        setBanners(response);
    }

    const getContentBlocks = async () => {
        const response = await getContentBlockbyKey({ "block_key": [contentBlockKey.homeAboutKey, contentBlockKey.homeAccountKey, contentBlockKey.homeFeatureKey] });
        setContentBlocksLoading(false);
        setContentBlocks(response);
    }

    const loadPremiumProfiles = async () => {
        const response = await getPremiumProfiles();
        setPremiumProfileLoading(false);
        setPremiumProfiles(response);
    }

    const loadPremiumOffers = async () => {
        const response = await getPremiumOffers();
        setPremiumOffersLoading(false);
        setPremiumOffers(response);
    }

    return (
        <>
            {bannersLoading ? (
                <BannerSkeleton />
            ) : (
                <HomeSlides banners={banners} />
            )}

            {contentBlocksLoading ? (
                <ContentBlockSkeleton />
            ) : (
                <ContentBlocks contentBlocks={contentBlocks} contentBlockKey={contentBlockKey} />
            )}

            {premiumProfileLoading ? (
                <HomeProfielSkeletonLoading length={4} />
            ) : (
                <PremiumProfiles sliderSetting={sliderSetting} premiumProfiles={premiumProfiles} />
            )}

            <BlogPosts />

            {premiumOffersLoading ? (
                <HomeProfielSkeletonLoading length={4} />
            ) : (
                <PremiumOffers sliderSetting={sliderSetting} premiumOffers={premiumOffers} />
            )}

        </>
    );
};

export default HomePage;