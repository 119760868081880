import { getLabel } from "../../common/getLabel"
import { useState } from "react";
const SearchBar = (props) => {
    const { handleFilterUpdate, handleFormShow, editBanner, handleFilter, handleClear } = props;
    const [searchItem, setSearchItem] = useState("")
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate('keyword', event.target.value, false);
    };

    const handleCancel = () => {
        setSearchItem("");
        handleClear();
    };
    return (
        <>
            <div className="row pb-5">
                <div className="col leftspace">
                    <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
                        <input className="search-input mx-4"
                            placeholder={getLabel("LBL_Search_by_Title")}
                            name="keyword"
                            value={searchItem}
                            onChange={(e) => {
                                handleInputChange(e)
                            }}
                        />
                        <div className="card-button">
                            <ul>
                                <li>
                                    <button
                                        type="submit"
                                        name="btn_submit"
                                        className="btn btn-brand btn-wide btn-search"
                                        onClick={() => handleFilter}
                                    >
                                        {getLabel("LBL_Search")}{" "}
                                    </button>
                                    <button
                                        type="button"
                                        name="btn_clear"
                                        className="btn btn-link btn_clear"
                                        onClick={(e) => {
                                            handleCancel();
                                        }}
                                    >
                                        {getLabel("LBL_Clear")}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div >
                <div className="col-md-4 header--action">
                    {editBanner && (
                        <button type="button" className="btn btn-primary" onClick={handleFormShow}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "#fff", transform: "", msFilter: "" }}>
                                <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z"></path>
                                <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                            </svg>
                            {getLabel("LBL_Add_Banner")}
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}
export default SearchBar