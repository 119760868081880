import { legacy_createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { authReducer } from "./services/authentication/reducer";
import { blogReducer } from "./services/blog/reducer";
import { blogCategoryReducer } from "./services/blog-category/reducer";
import { blogTagsReducer } from "./services/blog-tags/reducer";
import { headerFooterReducer } from "./services/common/reducer";
import { membershipReducer } from "./services/membership/reducer";
import { profileReducer } from "./services/profiles/reducer";
import { archiveReducer } from "./services/top-archive/reducer";
import { premiumOfferReducer } from "./services/premium-offers/reducer";
import { premiumProfileReducer } from "./services/premium-profiles/reducer";
import { metaTagsReducer } from "./services/meta-tags/reducer";

const frontReducer = combineReducers({
    auth: authReducer,
    blog: blogReducer,
    blogCategory: blogCategoryReducer,
    blogTags: blogTagsReducer,
    headerFooter: headerFooterReducer,
    membership: membershipReducer,
    profiles: profileReducer,
    archives: archiveReducer,
    premiumOffers: premiumOfferReducer,
    premiumProfiles: premiumProfileReducer,
    metaTags: metaTagsReducer,
})
const frontendstore = legacy_createStore(frontReducer, applyMiddleware(thunk))
export { frontendstore } 