import UpdateEvent from './updateEvent'
import Listing from './listing'
import SearchBar from './SearchBar'
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getPrivileges } from '../../common/adminPrivilege';
import DeleteModal from "../../common/deleteModal";
import { getDataEvents, deleteEvent } from '../../services/manage-events/action'
import { getAdminPaginationLimit } from '../../../common/common'

const EventIndex = () => {
    const [eventId, setEventId] = useState(false);
    const [formshow, setFormShow] = useState(false);
    const [deleteid, setDeleteId] = useState(null);
    const [deleteshow, setDeleteShow] = useState(false);
    const getPrivilege = getPrivileges();
    const editEvents = getPrivilege.canEditEvents();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = getAdminPaginationLimit();
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: ""
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch()
    const { events, totalCount } = useSelector((state) => state.events)
    const loading = useSelector((state) => state.events.loading)
    const error = useSelector((state) => state.events.error)

    useEffect(() => {
        dispatch(getDataEvents(requestBody))
    }, []);

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                setEventId(id);
            }
        } catch (error) { }
        setFormShow(true);
    }
    const handleFormClose = () => {
        setEventId(false);
        setFormShow(false);
    }
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataEvents(updatedFilterData))
    };
    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };
    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
            page: 1
        };
        setCurrentPage(1);
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataEvents(updatedFilterData))
        }
    };

    const handleFilter = () => {
        dispatch(getDataEvents(filterdata));
    };

    const handleClear = () => {
        setFilterData(requestBody);
        dispatch(getDataEvents(requestBody));
    };

    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id);
    };

    const handleDeleteClose = () => {
        setDeleteShow(false);
    };

    const handleDelete = async () => {
        try {
            await deleteEvent({ "id": deleteid });
            dispatch(getDataEvents(requestBody));
            handleDeleteClose();
        } catch (error) { }
    };
    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFilterUpdate={handleFilterUpdate}
                        filterdata={filterdata}
                        handleFilter={handleFilter}
                        handleClear={handleClear}
                    />
                    <Listing
                        events={events}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        handleFormShow={handleFormShow}
                        handlePaginate={handlePaginate}
                        calculateSerialNumber={calculateSerialNumber}
                        limit={limit}
                        editEvents={editEvents}
                        deleteModalOpen={deleteModalOpen}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            {formshow &&
                <UpdateEvent
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    eventId={eventId}
                    requestBody={requestBody}
                    editEvents={editEvents}
                />
            }
            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
        </>
    )
}

export default EventIndex
