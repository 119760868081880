import { baseUrl } from "./config";
import { getmyAxios } from "../../common/myAxios";

let conf = {
  baseURL: baseUrl+'/admin',
  adminURL: true,
  customURL: false,
  urlType: 'admin'
}

export const myAxios = getmyAxios(conf);
