import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { accountVerify } from '../../services/authentication/action';
import { LoadingIndicator } from '../../../common/loadingIndicator';

const AccountVerify = () => {
    const [accountStatus, setAccountStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const param = useParams();
    const token = param.token;
    const navigate = useNavigate("")
    useEffect(() => {
        const verifyAccount = async () => {
            if (token === undefined || token === '' || token === null) {
                navigate('/');
                return;
            }
            try {
                const data = { token: token }
                const response = await accountVerify(data);
                if (response === true) {
                    setAccountStatus(true);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error Account Verification:', error);
            }
        };

        verifyAccount();
    }, [navigate, token]);
    return (
        <>
            <div className="account--verify">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <h2>Account Verification Status</h2>
                                {loading ? (
                                    <LoadingIndicator />
                                ) : (
                                    <>
                                        {accountStatus && (
                                            <>
                                                <p>Your Account is verified Successfully</p>
                                                <p><Link to="/log-in">Click Here</Link> to Log in Now</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountVerify
