import {
    MANAGE_USER_LOADING,
    MANAGE_USER_ERROR,
    MANAGE_USER_SUCCESS,
} from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { displayProcessing, errorMessage, successMessage } from "../../common/message";
import { toast } from "react-toastify";
import { getLabel } from "../../common/getLabel";
const handleLoadingManageUser = (payload) => ({
    type: MANAGE_USER_LOADING,
    payload,
});
const handleErrorManageUser = () => ({
    type: MANAGE_USER_ERROR,
});
const handleSuccessManageUser = (payload) => ({
    type: MANAGE_USER_SUCCESS,
    payload,
});
const getDataManageUsers = (data) => async (dispatch) => {
    dispatch(handleLoadingManageUser());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessManageUser(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in tag listing", error);
        dispatch(handleErrorManageUser());
    }
};
const updateUserStatus = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/status", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const deleteUser = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const getUserbyId = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios
            .get(`/user/${id}`, { headers: headers(), adminURL: false })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const updateUser = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/setup", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const deleteUserImage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/image/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const updatePassword = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/change-password", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const verifiedUser = async (data) => {
    displayProcessing();
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/verify", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const sendAccountVerifyEmail = async (data) => {
    displayProcessing();
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/verify-email", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const loginUserProfile = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/login/user-profile", data, { headers: headers() })
            .then((res) => {
                if (res.data.success === true) {
                    const token = res.data.data.token;
                    const user_id = res.data.data.user_id;
                    const user_fname = res.data.data.user_fname;
                    const user_type = res.data.data.user_type;
                    const device_id = res.data.data.deviceId;
                    let actionTime = new Date().getTime();
                    const ttl = 24 * 60 * 60 * 1000;
                    const userDetails = {
                        tokenTime: actionTime,
                        token: token,
                        user_id: user_id,
                        user_fname: user_fname,
                        user_type: user_type,
                        device_id: device_id,
                        isLogged: true,
                        expiry: actionTime + ttl,
                    }
                    localStorage.setItem('userDetails', JSON.stringify(userDetails));
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const exportUsers = async (data) => {
    const export_type = data.export_type;
    displayProcessing();
    const arg = {
        headers: headers()
    }
    if (export_type == 1) {
        arg.responseType = 'blob';
    }
    return new Promise((resolve, reject) => {
        myAxios
            .post("/export-user", data, arg)
            .then((res) => {
                if (export_type == 1) {
                    resolve(res.data);
                    toast.dismiss()
                } else {
                    successMessage(resolve, res);
                }
            })
            .catch((error) => {
                toast.error(getLabel('LBL_No_Record_Found'));
                errorMessage(error, reject);
            });
    });
};

export {
    getDataManageUsers,
    updateUserStatus,
    deleteUser,
    getUserbyId,
    updateUser,
    deleteUserImage,
    updatePassword,
    verifiedUser,
    exportUsers,
    loginUserProfile,
    sendAccountVerifyEmail
};
