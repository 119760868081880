import { Link } from "react-router-dom";
import { baseUrl } from "../../common/config";
import { getLabel } from "../../common/getLabel";
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
const Listing = (props) => {
    const { profiles, totalCount, loading, error, limit, handlePaginate, calculateSerialNumber, editUserProfile, handleFormShow, deleteModalOpen, handlesTestimonialFormShow, currentPage } = props;
    const totalPages = Math.ceil(totalCount / limit);
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };

    return (
        <>
            <form id="faq-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_ACCOUNTS_ID")}</th>
                            <th>{getLabel("LBL_PIC")}</th>
                            <th>{getLabel("LBL_NAME")}</th>
                            <th>{getLabel("LBL_BUSINESS")}</th>
                            <th>{getLabel("LBL_CITY")}</th>
                            <th>{getLabel("LBL_STATE")}</th>
                            <th>{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={10} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="10" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {profiles && profiles.length > 0 ? (
                                    profiles.map((elem, index) => (
                                        <tr className="actions" key={index}>
                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_user_id ? `${elem.user_username}(${elem.uprofile_user_id})` : 'NA'}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="userimg">
                                                    {elem.profile_image && elem.profile_image[0] &&
                                                        <img
                                                            src={`${baseUrl}/${elem.profile_image[0].afile_physical_path}`}
                                                            className="img-fluid"
                                                            style={{ width: "50px" }}
                                                        />
                                                    }
                                                </span>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_fname} {elem.uprofile_lname}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_company_name}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_city}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_state_code}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="action">
                                                    <ul>
                                                        <li>
                                                            <a onClick={() => handleFormShow(elem.uprofile_id)}>
                                                                <i className='bx bx-pencil' variant="primary"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={() => handlesTestimonialFormShow(elem.uprofile_id)}>
                                                                <i className='bx bx-note' variant="primary"></i>
                                                            </a>
                                                        </li>
                                                        {editUserProfile && (
                                                            <li>
                                                                <a>
                                                                    <i
                                                                        className="bx bx-trash"
                                                                        onClick={() => deleteModalOpen(elem.uprofile_id)}
                                                                    ></i>
                                                                </a>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <Link to={`/trainer-profile/${elem["profileUrl.urlrewrite_custom"]}`} target="_blank">
                                                                <i className="bx bx-show"></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="10" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {profiles && profiles.length > 0 && (
                <Pagination
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                    currentPage={currentPage} />
            )}
        </>
    )
}


export default Listing