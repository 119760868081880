import { getLabel } from "../../common/getLabel"
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
import { getUserProfileStyles } from "../../../common/common";
const Listing = (props) => {
    const { rankings, totalCount, loading, error, handlePaginate, calculateSerialNumber, limit, editRankings, currentPage } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                <thead>
                    <tr>
                        <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                        <th>{getLabel("LBL_Eval_Final")}</th>
                        <th>{getLabel("LBL_Name")}</th>
                        <th>{getLabel("LBL_Grad_Class")}</th>
                        <th>{getLabel("LBL_Style")}</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <>
                            <TableSkeleton cards={limit} count={5} />
                        </>
                    ) : error ? (
                        <tr className="no--record">
                            <td colSpan="5" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                        </tr>
                    ) : (
                        <>
                            {rankings && rankings.length > 0 ? (
                                rankings.map((elem, index) => {
                                    return (
                                        <tr className="actions" key={index} >
                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_eval_final} </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {`${elem.uprofile_fname} ${elem.uprofile_lname}`}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.uprofile_grad_class} </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{getUserProfileStyles[elem.uprofile_style]} </span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr className="no--record">
                                    <td colSpan="5" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </table>
            {rankings && rankings.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    )
}

export default Listing