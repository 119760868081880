import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";
import { BLOG_CATEGORY_LOADING, BLOG_CATEGORY_ERROR, BLOG_CATEGORY_SUCCESS } from "./types";

const handleLoadingBlogCategory = (payload) => ({
    type: BLOG_CATEGORY_LOADING,
    payload,
});
const handleErrorBlogCategory = () => ({
    type: BLOG_CATEGORY_ERROR,
});
const handleSuccessBlogCategory = (payload) => ({
    type: BLOG_CATEGORY_SUCCESS,
    payload,
});

const getBlogsCategory = () => async (dispatch) => {
    dispatch(handleLoadingBlogCategory());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .get("/blog/categories")
                .then((res) => {
                    dispatch(handleSuccessBlogCategory(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorBlogCategory());
    }
};

export { getBlogsCategory };