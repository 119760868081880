import { FAQ_CATEGORY_LOADING, FAQ_CATEGORY_ERROR, FAQ_CATEGORY_SUCCESS } from "./type";

const initState = {
    loading: true,
    error: false,
    faqs: [],
};

const faqCategoryReducer = (state = initState, action) => {
    switch (action.type) {
        case FAQ_CATEGORY_LOADING:
            return { ...state, loading: true };
        case FAQ_CATEGORY_ERROR:
            return { ...state, loading: false, error: true };
        case FAQ_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                faqCategory: action.payload.faqCategories,
                totalCount: action.payload.totalRecords,
            };
        default:
            return state;
    }
};

export { faqCategoryReducer };