import ReactPaginate from "react-paginate";


const Pagination = ({ totalPages, handlePageClick, currentPage = 0 }) => {
    return (

        <>
            <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                previousLabel="Previous"
                pageCount={totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                forcePage={currentPage - 1}
            />
        </>
    )
 }
export default Pagination