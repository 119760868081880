import { useEffect } from "react";
import Listing from "./listings";
import SearchBar from "./searchBar";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../common/deleteModal";
import { getPrivileges } from '../../common/adminPrivilege';
import { getDataSubAdmins, updateSubAdminStatus, deleteSubAdmin } from "../../services/manage-subadmin/action";
import { useState } from "react";
import AddUpdateSubAdmin from "./addUpdatesubAdmin";
import { getAdminPaginationLimit } from "../../../common/common";
const SubAdminUserIndex = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const limit = getAdminPaginationLimit();
    const [formshow, setFormShow] = useState(false);
    const [adminId, setAdminId] = useState(false);
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: '',
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const [deleteid, setDeleteId] = useState(null);
    const [deleteshow, setDeleteShow] = useState(false);
    const dispatch = useDispatch();
    const { subAdminListing, totalCount } = useSelector(
        (state) => state.manageSubAdmin
    );
    const loading = useSelector((state) => state.manageSubAdmin.loading);
    const error = useSelector((state) => state.manageSubAdmin.error);
    useEffect(() => {
        dispatch(getDataSubAdmins(requestBody));
    }, []);

    const getPrivilege = getPrivileges();
    const editSubAdmin = getPrivilege.canEditSubAdmin();

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                setAdminId(id);
            } else {
                setAdminId(false);
            }
        } catch (error) { }
        setFormShow(true);
    };

    const handleFormClose = () => {
        setFormShow(false);
    };

    const handleStatus = async (e, id, status) => {
        const newStatus = status === 1 ? 0 : 1;
        subAdminListing.map((user) => {
            if (user.admin_id === id) {
                user.admin_status = newStatus;
            }
            return user;
        });
        const data = {
            admin_status: newStatus,
            admin_id: [id],
        };

        try {
            await updateSubAdminStatus(data);
            dispatch(getDataSubAdmins(requestBody));
        } catch (error) { }
    };

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataSubAdmins(updatedFilterData));
    };

    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id);
    };

    const handleDeleteClose = () => {
        setDeleteShow(false);
    };

    const handleDelete = async () => {
        try {
            await deleteSubAdmin({ admin_id: deleteid });
            dispatch(getDataSubAdmins(requestBody));
            handleDeleteClose();
        } catch (error) { }
    };

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFormShow={handleFormShow}
                        editSubAdmin={editSubAdmin}
                    />

                    <Listing
                        subAdminListing={subAdminListing}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        handleFormShow={handleFormShow}
                        handlePaginate={handlePaginate}
                        currentPage={currentPage}
                        limit={limit}
                        handleStatus={handleStatus}
                        deleteModalOpen={deleteModalOpen}
                        editSubAdmin={editSubAdmin}
                    />
                </div>
            </div>

            {formshow && (
                <AddUpdateSubAdmin
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    adminId={adminId}
                    requestBody={requestBody}
                    editSubAdmin={editSubAdmin}
                />
            )}

            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default SubAdminUserIndex;
