import { HEADER_FOOTER_LOADING, HEADER_FOOTER_ERROR, HEADER_FOOTER_SUCCESS } from "./types";
import { myAxios } from "../../common/myAxios";
import { errorMessage, successMessage, displayProcessing } from "../../common/message";

const handleLoadingHeaderFooter = (payload) => ({
    type: HEADER_FOOTER_LOADING,
    payload,
});
const handleErrorHeaderFooter = () => ({
    type: HEADER_FOOTER_ERROR,
});
const handleSuccessHeaderFooter = (payload) => ({
    type: HEADER_FOOTER_SUCCESS,
    payload,
});

const getHeaderFooterContent = () => async (dispatch) => {
    dispatch(handleLoadingHeaderFooter());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .get("/header-footer")
                .then((res) => {
                    dispatch(handleSuccessHeaderFooter(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorHeaderFooter());
    }
};

const contactFormSubmit = async (data) => {
    displayProcessing();
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/contact-form", data)
                .then((res) => {
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const newsletterFormSubmit = async (data) => {
    displayProcessing();
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("http://beta.kalpartz.com/parts", data, { customURL: true })
                .then((res) => {
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

export { getHeaderFooterContent, contactFormSubmit, newsletterFormSubmit };