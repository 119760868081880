import { CONTENT_PAGE_LOADING, CONTENT_PAGE_ERROR, CONTENT_PAGE_SUCCESS } from "./type";
const initState = { loading: true, error: false, contentPages: [] };
const contentPageReducer = (state = initState, action) => {
    switch (action.type) {
        case CONTENT_PAGE_LOADING:
            return { ...state, loading: true };
        case CONTENT_PAGE_ERROR:
            return { ...state, loading: false, error: true };
        case CONTENT_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                contentPages: action.payload.cpListing,
                totalCount: action.payload.totalRecords,
            };
        default:
            return state;
    }
};

export { contentPageReducer };
