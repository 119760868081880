import { headers } from "../../common/common";
import { myAxios } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const getProfilebyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/admin/profile/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const updateProfile = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/admin/update/profile", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

export { getProfilebyId, updateProfile };
