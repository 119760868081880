import Listing from "./listing";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getDataNavigationMenues, updateMenuStatus } from "../../services/navigation/action";
import AddUpdateNavigationMenu from "./addUpdateNavigation";
import { getPrivileges } from '../../common/adminPrivilege';
import { getAdminPaginationLimit } from "../../../common/common";

const NavigationMenuIndex = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [navId, setNavId] = useState(false);
    const limit = getAdminPaginationLimit();
    const requestBody = {
        page: currentPage,
        limit: limit,
        RegDate: "",
        toDate: "",
    };
    const getPrivilege = getPrivileges();
    const editNavigation = getPrivilege.canEditNavigation();
    const [filterdata, setFilterData] = useState(requestBody);
    const [formshow, setFormShow] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDataNavigationMenues());
    }, []);
    const { navigationMenues, totalCount } = useSelector((state) => state.navigationMenu);
    const loading = useSelector((state) => state.navigationMenu.loading);
    const error = useSelector((state) => state.navigationMenu.error);

    const handleFormClose = () => {
        setFormShow(false);
        setNavId(false);
    };

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                setNavId(id);
            }
        } catch (error) { }
        setFormShow(true);
    };

    const handleStatus = async (e, id, status) => {
        if (editNavigation) {
            const newStatus = status === 1 ? 0 : 1;
            navigationMenues.map((elem) => {
                if (elem["nav_id"] === id) {
                    elem["nav_active"] = newStatus;
                }
                return elem;
            });
            const data = {
                ["nav_active"]: newStatus,
                ["nav_id"]: [id],
            };
            try {
                await updateMenuStatus(data);
                dispatch(getDataNavigationMenues(requestBody));
            } catch (error) { }
        }
    };

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataNavigationMenues(updatedFilterData));
    };

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <Listing
                        navigationMenues={navigationMenues}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        handlePaginate={handlePaginate}
                        currentPage={currentPage}
                        limit={limit}
                        handleFormShow={handleFormShow}
                        handleStatus={handleStatus}
                        editNavigation={editNavigation}
                    />
                </div>
            </div>
            {formshow && (
                <AddUpdateNavigationMenu
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    requestBody={requestBody}
                    navId={navId}
                />
            )}
        </>
    );
};

export default NavigationMenuIndex;
