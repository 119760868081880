import { PROFILE_LOADING, PROFILE_ERROR, PROFILE_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";

const handleLoadingProfile = (payload) => ({
    type: PROFILE_LOADING,
    payload,
});
const handleErrorProfile = () => ({
    type: PROFILE_ERROR,
});
const handleSuccessProfile = (payload) => ({
    type: PROFILE_SUCCESS,
    payload,
});

const getProfiles = (data) => async (dispatch) => {
    dispatch(handleLoadingProfile());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/search-profile", data)
                .then((res) => {
                    dispatch(handleSuccessProfile(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorProfile());
    }
};

const getProfileDetails = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post(`/profile-details`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export { getProfiles, getProfileDetails };