import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch,useSelector } from "react-redux";
import { subAdminSchema } from "../../common/validation";
import {updateSubAdmin,getDataSubAdmins} from "../../services/manage-subadmin/action";

const AddUpdateSubAdmin = (props) => {
  const {
    formshow,
    handleFormClose,
    requestBody,
    adminId,
    editSubAdmin
  } = props;
  
  const adminData = useSelector(state =>
    state.manageSubAdmin.subAdminListing.find(adminData => adminData.admin_id === adminId)
  )
  const dispatch = useDispatch();

  const initialValues = {
    admin_id: (adminData)?adminData.admin_id:'',
    admin_email: (adminData)?adminData.admin_email:'',
    admin_name: (adminData)?adminData.admin_name:'',
    admin_status: (adminData)?adminData.admin_status:'',
    admin_username: (adminData)?adminData.admin_username:'',
    admin_password: "",
    confirm_password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: subAdminSchema,
    validateOnChange:false,
    onSubmit: async (values) => {
      try {
        const response = await updateSubAdmin(values);
        if (response === true) {
          dispatch(getDataSubAdmins(requestBody));
          handleFormClose();
        }
      } catch (error) {}
    },
  });
  const handleChange = (e) => {
    formik.handleChange(e);
  };
  
  return (
    <>
      <Modal
        show={formshow}
        onHide={handleFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Add_Update_Users_Form")}
          </h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_Name*")}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="admin_name"
                        name="admin_name"
                        onChange={handleChange}
                        defaultValue={formik.values.admin_name}
                        placeholder={getLabel("LBL_Name")}
                        disabled={!editSubAdmin}
                      />
                      <div>
                        {formik.touched.admin_name && formik.errors.admin_name && (
                            <p style={{ color: "#b94a48" }}>{formik.errors.admin_name}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_Email_Adderss*")}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="admin_email"
                        name="admin_email"
                        onChange={handleChange}
                        defaultValue={formik.values.admin_email}
                        placeholder={getLabel("LBL_Email_Address")}
                        disabled={!editSubAdmin}
                      />
                      <div>
                        {formik.touched.admin_email &&
                          formik.errors.admin_email && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.admin_email}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  
                  {!adminData && (
                    <>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="input">
                            {getLabel("LBL_Username*")}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="admin_username"
                            name="admin_username"
                            onChange={handleChange}
                            value={formik.values.admin_username}
                            autoComplete="off"
                            placeholder={getLabel("LBL_Username")}
                            disabled={!editSubAdmin}
                          />
                          <div>
                            {formik.touched.admin_username &&
                              formik.errors.admin_username && (
                                <p style={{ color: "#b94a48" }}>
                                  {formik.errors.admin_username}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="input">
                            {getLabel("LBL_Password*")}
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            id="admin_password"
                            name="admin_password"
                            onChange={handleChange}
                            value={formik.values.admin_password}
                            autoComplete="off"
                            placeholder={getLabel("LBL_Password")}
                            disabled={!editSubAdmin}
                          />
                          <div>
                            {formik.touched.admin_password &&
                              formik.errors.admin_password && (
                                <p style={{ color: "#b94a48" }}>
                                  {formik.errors.admin_password}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="input">
                            {getLabel("LBL_Confirm_Password*")}
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            onChange={handleChange}
                            value={formik.values.confirm_password}
                            autoComplete="off"
                            placeholder={getLabel("LBL_Confirm_Password")}
                          />
                          <div>
                            {formik.touched.confirm_password &&
                              formik.errors.confirm_password && (
                                <p style={{ color: "#b94a48" }}>
                                  {formik.errors.confirm_password}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group col-md-12">
                    <label htmlFor="inputState">
                      {getLabel("LBL_Status*")}
                    </label>
                    <select
                      as="select"
                      className="form-select"
                      aria-label="Default select example"
                      id="admin_status"
                      name="admin_status"
                      onChange={handleChange}
                      disabled={!editSubAdmin}
                      value={formik.values.admin_status}>
                      <option value="">{getLabel("LBL_Choose")}</option>
                      <option value="1">{getLabel("LBL_Active")}</option>
                      <option value="0">{getLabel("LBL_InActive")}</option>
                    </select>
                    <div>
                      {formik.touched.admin_status && formik.errors.admin_status && (
                        <p style={{ color: "#b94a48" }}>{formik.errors.admin_status}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFormClose}>
              {getLabel("LBL_Close")}
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Submit")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

    </>
  );
};
export default AddUpdateSubAdmin;
