import { Link } from "react-router-dom"
import React from 'react';
import { Outlet } from 'react-router-dom';
import { baseUrl } from './config';
import { getLabel } from './getLabel';
import useCookie from "./useCookie";
import { doLogout } from "../services/authentication/action";
import { useDispatch } from "react-redux";

const Navbar = () => {
    const dispatch = useDispatch();
    const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    const admin_name = adminDetails ? adminDetails.admin_name : null;
    const admin_image = adminDetails ? adminDetails.admin_image : null;
    const [adminSidebarCookie, setAdminSidebarCookie] = useCookie('adminSidebar');

    const handleSidebarToggle = () => {
        if (adminSidebarCookie == "1") {
            setAdminSidebarCookie('0', 30);
        } else {
            setAdminSidebarCookie('1', 30);
        }
    };

    const handleLogout = () => {
        dispatch(doLogout());
    };
    return (
        <>
            <section className="home-section">

                <div className="home-content">
                    <i className="bx bx-menu" onClick={handleSidebarToggle} ></i>
                    <span className="text">{getLabel('LBL_Dashboard')}</span>
                    <div className="dropdown user">
                        <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/admin/profile" data-bs-toggle="dropdown" aria-expanded="false">
                            {admin_image ? (
                                <img src={`${baseUrl}/${admin_image}`} alt="Profile" className="rounded-circle" />
                            ) : (
                                <img src="/images/man.png" alt="Profile" className="rounded-circle" />
                            )}
                            <span className="d-none d-md-block dropdown-toggle ps-2">{admin_name}</span>
                        </Link>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><Link className="dropdown-item" to="/admin/profile"><i className='bx bx-user' ></i> {getLabel('LBL_My_Profile')}</Link></li>
                            <li><Link className="dropdown-item" to="/admin/configuration"><i className='bx bx-cog' ></i> {getLabel('LBL_Account_Settings')}</Link></li>
                            <li><a className="dropdown-item" onClick={handleLogout}> <i className='bx bx-arrow-to-right' ></i> {getLabel('LBL_Sign_Out')}</a></li>
                        </ul>
                    </div>
                </div>

                <Outlet />

            </section>
        </>
    )
}


export default Navbar