import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BlogSkeletonLoading = ({length}) => {
    const baseColor = "#ebebeb";
    const highlightColor = "#cfcfcfab";
    return (
        Array.from({ length: length }, (_, i) => (
            <div key={i} className="col-lg-4 col-sm-6">
                <div className="news-listing">
                    <div className="new-image">
                        <Skeleton baseColor={baseColor} highlightColor={highlightColor} height={260} style={{ lineHeight: "unset" }} />
                    </div>
                    <h5><Skeleton baseColor={baseColor} highlightColor={highlightColor} height={30} /></h5>
                    <Skeleton baseColor={baseColor} highlightColor={highlightColor} width={150} />
                    <p className="m-0"><Skeleton baseColor={baseColor} highlightColor={highlightColor} count={3} height={10} /></p>
                    <Skeleton baseColor={baseColor} highlightColor={highlightColor} width={120} height={40} />
                </div>
            </div>
        ))
    )
}

export default BlogSkeletonLoading
