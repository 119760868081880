import { BLOG_TAG_ERROR, BLOG_TAG_LOADING, BLOG_TAG_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";

const handleLoadingBlogTag = (payload) => ({
  type: BLOG_TAG_LOADING,
  payload,
});
const handleErrorBlogTag = () => ({
  type: BLOG_TAG_ERROR,
});
const handleSuccessBlogTag = (payload) => ({
  type: BLOG_TAG_SUCCESS,
  payload,
});

const getDataBlogTags = (data) => async (dispatch) => {
  dispatch(handleLoadingBlogTag());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/blog/tag/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessBlogTag(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    dispatch(handleErrorBlogTag());
  }
};

const getBlogTagbyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/blog/tag/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateBlogTag = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/tag/setup", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteBlogTag = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/tag/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateBlogStatus = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/tag/updateStatus", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

export {
  getDataBlogTags,
  getBlogTagbyId,
  updateBlogTag,
  deleteBlogTag,
  updateBlogStatus,
};
