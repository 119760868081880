import React from 'react'
import { baseUrl } from '../../common/config';
import { Link } from 'react-router-dom';

const HomeSlides = (props) => {
    const { banners } = props;
    let sliderButtonCount = 0;
    let sliderCount = 0;
    return (
        <>
            <div className="slider-custom">
                {banners && banners.length > 0 && (
                    <div id="carouselExampleCaptions" className="carousel slide">
                        <div className="carousel-indicators">
                            {banners && banners.length > 1 && banners.map((banner, index) => {
                                return (
                                    <React.Fragment key={`indicator-${index}`}>
                                        {banner.images && (
                                            <button key={sliderButtonCount} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={sliderButtonCount++} className={`${sliderButtonCount === 1 ? 'active' : ''}`} aria-current="true" aria-label={`Slide ${sliderButtonCount}`} ></button>
                                        )}
                                    </React.Fragment>
                                );
                            })}

                        </div>
                        <div className="carousel-inner">
                            {banners && banners.map((banner, index) => {
                                if (banner.images) {
                                    sliderCount++;
                                    return (
                                        <React.Fragment key={`innner-${index}`}>
                                            <div key={sliderCount} className={`carousel-item ${sliderCount === 1 ? 'active' : ''}`}>
                                                <img src={`${baseUrl}/${banner.images[0].afile_physical_path}`} className="d-block w-100 img-fluid" loading="lazy" width="1920" height="786" alt={banner.images && banner.images[0] && banner.images[0].afile_name ? banner.images[0].afile_name : `slider-${sliderCount}`} />
                                                <div className="carousel-caption d-md-block">
                                                    <p className="sub-heading">{banner.banner_sub_heading}</p>
                                                    <p className="inner-sub-heading">{banner.banner_heading}</p>
                                                    <div className="btn-slide">
                                                        <Link to={banner.banner_url} className="btn btn-primary" target={banner.banner_target}>{banner.banner_button_label}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                }
                            })}
                        </div>
                        {banners && banners.length > 1 && (
                            <>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="left-arrow"><i className='bx bxs-chevron-left'></i></span></button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="left-arrow"><i className='bx bxs-chevron-right'></i></span></button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default HomeSlides
