import React from 'react'
import { stateListing } from '../../../common/common';

const SearchBar = (props) => {
    const { handleFilterUpdate, handleFilter, filterdata } = props
    const handleSubmit = (event) => {
        event.preventDefault();
        handleFilter();
    };
    return (
        <>
            <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="filter">
                                <h4>FILTER BY:</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 form-group state">
                            <select className="form-select" name="state_code" onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)} value={filterdata.state_code} aria-label="Default select example">
                                <option value="">State</option>
                                {stateListing && stateListing().map((state, index) => (
                                    <option key={index} value={state}>{state}</option>
                                ))}
                            </select>

                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="form-group triner-search">
                                <input type="text" className="form-control" name="keyword" onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)} value={filterdata.keyword} placeholder="Trainer Search" />
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-2">
                            <div className="go-btn">
                                <button type="submit" name="btn_submit" className="btn btn-primary" onClick={handleFilter}>GO</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SearchBar
