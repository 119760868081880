import { myAxios } from "../../common/myAxios";

export const getConfImageByType = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("frontend/common/find-images", data)
            .then((res) => {
                if(res.data.success === true){
                    resolve(res.data.data);
                }
            })
            .catch((error) => {
            });
    });
};