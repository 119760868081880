import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProfileSkeletonLoading = () => {
    const baseColor = "#ebebeb";
    const highlightColor = "#cfcfcfab";
    return (
        Array.from({ length: 8 }, (_, i) => (
            <div key={i} className="col-lg-3 col-sm-6 mb-5">
                <div className="border-trainer">
                    <div className="trainer-pro-info">
                        <Skeleton baseColor={baseColor} highlightColor={highlightColor} height={300} width={306} style={{ lineHeight: "unset" }} />
                    </div>
                    <p className="joe m-0"><Skeleton width={150} baseColor={baseColor} highlightColor={highlightColor} height={30} /></p>
                    <p><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={100} height={30} /></p>
                </div>
            </div>
        ))
    )
}

export default ProfileSkeletonLoading
