import { DEFAULT_META_TAGS_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
export const loadDefaultMeta = (data) => {
    return {
        type: DEFAULT_META_TAGS_SUCCESS,
        payload: data,
    };
};

export const getDefaultMeta = () => (dispatch) => {
    try {
        return new Promise(() => {
            myAxios
                .get("/default-meta", '', { adminURL: false })
                .then((res) => {
                    dispatch(loadDefaultMeta(res.data.data));
                })
                .catch(() => {
                });
        });
    } catch (error) {

    }
};