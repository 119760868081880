const BackendPasswordIndex = () => {
    return (
        <>
            <div className="bg-form admin--404">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form my-5">
                                <img src="/images/404-file.svg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default BackendPasswordIndex
