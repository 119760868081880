import { Link, Outlet, useLocation } from "react-router-dom"
import { useSelector } from "react-redux";
import { getPrivileges } from './adminPrivilege';
import { getLabel } from "./getLabel";
import { getConfImageByType } from "../../common-services/general-setting/action";
import { useEffect, useState } from "react";
import { baseUrl } from "./config";
import useCookie from "./useCookie";

const Sidebar = () => {
    useSelector((state) => state)
    const getPrivilege = getPrivileges();
    const viewSubAdmin = getPrivilege.canViewSubAdmin();
    const viewBanner = getPrivilege.canViewBanner();
    const viewBlog = getPrivilege.canViewBlog();
    const viewUsers = getPrivilege.canViewUser();
    const viewLabel = getPrivilege.canViewLabel();
    const canViewFaq = getPrivilege.canViewFaq();
    const canViewContentPage = getPrivilege.canViewContentPage();
    const canViewContentBlock = getPrivilege.canViewContentBlock();
    const canViewConfiguration = getPrivilege.canViewConfiguration();
    const canViewNavigation = getPrivilege.canViewNavigation();
    const canViewUserProfiles = getPrivilege.canViewUserProfiles();
    const canViewSchoolLogs = getPrivilege.canViewSchoolLogs();
    const canViewOffers = getPrivilege.canViewOffers();
    const canViewEvents = getPrivilege.canViewEvents();
    const canViewRankings = getPrivilege.canViewRankings();
    const canViewOrders = getPrivilege.canViewOrders();
    const canViewCoupons = getPrivilege.canViewCoupons();
    const canViewUrlRewrite = getPrivilege.canViewUrlRewrite();
    const canViewMetaTags = getPrivilege.canViewMetaTags();

    const [siteLogo, setSiteLogo] = useState(false);

    useEffect(() => {
        getSiteLogo();
    }, []);

    const getSiteLogo = async () => {
        const response = await getConfImageByType({ "types": [25] });
        if (response !== null) {
            setSiteLogo(response[0].afile_physical_path);
        }
    }

    const [adminSidebarCookie, setAdminSidebarCookie] = useCookie('adminSidebar');


    const location = useLocation();

    const ADMIN_DASHBOARD_PATH = "/admin/dashboard";
    const ADMIN_LABEL_PATH = "/admin/label";
    const ADMIN_FAQ_CATEGORY_PATH = "/admin/faqs-category";
    const ADMIN_FAQ_PATH = "/admin/faqs";
    const ADMIN_BANNER_PATH = "/admin/banners";
    const ADMIN_CONTENT_PAGE_PATH = "/admin/content-pages";
    const ADMIN_CONTENT_BLOCK_PATH = "/admin/content-blocks";
    const ADMIN_NAVIGATION_PATH = "/admin/navigation";
    const ADMIN_BLOG_PATH = "/admin/blog";
    const ADMIN_BLOG_TAG_PATH = "/admin/blog-tag";
    const ADMIN_BLOG_CATEGORY_PATH = "/admin/blog-category";
    const ADMIN_CONFIGURATION_PATH = "/admin/configuration";
    const ADMIN_USERS_PATH = "/admin/users";
    const ADMIN_SUB_ADMIN_USERS_PATH = "/admin/sub-admin";
    const ADMIN_SUB_ADMIN_USERS_PROFILES_PATH = "/admin/user-profile";
    const ADMIN_SUB_ADMIN_TRAINERS_PATH = "/admin/trainers";
    const ADMIN_PROFILE_PATH = "/admin/profile";
    const ADMIN_META_TAGS_PATH = "/admin/metatags";
    const ADMIN_URL_REWRITING_PATH = "/admin/url-rewriting";
    const ADMIN_SCHOOL_LOGS_PATH = "/admin/school-logos";
    const ADMIN_OFFERS_PATH = "/admin/offers";
    const ADMIN_EVENTS_PATH = "/admin/events";
    const ADMIN_RANKING_PATH = "/admin/rankings";
    const ADMIN_ORDER_PATH = "/admin/orders";
    const ADMIN_COUPON_PATH = "/admin/coupons";

    const cmsMenusArr = [
        ADMIN_LABEL_PATH,
        ADMIN_FAQ_CATEGORY_PATH,
        ADMIN_FAQ_PATH,
        ADMIN_BANNER_PATH,
        ADMIN_CONTENT_PAGE_PATH,
        ADMIN_CONTENT_BLOCK_PATH,
        ADMIN_NAVIGATION_PATH
    ];

    const blogMenuArr = [
        ADMIN_BLOG_PATH,
        ADMIN_BLOG_TAG_PATH,
        ADMIN_BLOG_CATEGORY_PATH
    ];

    const orderMenuArr = [
        ADMIN_ORDER_PATH,
        ADMIN_COUPON_PATH
    ];

    const offerEventsMenuArr = [
        ADMIN_SCHOOL_LOGS_PATH,
        ADMIN_OFFERS_PATH,
        ADMIN_EVENTS_PATH,
    ];

    const userMenuArr = [
        ADMIN_USERS_PATH,
        ADMIN_SUB_ADMIN_USERS_PATH,
        ADMIN_SUB_ADMIN_USERS_PROFILES_PATH,
        ADMIN_SUB_ADMIN_TRAINERS_PATH,
        ADMIN_RANKING_PATH
    ];

    const seoMenuArr = [
        ADMIN_META_TAGS_PATH,
        ADMIN_URL_REWRITING_PATH
    ];

    const isInArray = (array, value) => {
        return array.includes(value);
    };

    return (
        <>
            <div className={`sidebar ${adminSidebarCookie == "1" ? '' : 'close'}`}>
                <div className="logo-details">
                    <Link to={ADMIN_DASHBOARD_PATH}>
                        <span className="text">
                            <img src={siteLogo ? `${baseUrl}/${siteLogo}` : "/images/logo.png"} className="img-fluid" alt="Image link break" />
                        </span>
                    </Link>
                </div>

                <ul className="nav-links">
                    {(viewUsers || canViewUserProfiles) && (
                        <li className={location.pathname === ADMIN_DASHBOARD_PATH ? 'active' : ''}>
                            <Link to={ADMIN_DASHBOARD_PATH}>
                                <i className='bx bx-grid-alt' ></i>
                                <span className="link_name">{getLabel("LBL_Dashboard")}</span>
                            </Link>
                        </li>
                    )}
                    {(viewUsers || viewSubAdmin || canViewUserProfiles || canViewRankings) && (
                        <li>
                            <div className="iocn-link">
                                <a className={`arrow ${isInArray(userMenuArr, location.pathname) ? 'active' : ''}`}>
                                    <i className='bx bx-user-plus' ></i>
                                    <span className="link_name">{getLabel("LBL_Manage_Users")}</span>
                                </a>
                                <i className='bx bxs-chevron-down arrow' ></i>
                            </div>
                            <ul className="sub-menu" style={{ display: isInArray(userMenuArr, location.pathname) ? 'block' : 'none' }}>
                                {viewUsers && (<li className={location.pathname === ADMIN_USERS_PATH ? 'active' : ''}><Link to={ADMIN_USERS_PATH}>{getLabel("LBL_Manage_Users")}</Link></li>)}
                                {viewSubAdmin && (<li className={location.pathname === ADMIN_SUB_ADMIN_USERS_PATH ? 'active' : ''}><Link to={ADMIN_SUB_ADMIN_USERS_PATH}>{getLabel("LBL_Sub_Admin_Users")}</Link></li>)}
                                {canViewUserProfiles && (<li className={location.pathname === ADMIN_SUB_ADMIN_USERS_PROFILES_PATH ? 'active' : ''}><Link to={ADMIN_SUB_ADMIN_USERS_PROFILES_PATH}>{getLabel("LBL_Manage_User_Profiles")}</Link></li>)}
                                {canViewUserProfiles && (<li className={location.pathname === ADMIN_SUB_ADMIN_TRAINERS_PATH ? 'active' : ''}><Link to={ADMIN_SUB_ADMIN_TRAINERS_PATH}>{getLabel("LBL_Manage_Trainers")}</Link></li>)}
                                {canViewRankings && (<li className={location.pathname.startsWith(ADMIN_RANKING_PATH) ? 'active' : ''}><Link to={ADMIN_RANKING_PATH}>{getLabel("LBL_Manage_Rankings")}</Link></li>)}
                            </ul>
                        </li>
                    )}

                    {(canViewOrders || canViewCoupons) && (
                        <li>
                            <div className="iocn-link">
                                <a className={`arrow ${isInArray(orderMenuArr, location.pathname) ? 'active' : ''}`}>
                                    <i className='bx bx-store-alt' ></i>
                                    <span className="link_name">{getLabel("LBL_Orders_Management")}</span>
                                </a>
                                <i className='bx bxs-chevron-down arrow' ></i>
                            </div>
                            <ul className="sub-menu" style={{ display: isInArray(orderMenuArr, location.pathname) ? 'block' : 'none' }}>
                                {canViewOrders && (<li className={location.pathname.startsWith(ADMIN_ORDER_PATH) ? 'active' : ''}><Link to={ADMIN_ORDER_PATH}>{getLabel("LBL_Manage_Orders")}</Link></li>)}
                                {canViewCoupons && (<li className={location.pathname.startsWith(ADMIN_COUPON_PATH) ? 'active' : ''}><Link to={ADMIN_COUPON_PATH}>{getLabel("LBL_Manage_Coupons")}</Link></li>)}
                            </ul>
                        </li>
                    )}

                    {(viewLabel || canViewFaq || viewBanner || canViewContentPage || canViewContentBlock || canViewNavigation) && (
                        <li>
                            <div className="iocn-link">
                                <a className={`arrow ${isInArray(cmsMenusArr, location.pathname) || location.pathname.startsWith(ADMIN_NAVIGATION_PATH) ? 'active' : ''}`}>
                                    <i className='bx bx-notepad' ></i>
                                    <span className="link_name">{getLabel("LBL_CMS_Management")}</span>
                                </a>
                                <i className='bx bxs-chevron-down arrow' ></i>
                            </div>
                            <ul className="sub-menu" style={{ display: isInArray(cmsMenusArr, location.pathname) || location.pathname.startsWith(ADMIN_NAVIGATION_PATH) ? 'block' : 'none' }}>
                                {viewLabel && (<li className={location.pathname === ADMIN_LABEL_PATH ? 'active' : ''}><Link to={ADMIN_LABEL_PATH}>{getLabel("LBL_Manage_Language_Label")}</Link></li>)}
                                {canViewFaq && (<li className={location.pathname === ADMIN_FAQ_CATEGORY_PATH ? 'active' : ''}><Link to={ADMIN_FAQ_CATEGORY_PATH}>{getLabel("LBL_Manage_Faq_Category")}</Link></li>)}
                                {canViewFaq && (<li className={location.pathname === ADMIN_FAQ_PATH ? 'active' : ''}><Link to={ADMIN_FAQ_PATH}>{getLabel("LBL_Manage_Faq")}</Link></li>)}
                                {viewBanner && (<li className={location.pathname === ADMIN_BANNER_PATH ? 'active' : ''}><Link to={ADMIN_BANNER_PATH}>{getLabel("LBL_Manage_Home_Page_Banners")}</Link></li>)}
                                {canViewContentPage && (<li className={location.pathname === ADMIN_CONTENT_PAGE_PATH ? 'active' : ''}><Link to={ADMIN_CONTENT_PAGE_PATH}>{getLabel("LBL_Manage_Content_Pages")}</Link></li>)}
                                {canViewContentBlock && (<li className={location.pathname === ADMIN_CONTENT_BLOCK_PATH ? 'active' : ''}><Link to={ADMIN_CONTENT_BLOCK_PATH}>{getLabel("LBL_Manage_Content_Blocks")}</Link></li>)}
                                {canViewNavigation && (<li className={location.pathname.startsWith(ADMIN_NAVIGATION_PATH) ? 'active' : ''}><Link to={ADMIN_NAVIGATION_PATH}>{getLabel("LBL_Manage_Navigation_Menu")}</Link></li>)}
                            </ul>
                        </li>
                    )}

                    {(canViewSchoolLogs || canViewOffers || canViewEvents) && (
                        <li>
                            <div className="iocn-link">
                                <a className={`arrow ${isInArray(offerEventsMenuArr, location.pathname) ? 'active' : ''}`}>
                                    <i className='bx bx-calendar-event' ></i>
                                    <span className="link_name">{getLabel("LBL_Offers/Events")}</span>
                                </a>
                                <i className='bx bxs-chevron-down arrow' ></i>
                            </div>
                            <ul className="sub-menu" style={{ display: isInArray(offerEventsMenuArr, location.pathname) ? 'block' : 'none' }}>
                                {canViewSchoolLogs && (<li className={location.pathname === ADMIN_SCHOOL_LOGS_PATH ? 'active' : ''}><Link to={ADMIN_SCHOOL_LOGS_PATH}>{getLabel("LBL_Manage_School_Logos")}</Link></li>)}
                                {canViewOffers && (<li className={location.pathname.startsWith(ADMIN_OFFERS_PATH) ? 'active' : ''}><Link to={ADMIN_OFFERS_PATH}>{getLabel("LBL_Manage_Offers")}</Link></li>)}
                                {canViewEvents && (<li className={location.pathname.startsWith(ADMIN_EVENTS_PATH) ? 'active' : ''}><Link to={ADMIN_EVENTS_PATH}>{getLabel("LBL_Manage_Events")}</Link></li>)}
                            </ul>
                        </li>
                    )}

                    {canViewConfiguration && (
                        <li className={location.pathname === ADMIN_CONFIGURATION_PATH ? 'active' : ''}>
                            <div className="iocn-link">
                                <Link to={ADMIN_CONFIGURATION_PATH}>
                                    <i className='bx bx-cog' ></i>
                                    <span className="link_name">{getLabel("LBL_General_Settings")}</span>
                                </Link>
                            </div>
                        </li>
                    )}

                    {viewBlog && (
                        <li>
                            <div className="iocn-link">
                                <a className={`arrow ${isInArray(blogMenuArr, location.pathname) ? 'active' : ''}`}>
                                    <i className='bx bxl-blogger' ></i>
                                    <span className="link_name">{getLabel("LBL_Manage_Blogs")}</span>
                                </a>
                                <i className='bx bxs-chevron-down arrow' ></i>
                            </div>
                            <ul className="sub-menu" style={{ display: isInArray(blogMenuArr, location.pathname) ? 'block' : 'none' }}>
                                <li className={location.pathname === ADMIN_BLOG_PATH ? 'active' : ''}><Link to={ADMIN_BLOG_PATH}>{getLabel("LBL_Blog")}</Link></li>
                                <li className={location.pathname === ADMIN_BLOG_TAG_PATH ? 'active' : ''}><Link to={ADMIN_BLOG_TAG_PATH}>{getLabel("LBL_Blog_Tag")}</Link></li>
                                <li className={location.pathname === ADMIN_BLOG_CATEGORY_PATH ? 'active' : ''}><Link to={ADMIN_BLOG_CATEGORY_PATH}>{getLabel("LBL_Blog_Category")}</Link></li>
                            </ul>
                        </li>
                    )}

                    <li className={location.pathname === ADMIN_PROFILE_PATH ? 'active' : ''}>
                        <div className="iocn-link">
                            <Link to={ADMIN_PROFILE_PATH}>
                                <i className="bx bxs-user-account"></i>
                                <span className="link_name">{getLabel("LBL_Profile")}</span>
                            </Link>
                        </div>
                    </li>

                    {(canViewMetaTags || canViewUrlRewrite) && (
                        <li>
                            <div className="iocn-link">
                                <a className={`arrow ${isInArray(seoMenuArr, location.pathname) ? 'active' : ''}`}>
                                    <i className='bx bx-book-alt' ></i>
                                    <span className="link_name">{getLabel("LBL_SEO")}</span>
                                </a>
                                <i className='bx bxs-chevron-down arrow' ></i>
                            </div>
                            <ul className="sub-menu" style={{ display: isInArray(seoMenuArr, location.pathname) ? 'block' : 'none' }}>
                                {canViewMetaTags && (<li className={location.pathname === ADMIN_META_TAGS_PATH ? 'active' : ''}><Link to={ADMIN_META_TAGS_PATH}>{getLabel("LBL_Meta_Tags")}</Link></li>)}
                                {canViewUrlRewrite && (<li className={location.pathname === ADMIN_URL_REWRITING_PATH ? 'active' : ''}><Link to={ADMIN_URL_REWRITING_PATH}>{getLabel("LBL_Url_Rewriting")}</Link></li>)}
                            </ul>
                        </li>
                    )}
                </ul>
            </div>
            <Outlet />
        </>
    )
}
export default Sidebar