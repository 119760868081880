import { getLabel } from "../../common/getLabel";
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
import ChangePassword from "./changePassword";
import { useState } from "react";
import { Link } from "react-router-dom";

const Listing = (props) => {
    const {
        subAdminListing,
        totalCount,
        loading,
        error,
        handleFormShow,
        handlePaginate,
        currentPage,
        limit,
        handleStatus,
        deleteModalOpen,
        editSubAdmin,
    } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };

    const calculateSerialNumber = (index) => (currentPage - 1) * limit + index + 1;
    const totalPages = Math.ceil(totalCount / limit);
    const [subadminId, setSubAdminId] = useState(null);
    const [creadientFormShow, setCreadientalFormShow] = useState(false);
    const handleCreadientalFormShow = async (id) => {
        setSubAdminId(id);
        setCreadientalFormShow(true);
    };


    return (
        <>
            <form id="faq-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>

                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_User_Name")}</th>
                            <th>{getLabel("LBL_Name")}</th>
                            <th>{getLabel("LBL_Email")}</th>
                            {editSubAdmin && (<th>{getLabel("LBL_Status")}</th>)}
                            <th className="table-action">{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={6} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colSpan="6" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {subAdminListing && subAdminListing.length > 0 ? (
                                    subAdminListing.map((elem, index) => (
                                        <tr className="actions" key={index}>

                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem["admin_username"]}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem["admin_name"]}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem["admin_email"]}
                                                    </span>
                                                </div>
                                            </td>
                                            {editSubAdmin && (
                                                <td>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={elem["admin_status"] ? 1 : 0}
                                                            onChange={(e) =>
                                                                handleStatus(
                                                                    e,
                                                                    elem["admin_id"],
                                                                    elem["admin_status"]
                                                                )
                                                            }
                                                            disabled={elem["admin_id"] == 1 && true}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            )}
                                            {elem.admin_id != 1 && (
                                                <td>
                                                    <span className="action">
                                                        <ul>
                                                            <li>
                                                                <a data-id={elem["admin_id"]} onClick={() => handleFormShow(elem["admin_id"])}>
                                                                    <i className="bx bx-pencil" variant="primary"></i>
                                                                </a>
                                                            </li>
                                                            {editSubAdmin && (
                                                                <li>
                                                                    <a>
                                                                        <i
                                                                            className="bx bx-trash"
                                                                            onClick={() =>
                                                                                deleteModalOpen(elem["admin_id"])
                                                                            }
                                                                        ></i>
                                                                    </a>
                                                                </li>
                                                            )}
                                                            {/* three dots statrt */}
                                                            {editSubAdmin && (
                                                                <li>
                                                                    <div className="dropdown">
                                                                        <button
                                                                            className="btn btn-secondary dropdown-toggle dropdownMenuButton1"
                                                                            type="button"
                                                                            // id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <a href="#">
                                                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                                                            </a>
                                                                        </button>
                                                                        <ul
                                                                            className="dropdown-menu"
                                                                            aria-labelledby="dropdownMenuButton1"
                                                                        >
                                                                            {editSubAdmin && (
                                                                                <li>
                                                                                    <span
                                                                                        className="dropdown-item pointer"
                                                                                        onClick={() =>
                                                                                            handleCreadientalFormShow(
                                                                                                elem["admin_id"]
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i className="bx bx-lock-alt" />
                                                                                        Change Password
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                            <li>
                                                                                <Link
                                                                                    to={`/admin/adminusers/permission/${elem["admin_id"]}`}
                                                                                >
                                                                                    <span className="dropdown-item pointer">
                                                                                        <i className="bx bx-transfer"></i>
                                                                                        {getLabel("LBL_Permission")}
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            )}
                                                            {/* three dots end */}
                                                        </ul>
                                                    </span>
                                                </td>
                                            )}

                                        </tr>
                                    ))
                                ) : (
                                    <tr className="no--record">
                                        <td colSpan="6" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {subAdminListing && subAdminListing.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}

            <ChangePassword
                creadientFormShow={creadientFormShow}
                subadminId={subadminId}
                setCreadientalFormShow={setCreadientalFormShow}
            />
        </>
    );
};

export default Listing;
