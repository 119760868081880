import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { forgotPassword, isLoggedIn } from '../../services/authentication/action';
import { forgotPasswordSchema } from '../../common/validation';
import { getLabel } from '../../../common/getLabel';

const ForgotPasswod = () => {
    const navigate = useNavigate("")

    useEffect(() => {
        if (isLoggedIn() === true) {
            navigate('/');
            return;
        }
    }, [navigate]);

    const initialValues = {
        user_email: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: forgotPasswordSchema,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            const postData = {
                email: values.user_email,
            };
            try {
                const response = await forgotPassword(postData);
                resetForm();
                if (response === true) {
                    navigate('/log-in');
                }
            } catch (error) { }
        }
    })
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 mx-auto">
                        <div className="login-bg">
                            <div className="row">
                                <h2>{getLabel('LBL_Forgot_Password')}</h2>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="col-lg-12">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Email')}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="user_email"
                                                onChange={formik.handleChange}
                                                value={formik.values.user_email}
                                                placeholder={getLabel('LBL_*Enter_Email_Address')}
                                            />
                                            {formik.touched.user_email && formik.errors.user_email && (
                                                <span style={{ color: "#b94a48" }}>{formik.errors.user_email}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn btn-primary">{getLabel('LBL_Submit')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="return">
                            <Link to="/log-in"><strong><i className='bx bx-arrow-back'></i> {getLabel('LBL_RETURN_TO_LOGIN_PAGE')}</strong></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswod
