import { getLabel } from "../../common/getLabel";
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { changePassword,isLoggedIn } from "../../services/authentication/action";
import { useNavigate,useParams} from "react-router-dom";
import { changePasswordSchema } from '../../common/validation';

const Create = () => { 
  const dispatch = useDispatch();
  const param = useParams();
  const token = param.token;
  
  const navigate = useNavigate("")
  useEffect(()=>{
    if(isLoggedIn() === true){
        navigate('/admin/dashboard');
        return;
    }

    if(token == undefined || token == '' || token == null){
        navigate('/admin');
        return;
    }


  },[]);
  
 
  const initialValues = {
    userName: "",
    userPassword: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    validateOnChange:false,
    onSubmit: async (values) => {
      const postData = {
        new_password: values.password, 
        confirm_password: values.confirm_password,
        token:token
      };
      

      dispatch(changePassword(postData)).then((response)=>{
        
          navigate('/admin');
          return;
        
      })

    }
  })




  const handleChange = (e) => {
    formik.handleChange(e)
  };

  return (

    <>
      <div className="bg-form">
        <div className="container form">
          <div className="form-container" id="login-form">
            <h1>{getLabel("LBL_Change_Password")}</h1>
            <form onSubmit={formik.handleSubmit}>
              <label htmlFor="password">{getLabel("LBL_Password")}</label>
              <input
                className="form-control"
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                placeholder="Enter your password"
              />
              <div>
                {formik.touched.password && formik.errors.password && (
                  <p style={{ color: "#b94a48" }}>{formik.errors.password}</p>
                )}
              </div>
              <label htmlFor="confirm_password">{getLabel("LBL_Confirm_Password")}</label>
              <input
                className="form-control"
                type="password"
                id="confirm_password"
                name="confirm_password"
                onChange={handleChange}
                placeholder="Confirm your Password"
              />
              <div>
                {formik.touched.confirm_password && formik.errors.confirm_password && (
                  <p style={{ color: "#b94a48" }}>{formik.errors.confirm_password}</p>
                )}
              </div>
              <button type="submit">{getLabel("LBL_Submit")}</button>
            </form>
            
          </div>
        </div>
      </div>
    </>

  )
}


export default Create