import { getLabel } from "../../common/getLabel"
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
import { formatDate, getOrderStatus } from "../../../common/common";
import { Link } from "react-router-dom";

const Listing = (props) => {
    const { orders, totalCount, loading, error, handlePaginate, calculateSerialNumber, limit, currentPage } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <form id="blogTag-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_Order_ID")}</th>
                            <th>{getLabel("LBL_Profile_Name")}</th>
                            <th>{getLabel("LBL_Plan")}</th>
                            <th>{getLabel("LBL_Amount")}</th>
                            <th>{getLabel("LBL_Order_Billing_Date")}</th>
                            <th>{getLabel("LBL_Status")}</th>
                            <th className="table-action">{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={9} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="6" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {orders && orders.length > 0 ? (
                                    orders.map((elem, index) => {
                                        const profile_name = elem.order_profile_id == 0 ? 'New Player' : `${elem["userProfile.uprofile_fname"]} ${elem["userProfile.uprofile_lname"]}`;
                                        return (
                                            <tr className="actions" key={index} >
                                                <td>{calculateSerialNumber(index)}</td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{elem.order_invoice_number} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{profile_name} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{elem["plans.splan_title"]} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">${elem.order_net_amount} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{formatDate(elem.order_billing_date)} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{getOrderStatus(elem.order_status)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="action">
                                                        <ul>
                                                            <li><Link to={`/admin/order/${elem.order_id}`}><i className='bx bx-pencil'></i></Link></li>
                                                        </ul>
                                                    </span>

                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="9" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {orders && orders.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    )
}

export default Listing