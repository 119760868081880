import Modal from "react-bootstrap/Modal";
import { getLabel } from "../../common/getLabel";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { exportSchema } from "../../common/validation";
import { exportOrders, getDataOrders } from "../../services/manage-orders/action";
import { handleTimeZoneFormat } from "../../../common/common";
import CustomDatePicker from "../../../common/CustomDatePicker";
const ExportOrders = (props) => {
    const { exportFormShow, handleExportFormClose, requestBody } = props;
    const [exportType, setExportType] = useState(0);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            export_type: '',
            limit: '',
            date_from: '',
            date_to: '',
            order_by: '',
            sort_by: '',
            status: '',
        },
        validationSchema: exportSchema,
        validateOnChange: true,
        onSubmit: async (values) => {
            values.date_from = startDate;
            values.date_to = endDate;
            try {
                const response = await exportOrders(values);
                if (values.export_type == 1 && response) {
                    const fileName = "order-" + Date.now();
                    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName + '.xlsx';
                    link.click();

                    URL.revokeObjectURL(url);
                }
                handleExportFormClose();
                dispatch(getDataOrders(requestBody));
            } catch (error) { }
        },
    });

    const resetFields = () => {
        formik.setFieldValue('limit', '');
        formik.setFieldValue('date_from', '');
        formik.setFieldValue('date_to', '');
        formik.setFieldValue('order_by', '');
        formik.setFieldValue('sort_by', '');
        formik.setFieldValue('status', '');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        formik.handleChange(e);

        if (name === 'export_type') {
            setExportType(value);
            resetFields();
        }
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleStartDateChange = date => {
        setStartDate(handleTimeZoneFormat(date));
    };

    const handleEndDateChange = date => {
        setEndDate(handleTimeZoneFormat(date));
    };

    return (
        <>
            <Modal
                show={exportFormShow}
                onHide={handleExportFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Export_Orders")}</h5>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">{getLabel("LBL_Export_orders*")}</label>
                                        <select as="select" className="form-select" aria-label="Default select example" id="export_type" name="export_type" onChange={handleChange} value={formik.values.export_type}>
                                            <option value="">{getLabel("LBL_Choose")}</option>
                                            <option value="1">{getLabel("LBL_By_Limit")}</option>
                                            <option value="2">{getLabel("LBL_Whole_Data")}</option>
                                        </select>
                                        <div>
                                            {formik.touched.export_type && formik.errors.export_type && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.export_type}</p>
                                            )}
                                        </div>
                                    </div>
                                    {exportType != 0 && (
                                        <>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputState">{getLabel("LBL_Order_date_from")}</label>
                                                <CustomDatePicker
                                                    selectedDate={startDate}
                                                    onDateChange={handleStartDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    id="date_from"
                                                />
                                                <div>
                                                    {formik.touched.date_from && formik.errors.date_from && (
                                                        <p style={{ color: "#b94a48" }}>{formik.errors.date_from}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputState">{getLabel("LBL_Order_date_to")}</label>
                                                <CustomDatePicker
                                                    selectedDate={endDate}
                                                    onDateChange={handleEndDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    id="date_to"
                                                />
                                                <div>
                                                    {formik.touched.date_to && formik.errors.date_to && (
                                                        <p style={{ color: "#b94a48" }}>{formik.errors.date_to}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputState">{getLabel("LBL_Order_by")}</label>
                                                <select as="select" className="form-select" aria-label="Default select example" id="order_by" name="order_by" onChange={handleChange}>
                                                    <option value="">{getLabel("LBL_Choose")}</option>
                                                    <option value="order_billing_date">{getLabel("LBL_Order_date")}</option>
                                                    <option value="order_id">{getLabel("LBL_Order_ID")}</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputState">{getLabel("LBL_Sort_by")}</label>
                                                <select as="select" className="form-select" aria-label="Default select example" id="sort_by" name="sort_by" onChange={handleChange}>
                                                    <option value="">{getLabel("LBL_Choose")}</option>
                                                    <option value="asc">{getLabel("LBL_ASC")}</option>
                                                    <option value="desc">{getLabel("LBL_DESC")}</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="inputState">{getLabel("LBL_Order_status")}</label>
                                                <select as="select" className="form-select" aria-label="Default select example" id="status" name="status" onChange={handleChange}>
                                                    <option value="">{getLabel("LBL_Choose")}</option>
                                                    <option value="3">{getLabel("LBL_Current")}</option>
                                                    <option value="4">{getLabel("LBL_Cancelled")}</option>
                                                </select>
                                            </div>
                                        </>
                                    )}
                                    {exportType == 1 && (
                                        <>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="input">{getLabel("LBL_Limit*")}</label>
                                                    <input className="form-control" type="number" min="1" id="limit" name="limit" onChange={handleChange} placeholder={getLabel("LBL_Limit")} />
                                                    <div>
                                                        {formik.touched.limit && formik.errors.limit && (
                                                            <p style={{ color: "#b94a48" }}>{formik.errors.limit}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleExportFormClose}>
                            {getLabel("LBL_Close")}
                        </Button>
                        <Button variant="primary" type="submit">
                            {getLabel("LBL_Submit")}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ExportOrders
