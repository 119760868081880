import { RotatingLines } from 'react-loader-spinner';
import { useEffect } from 'react';
import axios from "axios";
import { baseUrl } from "./config";


export const myAxios = axios.create({
  baseURL: baseUrl,
});

myAxios.interceptors.request.use(function (config) {
  return config;
}, function (error) {

  return Promise.reject(error);
});

myAxios.interceptors.response.use(function (response) {

  return response;
}, function (error) {

  return Promise.reject(error);
});

export const headers = () => {
  const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
  let token = adminDetails ? adminDetails.token : null;
  const header = {
    'Authorization': 'Bearer ' + token,
  }
  return header;
}

export const isLoggedIn = () => {
  const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
  let token = adminDetails ? adminDetails.token : null;
  if (token) {
    return true;
  }
  return false;
}

export const useDelayedSearch = (filterKey, searchItem, handleFilterUpdate, isRecordSearched = false) => {
  useEffect(() => {
    if (isRecordSearched) {
      const delaySearch = setTimeout(() => {
        handleFilterUpdate(filterKey, searchItem, true);
      }, 1000);
      return () => clearTimeout(delaySearch);
    }
  }, [searchItem]);
};

export const handleCheckboxChange = (event, labelId, setSelectedCheckboxes) => {
  const isChecked = event.target.checked;
  if (isChecked) {
    setSelectedCheckboxes((prevSelected) => [...prevSelected, labelId]);
  } else {
    setSelectedCheckboxes((prevSelected) =>
      prevSelected.filter((id) => id !== labelId)
    );
  }
};

export const LodingIndicater = () => {
  return (
    <>
      <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          height="100"
          width="100"
          visible={true}
        />
      </div>
    </>
  )
}

export const pageLimit = 10;
export const adminId = 1;