import { getLabel } from "../../common/getLabel";
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { login, isLoggedIn } from "../../services/authentication/action";
import { useNavigate } from "react-router-dom";
import { loginSchema } from '../../common/validation';
import { Link } from "react-router-dom"
import { getUniqueId } from "../../../common/common";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate("")

  useEffect(() => {
    if (isLoggedIn() === true) {
      navigate('/admin/dashboard');
      return;
    }
  }, []);


  const initialValues = {
    userName: "",
    userPassword: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const postData = {
        user_name: values.userName,
        user_password: values.userPassword,
        user_device_id: getUniqueId()
      };
      dispatch(login(postData)).then((response) => {

        if (isLoggedIn() == true) {
          navigate('/admin/dashboard');
          return;
        }
      })
    }
  })




  const handleChange = (e) => {
    formik.handleChange(e)

  };

  return (

    <>
      <div className="bg-form">
        <div className="container form">
          <div className="form-container" id="login-form">
            <h1>{getLabel("LBL_Login")}</h1>
            <form onSubmit={formik.handleSubmit}>
              <label htmlFor="username">{getLabel("LBL_Username")}</label>
              <input
                className="form-control"
                type="text"
                id="userName"
                name="userName"
                onChange={handleChange}
                value={formik.values.userName}
                placeholder="Enter your username"
              />
              <div>
                {formik.touched.userName && formik.errors.userName && (
                  <p style={{ color: "#b94a48" }}>{formik.errors.userName}</p>
                )}
              </div>
              <label htmlFor="password">{getLabel("LBL_Password")}</label>
              <input
                className="form-control"
                type="password"
                id="userPassword"
                name="userPassword"
                onChange={handleChange}
                value={formik.values.userPassword}
                placeholder="Enter your Password"
              />
              <div>
                {formik.touched.userPassword && formik.errors.userPassword && (
                  <p style={{ color: "#b94a48" }}>{formik.errors.userPassword}</p>
                )}
              </div>
              <button type="submit">{getLabel("LBL_Login")}</button>
            </form>
            <p>{getLabel("LBL_Don't_have_an_account?")} <Link to="/admin/forgot-password" id="signup-link">{getLabel("LBL_Reset_Password")}</Link></p>
          </div>
        </div>
      </div>
    </>

  )
}


export default Login