import { PREMIUM_PROFILE_LOADING, PREMIUM_PROFILE_ERROR, PREMIUM_PROFILE_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";

const handleLoadingPremiumProfile = (payload) => ({
    type: PREMIUM_PROFILE_LOADING,
    payload,
});
const handleErrorPremiumProfile = () => ({
    type: PREMIUM_PROFILE_ERROR,
});
const handleSuccessPremiumProfile = (payload) => ({
    type: PREMIUM_PROFILE_SUCCESS,
    payload,
});

const getPremiumProfiles = (data) => async (dispatch) => {
    dispatch(handleLoadingPremiumProfile());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/search-premium-profiles", data)
                .then((res) => {
                    dispatch(handleSuccessPremiumProfile(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorPremiumProfile());
    }
};

export { getPremiumProfiles };