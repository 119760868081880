import {
  MANAGE_ORDER_CANCEL_ERROR,
  MANAGE_ORDER_CANCEL_LOADING,
  MANAGE_ORDER_CANCEL_SUCCESS,
} from "./type";
const initState = {
  loading: true,
  error: false,
  orderCancelReasons: [],
};
const orderCancelReducer = (state = initState, action) => {
  switch (action.type) {
    case MANAGE_ORDER_CANCEL_ERROR:
      return { ...state, loading: true };
    case MANAGE_ORDER_CANCEL_LOADING:
      return { ...state, loading: false, error: true };
    case MANAGE_ORDER_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        orderCancelReasons: action.payload.reasons,
        totalCount: action.payload.totalRecords,
      };
    default:
      return state;
  }
};

export { orderCancelReducer };
