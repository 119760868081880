import { useState } from "react";
import { getLabel } from "../../common/getLabel"

const SearchBar = (props) => {
    const { handleFilterUpdate, handleFilter, handleExportFormShow, handleClear, editOrder } = props;
    const [searchItem, setSearchItem] = useState("")
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate('keyword', event.target.value, false);
    };

    const handleFilterChange = (event) => {
        handleFilterUpdate(event.target.name, event.target.value, false);
    };

    const handleCancel = () => {
        setSearchItem("");
        handleClear();
    };
    return (
        <>
            <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
                <div className="row search-bar-filter">
                    <div className="col leftspace pb-5">
                        <input className="search-input mx-4"
                            placeholder={getLabel("LBL_Search_by_Keyword")}
                            name="keyword"
                            value={searchItem}
                            onChange={(e) => {
                                handleInputChange(e)
                            }}
                        />
                        <select name="status" className="form-select" id="status" style={{ width: "20%" }} onChange={(e) => { handleFilterChange(e) }}>
                            <option value="">{getLabel('LBL_Select_Status')}</option>
                            <option value="3">{getLabel('LBL_Current')}</option>
                            <option value="4">{getLabel('LBL_Cancelled')}</option>
                        </select>
                        <div className="card-button">
                            <ul>
                                <li>
                                    <button
                                        type="submit"
                                        name="btn_submit"
                                        className="btn btn-brand btn-wide btn-search"
                                        onClick={() => handleFilter}
                                    >
                                        {getLabel("LBL_Search")}{" "}
                                    </button>
                                    <button
                                        type="button"
                                        name="btn_clear"
                                        className="btn btn-link btn_clear"
                                        onClick={(e) => {
                                            handleCancel();
                                        }}
                                    >
                                        {getLabel("LBL_Clear")}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div >
                    {editOrder && (
                        <div className="col-md-3">
                            <button type="button" className="btn btn-primary float-right" onClick={handleExportFormShow} >
                                <i className="bx bx-export"></i> {getLabel("LBL_Export_Orders")}
                            </button>
                        </div>
                    )}
                </div>
            </form>
        </>
    )
}
export default SearchBar