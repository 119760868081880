import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const HomeProfileSkeletonLoading = ({ length }) => {
    const baseColor = "#ebebeb";
    const highlightColor = "#cfcfcfab";
    return (
        <>
            <div className="container-fluid custom-width premium my-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-5">
                            <h2><Skeleton width={270} height={45} baseColor={baseColor} highlightColor={highlightColor} /></h2>
                        </div>
                    </div>
                    {Array.from({ length }, (_, i) => (
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3" key={i}>
                            <div className="qbh-box-main">
                                <div className="qbh-image-box">
                                    <Skeleton baseColor={baseColor} highlightColor={highlightColor} height={260} style={{ lineHeight: "unset" }} />
                                </div>
                                <div className="p-4">
                                    <Skeleton baseColor={baseColor} highlightColor={highlightColor}style={{ marginTop: "10px" }} height={30} />
                                    <Skeleton baseColor={baseColor} highlightColor={highlightColor}style={{ marginTop: "10px" }} width={150} />
                                    <Skeleton baseColor={baseColor} highlightColor={highlightColor}style={{ marginTop: "10px" }} width={250} height={20} />
                                    <Skeleton baseColor={baseColor} highlightColor={highlightColor}style={{ marginTop: "10px" }} width={300} height={30} />
                                    <Skeleton baseColor={baseColor} highlightColor={highlightColor}style={{ marginTop: "10px" }} width={200} height={20} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default HomeProfileSkeletonLoading;
