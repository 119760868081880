import { CONTENT_BLOCK_LOADING, CONTENT_BLOCK_ERROR, CONTENT_BLOCK_SUCCESS } from "./type";
const initState = { loading: true, error: false, contentBlocks: [] };
const contentBlockReducer = (state = initState, action) => {
    switch (action.type) {
        case CONTENT_BLOCK_LOADING:
            return { ...state, loading: true };
        case CONTENT_BLOCK_ERROR:
            return { ...state, loading: false, error: true };
        case CONTENT_BLOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                contentBlocks: action.payload.blockPage,
                totalCount: action.payload.totalRecords,
            };
        default:
            return state;
    }
};

export { contentBlockReducer };
