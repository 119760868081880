import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getMembershipContent } from '../../services/membership/action'
import { renderHTML } from '../../../common/common'
import MemberShipSkeleton from '../../common/skeleton-loading/membership-skeleton-loading'
import { getLabel } from '../../../common/getLabel'
import { isLoggedIn } from '../../services/authentication/action'
import { loadMetaTags, MEMBERSHIP_PAGE_META_TYPE, SITE_TITLE } from '../../common/common'

const Pricing = () => {
    const dispatch = useDispatch();
    const { membership } = useSelector((state) => state.membership);
    const loading = useSelector((state) => state.membership.loading);
    const error = useSelector((state) => state.membership.error);
    const loggedIn = useSelector(isLoggedIn);

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const metaTags = {
        meta_type: MEMBERSHIP_PAGE_META_TYPE,
        meta_title: `${site_title} | Membership Options And Pricing | Join Now For Free`,
        meta_record_id: 0,
        meta_advance_tag: 1
    }

    useEffect(() => {
        loadMetaTags(metaTags);
        const delaySearch = setTimeout(() => {
            if (!membership || membership.length === 0) {
                dispatch(getMembershipContent());
            }
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, []);

    let memberShipPlan = [];
    if (membership && membership.length > 0) {
        memberShipPlan = membership.reduce((acc, plan) => {
            if (!acc[plan.splan_type]) {
                acc[plan.splan_type] = [];
            }
            acc[plan.splan_type].push(plan);
            return acc;
        }, {});
    }
    return (
        <>
            <div className="membership">
                <div className="container">
                    {loading ? (
                        <MemberShipSkeleton />
                    ) : error ? (
                        <div className="player-heading"><h2>{getLabel('LBL_No_Record_Found')}</h2></div>
                    ) : (
                        <>
                            {Object.keys(memberShipPlan).map((splanType, typeIndex) => (
                                <div className="row" key={typeIndex}>
                                    <div key={splanType} className="col-lg-12">
                                        <div className="player-heading">
                                            <h2>{splanType === '1' ? 'PLAYER MEMBERSHIP OPTIONS' : 'TRAINER MEMBERSHIP'}</h2>
                                        </div>
                                    </div>
                                    <div className={`col-lg-12 ${splanType === '1' ? 'price' : ''}`}>
                                        <div className={`row ${splanType !== '1' ? 'mb-5' : ''}`}>
                                            {memberShipPlan[splanType].map((plan, index) => (
                                                <div className="col-lg-4 col-sm-6 mx-auto" key={index}>
                                                    <div className={`basic-price${plan.splan_sub_heading ? '-pre' : ''}`}>
                                                        <div className="basic-price-title">{plan.splan_title}</div>
                                                        <div className="dashed-border-basic"></div>
                                                        {plan.splan_description && (
                                                            <div className="basic-price-list" dangerouslySetInnerHTML={renderHTML(plan.splan_description)} />
                                                        )}

                                                        <div className="dashed-border-basic"></div>
                                                        <div className="free">
                                                            {plan.splan_price ? `$${plan.splan_price} ` : 'FREE '}
                                                            <span className="forever">
                                                                {plan.splan_price_type == 0 && 'Forever'}
                                                                {plan.splan_price_type == 1 && 'Per Month'}
                                                                {plan.splan_price_type == 2 && 'Per Year'}
                                                            </span>
                                                        </div>
                                                        <Link to={loggedIn ? '/membership' : '/signup'} className="btn btn-primary">{getLabel('LBL_SIGN_Up')}</Link>
                                                        {plan.splan_sub_heading && (
                                                            <div className="get-month-free">{plan.splan_sub_heading}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default Pricing
