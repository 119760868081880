import React from 'react'
import { getLabel } from '../../../common/getLabel';
import { renderHTML } from '../../../common/common';

const ContentBlocks = (props) => {
    const { contentBlocks, contentBlockKey } = props;
    const contentBlocksData = contentBlocks.reduce((blockData, item) => {
        blockData[item.cb_type] = item;
        return blockData;
    }, {});

    return (
        <>
            {contentBlocksData && contentBlocksData[contentBlockKey.homeAboutKey] && (
                <section className="home--about-us" dangerouslySetInnerHTML={renderHTML(contentBlocksData[contentBlockKey.homeAboutKey].cb_content)} />
            )}

            {contentBlocksData && contentBlocksData[contentBlockKey.homeAccountKey] && (
                <section className="home--account" dangerouslySetInnerHTML={renderHTML(contentBlocksData[contentBlockKey.homeAccountKey].cb_content)} />
            )}

            <div className="bg-color-coupon">
                <div className="container-fluid custom-width">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12 col-md-12 col-xl-7 col-xxl-7">
                            <h1>{getLabel('LBL_TRY_PREMIUM_FREE_FOR_30_DAYS!_FREE_TRIAL_OFFER')}</h1>
                        </div>
                        <div className="col-lg-5 col-sm-12 col-md-12 col-xl-5 col-xxl-5">
                            <p>Enter promo code <span className="qbh-coupon">QBHLFREETRIAL</span> at checkout</p>
                        </div>
                    </div>
                </div>
            </div>

            {contentBlocksData && contentBlocksData[contentBlockKey.homeFeatureKey] && (
                <section className="home--feature" dangerouslySetInnerHTML={renderHTML(contentBlocksData[contentBlockKey.homeFeatureKey].cb_content)} />
            )}
        </>
    )
}

export default ContentBlocks
