import React, { useEffect, useState } from 'react'
import { Link, useOutletContext } from "react-router-dom"
import { useFormik } from "formik";
import { contactUsSchema } from '../../common/validation';
import { contactFormSubmit } from '../../services/common/action';
import { CONTACT_PAGE_META_TYPE, loadMetaTags, SITE_TITLE } from '../../common/common';
import { useSelector } from 'react-redux';
import CustomReCAPTCHA from '../../common/CustomReCAPTCHA';
import { validateRecaptcha } from '../../common/common';

const Contact = () => {
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [resetRecaptcha, setResetRecaptcha] = useState(false);
    const generalConfigurations = useOutletContext();
    const initialValues = {
        first_name: "",
        last_name: "",
        contact_email: "",
        reel_url: "",
        contact_comment: ""
    };

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const metaTags = {
        meta_type: CONTACT_PAGE_META_TYPE,
        meta_title: `${site_title} | Contact Us | Questions, Comments, Feedback`,
        meta_record_id: 0,
        meta_advance_tag: 1
    }

    useEffect(() => {
        loadMetaTags(metaTags);
    }, []);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: contactUsSchema,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            try {
                await validateRecaptcha(recaptchaToken);
                values.recaptcha = recaptchaToken;
                const response = await contactFormSubmit(values);
                if (response === true) {
                    resetForm();
                    setRecaptchaToken(null);
                    setResetRecaptcha(true);
                    setTimeout(() => setResetRecaptcha(false), 0);
                }
            } catch (error) {
                formik.setErrors({ recaptcha: error.message });
            }
        }
    })

    const handleChange = (e) => {
        formik.handleChange(e)
    };
    const handleRecaptchaChange = (value) => {
        setRecaptchaToken(value);
    };
    return (
        <>
            <div className="player-list-bulletin-board">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <h2>Contact us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row mb-5">
                    <div className="col-lg-8 col-sm-8">
                        <div className="contact-content">
                            <p>Please complete the form below to learn more about QB Hit List. Players may also include a highlight reel link (URL) for “Top 150” consideration. We try to respond to all inquiries within 24 hours. </p>

                            <p><strong>Existing Members: </strong> for Membership, Account and/or Billing questions, please email <a className="contact-email-info" href="mailto:customerservice@qbhitlist.com">customerservice@qbhitlist.com</a> directly. </p>

                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="first_name" className="form-control" onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} placeholder="First name" value={formik.values.first_name} onChange={handleChange} aria-label="First name" />
                                        {formik.touched.first_name && formik.errors.first_name && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.first_name}</span>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                        <input type="text" name="last_name" className="form-control" onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} placeholder="Last name" value={formik.values.last_name} onChange={handleChange} aria-label="Last name" />
                                        {formik.touched.last_name && formik.errors.last_name && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.last_name}</span>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                        <input type="text" name="contact_email" className="form-control" placeholder="Email Address" value={formik.values.contact_email} onChange={handleChange} aria-label="Email Address" />
                                        {formik.touched.contact_email && formik.errors.contact_email && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.contact_email}</span>
                                        )}
                                    </div>

                                    <div className="col-md-6">
                                        <input type="text" name="reel_url" className="form-control" placeholder="Highlight Reel URL (optional)" value={formik.values.reel_url} onChange={handleChange} aria-label="Highlight Reel URL (optional)" />
                                    </div>

                                    <div className="col-lg-12">
                                        <textarea className="form-control" name="contact_comment" id="exampleFormControlTextarea1" rows="5" value={formik.values.contact_comment} onChange={handleChange} placeholder="Comments"></textarea>
                                        {formik.touched.contact_comment && formik.errors.contact_comment && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.contact_comment}</span>
                                        )}
                                    </div>
                                    <div className="col-lg-12">
                                        <CustomReCAPTCHA onChange={handleRecaptchaChange} reset={resetRecaptcha} />
                                        {formik.errors.recaptcha && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.recaptcha}</span>
                                        )}
                                    </div>

                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-4">
                        <div className="contact-content">
                            <div className="bulletin-board-btn">
                                <Link to="/bulletin-board">
                                    <h5>Bulletin Board</h5>
                                    <h6>Camps, Trainers &amp; More!</h6>
                                </Link>
                            </div>

                            <div className="connect">
                                <h5>CONNECT:</h5>
                                <ul>
                                    {generalConfigurations.CONF_SOCIAL_MEDIA_TWITTER_URL && (
                                        <li><Link to={generalConfigurations.CONF_SOCIAL_MEDIA_TWITTER_URL} target="_blank"><img src="/images/twiter.png" className="img-fluid" /> {generalConfigurations.CONF_SOCIAL_MEDIA_TWITTER_URL}</Link></li>
                                    )}
                                    {generalConfigurations.CONF_SOCIAL_MEDIA_INSTAGRAM_URL && (
                                        <li><Link to={generalConfigurations.CONF_SOCIAL_MEDIA_INSTAGRAM_URL} target="_blank"><img src="/images/instagram.png" className="img-fluid" /> {generalConfigurations.CONF_SOCIAL_MEDIA_INSTAGRAM_URL}</Link></li>
                                    )}
                                    {generalConfigurations.CONF_SOCIAL_MEDIA_FACEBOOK_URL && (
                                        <li><Link to={generalConfigurations.CONF_SOCIAL_MEDIA_FACEBOOK_URL} target="_blank"><img src="/images/facebook.png" className="img-fluid" /> {generalConfigurations.CONF_SOCIAL_MEDIA_FACEBOOK_URL}</Link></li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
