import { LABEL_LOADING, LABEL_ERROR, LABEL_SUCCESS } from "./type";
const initState = {
  loading: true,
  error: false,
  labels: [],
  loadedLabelData: null,
};


const labelReducer = (state = initState, action) => {
  switch (action.type) {
    case LABEL_LOADING:
      return { ...state, loading: true };
    case LABEL_ERROR:
      return { ...state, loading: false, error: true };
    case LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        labels: action.payload.lebelall,
        totalCount: action.payload.totalRecords,
      };
    default:
      return state;
  }
};
export { labelReducer };
