import { PREMIUM_PROFILE_LOADING, PREMIUM_PROFILE_ERROR, PREMIUM_PROFILE_SUCCESS } from "./type";
const initState = { loading: true, error: false, premiumProfiles: [] };
const premiumProfileReducer = (state = initState, action) => {
    switch (action.type) {
        case PREMIUM_PROFILE_LOADING:
            return { ...state, loading: true };
        case PREMIUM_PROFILE_ERROR:
            return { ...state, loading: false, error: true };
        case PREMIUM_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                premiumProfiles: action.payload.rows,
                totalCount: action.payload.count,
            };
        default:
            return state;
    }
};

export { premiumProfileReducer };
