import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { deleteTestimonial, getDataUserProfiles, setupTestimonials } from '../../services/manage-user-profiles/action';
import { useDispatch } from "react-redux"
import { baseUrl } from '../../common/config';
const Testimonials = (props) => {
    const { testimonialData, profileId, testimonialFormshow, handleTestimonialClose, editUserProfile, requestBody } = props;

    const videoIntialFields = {
        pt_id: 0,
        testimonial_image: 0,
        pt_username: '',
        pt_content: ''
    };
    const [testimonialFields, setTestimonialFields] = useState([videoIntialFields]);
    const [fileData, setFileData] = useState({});
    const [previewImages, setPreviewImages] = useState({});
    const addTestimonialFields = () => {
        setTestimonialFields([...testimonialFields, { ...videoIntialFields }]);
    };
    const removeTestimonialField = (index) => {
        const updatedFields = [...testimonialFields];
        updatedFields.splice(index, 1);
        setTestimonialFields(updatedFields);
    };
    const handleTestimonialField = (index, field, value) => {
        const updatedFields = [...testimonialFields];
        updatedFields[index][field] = value;
        setTestimonialFields(updatedFields);
    };

    useEffect(() => {
        if (testimonialData && testimonialData.length > 0) {
            setTestimonialFields(testimonialData);
        }
    }, []);
    const filteredTestiminialFields = testimonialFields.filter(item => item.pt_id !== '' || item.testimonial_image !== '' || item.pt_username !== '' || item.pt_content !== '');
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {},
        validateOnChange: false,
        onSubmit: async (values) => {
            const formData = new FormData();
            let imageIndex = 0;
            filteredTestiminialFields.forEach((field, index) => {
                let testimonial_image_index = null;
                formData.append(`pt_id[${index}]`, field.pt_id);
                formData.append(`pt_username[${index}]`, field.pt_username);
                formData.append(`pt_content[${index}]`, field.pt_content);
                if (fileData[index]) {
                    fileData[index].forEach((file) => {
                        formData.append(`testimonial_image`, file);
                    });
                    testimonial_image_index = imageIndex;
                    imageIndex++;
                }
                formData.append(`testimonial_image_index[${index}]`, testimonial_image_index);
            });
            formData.append(`profile_id`, profileId);
            try {
                await setupTestimonials(formData);
                handleTestimonialClose();
                dispatch(getDataUserProfiles(requestBody));
            } catch (error) {
                console.log(error);
            }
        },
    });

    const handleFileChange = (e, index) => {
        const files = Array.from(e.target.files);
        const updatedFields = [...testimonialFields];
        updatedFields[index].testimonial_image = files;
        setTestimonialFields(updatedFields);
        setFileData(prevState => ({
            ...prevState,
            [index]: files
        }));

        const newPreviewImages = { ...previewImages };
        const file = files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            newPreviewImages[index] = previewUrl;
            setPreviewImages(newPreviewImages);
        }
    };


    const handleDeleteEvent = async (testimonialId, index) => {
        if (testimonialId) {
            const response = await deleteTestimonial({ "testimonialId": testimonialId });
            if (response === true) {
                removeTestimonialField(index)
            }
        } else {
            removeTestimonialField(index)
        }
    }

    return (
        <>
            <Modal
                show={testimonialFormshow}
                onHide={handleTestimonialClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Testimonials")}</h5>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body testimonial--body">
                            <div className="container">
                                <div className="row">
                                    {testimonialFields.map((field, index) => (
                                        <div className="col-lg-12 my-2" key={index}>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        name={`testimonial_image[${index}]`}
                                                        id={`testimonial_image[${index}]`}
                                                        multiple
                                                        onChange={(e) => handleFileChange(e, index)}
                                                        disabled={!editUserProfile}
                                                    />
                                                    {previewImages[index] ? (
                                                        <div>
                                                            <img
                                                                src={previewImages[index]}
                                                                style={{
                                                                    height: "100px",
                                                                    width: "100px",
                                                                    borderRadius: "50%",
                                                                }}
                                                                alt="image-preview"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {field.images && Object.keys(field.images).length > 0 && (
                                                                <div>
                                                                    <img
                                                                        src={`${baseUrl}/${field.images[0].afile_physical_path}`}
                                                                        style={{
                                                                            height: "100px",
                                                                            width: "100px",
                                                                            borderRadius: "50%",
                                                                        }}
                                                                        alt="image-path"
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" name={`pt_username[${index}]`} disabled={!editUserProfile} className="form-control" placeholder={getLabel('LBL_User_name')} aria-label="College" value={field.pt_username} onChange={(e) => handleTestimonialField(index, 'pt_username', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="form-group bio">
                                                        <textarea rows="4" className="form-control" disabled={!editUserProfile} placeholder={getLabel('LBL_Content')} name={`pt_content[${index}]`} value={field.pt_content} onChange={(e) => handleTestimonialField(index, 'pt_content', e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                                <input type="hidden" name="pt_id" value={field.pt_id} />
                                                {editUserProfile && index > 0 && (
                                                    <div className="col-lg-1 required_inp">
                                                        <div className="form-group">
                                                            <input type="button" className="inputRemove" value="X" onClick={() => handleDeleteEvent(field.pt_id, index)} />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {editUserProfile && (
                                        <div className="col-lg-12">
                                            <div className="add-more">
                                                <button type="button" className="add_input" onClick={addTestimonialFields}><i className='bx bx-plus'></i> {getLabel('LBL_Add_Testimonials')}</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {editUserProfile && (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleTestimonialClose}>
                                {getLabel("LBL_Close")}
                            </Button>
                            <Button variant="primary" type="submit">{getLabel("LBL_Submit")}</Button>
                        </Modal.Footer>
                    )}
                </form>
            </Modal>
        </>
    )
}

export default Testimonials
