import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { changeUserPasswordSchema } from "../../common/validation";
import { useFormik } from "formik";
import { updatePassword } from "../../services/manage-subadmin/action";
const UpdateProfilePassword = (props) => {
  const { formshow, setFormShow, admin_id } = props;

  const initialValues = {
    admin_id: admin_id,
    "current_password": "",
    "new_password": "",
    "confirm_password": "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changeUserPasswordSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        await updatePassword(values);
        handleFormClose();
        resetForm();
      } catch (error) { }
    },
  });

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  const handleFormClose = () => {
    formik.resetForm();
    setFormShow(false);
  };

  return (
    <>
      <Modal
        show={formshow}
        onHide={handleFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Change_Password")}
          </h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_Current_Password*")}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        id="current_password"
                        name="current_password"
                        onChange={handleChange}
                        value={formik.values.current_password}
                        autoComplete="off"
                      />
                      <div>
                        {formik.touched.current_password &&
                          formik.errors.current_password && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.current_password}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_New_Password*")}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        id="new_password"
                        name="new_password"
                        onChange={handleChange}
                        value={formik.values.new_password}
                        autoComplete="off"
                      />
                      <div>
                        {formik.touched.new_password &&
                          formik.errors.new_password && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.new_password}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">
                        {getLabel("LBL_Confirm_Password*")}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        onChange={handleChange}
                        value={formik.values.confirm_password}
                        autoComplete="off"
                      />
                      <div>
                        {formik.touched.confirm_password &&
                          formik.errors.confirm_password && (
                            <p style={{ color: "#b94a48" }}>
                              {formik.errors.confirm_password}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFormClose}>
              {getLabel("LBL_Cancel")}
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Update")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UpdateProfilePassword;
