import { PREMIUM_OFFERS_LOADING, PREMIUM_OFFERS_ERROR, PREMIUM_OFFERS_SUCCESS } from "./type";
const initState = { loading: true, error: false, premiumOffers: [] };
const premiumOfferReducer = (state = initState, action) => {
    switch (action.type) {
        case PREMIUM_OFFERS_LOADING:
            return { ...state, loading: true };
        case PREMIUM_OFFERS_ERROR:
            return { ...state, loading: false, error: true };
        case PREMIUM_OFFERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                premiumOffers: action.payload.rows,
                totalCount: action.payload.count,
            };
        default:
            return state;
    }
};

export { premiumOfferReducer };
