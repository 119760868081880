import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoggedIn } from '../services/authentication/action';

const RedirectIfLoggedIn = ({ to, children }) => {
    const loggedIn = useSelector(isLoggedIn);

    if (loggedIn) {
        return <Navigate to={to} />;
    }

    return children;
};

export default RedirectIfLoggedIn;
