import { getLabel } from "../../common/getLabel"
const SearchBar = (props) => {
    const { handleFilterUpdate, handleFilter, filterdata } = props;
    return (
        <>
            <div className="row">
                <div className="col-md-10 leftspace my-3">
                    <input className="search-input w-100"
                        placeholder={getLabel("LBL_Search_by_Title")}
                        name="keyword"
                        value={filterdata.keyword}
                        onChange={(e) => handleFilterUpdate(e.target.name, e.target.value, false)}
                    />
                </div >
                <div className="col-md-2 leftspace my-3">
                    <div className="input-group-append search">
                        <button type="submit" name="btn_submit" className="btn btn-brand btn-wide btn-search" onClick={handleFilter}>
                            {getLabel("LBL_Search")}
                        </button>
                    </div>
                </div >
            </div>
        </>
    )
}
export default SearchBar