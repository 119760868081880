import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { manageUserSchema } from "../../common/validation";
import { updateUser, getDataManageUsers } from "../../services/manage-users/action";
import { getUserTypes } from "../../../common/common";
const UpdateUser = (props) => {
    const { formshow, handleFormClose, userData, editUsers } = props;
    const initialValues = {
        user_id: (userData) ? userData.user_id : '',
        first_name: (userData) ? userData.user_fname : '',
        last_name: (userData) ? userData.user_lname : '',
        user_email: (userData) ? userData.user_email : '',
        user_type: (userData) ? userData.user_type : '',
        user_username: (userData) ? userData.user_username : '',
    }
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: manageUserSchema,
        onSubmit: async (values) => {
            const postedData = {
                user_id: values.user_id,
                first_name: values.first_name,
                last_name: values.last_name,
            }
            try {
                await updateUser(postedData);
                handleFormClose();
                dispatch(getDataManageUsers());
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    return (
        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">
                        {getLabel("LBL_User_Form")}
                    </h5>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label htmlFor="input">
                                            {getLabel("LBL_User_First_Name*")}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="first_name"
                                            onChange={handleChange}
                                            value={formik.values.first_name}
                                            placeholder={getLabel("LBL_User_First_Name")}
                                            disabled={!editUsers}
                                        />
                                        <div>
                                            {formik.touched.first_name && formik.errors.first_name && (
                                                <p style={{ color: "#b94a48" }}>
                                                    {formik.errors.first_name}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="input">
                                            {getLabel("LBL_User_Last_Name*")}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="last_name"
                                            onChange={handleChange}
                                            value={formik.values.last_name}
                                            placeholder={getLabel("LBL_last_name")}
                                            disabled={!editUsers}
                                        />
                                        <div>
                                            {formik.touched.last_name && formik.errors.last_name && (
                                                <p style={{ color: "#b94a48" }}>
                                                    {formik.errors.last_name}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="input">
                                            {getLabel("LBL_User_Name*")}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="user_username"
                                            value={formik.values.user_username}
                                            placeholder={getLabel("LBL_User_Name")}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="input">
                                            {getLabel("LBL_User_Email*")}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="user_email"
                                            value={formik.values.user_email}
                                            placeholder={getLabel("LBL_User_Email")}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputState">
                                            {getLabel("LBL_User_Type*")}
                                        </label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="user_type"
                                            name="user_type"
                                            value={formik.values.user_type}
                                            disabled
                                        >
                                            {Object.keys(getUserTypes).map((key) => (
                                                <option key={key} value={key}>
                                                    {getUserTypes[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {editUsers && (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFormClose}>
                                {getLabel("LBL_Close")}
                            </Button>
                            <Button variant="primary" type="submit">
                                {getLabel("LBL_Submit")}
                            </Button>
                        </Modal.Footer>
                    )}
                </form>
            </Modal>
        </>
    );
};
export default UpdateUser;
