import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Ads from './ads'
import EventsCalendar from './eventsCalendar'
import ClientServices from './clientServices'
import TrainerInformation from './trainerInfo'
import { getLabel } from '../../../../common/getLabel'
import Testimonials from './testimonials'

const TrainerProfile = (props) => {
    const { profileData, profileId, useDynamicFields, stateListing } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const handleTab = (tab) => {
        setCurrentTab(tab);
    }
    return (
        <>
            <div className="trainer-info">
                <div className="container-fluid custom-width">
                    <div className="row">
                        <div className="col-lg-4">
                            {profileData ? (
                                <h4>{profileData.uprofile_company_name}</h4>
                            ) : (
                                <h4>New Trainer</h4>
                            )}
                            <div className="d-flex align-items-start">
                                <div className="left-sidebar-trainer">
                                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link active" id="v-pills-home-tab" onClick={() => handleTab(1)} data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><i className='bx bx-user'></i> {getLabel('LBL_Trainer_Information')}</button>
                                        <button className={`nav-link ${!profileId ? 'disabled' : ''}`} onClick={() => handleTab(2)} id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" disabled={!profileId ? true : false}> <i className='bx bx-cog'></i> {getLabel('LBL_Services_&_Clients')}</button>
                                        <button className={`nav-link ${!profileId ? 'disabled' : ''}`} onClick={() => handleTab(3)} id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false" disabled={!profileId ? true : false}> <i className='bx bx-calendar'></i> {getLabel('LBL_Events_Calendar')}</button>
                                        <button className={`nav-link ${!profileId ? 'disabled' : ''}`} onClick={() => handleTab(4)} id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false" disabled={!profileId ? true : false}> <i className='bx bx-cog'></i> {getLabel('LBL_Ads')}</button>
                                        <button className={`nav-link ${!profileId ? 'disabled' : ''}`} onClick={() => handleTab(5)} id="v-pills-testimonial-tab" data-bs-toggle="pill" data-bs-target="#v-pills-testimonial" type="button" role="tab" aria-controls="v-pills-testimonial" aria-selected="false" disabled={!profileId ? true : false}> <i className='bx bx-cog'></i> {getLabel('LBL_Testimonials')}</button>
                                        {profileData && (
                                            <div className="view-pro-btn">
                                                <Link to={`/trainer-profile/${profileData.slug}`}><button type="button" className="btn btn-primary">{getLabel('LBL_VIEW_TRAINER')}</button></Link>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 rightsidebar">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                    <TrainerInformation profileData={profileData} profileId={profileId} stateListing={stateListing} />
                                </div>
                                {profileId && (
                                    <>
                                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                            <ClientServices profileData={profileData} profileId={profileId} useDynamicFields={useDynamicFields} />
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                            {currentTab == 3 && (
                                                <EventsCalendar profileId={profileId} useDynamicFields={useDynamicFields} />
                                            )}
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="0">
                                            {currentTab == 4 && (
                                                <Ads profileId={profileId} />
                                            )}
                                        </div>
                                        <div className="tab-pane fade" id="v-pills-testimonial" role="tabpanel" aria-labelledby="v-pills-testimonial-tab" tabIndex="0">
                                            {currentTab == 5 && (
                                                <Testimonials profileId={profileId} useDynamicFields={useDynamicFields} />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainerProfile