import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import { LoadingIndicator } from '../../common/loadingIndicator';

const Page404 = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <div className="container my-5">
                <div className="row">
                    {loading ? (
                        <div className="col-md-12 cms-skeleton">
                            <LoadingIndicator />
                        </div>
                    ) : (
                        <div className="col-lg-5 mx-auto">
                            <figure><img src="/images/404-file.svg" className="img-fluid" /></figure>
                            <p className="lost">Oops, look like you are lost.. </p>
                            <div className="lost-btn mb-5">
                                <Link to="/"><strong><i className="bx bx-arrow-back"></i> RETURN TO HOME PAGE</strong></Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Page404
