import React, { useEffect } from 'react'
import Header from './common/Header'
import Footer from './common/Footer'
import { Outlet } from "react-router-dom"
import ScrollToTop from './common/ScrollToTop'
import { useSelector, useDispatch } from "react-redux"
import { getHeaderFooterContent } from './services/common/action'
import MetaTags from './common/MetaTags'
const Main = () => {
    const dispatch = useDispatch();
    const { headerFooter } = useSelector((state) => state.headerFooter);
    const { metaTags } = useSelector((state) => state.metaTags);
    const generalConfigurations = headerFooter ? headerFooter.info : [];
    useEffect(() => {
        dispatch(getHeaderFooterContent());
    }, [dispatch]);
    return (
        <>
            <MetaTags record={metaTags} />
            <ScrollToTop />
            <Header headerFooter={headerFooter} />
            <Outlet context={generalConfigurations} />
            <Footer headerFooter={headerFooter} />
        </>
    )
}

export default Main
