import {
  MANAGE_ORDER_CANCEL_LOADING,
  MANAGE_ORDER_CANCEL_ERROR,
  MANAGE_ORDER_CANCEL_SUCCESS,
} from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingOrderCancel = (payload) => ({
  type: MANAGE_ORDER_CANCEL_LOADING,
  payload,
});
const handleErrorOrderCancel = () => ({
  type: MANAGE_ORDER_CANCEL_ERROR,
});
const handleSuccessOrderCancel = (payload) => ({
  type: MANAGE_ORDER_CANCEL_SUCCESS,
  payload,
});
const getDataOrderCancelReasons = (data) => async (dispatch) => {
  dispatch(handleLoadingOrderCancel());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/reason/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessOrderCancel(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    console.error("Error in manufacture listing", error);
    dispatch(handleErrorOrderCancel());
  }
};
const getOrderCancelbyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/reason/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateOrderCancelReason = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/reason/add", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteOrderbyId = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/reason/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
export {
  getDataOrderCancelReasons,
  getOrderCancelbyId,
  updateOrderCancelReason,
  deleteOrderbyId 
};
