import { getLabel } from "../../common/getLabel";
import { LoadingIndicator } from "../../common/loadingIndicator";
import Pagination from "../../common/reactPaginate";
const Listing = (props) => {
  const {
    orderCancelReasons,
    totalCount,
    loading,
    error,
    handlePaginate,
    currentPage,
    limit,
    handleFormShow,
    deleteModalOpen,
  } = props;
  const handlePageClick = (selectedPage) => {
    handlePaginate(selectedPage);
  };
  const calculateSerialNumber = (index) =>
    (currentPage - 1) * limit + index + 1;
  const totalPages = Math.ceil(totalCount / limit);

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : error ? (
        <h3 style={{ textAlign: "center" }}>
          {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
        </h3>
      ) : (
        <form id="contentPage-listing">
          {orderCancelReasons && orderCancelReasons.length > 0 ? (
            <table
              className="table table-striped table-bordered"
              cellSpacing="0"
              width="100%"
            >
              <thead>
                <tr>
                  <th className="col-check">
                    <input type="checkbox" className="selectAll-js" />
                  </th>
                  <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                  <th>{getLabel("LBL_Cancel_Reason_Name	")}</th>
                  <th className="table-action">{getLabel("LBL_Action")}</th>
                </tr>
              </thead>
              <tbody>
                {orderCancelReasons &&
                  orderCancelReasons.map((elem, index) => (
                    <tr className="actions" key={index}>
                      <th>
                        <input
                          type="checkbox"
                          name="order_id_content[]"
                          value={elem.cpage_id}
                          className="selectItem--js"
                        />
                      </th>
                      <td>{calculateSerialNumber(index)} </td>
                      <td>
                        <div className="verified-wrap">
                          <span className="user-profile_title">
                            {elem["ocreason_name"]}
                          </span>
                        </div>
                      </td>

                      <td>
                        <span className="action">
                          <ul>
                            <li>
                              <a
                                onClick={() =>
                                  handleFormShow(elem["ocreason_id"])
                                }
                              >
                                <i
                                  className="bx bx-pencil"
                                  variant="primary"
                                ></i>
                              </a>
                            </li>

                            <li>
                              <a
                                onClick={() =>
                                  deleteModalOpen(elem["ocreason_id"])
                                }
                              >
                                <i className="bx bx-trash"></i>
                              </a>
                            </li>
                          </ul>
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <h4 style={{ textAlign: "center" }}>
              {getLabel("LBL_No_result_found")}
            </h4>
          )}
        </form>
      )}

      {orderCancelReasons && orderCancelReasons.length > 0 && (
        <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
      )}
    </>
  );
};

export default Listing;
