import React from 'react';
import { useSelector } from 'react-redux';
import { getLabel } from '../../common/getLabel';
import { Link } from 'react-router-dom';
import { baseUrl } from './config';
import { formatDate } from '../../common/common';
import Slider from 'react-slick';
import { isEmpty } from 'lodash';

const BlogPosts = () => {
    const { headerFooter } = useSelector((state) => state.headerFooter);
    const footerArticles = headerFooter?.footer?.blogs?.data || [];
    const blogNoImage = headerFooter?.footer?.blogs?.blogDefaultImage || [];

    const sliderSetting = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        autoplay: false,
        arrows: footerArticles.length > 4,
        responsive: [
            { breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1, dots: false } },
            { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1, dots: false } },
            { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        ]
    };

    if(isEmpty(footerArticles) === true){
        return null;
    }

    return (
        <div className="bg-articles news--articles">
            <div className="container-fluid custom-width">
                <div className="row justify-content-center">
                    <div className="col-lg-12 qbh-padding-left">
                        <div className="heading">
                            <h2>{getLabel('LBL_Articles')}</h2>
                            <div className="space-line"></div>
                        </div>
                    </div>
                    <Slider className="row popular-blog-slider popular-product-slider" {...sliderSetting}>
                        {footerArticles.map((article, index) => (
                            <div className="col-lg-3 col-sm-6 col-md-6 col-xl-3 col-xxl-3" key={index}>
                                <div className="box-articles">
                                    <div className="articles-box">
                                        <div className="acrticles-image">
                                            <figure>
                                                <Link to={`/${article["blogUrl.urlrewrite_custom"]}`}>
                                                    <img
                                                        src={`${baseUrl}/${article.images?.[0]?.afile_physical_path || blogNoImage[0].afile_physical_path}`}
                                                        className="img-fluid"
                                                        alt={article.images && article.images[0] && article.images[0].afile_name ? article.images[0].afile_name : article.blog_title}
                                                    />
                                                </Link>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="articles-box-content">
                                        <div className="recruiting-btn">{article.category_title}</div>
                                        <div className="calendar"><i className='bx bx-calendar'></i> <span>{formatDate(article.blog_added_at)}</span></div>
                                        <div className="acrticles-description">
                                            <p><Link to={`/${article["blogUrl.urlrewrite_custom"]}`}>{article.blog_title}</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default BlogPosts;
