import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { blogTagSchema } from "../../common/validation";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateBlogTag, getDataBlogTags } from "../../services/blog-tag/action";
const AddUpdateBlogTag = (props) => {
  const { formshow, handleFormClose, requestBody, tagId } = props;

  const blogTagData = useSelector(state =>
    state.blogTag?.blogTags?.find(blogTag => blogTag.btag_id === tagId)
  );

  const initialValues = {
    id: (blogTagData) ? blogTagData.btag_id : '',
    status: (blogTagData) ? blogTagData.btag_status : '',
    title: (blogTagData) ? blogTagData.btag_title : '',
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: blogTagSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        const response = await updateBlogTag(values);
        if (response === true) {
          dispatch(getDataBlogTags(requestBody));
          handleFormClose();
        }
      } catch (error) { }
    },
  });

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>
      <Modal
        show={formshow}
        onHide={handleFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Blog_Tag_Form")}</h5>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="input">{getLabel("LBL_Blog_Tag_Name*")}</label>
                      <input className="form-control" type="text" id="title" name="title" onChange={handleChange} value={formik.values.title} placeholder={getLabel("LBL_Blog_Tag_Name")} />
                      <div>
                        {formik.touched.title && formik.errors.title && (
                          <p style={{ color: "#b94a48" }}>{formik.errors.title}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="inputState">{getLabel("LBL_Blog_Tag_Status*")}</label>
                    <select as="select" className="form-select" aria-label="Default select example" id="status" name="status" onChange={handleChange} value={formik.values.status}>
                      <option value="">{getLabel("LBL_Choose")}</option>
                      <option value="1">{getLabel("LBL_Active")}</option>
                      <option value="0">{getLabel("LBL_InActive")}</option>
                    </select>
                    <div>
                      {formik.touched.status && formik.errors.status && (
                        <p style={{ color: "#b94a48" }}>{formik.errors.status}</p>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFormClose}>
              {getLabel("LBL_Close")}
            </Button>
            <Button variant="primary" type="submit">
              {getLabel("LBL_Submit_Blog_Tag")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
export default AddUpdateBlogTag;
