import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";
import { BLOG_ERROR, BLOG_SUCCESS, BLOG_LOADING } from "./types";

const handleLoadingBlog = (payload) => ({
    type: BLOG_LOADING,
    payload,
});
const handleErrorBlog = () => ({
    type: BLOG_ERROR,
});
const handleSuccessBlog = (payload) => ({
    type: BLOG_SUCCESS,
    payload,
});
const getDataBlogs = (data) => async (dispatch) => {
    dispatch(handleLoadingBlog());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/blog/search", data)
                .then((res) => {
                    dispatch(handleSuccessBlog(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorBlog());
    }
};

const getDataBlogsDataById = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/blog/detail", data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export { getDataBlogs, getDataBlogsDataById };