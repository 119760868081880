import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export const displayProcessing = async (message = 'Loading...') => {
    toast.loading(message)
};
export const errorMessage = async (error, resolve) => {
    const eMessage = error.response.data.message;
    toast.dismiss()
    toast.error(eMessage);
};
export const successMessage = async (resolve, res) => {
    toast.dismiss()
    if (res.data.success === true) {
        toast.success(res.data.message);
        resolve(true);
    } else {
        toast.error(res.data.message);
        resolve(false);
    }
};
