import { useFormik } from "formik";
import { getLabel } from "../../common/getLabel";
import { getDataGeneralSettings, updateGeneralSetting } from "../../services/general-settings/action";
import { useDispatch } from "react-redux";
const GeneralPage = (props) => {
    const { generalSettings, editSetting } = props;
    const dispatch = useDispatch();

    const initialValues = {
        CONF_SITE_TITLE: (generalSettings) ? generalSettings.CONF_SITE_TITLE : '',
        CONF_SITE_OWNER_EMAIL: (generalSettings) ? generalSettings.CONF_SITE_OWNER_EMAIL : '',
        CONF_TELEPHONE: (generalSettings) ? generalSettings.CONF_TELEPHONE : '',
        CONF_FAX: (generalSettings) ? generalSettings.CONF_FAX : '',
        CONF_FRONT_ITEM_PER_PAGE: (generalSettings) ? generalSettings.CONF_FRONT_ITEM_PER_PAGE : '',
        CONF_ITEM_PER_PAGE: (generalSettings) ? generalSettings.CONF_ITEM_PER_PAGE : '',
        CONF_PROMO_CODE: (generalSettings) ? generalSettings.CONF_PROMO_CODE : '',
        CONF_FREE_PROFILE_LIMIT: (generalSettings) ? generalSettings.CONF_FREE_PROFILE_LIMIT : '',
    };
    const formik = useFormik({
        initialValues: initialValues,
        validateOnChange: false,
        onSubmit: async (values) => {

            const data = Object.entries(values).map(([key, value]) => ({
                conf_name: key,
                conf_value: value ? value : '',
            }));

            try {
                await updateGeneralSetting(data);
                dispatch(getDataGeneralSettings({ config_key: "CONF_SITE_TITLE" }));
            } catch (error) { }
        },
    });

    const handleChange = (e) => {
        if (editSetting) {
            formik.handleChange(e);
        }
    };

    var disable = "disabled";
    if (editSetting) {
        disable = "";
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row formcustom">
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_Site_Title")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_SITE_TITLE"
                            value={formik.values.CONF_SITE_TITLE}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Site_Title")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4">
                            {getLabel("LBL_Site_Owner_Email")}
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            name="CONF_SITE_OWNER_EMAIL"
                            value={formik.values.CONF_SITE_OWNER_EMAIL}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Email")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_Telephone")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_TELEPHONE"
                            value={formik.values.CONF_TELEPHONE}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Telephone")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">{getLabel("LBL_Fax")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_FAX"
                            value={formik.values.CONF_FAX}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Fax")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Admin_Default_Item_Per_Page")}
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="CONF_ITEM_PER_PAGE"
                            value={formik.values.CONF_ITEM_PER_PAGE}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Admin_Default_Item_Per_Page")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Frontend_Default_Item_Per_Page")}
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="CONF_FRONT_ITEM_PER_PAGE"
                            value={formik.values.CONF_FRONT_ITEM_PER_PAGE}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Item_Per_Page")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Promo_Code")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_PROMO_CODE"
                            value={formik.values.CONF_PROMO_CODE}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Promo_Code")}
                            disabled={disable}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input">
                            {getLabel("LBL_Free_Profile_Limit")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="CONF_FREE_PROFILE_LIMIT"
                            value={formik.values.CONF_FREE_PROFILE_LIMIT}
                            onChange={handleChange}
                            placeholder={getLabel("LBL_Promo_Code")}
                            disabled={disable}
                        />
                    </div>



                    {editSetting && (
                        <div className="form-group col-md-12">
                            <button className="btn btn-sm btn-secondary" type="submit">
                                Submit
                            </button>
                        </div>
                    )}
                </div>
            </form>
        </>
    );
};

export default GeneralPage;
