import {
  BlOG_CATEGORY_LOADING,
  BlOG_CATEGORY_ERROR,
  BlOG_CATEGORY_SUCCESS,
} from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";

const handleLoadingBlogCategory = (payload) => ({
  type: BlOG_CATEGORY_LOADING,
  payload,
});
const handleErrorBlogCategory = () => ({
  type: BlOG_CATEGORY_ERROR,
});
const handleSuccessBlogCategory = (payload) => ({
  type: BlOG_CATEGORY_SUCCESS,
  payload,
});

const getDataBlogCategories = (data) => async (dispatch) => {
  dispatch(handleLoadingBlogCategory());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/blog/category/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessBlogCategory(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    dispatch(handleErrorBlogCategory());
  }
};
const getBlogCategorybyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/blog/category/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateBlogCategory = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/category/setup", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteBlogCategory = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/category/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateBlogCategoryStatus = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/blog/category/status-update", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

export {
  getDataBlogCategories,
  getBlogCategorybyId,
  updateBlogCategory,
  deleteBlogCategory,
  updateBlogCategoryStatus,
};
