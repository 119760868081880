import { store } from "../store"
const SUBADMIN = 1;
const BANNER = 2;
const BLOG = 3;
const ADMIN_PERMISSION = 4;
const CONFIGURATION = 5;
const CONTENT_BLOCK = 6;
const FAQ = 7;
const LABEL = 8;
const CONTENT_PAGE = 9;
const URL_REWRITE = 10;
const META_TAGS = 11;
const NAVIGATION = 12;
const USER = 13;
const USER_PROFILES = 14;
const SCHOOL_LOGOS = 15;
const OFFERS = 16;
const EVENTS = 17;
const RANKINGS = 18;
const ORDERS = 19;
const COUPONS = 20;


const PRIVILEGE_READ = 1;
const PRIVILEGE_WRITE = 2;
const PRIVILEGE_NONE = 0;


export const getPermissionTypes = () => {
    return {
        [PRIVILEGE_NONE]: 'None',
        [PRIVILEGE_READ]: 'Read Only',
        [PRIVILEGE_WRITE]: 'Read and Write',
    }
}


const checkPermission = (admin_id, module_id, level) => {
    if (admin_id === 0) {
        const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
        admin_id = adminDetails ? adminDetails.admin_id : null
    }

    if (admin_id == 1) {
        return true
    }

    const getPermissions = store.getState().manageSubAdmin.adminModulePermissions;
    const nums = [1, 2];
    if (nums.includes(level) === false) {
        return false;
    }

    if (getPermissions) {
        if (level <= getPermissions[module_id]) {
            return true;
        } else {
            return false;
        }
    }
};

export const getPrivileges = () => {

    const canViewSubAdmin = (admin_id = 0) => { return checkPermission(admin_id, SUBADMIN, PRIVILEGE_READ) };
    const canEditSubAdmin = (admin_id = 0) => { return checkPermission(admin_id, SUBADMIN, PRIVILEGE_WRITE) };

    const canViewBanner = (admin_id = 0) => { return checkPermission(admin_id, BANNER, PRIVILEGE_READ) };
    const canEditBanner = (admin_id = 0) => { return checkPermission(admin_id, BANNER, PRIVILEGE_WRITE) };

    const canViewBlog = (admin_id = 0) => { return checkPermission(admin_id, BLOG, PRIVILEGE_READ) };
    const canEditBlog = (admin_id = 0) => { return checkPermission(admin_id, BLOG, PRIVILEGE_WRITE) };

    const canViewAdminPermission = (admin_id = 0) => { return checkPermission(admin_id, ADMIN_PERMISSION, PRIVILEGE_READ) };
    const canEditAdminPermission = (admin_id = 0) => { return checkPermission(admin_id, ADMIN_PERMISSION, PRIVILEGE_WRITE) };

    const canViewConfiguration = (admin_id = 0) => { return checkPermission(admin_id, CONFIGURATION, PRIVILEGE_READ) };
    const canEditConfiguration = (admin_id = 0) => { return checkPermission(admin_id, CONFIGURATION, PRIVILEGE_WRITE) };

    const canViewContentBlock = (admin_id = 0) => { return checkPermission(admin_id, CONTENT_BLOCK, PRIVILEGE_READ) };
    const canEditContentBlock = (admin_id = 0) => { return checkPermission(admin_id, CONTENT_BLOCK, PRIVILEGE_WRITE) };

    const canViewFaq = (admin_id = 0) => { return checkPermission(admin_id, FAQ, PRIVILEGE_READ) };
    const canEditFaq = (admin_id = 0) => { return checkPermission(admin_id, FAQ, PRIVILEGE_WRITE) };

    const canViewLabel = (admin_id = 0) => { return checkPermission(admin_id, LABEL, PRIVILEGE_READ) };
    const canEditLabel = (admin_id = 0) => { return checkPermission(admin_id, LABEL, PRIVILEGE_WRITE) };

    const canViewContentPage = (admin_id = 0) => { return checkPermission(admin_id, CONTENT_PAGE, PRIVILEGE_READ) };
    const canEditContentPage = (admin_id = 0) => { return checkPermission(admin_id, CONTENT_PAGE, PRIVILEGE_WRITE) };

    const canViewUrlRewrite = (admin_id = 0) => { return checkPermission(admin_id, URL_REWRITE, PRIVILEGE_READ) };
    const canEditUrlRewrite = (admin_id = 0) => { return checkPermission(admin_id, URL_REWRITE, PRIVILEGE_WRITE) };

    const canViewMetaTags = (admin_id = 0) => { return checkPermission(admin_id, META_TAGS, PRIVILEGE_READ) };
    const canEditMetaTags = (admin_id = 0) => { return checkPermission(admin_id, META_TAGS, PRIVILEGE_WRITE) };

    const canViewNavigation = (admin_id = 0) => { return checkPermission(admin_id, NAVIGATION, PRIVILEGE_READ) };
    const canEditNavigation = (admin_id = 0) => { return checkPermission(admin_id, NAVIGATION, PRIVILEGE_WRITE) };

    const canViewUser = (admin_id = 0) => { return checkPermission(admin_id, USER, PRIVILEGE_READ) };
    const canEditUser = (admin_id = 0) => { return checkPermission(admin_id, USER, PRIVILEGE_WRITE) };

    const canViewUserProfiles = (admin_id = 0) => { return checkPermission(admin_id, USER_PROFILES, PRIVILEGE_READ) };
    const canEditUserProfiles = (admin_id = 0) => { return checkPermission(admin_id, USER_PROFILES, PRIVILEGE_WRITE) };

    const canViewSchoolLogs = (admin_id = 0) => { return checkPermission(admin_id, SCHOOL_LOGOS, PRIVILEGE_READ) };
    const canEditSchoolLogs = (admin_id = 0) => { return checkPermission(admin_id, SCHOOL_LOGOS, PRIVILEGE_WRITE) };

    const canViewOffers = (admin_id = 0) => { return checkPermission(admin_id, OFFERS, PRIVILEGE_READ) };
    const canEditOffers = (admin_id = 0) => { return checkPermission(admin_id, OFFERS, PRIVILEGE_WRITE) };
    
    const canViewEvents = (admin_id = 0) => { return checkPermission(admin_id, EVENTS, PRIVILEGE_READ) };
    const canEditEvents = (admin_id = 0) => { return checkPermission(admin_id, EVENTS, PRIVILEGE_WRITE) };

    const canViewRankings = (admin_id = 0) => { return checkPermission(admin_id, RANKINGS, PRIVILEGE_READ) };
    const canEditRankings = (admin_id = 0) => { return checkPermission(admin_id, RANKINGS, PRIVILEGE_WRITE) };

    const canViewOrders = (admin_id = 0) => { return checkPermission(admin_id, ORDERS, PRIVILEGE_READ) };
    const canEditOrders = (admin_id = 0) => { return checkPermission(admin_id, ORDERS, PRIVILEGE_WRITE) };

    const canViewCoupons = (admin_id = 0) => { return checkPermission(admin_id, COUPONS, PRIVILEGE_READ) };
    const canEditCoupons = (admin_id = 0) => { return checkPermission(admin_id, COUPONS, PRIVILEGE_WRITE) };

    return {
        canViewSubAdmin,
        canEditSubAdmin,
        canViewBanner,
        canEditBanner,
        canViewBlog,
        canEditBlog,
        canViewAdminPermission,
        canEditAdminPermission,
        canViewConfiguration,
        canEditConfiguration,
        canViewContentBlock,
        canEditContentBlock,
        canViewFaq,
        canEditFaq,
        canViewLabel,
        canEditLabel,
        canViewContentPage,
        canEditContentPage,
        canViewUrlRewrite,
        canEditUrlRewrite,
        canViewMetaTags,
        canEditMetaTags,
        canViewNavigation,
        canEditNavigation,
        canViewUser,
        canEditUser,
        canViewUserProfiles,
        canEditUserProfiles,
        canViewSchoolLogs,
        canEditSchoolLogs,
        canViewOffers,
        canEditOffers,
        canViewEvents,
        canEditEvents,
        canViewRankings,
        canEditRankings,
        canViewOrders,
        canEditOrders,
        canViewCoupons,
        canEditCoupons
    };
}

export default getPrivileges;