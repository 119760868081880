import { BLOG_CATEGORY_LOADING, BLOG_CATEGORY_ERROR, BLOG_CATEGORY_SUCCESS } from "./types";
const initState = { loading: true, error: false, blogCateogries: [] };
const blogCategoryReducer = (state = initState, action) => {
    switch (action.type) {
        case BLOG_CATEGORY_LOADING:
            return { ...state, loading: true };
        case BLOG_CATEGORY_ERROR:
            return { ...state, loading: false, error: true };
        case BLOG_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                blogCateogries: action.payload,
            };
        default:
            return state;
    }
};

export { blogCategoryReducer };
