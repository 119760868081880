import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { updateBanner, getDataBanners, deleteBannerImage } from '../../services/manage-banner/action';
import { useState } from 'react';
import { baseUrl } from '../../common/config';
import { ImageDeleteModal } from '../../common/imageDeleteModal';
import { bannerSchema } from '../../common/validation';
import { useDispatch, useSelector } from 'react-redux';

const AddUpdateBanner = (props) => {
    const { formshow, handleFormClose, requestBody, bannerId, editBanner } = props
    const [bannerImage, setBannerImage] = useState(null)
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const [imageDeleteId, setImageDeletedId] = useState()
    const bannerData = useSelector(state => {
        const banners = state.banner.banners;
        if (banners && banners.length > 0) {
            return banners.find(banner => banner.banner_id === bannerId);
        }
        return null;
    });

    const imageId = bannerData && bannerData.images && Object.keys(bannerData.images).length > 0 && bannerData.images[0].afile_record_id
    const initialValues = {
        banner_id: (bannerData) ? bannerData.banner_id : '',
        banner_button_label: (bannerData) ? bannerData.banner_button_label : '',
        banner_description: (bannerData) ? bannerData.banner_description : '',
        banner_heading: (bannerData) ? bannerData.banner_heading : '',
        banner_sub_heading: (bannerData) ? bannerData.banner_sub_heading : '',
        banner_status: (bannerData) ? bannerData.banner_status : '',
        banner_target: (bannerData) ? bannerData.banner_target : '',
        banner_url: (bannerData) ? bannerData.banner_url : '',
        banner_display_order: (bannerData) ? bannerData.banner_display_order : '',
    };
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: bannerSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("banner_image", bannerImage);
            formData.append("banner_key", "home-slider");
            Object.entries(values).forEach(([key, value]) => {
                formData.append(key, value);
            });

            if (editBanner) {
                try {
                    const response = await updateBanner(formData);
                    if (response === true) {
                        handleFormClose();
                        dispatch(getDataBanners(requestBody))
                    }
                } catch (error) { }
            }
        },
    });

    const handleChange = (e) => {
        if (editBanner) {
            formik.handleChange(e);
        }
    };

    const handleCancelImage = () => {
        setBannerImage("")
    };

    const handleImageDeleteModalShow = (id) => {
        setImageDeletedId(id)
        setImageDeleteModalShow(true);
    }

    const handleDeleteImage = async () => {
        if (editBanner) {
            try {
                const data = { "banner_id": bannerData.banner_id }
                await deleteBannerImage(data)
                handleImageDeleteModalClose()
                const Name = 'image_list_' + imageDeleteId;
                document.querySelector('.' + Name).remove();
                dispatch(getDataBanners(requestBody))
            } catch (error) { }
        }
    }

    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false)

    return (
        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Banner_Form")}</h5>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input"> {getLabel("LBL_Banner_Heading*")} </label>
                                            <input
                                                type="text"
                                                id="banner_heading"
                                                name="banner_heading"
                                                onChange={handleChange}
                                                value={formik.values.banner_heading}
                                                className="form-control"
                                                placeholder={getLabel("LBL_Enter_Banner_Heading")}
                                                disabled={!editBanner}
                                            />
                                            <div>
                                                {formik.touched.banner_heading && formik.errors.banner_heading && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.banner_heading}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Banner_Sub_Heading*")}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="banner_sub_heading"
                                                name="banner_sub_heading"
                                                onChange={handleChange}
                                                value={formik.values.banner_sub_heading}
                                                placeholder={getLabel("LBL_Enter_Sub_Heading")}
                                                disabled={!editBanner}
                                            />
                                            <div>
                                                {formik.touched.banner_sub_heading && formik.errors.banner_sub_heading && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.banner_sub_heading}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Banner_Button_Label*")} </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="banner_button_label"
                                                name="banner_button_label"
                                                onChange={handleChange}
                                                value={formik.values.banner_button_label}
                                                placeholder={getLabel("LBL_Enter_Button Label")}
                                                disabled={!editBanner}
                                            />
                                            <div>
                                                {formik.touched.banner_button_label && formik.errors.banner_button_label && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.banner_button_label}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Banner_Url*")}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="banner_url"
                                                name="banner_url"
                                                onChange={handleChange}
                                                value={formik.values.banner_url}
                                                placeholder={getLabel("LBL_Enter_Banner_Url")}
                                                disabled={!editBanner}
                                            />
                                            <div>
                                                {formik.touched.banner_url && formik.errors.banner_url && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.banner_url}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Banner_Description*")}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={getLabel("LBL_Enter_Banner_Description")}
                                                id="banner_description"
                                                name="banner_description"
                                                onChange={handleChange}
                                                value={formik.values.banner_description}
                                                disabled={!editBanner}
                                            />
                                            <div>
                                                {formik.touched.banner_description && formik.errors.banner_description && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.banner_description}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input"> {getLabel("LBL_Banner_Image")}</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                onChange={((e) => setBannerImage(e.target.files[0]))}
                                                disabled={!editBanner}
                                            />
                                        </div>
                                        <div className='main-div'>
                                            {bannerImage ?
                                                (
                                                    <img src={URL.createObjectURL(bannerImage)} style={{ height: "50px", width: "50px", borderRadius: "50%" }} onClick={handleCancelImage} alt="image-path ist" />
                                                ) : (
                                                    <div className="show--image">
                                                        <div className={`image_list_${imageId}`}>
                                                            {bannerData && bannerData.images && Object.keys(bannerData.images).length > 0 &&
                                                                <div>
                                                                    <img
                                                                        src={`${baseUrl}/${bannerData.images[0].afile_physical_path}`}
                                                                        style={{ height: "50px", width: "50px", borderRadius: "50%" }} onClick={handleCancelImage} alt="image-path" />
                                                                    {editBanner && (

                                                                        <input
                                                                            type="button"
                                                                            value="x"
                                                                            className="btn-rmv1 rmv"
                                                                            onClick={(() => handleImageDeleteModalShow(imageId))} />
                                                                    )}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_Banner_Target*")}</label>
                                            <select
                                                as="select"
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="banner_target"
                                                name="banner_target"
                                                onChange={handleChange}
                                                disabled={!editBanner}
                                                value={formik.values.banner_target}>
                                                <option value="" disabled>{getLabel("LBL_Choose")}</option>
                                                <option value="_self">{getLabel("LBL_Current_Window")}</option>
                                                <option value="_blank">{getLabel("LBL_New_Window")}</option>
                                            </select>
                                            <div>
                                                {formik.touched.banner_target && formik.errors.banner_target && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.banner_target}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="input">
                                            {getLabel("LBL_Display_Order*")}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="banner_display_order"
                                            onChange={handleChange}
                                            value={formik.values.banner_display_order}
                                            placeholder={getLabel("LBL_Display_Order")}
                                            disabled={!editBanner}
                                        />
                                        <div>
                                            {formik.touched.banner_display_order && formik.errors.banner_display_order && (
                                                <p style={{ color: "#b94a48" }}>
                                                    {formik.errors.banner_display_order}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">{getLabel("LBL_Banner_Status*")}</label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="banner_status"
                                            name="banner_status"
                                            onChange={handleChange}
                                            value={formik.values.banner_status}
                                            disabled={!editBanner}
                                        >
                                            <option value="">{getLabel("LBL_Choose")}</option>
                                            <option value="1">{getLabel("LBL_Active")}</option>
                                            <option value="0">{getLabel("LBL_InActive")}</option>
                                        </select>
                                        <div>
                                            {formik.touched.banner_status && formik.errors.banner_status && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.banner_status}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={handleFormClose}
                        >
                            {getLabel("LBL_Close")}
                        </Button>
                        {editBanner && (
                            <Button variant="primary" type="submit">{getLabel("LBL_Submit_Banner")}</Button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
            <ImageDeleteModal
                imageDeleteModalshow={imageDeleteModalshow}
                handleImageDeleteModalClose={handleImageDeleteModalClose}
                handleDeleteImage={handleDeleteImage}
            />

        </>
    )
}


export default AddUpdateBanner