import { SEO_URL_LOADING, SEO_URL_ERROR, SEO_URL_SUCCESS } from "./type";
const initState = { loading: true, error: false, seoUrlListing: [] };
const seoUrlReducer = (state = initState, action) => {
  switch (action.type) {
    case SEO_URL_LOADING:
      return { ...state, loading: true };
    case SEO_URL_ERROR:
      return { ...state, loading: false, error: true };
    case SEO_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        seoUrlListing: action.payload.urls,
        totalCount: action.payload.totalRecords,
      };
    default:
      return state;
  }
};

export { seoUrlReducer };
