import React from 'react'
import { Helmet } from 'react-helmet'
import { getDefaultMetaTags, isEmpty } from '../../common/common';
import { baseUrl } from './config';
const MetaTags = () => {
    const records = getDefaultMetaTags();
    if (isEmpty(records) == true) {
        return null;
    }
    const record = records.tag;
    const favImage = records.fav;
    return (
        <>
            <Helmet>
                <title>{record.meta_title}</title>
                <meta name="application-name" content={record.meta_title} />
                <meta name="apple-mobile-web-app-title" content={record.title} />
                <meta name="description" content={record.meta_description} />
                <meta name="keywords" content={record.meta_keywords} />
                <link rel="icon" type="image/png" href={favImage && favImage.afile_physical_path ? `${baseUrl}/${favImage.afile_physical_path}` : "/images/favicon.ico"} sizes="16x16" />
            </Helmet>
        </>
    )
}

export default MetaTags
