import { getLabel } from "../../common/getLabel";
import TableSkeleton from "../../common/TableSkeleton";
const DefaultListing = (props) => {
    const { metaTagListing, loading, error, handleFormShow } = props;
    return (
        <>
            <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                <thead>
                    <tr>
                        <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                        <th>{getLabel("LBL_Title")}</th>
                        <th className="thead-action">{getLabel("LBL_Action")}</th>
                    </tr>
                </thead>
                <tbody>

                    {loading ? (
                        <>
                            <TableSkeleton cards={10} count={3} />
                        </>
                    ) : error ? (
                        <tr className="no--record">
                            <td colspan="3" className="text-center">
                                <div className="verified-wrap">
                                    <span className="user-profile_title">
                                        {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <>
                            {metaTagListing && metaTagListing.length > 0 ? (
                                metaTagListing.map((elem, index) => (
                                    <tr className="actions" key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className="verified-wrap">
                                                <span className="user-profile_title">
                                                    {elem["meta_title"]}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="action">
                                                <ul>
                                                    <li>
                                                        <a>
                                                            <i className="bx bx-pencil" onClick={() => handleFormShow(elem["meta_id"]) } ></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="no--record">
                                    <td colspan="3" className="text-center">
                                        <div className="verified-wrap">
                                            <span className="user-profile_title">
                                                {getLabel("LBL_No_result_found")}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </table>
        </>
    );
};

export default DefaultListing;
