import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const TableSkeleton = ({ cards, count, checkbox=false }) => (
  Array.from({ length: cards }, (_, i) => (
    <tr key={i} className="skeleton--loader actions">
      {checkbox && (
        <th><Skeleton baseColor="#ebebeb" highlightColor="#cfcfcfab" /></th>
      )}
      {Array.from({ length: count }, (_, j) => (
        <td key={j}><Skeleton baseColor="#ebebeb" highlightColor="#cfcfcfab" /></td>
      ))}
    </tr>
  ))
);

export default TableSkeleton;
