import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage } from "../../common/message";

const getDasboardData = async () => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .get("/dashboard", { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in Dasboard", error);
    }
};

export { getDasboardData };
