import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getDataUserProfiles, getuserListingForReAssign, reAssignProfile } from '../../services/manage-user-profiles/action';
import { assignProfileSchema } from '../../common/validation';

const ReAssignProfile = (props) => {
    const { assignProfileFormShow, handleProfileViewClose, profileData, userName, requestBody } = props;
    const dispatch = useDispatch();
    const [userRecords, setUserRecords] = useState([]);
    const [searchItem, setSearchItem] = useState(userName);
    const [isRecordSearched, setIsRecordSearched] = useState(false);
    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchItem(value);
        setIsRecordSearched(true);
    };
    useEffect(() => {
        if (searchItem && searchItem.trim() === "") {
            setUserRecords([]);
            setIsRecordSearched(false);
            formik.setFieldValue("user_id", "");
            return;
        }

        if (isRecordSearched && searchItem.length >= 3) {
            const delaySearch = setTimeout(() => {
                fetchUserListing();
            }, 1000);
            return () => clearTimeout(delaySearch);
        }
    }, [searchItem]);

    const fetchUserListing = async () => {
        const response = await getuserListingForReAssign({ "keyword": searchItem, user_id: profileData ? profileData.uprofile_user_id : '' });
        setUserRecords(response);
    };

    const initialValues = {
        user_id: profileData ? profileData.uprofile_user_id : '',
        profile_id: profileData ? profileData.uprofile_id : '',
    };

    const profileName = profileData && profileData.uprofile_fname
        ? profileData.uprofile_lname
            ? `${profileData.uprofile_fname} ${profileData.uprofile_lname}`
            : profileData.uprofile_fname
        : '';

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: assignProfileSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                const response = await reAssignProfile(values);
                if (response.success) {
                    handleProfileViewClose();
                    dispatch(getDataUserProfiles(requestBody));
                }
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };
    return (
        <Modal
            show={assignProfileFormShow}
            onHide={handleProfileViewClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_Re-assign_profile_For")} {profileName}</h5>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputState">{getLabel("LBL_Assign_To")}</label>
                                    <input
                                        className="form-control"
                                        id="keyword"
                                        type="search"
                                        name="keyword"
                                        placeholder="Enter Keyword..."
                                        value={searchItem}
                                        onChange={handleInputChange}
                                    />
                                    <div>
                                        {userRecords && userRecords.length > 0 && (
                                            <select
                                                as="select"
                                                className="form-select mt-2"
                                                aria-label="Default select example"
                                                id="user_id"
                                                name="user_id"
                                                onChange={handleChange}
                                                value={formik.values.user_id}
                                            >
                                                <option value="">
                                                    {getLabel("LBL_Select_User")}
                                                </option>
                                                {userRecords.map((elem) => (
                                                    <option key={elem.user_id} value={elem.user_id}>
                                                        {elem.user_username}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                        {formik.touched.user_id && formik.errors.user_id && (
                                            <p style={{ color: "#b94a48" }}>{formik.errors.user_id}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleProfileViewClose}>
                        {getLabel("LBL_Close")}
                    </Button>
                    <Button variant="primary" type="submit">
                        {getLabel("LBL_Submit")}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default ReAssignProfile;
