import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from "react-router-dom"
import Login from "./component/Auth/login"
import Forgot from "./component/Auth/forgotPassword"
import Create from "./component/Auth/createPassword"
import Dashboard from "./component/dashboard/dashboard"
import { ToastContainer } from 'react-toastify';
import Sidebar from "./common/sidebar"
import Navbar from "./common/navbar"
import LabelIndex from "./component/label"
import ManageBannerIndex from "./component/manage-Banner"
import FaqIndex from "./component/manage-Faq"
import ContentBlockIndex from "./component/manage-content-block"
import ContentPageIndex from "./component/content-page"
import GeneralSettingIndex from "./component/general-setting"
import BlogCategoryIndex from "./component/blog-category"
import BlogTagIndex from "./component/blog-tag"
import BlogIndex from "./component/blog"
import NavigationMenuIndex from "./component/navigation"
import SubNavigationIndex from "./component/navigation/subNavigation"
import ManageUserIndex from "./component/manage-Users"
import SubAdminUserIndex from "./component/subadmin-user"
import UpdatePermissionListing from "./component/subadmin-user/updatePermissionListing"
import { isLoggedIn } from "./services/authentication/action";
import ManageCancelReasonIndex from "./component/manage-order-cancel-reason"
import ManageOrderReturnIndex from "./component/manage-order-return-reason"
import BackendPasswordIndex from "./component/backend-password"
import ProfileIndex from "./component/profile"
import UrlReWritingIndex from "./component/url-rewriting"
import MetaTagIndex from "./component/meta-tag"
import { useSelector } from 'react-redux';
import { setPermissionsByUserId } from './services/manage-subadmin/action';
import { store } from "./store";
import FaqCategoryIndex from './component/faq-category';
import UserProfilesIndex from './component/manage-user-profiles';
import TrainerIndex from './component/manage-trainers';
import SchoolLogoIndex from './component/manage-school-logos';
import OfferIndex from './component/manage-offers';
import EventIndex from './component/manage-events';
import RankingIndex from './component/manage-rankings';
import OrderIndex from './component/manage-orders';
import OrderView from './component/manage-orders/view';
import CouponIndex from './component/manage-coupons';
import MetaTags from './common/MetaTags';
const AdminRoutes = () => {
    const loggedIn = useSelector(isLoggedIn);
    const [styleLoaded, setStyleLoaded] = useState(false);

    if (isLoggedIn()) {
        const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
        let admin_id = adminDetails ? adminDetails.admin_id : null;
        store.dispatch(setPermissionsByUserId(admin_id));
    }


    useEffect(() => {
        import('./common/AdminStyle')
            .then(() => {
                setStyleLoaded(true);
            })
            .catch(error => {
                console.error('Error loading frontend style:', error);
            });
    }, []);
    return (
        <>
            {styleLoaded && (
                <>
                    <MetaTags />
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/forgot-password" element={<Forgot />} />
                        <Route path="/create/password/:token" element={<Create />} />
                        {loggedIn ? (
                            <Route element={<Sidebar />}>
                                <Route element={<Navbar />}>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/label" element={<LabelIndex />} />
                                    <Route path="/banners" element={<ManageBannerIndex />} />
                                    <Route path="/faqs-category" element={<FaqCategoryIndex />} />
                                    <Route path="/faqs" element={<FaqIndex />} />
                                    <Route path="/content-blocks" element={<ContentBlockIndex />} />
                                    <Route path="/content-pages" element={<ContentPageIndex />} />
                                    <Route path="/configuration" element={<GeneralSettingIndex />} />
                                    <Route path="/blog-category" element={<BlogCategoryIndex />} />
                                    <Route path="/blog-tag" element={<BlogTagIndex />} />
                                    <Route path="/blog" element={<BlogIndex />} />
                                    <Route path="/navigation" element={<NavigationMenuIndex />} />
                                    <Route path="/navigation/:id" element={<SubNavigationIndex />} />
                                    <Route path="/users" element={<ManageUserIndex />} />
                                    <Route path="/sub-admin" element={<SubAdminUserIndex />} />
                                    <Route path="/user-profile" element={<UserProfilesIndex />} />
                                    <Route path="/trainers" element={<TrainerIndex />} />
                                    <Route path="/adminusers/permission/:id" element={<UpdatePermissionListing />} />
                                    <Route path="/order-cancel-reasons" element={<ManageCancelReasonIndex />} />
                                    <Route path="/order-return-reasons" element={<ManageOrderReturnIndex />} />
                                    <Route path="/:token" element={<BackendPasswordIndex />} />
                                    <Route path="/profile" element={<ProfileIndex />} />
                                    <Route path="/school-logos" element={<SchoolLogoIndex />} />
                                    <Route path="/offers" element={<OfferIndex />} />
                                    <Route path="/events" element={<EventIndex />} />
                                    <Route path="/rankings" element={<RankingIndex />} />
                                    <Route path="/url-rewriting" element={<UrlReWritingIndex />} />
                                    <Route path="/metatags" element={<MetaTagIndex />} />
                                    <Route path="/orders" element={<OrderIndex />} />
                                    <Route path="/order/:order_id" element={<OrderView />} />
                                    <Route path="/coupons" element={<CouponIndex />} />
                                </Route>
                            </Route>
                        ) : (
                            <Route path='*' element={<Navigate to="/admin" replace={true} />} />
                        )}

                    </Routes>

                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        limit='1'
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </>
            )}
        </>
    )
}


export default AdminRoutes