import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getDataManageUsers, updateUserStatus, deleteUser, getUserbyId } from "../../services/manage-users/action";
import DeleteModal from "../../common/deleteModal";
import Listing from "./listing";
import SearchBar from "./searchBar";
import UpdateUser from "./addUpdateUser";
import ExportUser from "./exportUser";
import { getAdminPaginationLimit } from "../../../common/common";
import getPrivileges from "../../common/adminPrivilege";
const ManageUserIndex = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [userData, setUserData] = useState('');
    const limit = getAdminPaginationLimit();
    const requestBody = {
        page: currentPage,
        limit: limit,
        date_from: "",
        date_to: "",
        keyword: "",
        status: "",
        user_verified: "",
    };
    const [deleteid, setDeleteId] = useState(null);
    const [exportFormShow, setExportFormShow] = useState(false);
    const [deleteshow, setDeleteShow] = useState(false);
    const [filterdata, setFilterData] = useState(requestBody);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [formshow, setFormShow] = useState(false);
    const [initialValues, setInitialValues] = useState({
        "user_id": "",
        "user_fname": "",
        "user_last_name": "",
        "user_phone_number": "",
        "image": null,
        "user_dob": null,
        "cred_users_email_id": "",
        "cred_users_active": "",
    });
    const getPrivilege = getPrivileges();
    const editUsers = getPrivilege.canEditUser();
    const dispatch = useDispatch();
    const { manageUsers, totalCount } = useSelector((state) => state.manageUser);
    const loading = useSelector((state) => state.manageUser.loading);
    const error = useSelector((state) => state.manageUser.error);
    const handleFormClose = () => {
        setFormShow(false);
        setUserData('')
    };

    useEffect(() => {
        dispatch(getDataManageUsers(requestBody));
    }, []);

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getUserbyId(id);
                const result = response.data;
                setUserData(result);
            }
        } catch (error) { }
        setFormShow(true);
    };
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataManageUsers(updatedFilterData));
    };
    const setOrderIdArray = () => {
        const checkedInputs = document.querySelectorAll(
            "input[name='order_id_user[]']:checked"
        );
        const newOrderIds = Array.from(checkedInputs, (input) => input.value);
        return newOrderIds;
    };
    const handleSelectedStatusUpdate = async (status) => {
        const data = {
            "user_id": setOrderIdArray(),
            "user_status": status,
        };
        try {
            await updateUserStatus(data);
            dispatch(getDataManageUsers(requestBody));
        } catch (error) { }
    };
    const handleStatus = async (e, id, status) => {
        const newStatus = status === 1 ? 0 : 1;
        manageUsers.map((user) => {
            if (user.user_id === id) {
                user.cred_users_active = newStatus;
            }
            return user;
        });
        const data = {
            "user_status": newStatus,
            "user_id": [id],
        };
        try {
            await updateUserStatus(data);
            dispatch(getDataManageUsers(requestBody));
        } catch (error) { }
    };
    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id);
    };
    const handleDeleteClose = () => {
        setDeleteShow(false);
    };
    const handleDelete = async () => {
        try {
            let data;
            if (typeof deleteid === "number") {
                data = [deleteid];
            } else {
                data = setOrderIdArray();
            }
            await deleteUser({ "user_id": data });
            dispatch(getDataManageUsers(requestBody));
            handleDeleteClose();
        } catch (error) { }
    };
    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataManageUsers(updatedFilterData));
        }
    };
    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataManageUsers(updatedFilterData));
    };
    const handleClear = () => {
        let updatedFilterData = {
            ...requestBody,
            page: 1,
        }
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        dispatch(getDataManageUsers(updatedFilterData));
    };
    const handleCheckboxChange = (event, labelId) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedCheckboxes((prevSelected) => [...prevSelected, labelId]);
        } else {
            setSelectedCheckboxes((prevSelected) =>
                prevSelected.filter((id) => id !== labelId)
            );
        }
    };

    const handleExportFormShow = () => {
        setExportFormShow(true);
    }

    const handleExportFormClose = () => {
        setExportFormShow(false);
    };
    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        // defineNames={defineNames}
                        handleSelectedStatusUpdate={handleSelectedStatusUpdate}
                        handleFilterUpdate={handleFilterUpdate}
                        filterdata={filterdata}
                        handleFilter={handleFilter}
                        handleClear={handleClear}
                        selectedCheckboxes={selectedCheckboxes}
                        deleteModalOpen={deleteModalOpen}
                        handleExportFormShow={handleExportFormShow}
                        editUsers={editUsers}
                    />
                    <Listing
                        manageUsers={manageUsers}
                        loading={loading}
                        error={error}
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        handlePaginate={handlePaginate}
                        handleStatus={handleStatus}
                        // defineNames={defineNames}
                        deleteModalOpen={deleteModalOpen}
                        handleCheckboxChange={handleCheckboxChange}
                        setSelectedCheckboxes={setSelectedCheckboxes}
                        handleFormShow={handleFormShow}
                        requestBody={requestBody}
                        editUsers={editUsers}
                    />
                </div>
            </div>
            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
            {formshow && (
                <UpdateUser
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    userData={userData}
                    initialValues={initialValues}
                    editUsers={editUsers}
                />
            )}

            {exportFormShow && (
                <ExportUser
                    exportFormShow={exportFormShow}
                    handleExportFormClose={handleExportFormClose}
                    requestBody={requestBody}
                />
            )}
        </>
    );
};

export default ManageUserIndex;
