
import { USER_PROFILE_LOADING, USER_PROFILE_ERROR, USER_PROFILE_SUCCESS } from "./type";

const initState = {
    loading: true,
    error: false,
    profiles: [],
};

const userProfileReducer = (state = initState, action) => {
    switch (action.type) {
        case USER_PROFILE_LOADING:
            return { ...state, loading: true };
        case USER_PROFILE_ERROR:
            return { ...state, loading: false, error: true };
        case USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                profiles: action.payload.rows,
                totalCount: action.payload.count,
            };
        default:
            return state;
    }
};

export { userProfileReducer };