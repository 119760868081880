import { BLOG_TAGS_LOADING, BLOG_TAGS_ERROR, BLOG_TAGS_SUCCESS } from "./types";
const initState = { loading: true, error: false, blogTags: [] };
const blogTagsReducer = (state = initState, action) => {
    switch (action.type) {
        case BLOG_TAGS_LOADING:
            return { ...state, loading: true };
        case BLOG_TAGS_ERROR:
            return { ...state, loading: false, error: true };
        case BLOG_TAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                blogTags: action.payload,
            };
        default:
            return state;
    }
};

export { blogTagsReducer };
