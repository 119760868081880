import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import SearchBar from './SearchBar'
import ArchiveListing from './archiveListing'
import { getArchiveProfiles } from '../../services/top-archive/action'
import ArchiveProfileListing from './archiveProfileListing'
import { getUserProfileStyles } from '../../../common/common'
import { ARCHIVE_PAGE_META_TYPE, loadMetaTags, SITE_TITLE } from '../../common/common'

const TopArchive = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [archiveProfileShow, setArchiveProfileShow] = useState(false);
    const limit = 20;
    const requestBody = {
        page: currentPage,
        limit: limit,
        is_archive_profile: false
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const [profileFilterdata, setProfileFilterData] = useState(requestBody);
    const dispatch = useDispatch();
    const { archives, totalCount } = useSelector((state) => state.archives);
    const loading = useSelector((state) => state.archives.loading);
    const error = useSelector((state) => state.archives.error);
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getArchiveProfiles(updatedFilterData));
    };

    const handleFilter = () => {
        dispatch(getArchiveProfiles(filterdata));
        setArchiveProfileShow(false);
    };

    const handleFilterUpdate = async (name, value) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
    };

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const metaTags = {
        meta_type: ARCHIVE_PAGE_META_TYPE,
        meta_title: `Top 200 Archive - ${site_title}`,
        meta_record_id: 0,
        meta_advance_tag: 1
    }

    useEffect(() => {
        loadMetaTags(metaTags);
        const delaySearch = setTimeout(() => {
            dispatch(getArchiveProfiles(requestBody));
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, []);

    const handleArchiveData = (archivePostedData) => {
        const updatePostedData = {
            ...archivePostedData,
            is_archive_profile: true
        }
        setProfileFilterData(updatePostedData);
        dispatch(getArchiveProfiles(updatePostedData));
        setArchiveProfileShow(true);
    }
    return (
        <>
            <div className="player-list-archive">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <h2>QBHL TOP 200</h2>
                                <h4 className="text-center"><a>ARCHIVE</a></h4>
                                {archiveProfileShow && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="text-center"><a>Class of {profileFilterdata.class} - {getUserProfileStyles[profileFilterdata.style]} - published {profileFilterdata.month}/{profileFilterdata.year}</a></h4>
                                        </div>
                                        <div className="col-md-2">
                                            <a onClick={() => handleFilter()} className="btn btn-primary back-archives"><i className="bx bx-arrow-back"></i> Back</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {!archiveProfileShow ? (
                            <>
                                <SearchBar
                                    handleFilterUpdate={handleFilterUpdate}
                                    handleFilter={handleFilter}
                                    filterdata={filterdata}
                                />
                                <ArchiveListing
                                    error={error}
                                    archives={archives}
                                    loading={loading}
                                    totalCount={totalCount}
                                    currentPage={currentPage}
                                    limit={limit}
                                    handlePaginate={handlePaginate}
                                    handleArchiveData={handleArchiveData}
                                />
                            </>
                        ) : (
                            <ArchiveProfileListing
                                error={error}
                                archives={archives}
                                loading={loading}
                                totalCount={totalCount}
                                currentPage={currentPage}
                                limit={limit}
                                handlePaginate={handlePaginate}
                            />
                        )}
                    </div>
                </div>
            </div>

        </>
    )
}

export default TopArchive
