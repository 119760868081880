import { useEffect, useState } from "react";
import SearchBar from "./searchBar";
import Listing from "./listing";
import AddUpdateLavel from "./addUpdateLavel";
import { getDataLabels } from "../../services/label/action";
import { useSelector, useDispatch } from "react-redux";
import { getPrivileges } from '../../common/adminPrivilege';
import { getAdminPaginationLimit } from "../../../common/common";
const LabelIndex = () => {

    const [labelId, setLabelId] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = getAdminPaginationLimit();
    const [formshow, setFormShow] = useState(false);
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: "",
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch()
    const { labels, totalCount } = useSelector((state) => state.label)
    const loading = useSelector((state) => state.label.loading)
    const error = useSelector((state) => state.label.error)
    const getPrivilege = getPrivileges();
    const editLabel = getPrivilege.canEditLabel();

    useEffect(() => {
        dispatch(getDataLabels(requestBody))
    }, []);

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                setLabelId(id);
            }
        } catch (error) { }
        setFormShow(true);
    };

    const handleFormClose = () => {
        setFormShow(false);
        setLabelId(false);
    };

    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataLabels(updatedFilterData))
    };

    const calculateSerialNumber = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };

    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
            page: 1,
        };
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataLabels(updatedFilterData))
        }
    };

    const handleFilter = () => {
        let updatedFilterData = {
            ...filterdata,
            page: 1,
        }
        setCurrentPage(1)
        dispatch(getDataLabels(updatedFilterData));
    };

    const handleClear = () => {
        let updatedFilterData = {
            ...requestBody,
            page: 1,
        }
        setCurrentPage(1)
        setFilterData(updatedFilterData);
        dispatch(getDataLabels(updatedFilterData));
    };

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFilterUpdate={handleFilterUpdate}
                        handleFilter={handleFilter}
                        handleClear={handleClear}
                    />
                    <Listing
                        loading={loading}
                        error={error}
                        labels={labels}
                        handlePaginate={handlePaginate}
                        totalCount={totalCount}
                        limit={limit}
                        handleFormShow={handleFormShow}
                        handleFilterUpdate={handleFilterUpdate}
                        calculateSerialNumber={calculateSerialNumber}
                        editLabel={editLabel}
                        currentPage={currentPage}
                    />
                </div>
            </div>
            {formshow && (
                <AddUpdateLavel
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    requestBody={requestBody}
                    labelId={labelId}
                />
            )}
        </>
    );
};
export default LabelIndex;
