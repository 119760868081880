import { META_TAGS_LOADING, META_TAGS_ERROR, META_TAGS_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";


/*const handleLoadingMetaTags = (payload) => ({
    type: META_TAGS_LOADING,
    payload,
});
const handleErrorMetaTags = () => ({
    type: META_TAGS_ERROR,
});
const handleSuccessMetaTags = (payload) => ({
    type: META_TAGS_SUCCESS,
    payload,
});

const writeMetaTags = (data) => async (dispatch) => {
    dispatch(handleLoadingMetaTags());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/search-profile", data)
                .then((res) => {
                    dispatch(handleSuccessMetaTags(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorMetaTags());
    }
};*/

const writeMetaTags = (data) => ({
    type: META_TAGS_SUCCESS,
    payload: data
});

const getMetaTags = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post(`/meta-tags`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
            });
    });
};

export { writeMetaTags, getMetaTags };