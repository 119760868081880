import { getLabel } from "../../common/getLabel";
import { useState } from "react";
import { baseUrl } from "../../common/config";
import { uploadMediaImage, deleteMediaImage } from "../../services/general-settings/action";
import { ImageDeleteModal } from "../../common/imageDeleteModal";

const MediaPage = (props) => {
    const { generalSettings, editSetting } = props;
    const imagepath = generalSettings.attachments;
    const transformedData = imagepath && Array.isArray(imagepath) ?
        imagepath.reduce((imageData, item) => {
            imageData[item.afile_type] = item;
            return imageData;
        }, {}) : {};
    const defineName = {
        siteColoredLogo: 25,
        siteFavicon: 26,
        footerLogo: 27,
        blogNoImage: 28,
        profileNoImage: 29,
        trainerNoImage: 30,
        profileCoverImage: 31
    };
    const [uploadData, setUploadData] = useState(transformedData);

    const [imageDeleteId, setImageDeletedId] = useState('');
    const [imageDeleteType, setImageDeleteType] = useState('');
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const handleImageChange = async (e, key) => {
        const file = e.target.files[0];
        try {
            const formData = new FormData();
            formData.append("afile_type", key);
            formData.append("config_image", file);
            const response = await uploadMediaImage(formData);
            if (
                response.success &&
                response.data &&
                response.data.afile_physical_path
            ) {
                setUploadData((prevState) => ({
                    ...prevState,
                    [key]: response.data,
                }));
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    const handleImageDeleteModalShow = (imageType, id) => {
        setImageDeleteType(imageType);
        setImageDeletedId(id);
        setImageDeleteModalShow(true);
    };
    const handleDeleteImage = async () => {
        try {
            const data = { afile_id: imageDeleteId, afile_type: imageDeleteType };
            await deleteMediaImage(data);
            setUploadData(prevUploadData => {
                const updatedUploadData = { ...prevUploadData };
                delete updatedUploadData[imageDeleteType];
                return updatedUploadData;
            });
            handleImageDeleteModalClose();
            const Name = "image_list_" + imageDeleteId;
            document.querySelector("." + Name).remove();
        } catch (error) { }
    };
    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false);

    return (
        <>
            <div className="row formcustom">
                <div className="col-md-6 ">
                    <label htmlFor="Image" className="form-label">
                        {getLabel("LBL_SIte_Colored_Logo")}
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="file"
                        id={defineName.siteColoredLogo}
                        name={defineName.siteColoredLogo}
                        onChange={(e) => handleImageChange(e, defineName.siteColoredLogo)}
                        disabled={!editSetting}
                    />
                    {uploadData && uploadData[defineName.siteColoredLogo] && (
                        <div>
                            <div className={`image_list_${uploadData[defineName.siteColoredLogo].afile_id}`}>
                                <img
                                    src={`${baseUrl}/${uploadData[defineName.siteColoredLogo].afile_physical_path}`}
                                    alt={`Uploaded Site Colored Logo`}
                                    className="img-fluid"
                                />
                                {editSetting && (
                                    <input
                                        type="button"
                                        value="x"
                                        className="btn-rmv1 rmv"
                                        onClick={() =>
                                            handleImageDeleteModalShow(defineName.siteColoredLogo, uploadData[defineName.siteColoredLogo].afile_id)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>


                {/* iid img */}

                <div className="col-md-6 ">
                    <label htmlFor="Image" className="form-label">
                        {getLabel("LBL_Site_Favicon")}
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="file"
                        id={defineName.siteFavicon}
                        name={defineName.siteFavicon}
                        onChange={(e) => handleImageChange(e, defineName.siteFavicon)}
                        disabled={!editSetting}
                    />
                    {uploadData && uploadData[defineName.siteFavicon] && (
                        <div>
                            <div className={`image_list_${uploadData[defineName.siteFavicon].afile_id}`}>
                                <img
                                    src={`${baseUrl}/${uploadData[defineName.siteFavicon].afile_physical_path}`}
                                    alt={`Uploaded Site Colored Logo`}
                                    className="img-fluid"
                                />
                                {editSetting && (
                                    <input
                                        type="button"
                                        value="x"
                                        className="btn-rmv1 rmv"
                                        onClick={() =>
                                            handleImageDeleteModalShow(defineName.siteFavicon, uploadData[defineName.siteFavicon].afile_id)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>


                {/* iiird */}

                <div className="col-md-6 ">
                    <label htmlFor="Image" className="form-label">
                        {getLabel("LBL_Footer_Logo")}
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="file"
                        id={defineName.footerLogo}
                        name={defineName.footerLogo}
                        onChange={(e) => handleImageChange(e, defineName.footerLogo)}
                        disabled={!editSetting}
                    />
                    {uploadData && uploadData[defineName.footerLogo] && (
                        <div>
                            <div className={`image_list_${uploadData[defineName.footerLogo].afile_id}`}>
                                <img
                                    src={`${baseUrl}/${uploadData[defineName.footerLogo].afile_physical_path}`}
                                    alt={`Uploaded Site Colored Logo`}
                                    className="img-fluid"
                                />
                                {editSetting && (
                                    <input
                                        type="button"
                                        value="x"
                                        className="btn-rmv1 rmv"
                                        onClick={() =>
                                            handleImageDeleteModalShow(defineName.footerLogo, uploadData[defineName.footerLogo].afile_id)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {/* 9th */}
                <div className="col-md-6 ">
                    <label htmlFor="Image" className="form-label">
                        {getLabel("LBL_Blog_No_Image")}
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="file"
                        id={defineName.blogNoImage}
                        name={defineName.blogNoImage}
                        onChange={(e) => handleImageChange(e, defineName.blogNoImage)}
                        disabled={!editSetting}
                    />
                    {uploadData && uploadData[defineName.blogNoImage] && (
                        <div>
                            <div className={`image_list_${uploadData[defineName.blogNoImage].afile_id}`}>
                                <img
                                    src={`${baseUrl}/${uploadData[defineName.blogNoImage].afile_physical_path}`}
                                    alt={`Uploaded Site Colored Logo`}
                                    className="img-fluid"
                                />
                                {editSetting && (
                                    <input
                                        type="button"
                                        value="x"
                                        className="btn-rmv1 rmv"
                                        onClick={() =>
                                            handleImageDeleteModalShow(defineName.blogNoImage, uploadData[defineName.blogNoImage].afile_id)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-md-6 ">
                    <label htmlFor="Image" className="form-label">
                        {getLabel("LBL_Profile_No_Image")}
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="file"
                        id={defineName.profileNoImage}
                        name={defineName.profileNoImage}
                        onChange={(e) => handleImageChange(e, defineName.profileNoImage)}
                        disabled={!editSetting}
                    />
                    {uploadData && uploadData[defineName.profileNoImage] && (
                        <div>
                            <div className={`image_list_${uploadData[defineName.profileNoImage].afile_id}`}>
                                <img
                                    src={`${baseUrl}/${uploadData[defineName.profileNoImage].afile_physical_path}`}
                                    alt={`Uploaded Site Colored Logo`}
                                    className="img-fluid"
                                />
                                {editSetting && (
                                    <input
                                        type="button"
                                        value="x"
                                        className="btn-rmv1 rmv"
                                        onClick={() =>
                                            handleImageDeleteModalShow(defineName.profileNoImage, uploadData[defineName.profileNoImage].afile_id)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-md-6 ">
                    <label htmlFor="Image" className="form-label">
                        {getLabel("LBL_Trainer_No_Image")}
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="file"
                        id={defineName.trainerNoImage}
                        name={defineName.trainerNoImage}
                        onChange={(e) => handleImageChange(e, defineName.trainerNoImage)}
                        disabled={!editSetting}
                    />
                    {uploadData && uploadData[defineName.trainerNoImage] && (
                        <div>
                            <div className={`image_list_${uploadData[defineName.trainerNoImage].afile_id}`}>
                                <img
                                    src={`${baseUrl}/${uploadData[defineName.trainerNoImage].afile_physical_path}`}
                                    alt={`Uploaded Site Colored Logo`}
                                    className="img-fluid"
                                />
                                {editSetting && (
                                    <input
                                        type="button"
                                        value="x"
                                        className="btn-rmv1 rmv"
                                        onClick={() =>
                                            handleImageDeleteModalShow(defineName.trainerNoImage, uploadData[defineName.trainerNoImage].afile_id)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-md-6 ">
                    <label htmlFor="Image" className="form-label">
                        {getLabel("LBL_Profile_Cover_No_Image")}
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="file"
                        id={defineName.profileCoverImage}
                        name={defineName.profileCoverImage}
                        onChange={(e) => handleImageChange(e, defineName.profileCoverImage)}
                        disabled={!editSetting}
                    />
                    {uploadData && uploadData[defineName.profileCoverImage] && (
                        <div>
                            <div className={`image_list_${uploadData[defineName.profileCoverImage].afile_id}`}>
                                <img
                                    src={`${baseUrl}/${uploadData[defineName.profileCoverImage].afile_physical_path}`}
                                    alt={`Uploaded Site Colored Logo`}
                                    className="img-fluid"
                                />
                                {editSetting && (
                                    <input
                                        type="button"
                                        value="x"
                                        className="btn-rmv1 rmv"
                                        onClick={() =>
                                            handleImageDeleteModalShow(defineName.profileCoverImage, uploadData[defineName.profileCoverImage].afile_id)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <ImageDeleteModal
                imageDeleteModalshow={imageDeleteModalshow}
                handleImageDeleteModalClose={handleImageDeleteModalClose}
                handleDeleteImage={handleDeleteImage}
            />
        </>
    );
};

export default MediaPage;
