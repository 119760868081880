import { SEO_URL_LOADING, SEO_URL_ERROR, SEO_URL_SUCCESS } from "./type";
import { headers } from "../../common/common";
import { myAxios } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingSeoUrl = (payload) => ({
  type: SEO_URL_LOADING,
  payload,
});
const handleErrorSeoUrl = () => ({
  type: SEO_URL_ERROR,
});
const handleSuccessSeoUrl = (payload) => ({
  type: SEO_URL_SUCCESS,
  payload,
});
const getDataSeoUrls = (data) => async (dispatch) => {
  dispatch(handleLoadingSeoUrl());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/admin/url-rewrite/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessSeoUrl(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    dispatch(handleErrorSeoUrl());
  }
};
const getSeoUrlbyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/admin/url/rewrite/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateSeoUrl = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/admin/url-rewrite/setup", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
export {
  getDataSeoUrls,
  getSeoUrlbyId,
  updateSeoUrl,
};
