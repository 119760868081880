import AddUpdateBlogCategory from "./addUpdateBlogCategory";
import Listing from "./listing";
import SearchBar from "./serachBar";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../common/deleteModal";
import { getDataBlogCategories, getBlogCategorybyId, updateBlogCategoryStatus, deleteBlogCategory, } from "../../services/blog-category/action";
import { useEffect, useState } from "react";
import { getPrivileges } from '../../common/adminPrivilege';
import { getAdminPaginationLimit } from "../../../common/common";

const BlogCategoryIndex = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryData, setCategoryData] = useState('');
  const limit = getAdminPaginationLimit();
  const requestBody = {
    date_from: "",
    date_to: "",
    page: currentPage,
    limit: limit,
    keyword: "",
  };
  const [filterdata, setFilterData] = useState(requestBody);
  const [formshow, setFormShow] = useState(false);
  const [deleteid, setDeleteId] = useState(null);
  const [deleteshow, setDeleteShow] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const dispatch = useDispatch();
  const { blogCategories, totalCount } = useSelector((state) => state.blogCategory);
  const loading = useSelector((state) => state.blogCategory.loading);
  const error = useSelector((state) => state.blogCategory.error);
  const getPrivilege = getPrivileges();
  const editBlog = getPrivilege.canEditBlog();

  useEffect(() => {
    dispatch(getDataBlogCategories(requestBody));
  }, []);

  const handleFormClose = () => {
    setFormShow(false);
    setCategoryData('');
  };

  const handleFormShow = async (id) => {
    try {
      if (typeof id === "number") {
        const response = await getBlogCategorybyId(id);
        const result = response.data;
        setCategoryData(result);
      }
    } catch (error) { }
    setFormShow(true);
  };

  const handleDeleteClose = () => {
    setDeleteShow(false);
  };

  const deleteModalOpen = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  const handleStatus = async (e, id, status) => {
    if (editBlog) {
      const newStatus = status === 1 ? 0 : 1;
      blogCategories.map((blogcategory) => {
        if (blogcategory.bcat_id === id) {
          blogcategory.bcat_status = newStatus;
        }
        return blogcategory;
      });
      const data = {
        "status": newStatus,
        "id": [id],
      };
      try {
        await updateBlogCategoryStatus(data);
        dispatch(getDataBlogCategories(requestBody));
      } catch (error) { }
    }
  };

  const setOrderIdArray = () => {
    const checkedInputs = document.querySelectorAll(
      "input[name='order_id_blogcategory[]']:checked"
    );
    const newOrderIds = Array.from(checkedInputs, (input) => input.value);
    return newOrderIds;
  };

  const handleDelete = async () => {
    try {
      let data;
      if (typeof deleteid === "number") {
        data = [deleteid];
      } else {
        data = setOrderIdArray();
      }
      await deleteBlogCategory({ "id": data });
      dispatch(getDataBlogCategories(requestBody));
      handleDeleteClose();
    } catch (error) { }
  };

  const handleSelectedStatusUpdate = async (status) => {
    const data = {
      "id": setOrderIdArray(),
      "status": status,
    };
    try {
      await updateBlogCategoryStatus(data);
      dispatch(getDataBlogCategories(requestBody));
    } catch (error) { }
  };

  const handleFilterUpdate = async (name, value, boolen) => {
    let updatedFilterData;
    updatedFilterData = {
      ...filterdata,
      [name]: value,
    };
    setFilterData(updatedFilterData);
    if (boolen === true) {
      dispatch(getDataBlogCategories(updatedFilterData));
    }
  };

  const handleFilter = () => {
    let updatedFilterData = {
      ...filterdata,
      page: 1,
  }
  setCurrentPage(1)
    dispatch(getDataBlogCategories(updatedFilterData));
  };

  const handleClear = () => {
    setFilterData(requestBody);
    dispatch(getDataBlogCategories(requestBody));
  };

  const handlePaginate = (selectedPage) => {
    const updatedFilterData = {
      ...filterdata,
      page: selectedPage.selected + 1,
    };
    setCurrentPage(selectedPage.selected + 1);
    setFilterData(updatedFilterData);
    dispatch(getDataBlogCategories(updatedFilterData));
  };

  const handleCheckboxChange = (event, labelId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, labelId]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((id) => id !== labelId)
      );
    }
  };

  return (
    <>
      <div className="bg-whites mt-4 pt-5">
        <div className="container-fluid inner-section">
          <SearchBar
            handleFormShow={handleFormShow}
            handleSelectedStatusUpdate={handleSelectedStatusUpdate}
            handleFilterUpdate={handleFilterUpdate}
            handleFilter={handleFilter}
            handleClear={handleClear}
            filterdata={filterdata}
            deleteModalOpen={deleteModalOpen}
            selectedCheckboxes={selectedCheckboxes}
            editBlog={editBlog}
          />
          <Listing
            blogCategories={blogCategories}
            totalCount={totalCount}
            loading={loading}
            error={error}
            handlePaginate={handlePaginate}
            currentPage={currentPage}
            limit={limit}
            handleFormShow={handleFormShow}
            handleStatus={handleStatus}
            deleteModalOpen={deleteModalOpen}
            handleCheckboxChange={handleCheckboxChange}
            setSelectedCheckboxes={setSelectedCheckboxes}
            editBlog={editBlog}
          />
        </div>
      </div>
      {formshow && (
        <AddUpdateBlogCategory
          formshow={formshow}
          handleFormClose={handleFormClose}
          requestBody={requestBody}
          categoryData={categoryData}
        />
      )}

      <DeleteModal
        show={deleteshow}
        deleteModalOpen={deleteModalOpen}
        handleDeleteClose={handleDeleteClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default BlogCategoryIndex;
