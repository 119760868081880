import { MEMBERSHIP_LOADING, MEMBERSHIP_ERROR, MEMBERSHIP_SUCCESS } from "./types";
import { myAxios } from "../../common/myAxios";
import { displayProcessing, errorMessage, successMessage } from "../../common/message";
import { headers } from "../../common/common";

const handleLoadingMembership = (payload) => ({
    type: MEMBERSHIP_LOADING,
    payload,
});
const handleErrorMembership = () => ({
    type: MEMBERSHIP_ERROR,
});
const handleSuccessMembership = (payload) => ({
    type: MEMBERSHIP_SUCCESS,
    payload,
});

const getMembershipContent = (data) => async (dispatch) => {
    dispatch(handleLoadingMembership());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/plans", data)
                .then((res) => {
                    dispatch(handleSuccessMembership(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorMembership());
    }
};

const membershipCheckout = async (data) => {
    displayProcessing();
    return new Promise((resolve, reject) => {
        myAxios
            .post("/checkout", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const checkCouponIsValid = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/check-coupon", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getPlanDetails = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/single-plan", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

export { getMembershipContent, membershipCheckout, checkCouponIsValid, getPlanDetails };