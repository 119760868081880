import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ArchiveSkeletonLoading = ({length}) => {
    const baseColor = "#ebebeb";
    const highlightColor = "#cfcfcfab";
    return (
        Array.from({ length: length }, (_, i) => (
            <div className="col-lg-3 col-sm-6 my-3" key={i}>
                <div className="buttetin-image">
                    <Skeleton baseColor={baseColor} highlightColor={highlightColor} height={228} style={{ lineHeight: "unset" }} />
                </div>
            </div>
        ))
    )
}

export default ArchiveSkeletonLoading
