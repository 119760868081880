import { getLabel } from "../../common/getLabel"
import { baseUrl } from "../../common/config";
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";
const Listing = (props) => {
    const { logos, totalCount, loading, error, handleFormShow, handlePaginate, calculateSerialNumber, limit, editSchoolLogs, deleteModalOpen, currentPage } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <form id="faq-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_School_Name")}</th>
                            <th>{getLabel("LBL_Logo")}</th>
                            <th className="table-action">{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={4} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="4" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {logos && logos.length > 0 ? (
                                    logos.map((elem, index) => {
                                        const imagePath = (elem.school_image) ? elem.school_image[0] : '';
                                        return (
                                            <tr className="actions" key={index} >
                                                <td>{calculateSerialNumber(index)}</td>
                                                <td>
                                                    <div className="verified-wrap">
                                                        <span className="user-profile_title">{elem.sch_name} </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="userimg">
                                                        {imagePath &&
                                                            <img
                                                                src={`${baseUrl}/${imagePath.afile_physical_path}`}
                                                                className="img-fluid" />
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="action">
                                                        <ul>
                                                            <li><a onClick={(() => handleFormShow(elem.sch_id))}><i className='bx bx-pencil'></i></a></li>
                                                            {editSchoolLogs && (
                                                                <li><a><i className="bx bx-trash" onClick={() => deleteModalOpen(elem.sch_id)} ></i></a></li>
                                                            )}
                                                        </ul>
                                                    </span>

                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="4" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {logos && logos.length > 0 && (
                <Pagination
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                    currentPage={currentPage} />
            )}
        </>
    )
}

export default Listing