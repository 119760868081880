import { getLabel } from "../../common/getLabel";
import { useState } from "react";
import { handleTimeZoneFormat } from "../../../common/common";
import CustomDatePicker from "../../../common/CustomDatePicker";
const SearchBar = (props) => {
    const {
        handleFormShow,
        handleSelectedStatusUpdate,
        handleFilterUpdate,
        filterdata,
        handleFilter,
        handleClear,
        selectedCheckboxes,
        deleteModalOpen,
        editBlog
    } = props;

    const [searchItem, setSearchItem] = useState("");
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate('keyword', event.target.value, false);
    };

    const [startDate, setStartDate] = useState(filterdata.date_from ? new Date(filterdata.date_from) : null);
    const [endDate, setEndDate] = useState(filterdata.date_to ? new Date(filterdata.date_to) : null);

    const handleStartDateChange = date => {
        setStartDate(date);
        handleFilterUpdate('date_from', handleTimeZoneFormat(date), false);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
        handleFilterUpdate('date_to', handleTimeZoneFormat(date), false);
    };

    const handleFormClear = () => {
        setStartDate(null);
        setEndDate(null);
        handleClear();
    };

    const handleCancel = () => {
        setSearchItem("");
        setStartDate(null);
        setEndDate(null);
        handleFilterUpdate('date_from', '', false);
        handleFilterUpdate('date_to', '', false);
        handleFilterUpdate('status', '', false);
        handleClear();
    };


    return (
        <>
            <div className="row pb-5">
                <div className="col leftspace">
                    <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
                        <input className="search-input" placeholder={getLabel("LBL_Search_by_Blogs")} name="keyword" value={searchItem} onChange={(e) => { handleInputChange(e); }} />

                        <a className="btn btn-primary" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample" href="#collapseExample">
                            <i className="bx bx-chevrons-down"></i>
                        </a>

                        <div className="card-button">
                            <ul>
                                <li>
                                    <button
                                        type="submit"
                                        name="btn_submit"
                                        className="btn btn-brand btn-wide btn-search"
                                        onClick={() => handleFilter}
                                    >
                                        {getLabel("LBL_Search")}{" "}
                                    </button>
                                    <button
                                        type="button"
                                        name="btn_clear"
                                        className="btn btn-link btn_clear"
                                        onClick={(e) => {
                                            handleCancel();
                                        }}
                                    >
                                        {getLabel("LBL_Clear")}
                                    </button>
                                </li>
                                {editBlog && (
                                    <>
                                        <li>
                                            <a onClick={() => handleSelectedStatusUpdate(1)} className="btntoltip" title="" data-bs-toggle="popover" data-bs-placement="top" data-bs-original-title="Mark as active">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "#ccc", transform: "", msFilter: "" }}>
                                                    <path d="M8 9c-1.628 0-3 1.372-3 3s1.372 3 3 3 3-1.372 3-3-1.372-3-3-3z"></path>
                                                    <path d="M16 6H8c-3.3 0-5.989 2.689-6 6v.016A6.01 6.01 0 0 0 8 18h8a6.01 6.01 0 0 0 6-5.994V12c-.009-3.309-2.699-6-6-6zm0 10H8a4.006 4.006 0 0 1-4-3.99C4.004 9.799 5.798 8 8 8h8c2.202 0 3.996 1.799 4 4.006A4.007 4.007 0 0 1 16 16zm4-3.984.443-.004.557.004h-1z"></path>
                                                </svg>
                                                {getLabel("LBL_Activate")}
                                            </a>
                                        </li>

                                        <li>
                                            <a onClick={() => handleSelectedStatusUpdate(0)} className="btntoltip" title="" data-bs-toggle="popover" data-bs-placement="top" data-bs-original-title="Mark as active">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "#ccc", transform: "", msFilter: "" }}>
                                                    <path d="M8 9c-1.628 0-3 1.372-3 3s1.372 3 3 3 3-1.372 3-3-1.372-3-3-3z"></path>
                                                    <path d="M16 6H8c-3.3 0-5.989 2.689-6 6v.016A6.01 6.01 0 0 0 8 18h8a6.01 6.01 0 0 0 6-5.994V12c-.009-3.309-2.699-6-6-6zm0 10H8a4.006 4.006 0 0 1-4-3.99C4.004 9.799 5.798 8 8 8h8c2.202 0 3.996 1.799 4 4.006A4.007 4.007 0 0 1 16 16zm4-3.984.443-.004.557.004h-1z"></path>
                                                </svg>
                                                {getLabel("LBL_Deactivate")}
                                            </a>
                                        </li>

                                        <li>
                                            <a onClick={deleteModalOpen} className={`btntoltip ${selectedCheckboxes.length === 0 ? "btn-disable" : ""}`} title="Mark as active" data-bs-toggle="popover" data-bs-placement="top" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "#ccc", transform: "", msFilter: "" }} >
                                                    <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                                                    <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                                                </svg>
                                                {getLabel("LBL_Delete")}
                                            </a>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>

                        <div className="collapse" id="collapseExample">
                            <div className="card card-body">
                                <div className="container-fluid inner-section">
                                    <div className="row">

                                        <div className="col-lg-4 col-sm-3 col-12">
                                            <div className="form-group">
                                                <label className="label">{getLabel("LBL_Blogs_status")}</label>
                                                <br></br>
                                                <select onChange={(e) => handleFilterUpdate(e.target.name, parseInt(e.target.value, false))} value={filterdata.blog_status} name="status" className="form-select user" aria-label="Default select example">
                                                    <option >{getLabel("LBL_Choose")}</option>
                                                    <option value="1">{getLabel("LBL_Active")} </option>
                                                    <option value="0">{getLabel("LBL_InActive")}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-sm-3 col-12">
                                            <div className="form-group">
                                                <label className="label">{getLabel("LBL_Date_from")}</label>
                                                <br></br>
                                                <CustomDatePicker
                                                    selectedDate={startDate}
                                                    onDateChange={handleStartDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    id="birthday"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-sm-3 col-12">
                                            <div className="form-group">
                                                <label className="label">{getLabel("LBL_Date_to")}</label>
                                                <br></br>
                                                <CustomDatePicker
                                                    selectedDate={endDate}
                                                    onDateChange={handleEndDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    id="birthday"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4 header--action">
                    {editBlog && (
                        <button type="button" className="btn btn-primary" onClick={handleFormShow}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "#fff", transform: "", msFilter: "" }}>
                                <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z"></path>
                                <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                            </svg>
                            {getLabel("LBL_Add_Blog")}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default SearchBar;
