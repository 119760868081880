import React from 'react'
import { Link } from 'react-router-dom';

const BlogTags = (props) => {
    const { tagLoading, tagError, blogTags } = props;
    return (
        <>
            {blogTags && blogTags.length > 0 && (
                <>
                    <div className="news-letter-title my-4">Tags</div>
                    <div className="tag-list">
                        {blogTags && blogTags.length > 0 && blogTags.map((tag, index) => (
                            <Link key={index} to={`/tag/${tag["btagUrl.urlrewrite_custom"]}`}> {tag.btag_title}</Link>
                        ))}
                    </div>
                </>
            )}
        </>
    )
}

export default BlogTags
