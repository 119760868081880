import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {getDataOrderCancelReasons,getOrderCancelbyId,deleteOrderbyId,updateShippingBoxStatus} from "../../services/manage-order-cancel/action";
import Listing from "./listing";
import SearchBar from "./searchBar";
import AddUpdateOrderCancel from "./addUpdateCancelReason";
import DeleteModal from "../../common/deleteModal";

const ManageCancelReasonIndex = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const defineNames = {
    id: "ocreason_id",
    title: "ocreason_name",
  };
  const requestBody = {
    page: currentPage,
    limit: limit,
    [defineNames.title]: "",
  };
  const [filterdata, setFilterData] = useState(requestBody);
  const [deleteid, setDeleteId] = useState(null);
  const [deleteshow, setDeleteShow] = useState(false);
  const { orderCancelReasons, totalCount } = useSelector(
    (state) => state.orderCancelReason
  );
  const loading = useSelector((state) => state.orderCancelReason.loading);
  const error = useSelector((state) => state.orderCancelReason.error);
  const [formshow, setFormShow] = useState(false);
  const [initialValues, setInitialValues] = useState({
    [defineNames.title]: "",
  });
  const handleFormClose = () => {
    setFormShow(false);
    setInitialValues(() => ({
      ..."",
    }));
  };
  const handleFormShow = async (id) => {
    try {
      if (typeof id === "number") {
        const response = await getOrderCancelbyId(id);
        const result = response.data;
        setInitialValues({
          ...result,
        });
      }
    } catch (error) {}
    setFormShow(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataOrderCancelReasons(requestBody));
  }, []);

  const handlePaginate = (selectedPage) => {
    const updatedFilterData = {
      ...filterdata,
      page: selectedPage.selected + 1,
    };
    setCurrentPage(selectedPage.selected + 1);
    setFilterData(updatedFilterData);
    dispatch(getDataOrderCancelReasons(updatedFilterData));
  };

  const handleFilterUpdate = async (name, value, boolen) => {
    let updatedFilterData;
    updatedFilterData = {
      ...filterdata,
      [name]: value,
    };
    setFilterData(updatedFilterData);
    if (boolen === true) {
      dispatch(getDataOrderCancelReasons(updatedFilterData));
    }
  };
  const handleDeleteClose = () => {
    setDeleteShow(false);
  };
  const deleteModalOpen = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };
  const handleDelete = async () => {
    try {
      let data;
      if (typeof deleteid === "number") {
        data = [deleteid];
      }
      await deleteOrderbyId({ [defineNames.id]: data });
      dispatch(getDataOrderCancelReasons(requestBody));
      handleDeleteClose();
    } catch (error) {}
  };
  return (
    <>
      <div className="bg-whites mt-4 pt-5">
        <div className="container-fluid inner-section">
          <SearchBar
            handleFormShow={handleFormShow}
            handleFilterUpdate={handleFilterUpdate}
            defineNames={defineNames}
            filterdata={filterdata}
          />
          <Listing
            orderCancelReasons={orderCancelReasons}
            totalCount={totalCount}
            loading={loading}
            error={error}
            handlePaginate={handlePaginate}
            currentPage={currentPage}
            limit={limit}
            handleFormShow={handleFormShow}
            deleteModalOpen={deleteModalOpen}
          />
        </div>
      </div>
      {formshow && (
        <AddUpdateOrderCancel
          defineNames={defineNames}
          initialValues={initialValues}
          handleFormClose={handleFormClose}
          formshow={formshow}
          requestBody={requestBody}
        />
      )}

      <DeleteModal
        show={deleteshow}
        deleteModalOpen={deleteModalOpen}
        handleDeleteClose={handleDeleteClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default ManageCancelReasonIndex;
