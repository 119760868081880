import { ORDER_LOADING, ORDER_ERROR, ORDER_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { displayProcessing, errorMessage, successMessage } from "../../common/message";
import { toast } from "react-toastify";
import { getLabel } from "../../common/getLabel";

const handleLoadingOrder = (payload) => ({
    type: ORDER_LOADING,
    payload,
});

const handleErrorOrder = () => ({
    type: ORDER_ERROR,
});

const handleSuccessOrder = (payload) => ({
    type: ORDER_SUCCESS,
    payload,
});

const getDataOrders = (data) => async (dispatch) => {
    dispatch(handleLoadingOrder());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/order/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessOrder(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in tag listing", error);
        dispatch(handleErrorOrder());
    }
};

const getOrderbyId = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios
            .get(`/order/${id}`, { headers: headers() })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const exportOrders = async (data) => {
    const export_type = data.export_type;
    displayProcessing();
    const arg = {
        headers: headers()
    }
    if (export_type == 1) {
        arg.responseType = 'blob';
    }
    return new Promise((resolve, reject) => {
        myAxios
            .post("/order-exports", data, arg)
            .then((res) => {
                if(export_type == 1){
                    resolve(res.data);
                    toast.dismiss()
                }else{
                    successMessage(resolve, res);
                }
            })
            .catch((error) => {
                toast.error(getLabel('LBL_No_Record_Found'));
                errorMessage(error, reject);
            });
    });
};

export {
    getDataOrders,
    getOrderbyId,
    exportOrders
};
