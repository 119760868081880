import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { useDispatch, useSelector } from 'react-redux';
import { getDataEvents, setUpEvents } from '../../services/manage-events/action';
import { useState } from 'react';
import CustomDatePicker from '../../../common/CustomDatePicker';
import { handleTimeZoneFormat } from '../../../common/common';

const UpdateEvent = (props) => {
    const { formshow, handleFormClose, eventId, requestBody, editEvents } = props
    const eventData = useSelector(state => {
        const events = state.events.events;
        if (events && events.length > 0) {
            return events.find(events => events.uevent_id === eventId);
        }
        return null;
    });
    const dispatch = useDispatch();

    const formatEventDate = (date) => {
        if (date) {
            const start_date = new Date(date);
            return start_date.toISOString().split('T')[0];
        }
        return false;
    }

    const initialValues = {
        event_id: (eventData) ? eventData.uevent_id : '',
        title: (eventData) ? eventData.uevent_title : '',
        color: (eventData) ? eventData.uevent_color : '',
        location: (eventData) ? eventData.uevent_location : '',
        description: (eventData) ? eventData.uevent_description : '',
        start_stop_time: (eventData) ? eventData.uevent_start_stop : '',
        start_date: (eventData) ? formatEventDate(eventData.uevent_start_date) : '',
        end_date: (eventData) ? formatEventDate(eventData.uevent_end_date) : '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: schoolLogoSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const postedData = {
                event_id: values.event_id,
                title: values.title,
                color: values.color,
                location: values.location,
                description: values.description,
                start_stop_time: values.start_stop_time,
                start_date: startDate,
                end_date: endDate
            }
            if (editEvents) {
                try {
                    const response = await setUpEvents(postedData);
                    if (response.success === true) {
                        handleFormClose();
                        dispatch(getDataEvents(requestBody))
                    }
                } catch (error) { }
            }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const [startDate, setStartDate] = useState(initialValues.start_date ? new Date(initialValues.start_date) : null);
    const [endDate, setEndDate] = useState(initialValues.end_date ? new Date(initialValues.end_date) : null);

    const handleStartDateChange = date => {
        setStartDate(handleTimeZoneFormat(date));
    };

    const handleEndDateChange = date => {
        setEndDate(handleTimeZoneFormat(date));
    };
    return (
        <Modal
            show={formshow}
            onHide={handleFormClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_UPDATE_AN_ENTRY_IN_EVENTS")}</h5>
            </Modal.Header>

            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_EVENT_NAME")}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="title"
                                            name="title"
                                            onChange={handleChange}
                                            value={formik.values.title}
                                            placeholder={getLabel("LBL_EVENT_NAME")}
                                            disabled={!editEvents}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_EVENT_START")}</label>
                                        <CustomDatePicker
                                            selectedDate={startDate}
                                            onDateChange={handleStartDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            id="start_date"
                                            disabled={!editEvents}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_EVENT_END")}</label>
                                        <CustomDatePicker
                                            selectedDate={endDate}
                                            onDateChange={handleEndDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            id="end_date"
                                            disabled={!editEvents}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_EVENT_LOCATION")}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="location"
                                            name="location"
                                            onChange={handleChange}
                                            value={formik.values.location}
                                            placeholder={getLabel("LBL_EVENT_LOCATION")}
                                            disabled={!editEvents}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_EVENT_DESC")}</label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            rows="3"
                                            required=""
                                            value={formik.values.description}
                                            onChange={handleChange}
                                            disabled={!editEvents}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_EVENT_COLOR")}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="color"
                                            name="color"
                                            onChange={handleChange}
                                            value={formik.values.color}
                                            placeholder={getLabel("LBL_EVENT_COLOR")}
                                            disabled={!editEvents}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="input">{getLabel("LBL_EVENT_STARTTIME")}</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="start_stop_time"
                                            name="start_stop_time"
                                            onChange={handleChange}
                                            value={formik.values.start_stop_time}
                                            placeholder={getLabel("LBL_EVENT_STARTTIME")}
                                            disabled={!editEvents}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFormClose}>
                        {getLabel("LBL_Close")}
                    </Button>
                    {editEvents && (
                        <Button variant="primary" type="submit">{getLabel("LBL_Submit")}</Button>
                    )}
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default UpdateEvent
