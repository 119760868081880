import { baseUrl } from "./config";
import { getmyAxios } from "../../common/myAxios";

let conf = {
  baseURL: baseUrl+'/frontend',
  customURL: false,
  frontendURL: true,
  urlType: 'front'
}

export const myAxios = getmyAxios(conf);