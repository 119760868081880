import { BLOG_ERROR, BLOG_SUCCESS, BLOG_LOADING } from "./types";
const initState = { loading: true, error: false, blogs: [] };
const blogReducer = (state = initState, action) => {
    switch (action.type) {
        case BLOG_LOADING:
            return { ...state, loading: true };
        case BLOG_ERROR:
            return { ...state, loading: false, error: true };
        case BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                blogs: action.payload.blog,
                totalCount: action.payload.totalRecords,
                defaultBlogImg: action.payload.defaultBlogImg,
                category: action.payload.category,
                tag: action.payload.tag,
            };
        default:
            return state;
    }
};

export { blogReducer };
