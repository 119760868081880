import { LOAD_ORDER_STATUS } from "./type";

const initialState = {
    orderStatus: null,
};

const loadOrderStatus = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ORDER_STATUS:
            return {
                ...state,
                orderStatus: action.payload,
            };
        default:
            return state;
    }
};

export default loadOrderStatus;
