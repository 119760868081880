import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TrainerProfile from '../trainer';
import ParentStudentProfile from '../parent-student';
import { getProfileData } from '../../../services/account/action';
import { LoadingIndicator } from '../../../../common/loadingIndicator';
import { stateListing } from '../../../../common/common';

const ProfileView = () => {
    const { profileId } = useParams();
    const [userType, setUserType] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [formShow, setFormShow] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        setUserType(userDetails ? userDetails.user_type : null);
        getProfileDetails();
    }, []);

    const getProfileDetails = async () => {
        if (profileId) {
            try {
                setLoading(true);
                const response = await getProfileData({ "profile_id": profileId });
                setProfileData(response);
                setFormShow(true);
            } catch (error) {
                console.error('Error fetching profile data:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setFormShow(true);
            setLoading(false);
        }
    }


    const useDynamicFields = (initialFields) => {
        const [fields, setFields] = useState([initialFields]);
        const addField = () => {
            setFields([...fields, { ...initialFields }]);
        };
        const removeField = (index) => {
            const updatedFields = [...fields];
            updatedFields.splice(index, 1);
            setFields(updatedFields);
        };
        const handleChange = (index, field, value) => {
            const updatedFields = [...fields];
            updatedFields[index][field] = value;
            setFields(updatedFields);
        };
        return { fields, setFields, addField, removeField, handleChange };
    };

    const renderProfile = () => {
        switch (userType) {
            case "1":
            case "2":
                return <ParentStudentProfile profileData={profileData} profileId={profileId} useDynamicFields={useDynamicFields} stateListing={stateListing} />;
            case "3":
                return <TrainerProfile profileData={profileData} profileId={profileId} useDynamicFields={useDynamicFields} stateListing={stateListing} />;
            default:
                return null;
        }
    };

    if (loading) {
        return (
            <div className="container cms-skeleton">
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <>
            {formShow && (
                renderProfile()
            )}
        </>
    );
};

export default ProfileView;