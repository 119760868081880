import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import SearchBar from './SearchBar'
import Listing from './listing'
import { getLabel } from '../../../common/getLabel'
import { getPremiumProfiles } from '../../services/premium-profiles/action'
import { loadMetaTags, PREMIUM_PROFILE_PAGE_META_TYPE, SITE_TITLE } from '../../common/common'
const PremiumProfiles = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 20;
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: ""
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch();
    const { premiumProfiles, totalCount } = useSelector((state) => state.premiumProfiles);
    const loading = useSelector((state) => state.premiumProfiles.loading);
    const error = useSelector((state) => state.premiumProfiles.error);
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getPremiumProfiles(updatedFilterData));
    };

    const handleFilter = () => {
        const updatedFilterData = {
            ...filterdata,
        };
        dispatch(getPremiumProfiles(updatedFilterData));
    };

    const handleFilterUpdate = async (name, value) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
    };

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const metaTags = {
        meta_type: PREMIUM_PROFILE_PAGE_META_TYPE,
        meta_title: `Premium Profiles - ${site_title}`,
        meta_record_id: 0,
        meta_advance_tag: 1
    }

    useEffect(() => {
        loadMetaTags(metaTags);
        dispatch(getPremiumProfiles(requestBody));
        // const delaySearch = setTimeout(() => {
        // }, 1000);
        // return () => clearTimeout(delaySearch);
    }, []);
    return (
        <>
            <div className="player-list">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="player-heading">
                                <span><h2><img src="/images/trophy-star (1).png" className="img-fluid" /> {getLabel('LBL_QBHL_PREMIUM_PROFILES')}</h2></span>
                            </div>
                        </div>

                        <SearchBar
                            handleFilterUpdate={handleFilterUpdate}
                            handleFilter={handleFilter}
                            filterdata={filterdata}
                        />

                        <Listing
                            error={error}
                            premiumProfiles={premiumProfiles}
                            loading={loading}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            limit={limit}
                            handlePaginate={handlePaginate}
                        />

                    </div>
                </div>
            </div>
        </>
    )
}

export default PremiumProfiles
