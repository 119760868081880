import { META_TAG_LOADING, META_TAG_ERROR, META_TAG_SUCCESS } from "./type";
import { headers } from "../../common/common";
import { myAxios } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingMetaTag = (payload) => ({
  type: META_TAG_LOADING,
  payload,
});
const handleErrorMetaTag = () => ({
  type: META_TAG_ERROR,
});
const handleSuccessMetaTag = (payload) => ({
  type: META_TAG_SUCCESS,
  payload,
});
const getDataMetaTags = (data) => async (dispatch) => {
  dispatch(handleLoadingMetaTag());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/admin/meta-tag/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessMetaTag(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    dispatch(handleErrorMetaTag());
  }
};
const getMetaTagbyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/admin/meta-tag/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateMetaTag = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/admin/meta-tag/setup", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
export { getDataMetaTags, getMetaTagbyId, updateMetaTag };
