
import { FAQ_LOADING, FAQ_ERROR, FAQ_SUCCESS } from "./type";

const initState = {
  loading: true,
  error: false,
  faqs: [],
};

const faqReducer = (state = initState, action) => {
  switch (action.type) {
    case FAQ_LOADING:
      return { ...state, loading: true };
    case FAQ_ERROR:
      return { ...state, loading: false, error: true };
    case FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        faqs: action.payload.faqDetails,
        totalCount: action.payload.totalRecords,
      };
    default:
      return state;
  }
};

export { faqReducer };