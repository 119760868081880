import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const TrainerSkeletonFrontSideLoading = ({ length }) => {
    const baseColor = "#ebebeb";
    const highlightColor = "#cfcfcfab";
    return (
        Array.from({ length: length }, (_, i) => (
            <div className="col-lg-12 col-sm-6" key={i}>
                <div className="qbh-player-list-box" style={{ padding: "35px", height: "unset" }}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="trainer-pro">
                                <Skeleton circle={true} baseColor={baseColor} highlightColor={highlightColor} height={200} width={200} style={{ lineHeight: "unset" }} />
                            </div>
                        </div>

                        <div className="col-lg-9">
                            <div className="school-list-trainer">
                                <div className="player-pro-name"><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={40} /></div>
                                <div className="address-list">
                                    <ul>
                                        <li><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                        <li><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                        <li><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                        <li><Skeleton baseColor={baseColor} highlightColor={highlightColor} width={300} height={30} /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    )
}

export default TrainerSkeletonFrontSideLoading
