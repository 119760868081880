import { RANKING_LOADING, RANKING_ERROR, RANKING_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingRanking = (payload) => ({
    type: RANKING_LOADING,
    payload,
});

const handleErrorRanking = () => ({
    type: RANKING_ERROR,
});

const handleSuccessRanking = (payload) => ({
    type: RANKING_SUCCESS,
    payload,
});

const getDataRankings = (data) => async (dispatch) => {
    dispatch(handleLoadingRanking());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/get-archive-ranking", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessRanking(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in User Profile listing", error);
        dispatch(handleErrorRanking());
    }
};

const setUpRanking = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/setup-archive-ranking", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export {
    getDataRankings,
    setUpRanking
};
