import * as Yup from "yup";
import { getLabel } from "./getLabel";
const today = new Date().toISOString().split('T')[0];
export const commonValidationSchema = Yup.object({
    name: Yup.string().required(() => getLabel('LBL_Required_name')),
    status: Yup.number().required(() => getLabel('LBL_Required_status')),
});
export const TagSchema = commonValidationSchema.shape({
});
export const categorySchema = commonValidationSchema.shape({
    parent: Yup.number().required(() => getLabel('LBL_Required_category_parent')),
    featured: Yup.number().required(() => getLabel('LBL_Required_category_featured'))
});
export const brandSchema = Yup.object().shape({
    brand_name: Yup.string().required(() => getLabel('LBL_Required_brand_name'))
        .matches(/^[a-zA-Z ]*$/, 'Only alphanumeric characters are allowed in the brand name'),
    brand_featured: Yup.number().required(() => getLabel('LBL_Required_brand_features')),
    brand_status: Yup.number().required(() => getLabel('LBL_Required_brand_status'))
})
export const loginSchema = Yup.object({
    userName: Yup.string().required(() => getLabel('LBL_Required_user_name')),
    userPassword: Yup.string().required(() => getLabel('LBL_Required_password'))
});
export const manufactureSchema = Yup.object().shape({
    prodmfr_name: Yup.string().required(() => getLabel('LBL_Required_manufacture')),
    prodmfr_status: Yup.number().required(() => getLabel('LBL_Required_status')),
})
export const reviewSchema = Yup.object().shape({
    pr_status: Yup.number().required(() => getLabel('LBL_Required_status')),
})
export const faqSchema = Yup.object().shape({
    title: Yup.string().required(() => getLabel('LBL_Required_faq_title')),
    content: Yup.string().required(() => getLabel('LBL_Required_faq_content')),
    display_order: Yup.number().required(() => getLabel('LBL_Required_faq_display_order')).min(0),
    status: Yup.number().required(() => getLabel('LBL_Required_faq_status')),
    category_id: Yup.number().required(() => getLabel('LBL_Required_faq_category')),
})
export const faqCategorySchema = Yup.object().shape({
    title: Yup.string().required(() => getLabel('LBL_Required_faq_title')),
    status: Yup.number().required(() => getLabel('LBL_Required_faq_status')),
})
export const productSchema = Yup.object().shape({
    product_name: Yup.string().required(() => getLabel('LBL_Required_product_name')),
    product_sku_no: Yup.string().required(() => getLabel('LBL_Required_product_squ')),
    product_qty: Yup.number().required(() => getLabel('LBL_Required_product_quantity')).min(0, "quantity must be at least 0"),
    product_length: Yup.number().required(() => getLabel('LBL_Required_product_length')).min(0, "length must be at least 0"),
    product_width: Yup.number().required(() => getLabel('LBL_Required_product_width')).min(0, "width must be at least 0"),
    product_height: Yup.number().required(() => getLabel('LBL_Required_product_height')).min(0, "height must be at least 0"),
    product_weight: Yup.number().required(() => getLabel('LBL_Required_product_weigth')).min(0, "weight must be at least 0"),
    product_status: Yup.number().required(() => getLabel('LBL_Required_product_status')).min(0, "status must be at least 0"),
    product_short_description: Yup.string().required(() => getLabel('LBL_Required_product_short_description')).min(5, "short description must be at least 5"),
    prodcat_id: Yup.array().required(() => getLabel('LBL_Required_product_category')).min(1, 'At least one category must be selected'),
    product_regular_price: Yup.number().required(() => getLabel('LBL_Required_regular_price')).min(0, "regular price must be at least 0"),
    product_sale_price: Yup.number().min(0, "Sale price must be at least 0")
        .test('is-less-than-regular-price', 'Sale price must be less than regular price', function (value) {
            const regularPrice = this.parent.product_regular_price;
            return !value || (value && value < regularPrice);
        }),
});
export const bannerSchema = Yup.object().shape({
    banner_heading: Yup.string().required(() => getLabel('LBL_Required_bannere_heading')),
    banner_sub_heading: Yup.string().required(() => getLabel('LBL_Required_sub_heading')),
    banner_button_label: Yup.string().required(() => getLabel('LBL_Required_button_label')),
    banner_url: Yup.string().required(() => getLabel('LBL_Required_banner_url')),
    // banner_key: Yup.string().required(() => getLabel('LBL_Required_banner_key')),
    banner_description: Yup.string().required(() => getLabel('LBL_Required_banner_description')),
    banner_status: Yup.number().required(() => getLabel('LBL_Required_banner_status')),
    banner_display_order: Yup.number().required(() => getLabel('LBL_Required_Display_Order')),
})
export const contentBlockSchema = Yup.object().shape({
    cb_title: Yup.string().required(() => getLabel('LBL_Required_content_title')),
    cb_status: Yup.number().required(() => getLabel('LBL_Required_status'))
})
export const contentPageSchema = Yup.object().shape({
    title: Yup.string().required(() => getLabel('LBL_Required_content_title')),
    banner_title: Yup.string().required(() => getLabel('LBL_Required_image_title')),
    banner_button_url: Yup.string().required(() => getLabel('LBL_Required_button_url')),
    banner_button: Yup.string().required(() => getLabel('LBL_Required_button_label')),
    banner_content: Yup.string().required(() => getLabel('LBL_Required_image_content')),
    banner_button_target: Yup.string().required(() => getLabel('LBL_Required_button_target'))
})
export const homeSlideSchema = Yup.object().shape({
    hps_title: Yup.string().required(() => getLabel('LBL_Required_title')),
    hps_sub_title: Yup.string().required(() => getLabel('LBL_Required_sub_title')),
    hps_button_label: Yup.string().required(() => getLabel('LBL_Required_button_label')),
    hps_url: Yup.string().required(() => getLabel('LBL_Required_url')),
    hps_target: Yup.string().required(() => getLabel('LBL_Required_target')),
    hps_display_order: Yup.string().required(() => getLabel('LBL_Required_order')),
    hps_status: Yup.number().required(() => getLabel('LBL_Required_status'))
})
export const blogTagSchema = Yup.object().shape({
    title: Yup.string().required(() => getLabel('LBL_Required_title')),
    status: Yup.number().required(() => getLabel('LBL_Required_status')),
})
export const blogCategorySchema = Yup.object().shape({
    title: Yup.string().required(() => getLabel('LBL_Required_title')),
    status: Yup.number().required(() => getLabel('LBL_Required_status')),
})
export const couponSchema = Yup.object().shape({
    coupon_code: Yup.string().required(() => getLabel('LBL_Required_Coupon_code')),
    coupon_price: Yup.number().required(() => getLabel('LBL_Required_Price')),
    coupon_user_type: Yup.number().required(() => getLabel('LBL_Required_User_type')),
    coupon_subscription_type: Yup.number().required(() => getLabel('LBL_Required_Subscription_type')),
    coupon_status: Yup.number().required(() => getLabel('LBL_Required_status')),
})
export const blogSchema = Yup.object().shape({
    blog_title: Yup.string().required(() => getLabel('LBL_Required_title')),
    blog_status: Yup.number().required(() => getLabel('LBL_Required_status')),
    blog_author_name: Yup.string().required(() => getLabel('LBL_Required_author_Name')),
    // blog_short_description: Yup.string().required(() => getLabel('LBL_Required_short_description')),
})
export const navigationMenuSchema = Yup.object().shape({
    nav_title: Yup.string().required(() => getLabel('LBL_Required_title')),
    nav_active: Yup.number().required(() => getLabel('LBL_Required_status')),
})
export const navigationMenuDescriptionSchema = Yup.object().shape({
    title: Yup.string().required(() => getLabel('LBL_Required_title')),
    type: Yup.number().required(() => getLabel('LBL_Required_Type')),
    target: Yup.string().required(() => getLabel('LBL_Required_Link_Target')),
    display_order: Yup.number().required(() => getLabel('LBL_Required_Display_Order')),
})
const phoneRegExp = /^[0-9]{10}$/;
export const manageUserSchema = Yup.object().shape({
    first_name: Yup.string().required(() => getLabel('LBL_Required_First_Name')),
    last_name: Yup.string().required(() => getLabel('LBL_Required_Last_Name')),
})
export const subUserSchema = Yup.object().shape({
    user_password: Yup.string().min(8, () => getLabel('LBL_Password must be 8 characters long'))
        .matches(/[0-9]/, () => getLabel('LBL_Password_requires_a_number'))
        .matches(/[a-z]/, () => getLabel('LBL_Password_requires_a_lowercase_letter'))
        .matches(/[A-Z]/, () => getLabel('LBL_Password_requires_an_uppercase_letter'))
        .matches(/[^\w]/, () => getLabel('LBL_Password_requires_a_symbol'))
        .required(() => getLabel('LBL_Required_Password')),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('user_password'), null], () => getLabel('LBL_Must_match_password_field_value'))
        .required(() => getLabel('LBL_Required_confirm_Password')),
})
export const changeUserPasswordSchema = Yup.object().shape({
    current_password: Yup.string()
        .required(() => getLabel('LBL_Required_current_Password')),
    new_password: Yup.string().min(8, () => getLabel('LBL_Password must be 8 characters long'))
        .notOneOf([Yup.ref('current_password'), null], () => getLabel('LBL_New_Password_should_be_diffent'))
        .matches(/[0-9]/, () => getLabel('LBL_Password_requires_a_number'))
        .matches(/[a-z]/, () => getLabel('LBL_Password_requires_a_lowercase_letter'))
        .matches(/[A-Z]/, () => getLabel('LBL_Password_requires_an_uppercase_letter'))
        .matches(/[^\w]/, () => getLabel('LBL_Password_requires_a_symbol'))
        .required(() => getLabel('LBL_Required_Password')),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], () => getLabel('LBL_Must_match_password_field_value'))
        .required(() => getLabel('LBL_Required_confirm_Password')),
})

export const subAdminCredentialSchema = Yup.object().shape({
    new_password: Yup.string().min(8, () => getLabel('LBL_Password must be 8 characters long'))
        .notOneOf([Yup.ref('current_password'), null], () => getLabel('LBL_New_Password_should_be_diffent'))
        .matches(/[0-9]/, () => getLabel('LBL_Password_requires_a_number'))
        .matches(/[a-z]/, () => getLabel('LBL_Password_requires_a_lowercase_letter'))
        .matches(/[A-Z]/, () => getLabel('LBL_Password_requires_an_uppercase_letter'))
        .matches(/[^\w]/, () => getLabel('LBL_Password_requires_a_symbol'))
        .required(() => getLabel('LBL_Required_Password')),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], () => getLabel('LBL_Must_match_password_field_value'))
        .required(() => getLabel('LBL_Required_confirm_Password')),
})

export const subAdminSchema = Yup.lazy(values => {
    return Yup.object().shape({
        admin_id: Yup.number(),
        admin_email: Yup.string().email(() => getLabel("LBL_Invalid_E-Mail")).required(() => getLabel("LBL_E-Mail_Address_Is_Required")),
        admin_status: Yup.number().required(() => getLabel("LBL_Status_is_Required")).oneOf([0, 1], () => getLabel("LBL_Invalid_Status")),
        admin_name: Yup.string().required(() => getLabel("LBL_Required_Admin_Name")),
        admin_username: !values.admin_id ? Yup.string().required(() => getLabel("LBL_Required_username")) : Yup.string().nullable(),
        admin_password: !values.admin_id ? Yup.string().min(8, () => getLabel('LBL_Password must be 8 characters long'))
            .notOneOf([Yup.ref('current_password'), null], () => getLabel('LBL_Required_Password'))
            .matches(/[0-9]/, () => getLabel('LBL_Password_requires_a_number'))
            .matches(/[a-z]/, () => getLabel('LBL_Password_requires_a_lowercase_letter'))
            .matches(/[A-Z]/, () => getLabel('LBL_Password_requires_an_uppercase_letter'))
            .matches(/[^\w]/, () => getLabel('LBL_Password_requires_a_symbol'))
            .required(() => getLabel('LBL_Required_Password')) : Yup.string().nullable(),
        confirm_password: !values.admin_id ? Yup.string()
            .oneOf([Yup.ref('admin_password'), null], () => getLabel('LBL_Must_match_password_field_value'))
            .required(() => getLabel('LBL_Required_confirm_Password')) : Yup.string().nullable(),
    })
})
export const promotionSchema = Yup.object().shape({
    promotion_status: Yup.number().required(() => getLabel('LBL_Required_status')),
})
export const orderCancelReasonSchema = Yup.object().shape({
    ocreason_name: Yup.string().required(() => getLabel('LBL_Required_cancel_reason')),
})
export const orderReturnReasonSchema = Yup.object().shape({
    orreason_name: Yup.string().required(() => getLabel('LBL_Required_order_return_reason')),
})
export const shippingCompanySchema = Yup.object().shape({
    scompany_title: Yup.string().required(() => getLabel('LBL_Required_title')),
    scompany_status: Yup.number().required(() => getLabel('LBL_Required_status')),
})
export const forgotPasswordSchema = Yup.object().shape({
    admin_email: Yup.string().email(() => getLabel("LBL_Invalid_email_Address")).required(() => getLabel("LBL_Email_Address_Is_Required")),
})
export const changePasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, () => getLabel('LBL_Password must be 8 characters long'))
        .matches(/[0-9]/, () => getLabel('LBL_Password_requires_a_number'))
        .matches(/[a-z]/, () => getLabel('LBL_Password_requires_a_lowercase_letter'))
        .matches(/[A-Z]/, () => getLabel('LBL_Password_requires_an_uppercase_letter'))
        .matches(/[^\w]/, () => getLabel('LBL_Password_requires_a_symbol'))
        .required(() => getLabel('LBL_Required_Password')),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], () => getLabel('LBL_Password_is_not_matched'))
        .required(() => getLabel('LBL_Required_confirm_Password')),
})
export const profileSchema = Yup.object().shape({
    admin_name: Yup.string().required(() => getLabel("LBL_Required_name")),
});
export const rewriteUrlSchema = Yup.object().shape({
    urlrewrite_custom: Yup.string().required(() =>
        getLabel("LBL_Required_custom")
    ),
});
export const metaTagSchema = Yup.object().shape({
    meta_title: Yup.string().required(() => getLabel("LBL_Required_title")),
});

export const trainerProfileSchema = Yup.object().shape({
    first_name: Yup.string().required(() => getLabel('LBL_Required_First_Name')),
    last_name: Yup.string().required(() => getLabel('LBL_Required_last_name')),
    company_name: Yup.string().required(() => getLabel('LBL_Required_Business_Name')),
    address: Yup.string().required(() => getLabel('LBL_Required_Address')),
    city: Yup.string().required(() => getLabel('LBL_Required_City')),
    state_code: Yup.string().required(() => getLabel('LBL_Required_State')),
    zip: Yup.string().required(() => getLabel('LBL_Required_Zip')),
    phone_no: Yup.string().required(() => getLabel('LBL_Required_Phone')),
    email: Yup.string().email(() => getLabel("LBL_Invalid_E-Mail")).required(() => getLabel("LBL_E-Mail_Address_Is_Required")),
})

export const userProfileSchema = Yup.object().shape({
    first_name: Yup.string().required(() => getLabel('LBL_Required_First_Name')),
    last_name: Yup.string().required(() => getLabel('LBL_Required_last_name')),
    style: Yup.string().required(() => getLabel('LBL_Required_Position')),
    hs_name: Yup.string().required(() => getLabel('LBL_Required_School_name')),
    hs_city: Yup.string().required(() => getLabel('LBL_Required_City')),
    hs_state: Yup.string().required(() => getLabel('LBL_Required_State')),
    // url_slug: Yup.string().required(() => getLabel('LBL_Required_Url_Slug')),
    grad_class: Yup.string().required(() => getLabel('LBL_Required_Graduation_Class')),
    email: Yup.string().email(() => getLabel("LBL_Invalid_E-Mail")),
})
export const schoolLogoSchema = Yup.object().shape({
    title: Yup.string().required(() => getLabel('LBL_Required_School_Name'))
})
export const assignProfileSchema = Yup.object().shape({
    user_id: Yup.string()
        .required(() => getLabel('LBL_Required_User_Name'))
        .test('is-positive', () => getLabel('LBL_Required_User_Name'), value => {
            const num = Number(value);
            return !isNaN(num) && num > 0;
        }),
    profile_id: Yup.string()
        .required(() => getLabel('LBL_Required_Profile_Id')),
});

export const rankingProfileSchema = Yup.object().shape({
    year: Yup.string().required(() => getLabel('LBL_Required_Year')),
    month: Yup.string().required(() => getLabel('LBL_Required_Month')),
})
export const exportSchema = Yup.object().shape({
    export_type: Yup.string().required(getLabel("LBL_Required")),
    limit: Yup.number().min(1, getLabel("LBL_Limit_must_start_with_1")),
    limit: Yup.number()
        .when('export_type', {
            is: '1',
            then: () => Yup.number()
                .required(getLabel("LBL_Required"))
                .min(1, getLabel("LBL_Limit_must_start_with_1"))
                .max(1000, getLabel("LBL_Limit_should_not_exceed_1000"))
                .integer(getLabel("LBL_Limit_must_be_an_integer")),
            otherwise: () => Yup.number().nullable()
        }),
})