import React from 'react'
import { Link, NavLink } from "react-router-dom"
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
import { baseUrl } from './config';
import { formatDate } from '../../common/common';
import { getLabel } from '../../common/getLabel';
import BlogPosts from './BlogPosts';

const Footer = (props) => {
    const { headerFooter } = props;
    const location = useLocation();
    const isUserDashboardPages = location.pathname.startsWith("/qbhl-admin");
    const isHomePage = location.pathname === "/";
    const isBulletinPage = location.pathname === "/bulletin-board";
    var articlesSetting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        autoplay: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },

        ]
    };
    const footerMenus = headerFooter ? headerFooter.footer.links : [];
    const footerLogo = headerFooter ? headerFooter.footer.logo : [];
    const footerArticles = headerFooter && headerFooter.footer.blogs ? headerFooter.footer.blogs.data : [];
    const blogNoImage = headerFooter && headerFooter.footer.blogs ? headerFooter.footer.blogs.blogDefaultImage : [];
    const generalConfigurations = headerFooter ? headerFooter.info : [];
    return (
        <>
            {!isUserDashboardPages && (
                <>
                    {!isHomePage && (
                        <>
                            {!isBulletinPage && (
                                <div className="bg-color-coupon">
                                    <div className="container-fluid custom-width">
                                        <div className="row">
                                            <div className="col-lg-7 col-sm-12 col-md-12 col-xl-7 col-xxl-7">
                                                <h1>{getLabel('LBL_TRY_PREMIUM_FREE_FOR_30_DAYS!_FREE_TRIAL_OFFER')}</h1>
                                            </div>
                                            <div className="col-lg-5 col-sm-12 col-md-12 col-xl-5 col-xxl-5">
                                                {generalConfigurations.CONF_PROMO_CODE && (
                                                    <p>Enter promo code <span className="qbh-coupon">{generalConfigurations.CONF_PROMO_CODE}</span> at checkout</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </>
                    )}
                    {!isHomePage && footerArticles && (
                        <BlogPosts />
                    )}

                    {/*<div className="dashed-bottom"></div>
                    <div className="twitter-bg">
                        <div className="container-fluid custom-width">
                            <div className="row">
                                <div className="col-lg-12 twitter-qbh">
                                    <h2>@QBHITLIST</h2>
                                </div>
                            </div>
                            <Slider className="row twitter-slider" {...articlesSetting}>
                                <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3 twitter-slider-slide">
                                    <div className="twitterbg-box">
                                        <div className="twitterbg-box-inner">
                                            <p>QB Hit List | @qbhitlist</p>
                                        </div>
                                        <p>RT <span className="name-text"><a href="#">@jcboice:</a></span> Quarterbacks lead with courage! Just know
                                            that courage doesn’t mean you’re not afraid. Real courage is when you’re afraid b… </p>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3  twitter-slider-slide">
                                    <div className="twitterbg-box">
                                        <div className="twitterbg-box-inner">
                                            <p>QB Hit List | @qbhitlist</p>
                                        </div>
                                        <p>RT <span className="name-text"><a href="#">@jcboice:</a></span> Quarterbacks lead with courage! Just know
                                            that courage doesn’t mean you’re not afraid. Real courage is when you’re afraid b… </p>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3  twitter-slider-slide">
                                    <div className="twitterbg-box">
                                        <div className="twitterbg-box-inner">
                                            <p>QB Hit List | @qbhitlist</p>
                                        </div>
                                        <p>RT <span className="name-text"><a href="#">@jcboice:</a></span> Quarterbacks lead with courage! Just know
                                            that courage doesn’t mean you’re not afraid. Real courage is when you’re afraid b… </p>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3  twitter-slider-slide">
                                    <div className="twitterbg-box">
                                        <div className="twitterbg-box-inner">
                                            <p>QB Hit List | @qbhitlist</p>
                                        </div>
                                        <p>RT <span className="name-text"><a href="#">@jcboice:</a></span> Quarterbacks lead with courage! Just know
                                            that courage doesn’t mean you’re not afraid. Real courage is when you’re afraid b… </p>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3  twitter-slider-slide">
                                    <div className="twitterbg-box">
                                        <div className="twitterbg-box-inner">
                                            <p>QB Hit List | @qbhitlist</p>
                                        </div>
                                        <p>RT <span className="name-text"><a href="#">@jcboice:</a></span> Quarterbacks lead with courage! Just know
                                            that courage doesn’t mean you’re not afraid. Real courage is when you’re afraid b… </p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>*/}
                </>
            )}
            <div className="footer-bg">
                <div className="container-fluid custom-width">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="foter-logo">
                                <figure>
                                    <NavLink to="/">
                                        <img
                                            src={footerLogo && footerLogo.length > 0 ? `${baseUrl}/${footerLogo[0].afile_physical_path}` : "/images/foter-logo.png"}
                                            className="img-fluid"
                                            alt="Site Logo"
                                            loading="lazy"
                                            width="317"
                                            height="86"
                                        />
                                    </NavLink>
                                </figure>
                            </div>
                            {generalConfigurations.CONF_FOOTER_TEXT && (
                                <p>{generalConfigurations.CONF_FOOTER_TEXT}</p>
                            )}
                        </div>
                        <div className="border-dashd"></div>
                        <div className="menu-footer">
                            <ul>
                                {Object.entries(footerMenus).map(([index, menu]) => (
                                    <li key={index}><Link to={menu.url}>{menu.title}</Link></li>
                                ))}
                            </ul>
                        </div>
                        {generalConfigurations && (
                            <div className="social-media">
                                <ul>
                                    <li>Follow us on : </li>
                                    {generalConfigurations.CONF_SOCIAL_MEDIA_FACEBOOK_URL && (
                                        <li className="circle"><a href={generalConfigurations.CONF_SOCIAL_MEDIA_FACEBOOK_URL}><i className='bx bxl-facebook'></i></a></li>
                                    )}
                                    {generalConfigurations.CONF_SOCIAL_MEDIA_TWITTER_URL && (
                                        <li className="circle"><a href={generalConfigurations.CONF_SOCIAL_MEDIA_TWITTER_URL}><i className='bx bxl-twitter'></i></a></li>
                                    )}
                                    {generalConfigurations.CONF_SOCIAL_MEDIA_INSTAGRAM_URL && (
                                        <li className="circle"><a href={generalConfigurations.CONF_SOCIAL_MEDIA_INSTAGRAM_URL}><i className='bx bxl-instagram'></i></a></li>
                                    )}
                                </ul>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            {generalConfigurations.CONF_COPYRIGHT_TEXT && (
                <div className="foter-bottom">
                    <div className="col-lg-12">
                        <p>{generalConfigurations.CONF_COPYRIGHT_TEXT.replace('%s', new Date().getFullYear())}</p>
                    </div>
                </div>
            )}
            <div id="overlay--loader"></div>
        </>
    )
}

export default Footer
