import { getLabel } from "../../common/getLabel";
import Pagination from "../../common/reactPaginate";
import { Link } from "react-router-dom";
import TableSkeleton from "../../common/TableSkeleton";

const Listing = (props) => {
    const {
        navigationMenues,
        totalCount,
        loading,
        error,
        handlePaginate,
        currentPage,
        limit,
        handleFormShow,
        handleStatus,
        editNavigation
    } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const calculateSerialNumber = (index) =>
        (currentPage - 1) * limit + index + 1;
    const totalPages = Math.ceil(totalCount / limit);

    return (
        <>
            <form id="faq-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_Title")}</th>
                            <th>{getLabel("LBL_Status")}</th>
                            <th className="table-action">{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={4} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="4" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {navigationMenues && navigationMenues.length > 0 ? (
                                    navigationMenues.map((elem, index) => (
                                        <tr className="actions" key={index}>
                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem.nav_title}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={elem.nav_active ? 1 : 0}

                                                        onChange={(e) =>
                                                            handleStatus(e, elem.nav_id, elem.nav_active)
                                                        }

                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <span className="action">
                                                    <ul>
                                                        {editNavigation && (
                                                            <li>
                                                                <a onClick={() => handleFormShow(elem.nav_id)}>
                                                                    <i
                                                                        className="bx bx-pencil"
                                                                        variant="primary"
                                                                    ></i>
                                                                </a>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <Link to={`/admin/navigation/${elem.nav_id}`}>
                                                                <i className='bx bx-dots-horizontal-rounded' ></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="4" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {navigationMenues && navigationMenues.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    );
};

export default Listing;
