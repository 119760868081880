import { LABEL_LOADING, LABEL_ERROR, LABEL_SUCCESS, LOAD_LABELS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";

//labelsection
const handleLoadingLabel = (payload) => ({
  type: LABEL_LOADING,
  payload,
});
const handleErrorLabel = () => ({
  type: LABEL_ERROR,
});
const handleSuccessLabel = (payload) => ({
  type: LABEL_SUCCESS,
  payload,
});
const getDataLabels = (data) => async (dispatch) => {
  dispatch(handleLoadingLabel());
  try {
    return new Promise((resolve, reject) => {
      myAxios
        .post("/labels/search", data, { headers: headers() })
        .then((res) => {
          dispatch(handleSuccessLabel(res.data.data));
        })
        .catch((error) => {
          errorMessage(error, reject);
        });
    });
  } catch (error) {
    dispatch(handleErrorLabel());
  }
};
const getLabelbyId = async (id) => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(`/labels/${id}`, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const updateLabel = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/labels/setup", data, { headers: headers(), adminURL: false })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};
const deleteLabel = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/labels/delete", data, { headers: headers() })
      .then((res) => {
        successMessage(resolve, res);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

export const getLabels = async (body) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/labels", body, { headers: headers() })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

export const loadLabels = async () => {
  return new Promise((resolve, reject) => {
    myAxios
      .get("/loadLabel")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        errorMessage(error, reject);
      });
  });
};

const createNewLabel = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/labels/add", data,{ adminURL: false })
      .then((res) => {
      })
      .catch((error) => {
      });
  });
};

const getLabelByKey = async (data) => {
  return new Promise((resolve, reject) => {
    myAxios
      .post("/labels/find-by-key", data,{ adminURL: false })
      .then((res) => {
      })
      .catch((error) => {
      });
  });
};

export { getDataLabels, getLabelbyId, updateLabel, deleteLabel , createNewLabel, getLabelByKey};
