import React, { useEffect, useState } from 'react'
import { cancelSubscription, getAccountDetails, getBillingOrders } from '../../../services/account/action';
import AccountSettingForm from './form';
import { formatDate, getOrderStatus, isCardAboutExpired, isCardExpired, renderHTML } from '../../../../common/common';
import { Link } from 'react-router-dom';
import { ProfileDowngradeModal } from '../profileDowngradeModal';
import { getLabel } from '../../../../common/getLabel';
import { addOverlay } from '../../../common/common';

const AccountSetting = () => {
    const [accountDetails, setAccountDetails] = useState(null);
    const [billingOrders, setBillingOrders] = useState(null);
    const [formShow, setFormShow] = useState(false);
    const [profileDowngradeModalshow, setProfileDowngradeModalShow] = useState(false);
    const [orderId, setOrderId] = useState(false);

    useEffect(() => {
        getAccountData();
        loadBillingOrders();
    }, []);

    const getAccountData = async () => {
        const response = await getAccountDetails();
        setAccountDetails(response);
        setFormShow(true);
    }

    const loadBillingOrders = async () => {
        const response = await getBillingOrders();
        setBillingOrders(response);
    }

    const nextBillingDateMsg = (billingDate, orderPlanId) => {
        if (!billingDate || !orderPlanId) {
            return null;
        }
        const billingDateObj = new Date(billingDate);
        let nextBillingDate;
        if (orderPlanId === 2) {
            nextBillingDate = new Date(billingDateObj.setFullYear(billingDateObj.getFullYear() + 1));
        } else if (orderPlanId === 3) {
            nextBillingDate = new Date(billingDateObj.setMonth(billingDateObj.getMonth() + 1));
        } else {
            return null;
        }
        const formattedDate = formatDate(nextBillingDate);

        return `Your Next Billing Date is ${formattedDate}`;
    }


    const handleCancelSubscription = async () => {
        addOverlay();
        const response = await cancelSubscription({ orderId: orderId });
        handleProfileDowngradeModalClose();
        loadBillingOrders();
    }

    const handleProfileDowngradeModalClose = () => setProfileDowngradeModalShow(false);

    const handleProfileDowngradeModalShow = (order_id) => {
        setOrderId(order_id);
        setProfileDowngradeModalShow(true);
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mx-auto mb-5">
                        <div className="login-bg mb-5">
                            {formShow && (<AccountSettingForm accountDetails={accountDetails} />)}
                        </div>

                    </div>
                </div>
            </div>
            <hr />
            <div className="premium-pro account-order">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 sign-up-label pt-4">
                            <label className="label-text-premium">{getLabel('LBL_PREMIUM_PROFILES')}</label>
                            {billingOrders && billingOrders.length > 0 ? (
                                <p>{getLabel('LBL_To_cancel_your_subscription_and_downgrade_your_profile,_click_the_downgrade_button_for_the_appropriate_profile.')}</p>
                            ) : (
                                <p>None</p>
                            )}
                        </div>


                        {billingOrders && billingOrders.length > 0 && billingOrders.map((order, index) => {
                            const profile_name = order.order_profile_id == 0 ? 'New Player' : `${order["userProfile.uprofile_fname"]} ${order["userProfile.uprofile_lname"]}`;
                            let cancelNote = '';
                            let freeTrial = '';
                            let currentSince = '';
                            if (order.order_status != 3 && order.order_last_date) {
                                cancelNote = '<div class="alert alert-warning text-center">Will Expire ' + formatDate(order.order_last_date) + '</div>';
                            } else {
                                cancelNote = '';
                            }

                            if (new Date(order.order_billing_date).getTime() > (Date.now() + 86400 * 1000)) {
                                freeTrial = (
                                    <div className="alert alert-success text-center">
                                        {getLabel('LBL_FREE_TRIAL_ACTIVE_TILL')} {new Date(order.order_billing_date).toLocaleDateString()}
                                    </div>
                                );
                            } else {
                                currentSince = (
                                    <small>(since {new Date(order.order_billing_date).toLocaleDateString()})</small>
                                );
                            }

                            const nextBillingDate = nextBillingDateMsg(order.order_exp_date, order.order_plan_id);
                            return (
                                <div className="col-lg-4 col-sm-6 mt-4" key={index}>
                                    <div className="premium-profile-bg">
                                        <div className="premium-title-blank">{profile_name}</div>
                                        {freeTrial && (
                                            <>
                                                {freeTrial}
                                            </>
                                        )}
                                        <div className="monthly-price">${order.order_net_amount} {order["plans.splan_title"]}</div>
                                        <div className="subscription-id">{getLabel('LBL_Subscription_ID')} : <span className="id-number">{order.order_subscription_id}</span></div>
                                        <div className="status-current">{getLabel('LBL_Status')} : <span className="id-number">{getOrderStatus(order.order_status)}</span>
                                            {currentSince && (
                                                <p>{currentSince}</p>
                                            )}
                                        </div>
                                        <div className="last-digit">{getLabel('LBL_Last_4_Digits_on_Card')}: <span className="id-number">{order["orderInfo.order_info_cc_last"]}</span></div>
                                        <div className="card-exe">{getLabel('LBL_Card_Expires')}: <span className="id-number">{order['orderInfo.order_info_card_exp']}</span></div>
                                        <div className="btn-update text-center">
                                            {!cancelNote ? (
                                                <>
                                                    {order["orderInfo.order_info_card_exp"] && isCardExpired(order["orderInfo.order_info_card_exp"]) ? (
                                                        <div className="alert alert-danger">{getLabel('LBL_Your_Credit_card_is_expired._Please_update.')}</div>
                                                    ) : order["orderInfo.order_info_card_exp"] && isCardAboutExpired(order["orderInfo.order_info_card_exp"]) ? (
                                                        <div className="alert alert-warning">{getLabel('LBL_Your_Credit_card_is_about_to_expire._Please_update.')}</div>
                                                    ) : null}
                                                    <Link to={`/qbhl-admin/billing-update/${order.order_id}`} className="btn btn-primary ">{getLabel('LBL_update_billing')}</Link>
                                                </>
                                            ) : (
                                                <>
                                                    {order.order_renew && (
                                                        <Link to={`/membership/?upgrade=${order.order_profile_id}`} className="btn btn-primary ">{getLabel('LBL_UPGRADE')}</Link>
                                                    )}
                                                </>
                                            )}
                                            {order.order_status == 3 && (
                                                <div className="btn-download">
                                                    <a onClick={() => handleProfileDowngradeModalShow(order.order_id)} className="btn btn-primary ">{getLabel('LBL_Downgrade')}</a>
                                                </div>
                                            )}
                                            {order.order_renew && (
                                                <p dangerouslySetInnerHTML={renderHTML(cancelNote)} />
                                            )}
                                            {!cancelNote && (
                                                <>
                                                    <p className="alert alert-primary text-center">{nextBillingDate}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <ProfileDowngradeModal
                profileDowngradeModalshow={profileDowngradeModalshow}
                handleProfileDowngradeModalClose={handleProfileDowngradeModalClose}
                handleCancelSubscription={handleCancelSubscription}
            />
        </>
    )
}

export default AccountSetting
