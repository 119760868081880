import { getLabel } from "../../common/getLabel";
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { forgotPassword,isLoggedIn } from "../../services/authentication/action";
import { useNavigate } from "react-router-dom";
import { forgotPasswordSchema } from '../../common/validation';
import { Link } from "react-router-dom"
 
const Forgot = () => { 
  const dispatch = useDispatch();
  // const value = useSelector((state) => state)

  const navigate = useNavigate("")
  useEffect(()=>{
    if(isLoggedIn() === true){
        navigate('/admin/dashboard');
        return;
    }    
  },[]);
  
 
  const initialValues = {
    admin_email: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordSchema,
    validateOnChange:false,
    onSubmit: async (values, {resetForm }) => {
        const postData = {
            admin_email: values.admin_email, 
        };
        dispatch(forgotPassword(postData)).then((response)=>{
          if(response === true){
            navigate('/admin');
            resetForm();
          }
        })
    }
  })

  return (

    <>
      <div className="bg-form">
        <div className="container form">
          <div className="form-container" id="login-form">
            <h1>{getLabel("LBL_Forgot_Password")}</h1>
            <form onSubmit={formik.handleSubmit}>
              <label htmlFor="admin_email">{getLabel("LBL_Email_Address")}</label>
              <input
                className="form-control"
                type="text"
                id="admin_email"
                name="admin_email"
                onChange={formik.handleChange}
                placeholder="Enter your email address"
                value={formik.values.admin_email}
              />
              <div>
                {formik.touched.admin_email && formik.errors.admin_email && (
                  <p style={{ color: "#b94a48" }}>{formik.errors.admin_email}</p>
                )}
              </div>
              
              <div className="forgot-password-btns">
                <ul>
                    <li><button type="submit">{getLabel("LBL_Submit")}</button></li>
                    <li><Link className="button"  to="/admin">{getLabel("LBL_Back")}</Link></li>
                </ul>
            </div>
              
            </form>
            
          </div>
        </div>
      </div>
    </>

  )
}


export default Forgot