import { LOAD_ORDER_STATUS } from "./type";
import { myAxios } from "../../common/myAxios";
export const loadOrderStatus = (data) => {
    return {
        type: LOAD_ORDER_STATUS,
        payload: data,
    };
};

export const getOrderStatus = () => (dispatch) => {
    try {
        return new Promise(() => {
            myAxios
                .get("/order-status", '', { adminURL: false })
                .then((res) => {
                    dispatch(loadOrderStatus(res.data.data));
                })
                .catch(() => {
                });
        });
    } catch (error) {

    }
};