import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"
import { formatDate, renderHTML } from '../../../common/common';
import { getDataBlogsDataById } from '../../services/blog/action';
import { getBlogsCategory } from '../../services/blog-category/action';
import BlogCategory from './category';
import { getBlogsTags } from '../../services/blog-tags/action';
import BlogTags from './tags';
import { baseUrl } from '../../common/config';
import { BLOG_DETAIL_PAGE_META_TYPE, loadMetaTags } from '../../common/common';
import Newsletter from './Newsletter';

const BlogView = ({record_id}) => {
    const navigate = useNavigate();
    const [blogData, setBlogData] = useState([]);
    const dispatch = useDispatch();

    const { blogCateogries } = useSelector((state) => state.blogCategory);
    const categoryLoading = useSelector((state) => state.blogCategory.loading);
    const categoryError = useSelector((state) => state.blogCategory.error);

    const { blogTags } = useSelector((state) => state.blogTags);
    const tagLoading = useSelector((state) => state.blogTags.loading);
    const tagError = useSelector((state) => state.blogTags.error);

    useEffect(() => {
        fetchData();
        if (!blogCateogries || blogCateogries.length === 0) {
            dispatch(getBlogsCategory());
        }
        if (!blogTags || blogTags.length === 0) {
            dispatch(getBlogsTags());
        }
    }, [record_id, navigate, dispatch]);

    const fetchData = async () => {
        if (!record_id) {
            navigate('/');
            return;
        }
        const response = await getDataBlogsDataById({ id: record_id });
        if (response.success === true) {
            setBlogData(response.data);
            const metaTags = {
                meta_type: BLOG_DETAIL_PAGE_META_TYPE,
                meta_title: response.data ? response.data.blog_title : 'Blog Page',
                meta_record_id: record_id
            }
            loadMetaTags(metaTags);
        }else{
            navigate('/');
            return;
        }
    }

    return (
        <>
            {blogData && blogData.blogCategory && blogData.blogCategory.length > 0 && (
                <div className="player-list-bulletin-board">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="player-heading">
                                    <p>CATEGORY</p>
                                    <h2>
                                        {blogData.blogCategory.map((category, index) => (
                                            <React.Fragment key={index}>
                                                {category["category.bcat_title"]}
                                                {index !== blogData.blogCategory.length - 1 && ", "}
                                            </React.Fragment>
                                        ))}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="container-fluid custom-width mt-4">
                <div className="row">
                    <div className="col-lg-8 mb-5">
                        <div className="row">
                            <div className="row news-detail-space">
                                <div className="col-lg-12">
                                    <div className="news-listing-details">
                                        <div className="new-image">
                                            <figure>
                                                {blogData.blogImage?.[0]?.afile_physical_path || blogData.defaultImg?.[0]?.afile_physical_path ? (
                                                    <img
                                                        src={`${baseUrl}/${blogData.blogImage?.[0]?.afile_physical_path || blogData.defaultImg?.[0]?.afile_physical_path}`}
                                                        className="img-fluid"
                                                        alt={blogData.blog_title}
                                                    />
                                                ) : null}
                                            </figure>
                                        </div>
                                        <h3 className="entry-title">{blogData.blog_title}
                                            <p><time dateTime={formatDate(blogData.blog_added_at)} pubdate=""><i className='bx bx-calendar'></i> {formatDate(blogData.blog_added_at)}</time></p>
                                        </h3>
                                        <div className="writer">
                                            <p>Written by {blogData.blog_author_name}</p>
                                        </div>
                                        <p dangerouslySetInnerHTML={renderHTML(blogData.blog_long_description)} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="right-sidebar">
                            <Newsletter />
                            <BlogCategory categoryLoading={categoryLoading} categoryError={categoryError} blogCateogries={blogCateogries} />
                            <BlogTags tagLoading={tagLoading} tagError={tagError} blogTags={blogTags} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogView
