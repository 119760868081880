import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getOrderbyId } from "../../services/manage-orders/action";
import { formatDate, getOrderStatus, getUserProfileStyles, getUserTypes, isEmpty } from "../../../common/common";
import { LoadingIndicator } from "../../common/loadingIndicator";
import { getLabel } from "../../common/getLabel";

const OrderView = () => {
    const { order_id } = useParams();
    const [orderData, setOrderData] = useState('');
    useEffect(() => {
        const delaySearch = setTimeout(() => {
            loadOrderDetails(order_id);
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, []);
    const loadOrderDetails = async (order_id) => {
        try {
            const response = await getOrderbyId(order_id);
            const result = response.data;
            setOrderData(result);
        } catch (error) { }
    };
    const profile_name = orderData.order_profile_id == 0 ? 'New Player' : `${orderData["userProfile.uprofile_fname"]} ${orderData["userProfile.uprofile_lname"]}`;
    return (
        <>
            <div className="bg-whites mt-4 pt-5 order-info">
                <div className="container-fluid inner-section">
                    <div className="row">
                        <div className="col leftspace pb-5">
                            <Link className="btn btn-success btn-sm float-right" to="/admin/orders">{getLabel("LBL_Back")}</Link>
                        </div>
                        <div className="col-lg-12 col-md-12 space">
                            <div className="card form-edit-body">
                                {orderData ? (
                                    <>
                                        <section className="section order--details">
                                            <div className="sectionhead">
                                                <h4>{getLabel('LBL_Order_Detail')}</h4>
                                                <div className="sectionbody">
                                                    <table className="table table--details">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>{getLabel('LBL_Order_ID')}:</strong> {orderData.order_invoice_number}</td>
                                                                <td><strong>{getLabel('LBL_Order_Date')}: </strong> {formatDate(orderData.order_added_at)}</td>
                                                                <td><strong>{getLabel('LBL_Order_Expiry_Date')}: </strong> {formatDate(orderData.order_exp_date)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>{getLabel('LBL_Order_Net_Amount')}:</strong> ${orderData.order_net_amount}</td>
                                                                <td><strong>{getLabel('LBL_Order_Subscription_ID')}: </strong> {orderData.order_subscription_id}</td>
                                                                <td><strong>{getLabel('LBL_Order_Status')}: </strong> {getOrderStatus(orderData.order_status)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </section>
                                        {orderData["userProfile.uprofile_id"] && isEmpty(orderData["userProfile.uprofile_id"]) !== true && (
                                            <>
                                                <hr />
                                                <section className="section profile--details">
                                                    <div className="sectionhead">
                                                        <h4>{getLabel('LBL_Profile_Detail')}</h4>
                                                        <div className="sectionbody">
                                                            <table className="table table--details">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>{getLabel('LBL_Name')}:</strong> {profile_name}</td>
                                                                        <td><strong>{getLabel('LBL_Phone')}: </strong> {orderData["userProfile.uprofile_phone_no"]}</td>
                                                                        <td><strong>{getLabel('LBL_Email')}: </strong> {orderData["userProfile.uprofile_email"]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>{getLabel('LBL_Profile_Premium_Overdie')}:</strong> {orderData["userProfile.uprofile_premium_overide"] ? 'Yes' : 'No'}</td>
                                                                        <td><strong>{getLabel('LBL_Profile_Grad_Class')}: </strong> {orderData["userProfile.uprofile_grad_class"]}</td>
                                                                        <td><strong>{getLabel('LBL_Style')}: </strong> {getUserProfileStyles[orderData["userProfile.uprofile_style"]]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>{getLabel('LBL_State')}:</strong> {orderData["userProfile.uprofile_state_code"] ? 'Yes' : 'No'}</td>
                                                                        <td><strong>{getLabel('LBL_City')}: </strong> {orderData["userProfile.uprofile_city"]}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </section>
                                            </>
                                        )}
                                        {orderData["user.user_id"] && isEmpty(orderData["user.user_id"]) !== true && (
                                            <>
                                                <hr />
                                                <section className="section profile--details">
                                                    <div className="sectionhead">
                                                        <h4>{getLabel('LBL_Users_Detail')}</h4>
                                                        <div className="sectionbody">
                                                            <table className="table table--details">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>{getLabel('LBL_Name')}:</strong> {orderData["user.user_fname"]} {orderData["user.user_lname"]}</td>
                                                                        <td><strong>{getLabel('LBL_Email')}: </strong> {orderData["user.user_email"]}</td>
                                                                        <td><strong>{getLabel('LBL_Type')}: </strong> {getUserTypes[orderData["user.user_type"]]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>{getLabel('LBL_User_Added_at')}:</strong> {formatDate(orderData["user.user_added_at"])}</td>
                                                                        <td><strong>{getLabel('LBL_User_Status')}: </strong> {orderData["user.user_is_active"] ? 'Active' : 'In Active'}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </section>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <section className="section profile--details">
                                        <div className="sectionhead">
                                            <LoadingIndicator />
                                        </div>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderView
