import { getLabel } from "../../common/getLabel";
import { useFormik } from "formik";
import { updateGeneralSetting } from "../../services/general-settings/action";
import { getDataGeneralSettings } from "../../services/general-settings/action";
import { useDispatch } from "react-redux";
const SocialMediaPage = (props) => {
  const { generalSettings,editSetting } = props;
  const dispatch = useDispatch();
  
  const initialValues = {
    CONF_SOCIAL_MEDIA_FACEBOOK_URL: (generalSettings)?generalSettings.CONF_SOCIAL_MEDIA_FACEBOOK_URL:'',
    CONF_SOCIAL_MEDIA_INSTAGRAM_URL: (generalSettings)?generalSettings.CONF_SOCIAL_MEDIA_INSTAGRAM_URL:'',
    CONF_SOCIAL_MEDIA_TWITTER_URL: (generalSettings)?generalSettings.CONF_SOCIAL_MEDIA_TWITTER_URL:'',
    CONF_SOCIAL_MEDIA_LINKEDLN_URL: (generalSettings)?generalSettings.CONF_SOCIAL_MEDIA_LINKEDLN_URL:'',    
  };

 
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
     const data = Object.entries(values).map(([key, value]) => ({
        conf_name: key,
        conf_value: value?value:'',
      }));
      try {
        await updateGeneralSetting(data);
        dispatch(getDataGeneralSettings());
      } catch (error) {}
    },
  });
  const handleChange = (e) => {
    if(editSetting){
      formik.handleChange(e);
    }
  };
  
  var disable = "disabled";
  if(editSetting){
    disable = "";
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row formcustom">
          <div className="form-group col-md-12">
            <label htmlFor="input">{getLabel("LBL_Facebook_Url")}</label>
            <input
              type="text"
              className="form-control"
              placeholder={getLabel("LBL_Social_Media_Link")}
              name="CONF_SOCIAL_MEDIA_FACEBOOK_URL"
              value={formik.values.CONF_SOCIAL_MEDIA_FACEBOOK_URL}
              onChange={handleChange}
              disabled={disable}
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="input">{getLabel("LBL_Instagram_Url")}</label>
            <input
              type="text"
              className="form-control"
              placeholder={getLabel("LBL_Social_Media_Link")}
              name="CONF_SOCIAL_MEDIA_INSTAGRAM_URL"
              value={formik.values.CONF_SOCIAL_MEDIA_INSTAGRAM_URL}
              onChange={handleChange}
              disabled={disable}
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="input">{getLabel("LBL_Twiiter_Url")}</label>
            <input
              type="text"
              className="form-control"
              placeholder={getLabel("LBL_Social_Media_Link")}
              name="CONF_SOCIAL_MEDIA_TWITTER_URL"
              value={formik.values.CONF_SOCIAL_MEDIA_TWITTER_URL}
              onChange={handleChange}
              disabled={disable}
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="input">{getLabel("LBL_Linkedln_Url")}</label>
            <input
              type="text"
              className="form-control"
              placeholder={getLabel("LBL_Social_Media_Link")}
              name="CONF_SOCIAL_MEDIA_LINKEDLN_URL"
              value={formik.values.CONF_SOCIAL_MEDIA_LINKEDLN_URL}
              onChange={handleChange}
              disabled={disable}
            />
          </div>
          {editSetting && (
            <div className="form-group col-md-12">
              <button className="btn btn-sm btn-secondary" type="submit">
                  {getLabel("LBL_Submit")}
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};
export default SocialMediaPage;
