import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { getTrainingStatsData, setupTrainerProfileInfo } from '../../../services/account/action';
import { getLabel } from '../../../../common/getLabel'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDatePicker from '../../../../common/CustomDatePicker';

const TrainingStats = (props) => {
    const { profileId, useDynamicFields } = props;
    const { fields: trainingField, setFields: setTrainingFields, addField: addTrainingFields, removeField: removeTrainingField, handleChange: handleTrainingField } = useDynamicFields({ training_date: '', training_name: '', training_location: '' });
    const { fields: campFields, setFields: setCampFieldss, addField: addCampFieldss, removeField: removeCampFields, handleChange: handleCampFields } = useDynamicFields({ event_date: '', event_name: '', event_location: '' });

    const [numYears, setNumYears] = useState(1);

    const initialFields = { year: '', games: '', attempt: '', complete: '', yard: '', pass_td: '', rush_yd: '', rush_td: '', wr_receptions: '', wr_total_yds: '', wr_yards_games: '', wr_tds: '' }
    const [yearsField, setYearsFields] = useState([initialFields]);
    const addField = () => {
        setYearsFields([...yearsField, { ...initialFields }]);
        setNumYears(numYears + 1);
    };
    const removeField = (index) => {
        const updatedFields = [...yearsField];
        updatedFields.splice(index);
        setYearsFields(updatedFields);
        setNumYears(index);
    };
    const handleYearsField = (index, field, value) => {
        const updatedFields = [...yearsField];
        updatedFields[index][field] = value;
        setYearsFields(updatedFields);
    };

    const trainingStatKeys = {};
    yearsField.forEach((obj, index) => {
        const yearKey = `year_${index + 1}`;
        if (Object.values(obj).some(val => val !== '')) {
            trainingStatKeys[yearKey] = obj;
        }
    });

    useEffect(() => {
        loadTrainingStatsData();
    }, []);

    const filteredTrainingField = trainingField.filter(item => item.training_date !== '' || item.training_name !== '' || item.training_location !== '');
    const filteredCampsField = campFields.filter(item => item.event_date !== '' || item.event_name !== '' || item.event_location !== '');

    const formik = useFormik({
        initialValues: {
            sparq_dash: '',
            sparq_bench: '',
            sparq_vertical: '',
            sparq_broad: '',
            sparq_cone: '',
        },
        validateOnChange: false,
        onSubmit: async (values) => {
            const postData = {
                // user_id: String(user_id),
                profile_id: profileId,
                training_info:
                {
                    ...trainingStatKeys,
                    training: filteredTrainingField,
                    camps: filteredCampsField,
                    sparq: {
                        dash: values.sparq_dash,
                        bench: values.sparq_bench,
                        vertical: values.sparq_vertical,
                        broad: values.sparq_broad,
                        cone: values.sparq_cone
                    }
                }
            }
            try {
                await setupTrainerProfileInfo(postData);
            } catch (error) {
                console.log(error);
            }
        },
    });

    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const loadTrainingStatsData = async () => {
        const response = await getTrainingStatsData({ "profile_id": profileId });
        const sparq = (response && response.sparq) ? JSON.parse(response.sparq) : '';
        const updatedValues = {
            sparq_dash: (sparq && sparq.dash) ? sparq.dash : '',
            sparq_bench: (sparq && sparq.bench) ? sparq.bench : '',
            sparq_vertical: (sparq && sparq.vertical) ? sparq.vertical : '',
            sparq_broad: (sparq && sparq.broad) ? sparq.broad : '',
            sparq_cone: (sparq && sparq.cone) ? sparq.cone : '',
        }
        formik.setValues(updatedValues);
        if (response && response.training && response.training.length > 0) {
            setTrainingFields(JSON.parse(response.training));
        }
        if (response && response.camps && response.camps.length > 0) {
            setCampFieldss(JSON.parse(response.camps));
        }

        let yearsData = [];
        for (let i = 1; i <= 4; i++) {
            const yearKey = `year_${i}`;
            if (response && response[yearKey]) {
                yearsData.push(JSON.parse(response[yearKey]));
            }
        }
        if (yearsData && yearsData.length > 0) {
            setYearsFields(yearsData);
            setNumYears(yearsData.length);
        }
    }

    const handleTrainingDateChange = (index, field, date) => {
        handleTrainingField(index, field, date);
    };
    const handleCampDateChange = (index, field, date) => {
        handleCampFields(index, field, date);
    };


    return (
        <>
            <h4>{getLabel('LBL_Stats')}</h4>
            <form onSubmit={formik.handleSubmit}>
                <div className="row bg-white-tranier mb-5">
                    <div className="col-lg-12">
                        {yearsField.map((field, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && (
                                    <div className="add-more">
                                        <button className="add_input" onClick={() => removeField(index)}><i className='bx bx-minus'></i> Year {index + 1}</button>
                                    </div>
                                )}
                                <div className="row mt-4">
                                    <div className="col-lg-12">
                                        <div className="bio">
                                            <label>Year {index + 1}</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Year')}</label>
                                            <input name={`year[${index}]`} value={field.year} onChange={(e) => handleYearsField(index, 'year', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Games_Played')}</label>
                                            <input name={`games[${index}]`} value={field.games} onChange={(e) => handleYearsField(index, 'games', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Passing_Attempts')}</label>
                                            <input name={`attempt[${index}]`} value={field.attempt} onChange={(e) => handleYearsField(index, 'attempt', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Completions')}</label>
                                            <input name={`complete[${index}]`} value={field.complete} onChange={(e) => handleYearsField(index, 'complete', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Passing_Yards')}</label>
                                            <input name={`yard[${index}]`} value={field.yard} onChange={(e) => handleYearsField(index, 'yard', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Passing_TDs')}</label>
                                            <input name={`pass_td[${index}]`} value={field.pass_td} onChange={(e) => handleYearsField(index, 'pass_td', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Rushing_Yards')}</label>
                                            <input name={`rush_yd[${index}]`} value={field.rush_yd} onChange={(e) => handleYearsField(index, 'rush_yd', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Rushing_TDs')}</label>
                                            <input name={`rush_td[${index}]`} value={field.rush_td} onChange={(e) => handleYearsField(index, 'rush_td', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Receptions(WR)')}</label>
                                            <input name={`wr_receptions[${index}]`} value={field.wr_receptions} onChange={(e) => handleYearsField(index, 'wr_receptions', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Total_Yds(WR)')}</label>
                                            <input name={`wr_total_yds[${index}]`} value={field.wr_total_yds} onChange={(e) => handleYearsField(index, 'wr_total_yds', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Yards/game(WR)')}</label>
                                            <input name={`wr_yards_games[${index}]`} value={field.wr_yards_games} onChange={(e) => handleYearsField(index, 'wr_yards_games', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_TDs(WR)')}</label>
                                            <input name={`wr_tds[${index}]`} value={field.wr_tds} onChange={(e) => handleYearsField(index, 'wr_tds', e.target.value)} type="number" min="0" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        {numYears <= 3 && (
                            <div className="add-more">
                                <button type="button" className="add_input" onClick={addField}><i className='bx bx-plus'></i> Year {numYears + 1}</button>
                            </div>
                        )}

                    </div>

                    <div className="col-lg-12 mt-4">
                        <div className="bio">
                            <label>{getLabel('LBL_SPARQ')}</label>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="from-group">
                            <label>{getLabel('LBL_40_Yard_Dash')}</label>
                            <input type="text" name="sparq_dash" value={formik.values.sparq_dash} onChange={handleChange} className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="from-group">
                            <label>{getLabel('LBL_Bench_Press')}</label>
                            <input type="text" name="sparq_bench" value={formik.values.sparq_bench} onChange={handleChange} className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="from-group">
                            <label>{getLabel('LBL_Vertical_Jump')}</label>
                            <input type="text" name="sparq_vertical" value={formik.values.sparq_vertical} onChange={handleChange} className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="from-group">
                            <label>{getLabel('LBL_Broad_Jump')}</label>
                            <input type="text" name="sparq_broad" value={formik.values.sparq_broad} onChange={handleChange} className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="from-group">
                            <label>{getLabel('LBL_3-Cone_Drill')}</label>
                            <input type="text" name="sparq_cone" value={formik.values.sparq_cone} onChange={handleChange} className="form-control" />
                        </div>
                    </div>

                    <div className="col-lg-12 mt-4">
                        <div className="bio">
                            <label>{getLabel('LBL_Training')}</label>
                        </div>
                    </div>

                    {trainingField.map((field, index) => (
                        <div className="col-lg-12" key={index}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <CustomDatePicker
                                            selectedDate={field.training_date ? new Date(field.training_date) : null}
                                            onDateChange={(date) => handleTrainingDateChange(index, 'training_date', date)}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" name={`training_name[${index}]`} className="form-control" placeholder={getLabel('LBL_Name/Type')} value={field.training_name} aria-label="Name/Type" onChange={(e) => handleTrainingField(index, 'training_name', e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" name={`training_location[${index}]`} className="form-control" placeholder={getLabel('LBL_Location')} value={field.training_location} aria-label="Location" onChange={(e) => handleTrainingField(index, 'training_location', e.target.value)} />
                                    </div>
                                </div>
                                {index > 0 && (
                                    <div className="col-lg-1 required_inp">
                                        <div className="form-group">
                                            <input type="button" className="inputRemove" value="X" onClick={() => removeTrainingField(index)}></input>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    <div className="col-lg-12">
                        <div className="add-more">
                            <button type="button" className="add_input" onClick={addTrainingFields}><i className='bx bx-plus'></i> {getLabel('LBL_Add_Training')}</button>
                        </div>
                    </div>

                    <div className="col-lg-12 mt-4">
                        <div className="bio">
                            <label>{getLabel('LBL_Camps')}</label>
                        </div>
                    </div>

                    {campFields.map((field, index) => (
                        <div className="col-lg-12" key={index}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <CustomDatePicker
                                            selectedDate={field.event_date ? new Date(field.event_date) : null}
                                            onDateChange={(date) => handleCampDateChange(index, 'event_date', date)}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" name={`event_name[${index}]`} className="form-control" placeholder={getLabel('LBL_Name/Type')} value={field.event_name} aria-label="Name/Type" onChange={(e) => handleCampFields(index, 'event_name', e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" name={`event_location[${index}]`} className="form-control" placeholder={getLabel('LBL_Location')} value={field.event_location} aria-label="Location" onChange={(e) => handleCampFields(index, 'event_location', e.target.value)} />
                                    </div>
                                </div>
                                {index > 0 && (
                                    <div className="col-lg-1 required_inp">
                                        <div className="form-group">
                                            <input type="button" className="inputRemove" value="X" onClick={() => removeCampFields(index)}></input>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    <div className="col-lg-12">
                        <div className="add-more">
                            <button type="button" className="add_input" onClick={addCampFieldss}><i className='bx bx-plus'></i> {getLabel('LBL_Add_Camps')}</button>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-5">
                        <div className="save-btn">
                            <button type="submit" className="btn btn-primary">{getLabel('LBL_Save_Changes')}</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default TrainingStats
