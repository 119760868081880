import { RotatingLines } from 'react-loader-spinner';

export const LoadingIndicator = () => {
    return (
      <>
        <div style={{ height: "200px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            height="100"
            width="100"
            visible={true}
          />
        </div>
      </>
    )
  }


