import { getLabel } from "../../common/getLabel";
import Pagination from "../../common/reactPaginate";
import TableSkeleton from "../../common/TableSkeleton";

const Listing = (props) => {
    const { faqs, totalCount, loading, error, limit, handlePaginate, handleFormShow, calculateSerialNumber, deleteModalOpen, handleCheckboxChange, handleStatus, editFaq, currentPage } = props;

    const totalPages = Math.ceil(totalCount / limit);
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };

    return (
        <>
            <form id="faq-listing">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="col-check"><input type="checkbox" className="selectAll-js"
                                onChange={(e) => handleCheckboxChange(e, "selectedlavel")}
                            /></th>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_Title")}</th>
                            <th>{getLabel("LBL_Display_Order")}</th>
                            <th>{getLabel("LBL_Status")}</th>
                            <th className="table-action">{getLabel("LBL_Action")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={5} checkbox={true} />
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="6" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {faqs && faqs.length > 0 ? (
                                    faqs.map((elem, index) => (
                                        <tr className="actions" key={index}>
                                            <th>
                                                <input type="checkbox" name="order_id_faq[]"
                                                    value={elem.faq_id}
                                                    className="selectItem--js"
                                                    onChange={(e) => handleCheckboxChange(e, elem.faq_id)}
                                                />
                                            </th>
                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">{elem.faq_title}</span>
                                                </div>
                                            </td>
                                            <td>{elem.faq_display_order}</td>
                                            <td>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={elem.faq_status ? 1 : 0}
                                                        onChange={(e) => handleStatus(e, elem.faq_id, elem.faq_status)}
                                                        disabled={!editFaq}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <span className="action">
                                                    <ul>
                                                        <li>
                                                            <a onClick={() => handleFormShow(elem.faq_id)}>
                                                                <i className='bx bx-pencil' variant="primary"></i>
                                                            </a>
                                                        </li>
                                                        {editFaq && (
                                                            <li>
                                                                <a onClick={() => deleteModalOpen(elem.faq_id)}>
                                                                    <i className='bx bx-trash'></i>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="6" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {faqs && faqs.length > 0 && (
                <Pagination
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                    currentPage={currentPage} />
            )}
        </>
    )
}


export default Listing