import { getLabel } from "../../common/getLabel";
import { useFormik } from "formik";
import { updateGeneralSetting } from "../../services/general-settings/action";
import { getDataGeneralSettings } from "../../services/general-settings/action";
import { useDispatch } from "react-redux";
const AdddressPage = (props) => {
  const { generalSettings,editSetting } = props;
  const dispatch = useDispatch();
  
  const initialValues = {
    CONF_COUNTRY: (generalSettings)?generalSettings.CONF_COUNTRY:'',
    CONF_STATE_CODE: (generalSettings)?generalSettings.CONF_STATE_CODE:'',
    CONF_POSTAL_CODE: (generalSettings)?generalSettings.CONF_POSTAL_CODE:'',
    CONF_CITY: (generalSettings)?generalSettings.CONF_CITY:'',
    CONF_TIMINGS: (generalSettings)?generalSettings.CONF_TIMINGS:'',
    CONF_ADDRESS_LINE_1: (generalSettings)?generalSettings.CONF_ADDRESS_LINE_1:'',
    CONF_ADDRESS_LINE_2: (generalSettings)?generalSettings.CONF_ADDRESS_LINE_2:'',    
    CONF_LOCATION_ADDRESS_LINK: (generalSettings)?generalSettings.CONF_LOCATION_ADDRESS_LINK:'',    
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const data = Object.entries(values).map(([key, value]) => ({
        conf_name: key,
        conf_value: value?value:'',
      }));
      try {
        await updateGeneralSetting(data);
        dispatch(getDataGeneralSettings());
      } catch (error) {}
    },
  });
  const handleChange = (e) => {
    if(editSetting){
      formik.handleChange(e);
    }
  };

  var disable = "disabled";
  if(editSetting){
    disable = "";
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row formcustom">
          <div className="form-group col-md-6">
            <label htmlFor="inputState">{getLabel("LBL_Country")}</label>
            <select
              className="form-select"
              aria-label="Default select example"
              name="CONF_COUNTRY"
              value={formik.values.CONF_COUNTRY}
              onChange={handleChange}
              disabled={disable}
            >
              <option value="">{getLabel("LBL_Select")}</option>
              <option value="1">{getLabel("LBL_United_States")}</option>
              <option value="2">{getLabel("LBL_Canada")}</option>
            </select>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputState">{getLabel("LBL_State")}</label>
            <select
              className="form-select"
              aria-label="Default select example"
              name="CONF_STATE_CODE"
              value={formik.values.CONF_STATE_CODE}
              onChange={handleChange}
              disabled={disable}
            >
              <option value="">{getLabel("LBL_Select")}</option>
              <option value="1">{getLabel("LBL_Alabama")}</option>
              <option value="2">{getLabel("LBL_Alaska")}</option>
              <option value="3">{getLabel("LBL_American_Samoa")}</option>
              <option value="4"> {getLabel("LBL_Indiana")}</option>
              <option value="5">{getLabel("LBL_Washington")}</option>
            </select>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputZip">{getLabel("LBL_Postal_Code*")}</label>
            <input
              type="text"
              className="form-control"
              name="CONF_POSTAL_CODE"
              value={formik.values.CONF_POSTAL_CODE}
              onChange={handleChange}
              disabled={disable}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputCity">{getLabel("LBL_City")}</label>
            <input
              type="text"
              className="form-control"
              name="CONF_CITY"
              value={formik.values.CONF_CITY}
              onChange={handleChange}
              disabled={disable}
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="inputCity">{getLabel("LBL_Timings")}</label>
            <input
              type="text"
              className="form-control"
              name="CONF_TIMINGS"
              value={formik.values.CONF_TIMINGS}
              onChange={handleChange}
              disabled={disable}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label" htmlFor="basic-default-bio">
              {getLabel("LBL_Address_Line_1")}
            </label>
            <textarea
              className="form-control"
              name="CONF_ADDRESS_LINE_1"
              value={formik.values.CONF_ADDRESS_LINE_1}
              onChange={handleChange}
              rows="3"
              required=""
              disabled={disable}
            ></textarea>
          </div>
          <div className="col-md-6 ">
            <label className="form-label" htmlFor="basic-default-bio">
              {getLabel("LBL_Address_Line_2")}
            </label>
            <textarea
              className="form-control"
              name="CONF_ADDRESS_LINE_2"
              value={formik.values.CONF_ADDRESS_LINE_2}
              onChange={handleChange}
              rows="3"
              required=""
              disabled={disable}
            ></textarea>
          </div>
          <div className="col-md-12">
            <label className="form-label" htmlFor="basic-default-bio">
              {getLabel("LBL_Location_Address_Link")}
            </label>
            <textarea
              className="form-control"
              name="CONF_LOCATION_ADDRESS_LINK"
              value={formik.values.CONF_LOCATION_ADDRESS_LINK}
              onChange={handleChange}
              rows="3"
              required=""
              disabled={disable}
            ></textarea>
          </div>
        </div>
        <br></br>
        {editSetting && (
          <div className="form-group col-md-6">
            <button className="btn btn-sm btn-secondary" type="submit">
              {getLabel("LBL_Submit")}
            </button>
          </div>
        )}
      </form>
    </>
  );
};
export default AdddressPage;
