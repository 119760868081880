import { formatDate } from '@fullcalendar/core/index.js';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { formatCalendarDate } from '../../../common/common';
import { getLabel } from '../../../common/getLabel';

const EventsModal = ({ eventData, eventModalShow, handleEventModalClose }) => {
    return (
        <>
            <Modal className="events-modal rightsidebar" show={eventModalShow} onHide={handleEventModalClose}  >
                {eventData && (
                    <>
                        {eventData.title && (
                            <Modal.Header closeButton>
                                <Modal.Title>{eventData.title}</Modal.Title>
                            </Modal.Header>
                        )}
                        <Modal.Body>
                            {eventData.start && (
                                <p><strong>{getLabel('LBL_Start_Date')}: </strong>{formatCalendarDate(eventData.start)}</p>
                            )}
                            {eventData?.extendedProps?.end_date && (
                                <p><strong>{getLabel('LBL_End_Data')}: </strong>{formatCalendarDate(eventData.extendedProps.end_date)}</p>
                            )}
                            {eventData.extendedProps.start_stop && (
                                <p><strong>{getLabel('LBL_Times_(start/stop)')}: </strong>{eventData.extendedProps.start_stop}</p>
                            )}
                            {eventData.extendedProps.location && (
                                <p><strong>{getLabel('LBL_Location')}: </strong>{eventData.extendedProps.location}</p>
                            )}
                            {eventData.extendedProps.description && (
                                <p>{eventData.extendedProps.description}</p>
                            )}
                        </Modal.Body>
                    </>
                )}
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleEventModalClose}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EventsModal