import React, { useEffect, useState, useRef } from 'react'
import { getDataBulletinBoard, setupAdsData } from '../../../services/account/action';
import { getLabel } from '../../../../common/getLabel'
import { baseUrl } from '../../../common/config';
import { useFormik } from "formik";
import { trainerAdSchema } from '../../../common/validation';

const Ads = (props) => {
    const { profileId } = props;
    const [adData, setAdData] = useState(null);
    const [uploadImage, setUploadImage] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        loadBulletinBoard();
    }, []);

    const loadBulletinBoard = async () => {
        const response = await getDataBulletinBoard({ "profile_id": profileId });
        setAdData(response.events);
        const updatedValues = {
            tads_id: (response && response.events && response.events.length > 0 && response.events[0].tads_id) ? response.events[0].tads_id : 0,
            title: (response && response.events && response.events.length > 0 && response.events[0].tads_title) ? response.events[0].tads_title : '',
            link: (response && response.events && response.events.length > 0 && response.events[0].tads_link) ? response.events[0].tads_link : '',
        }
        formik.setValues(updatedValues);
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            link: '',
            tads_id: 0,
        },
        validationSchema: trainerAdSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("link", values.link);
            formData.append("ads_id", values.tads_id);
            formData.append("profile_id", profileId);
            formData.append("ads_image", uploadImage);
            try {
                await setupAdsData(formData);
            } catch (error) { }
        },
    });

    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const handleCancelImage = () => {
        setUploadImage("");
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    return (
        <>
            <h4>{getLabel('LBL_Ad_Manager')}</h4>
            <form onSubmit={formik.handleSubmit}>
                <div className="row bg-white-tranier mb-5">
                    <p>{getLabel('LBL_Minimum_image_size_is_360x250._Maximum_file_size_is_5MB,_jpg_or_png_image_formats_only.')}</p>
                    <label htmlFor="formFile"><strong>{getLabel('LBL_Current_Ad')}</strong></label>
                    <div className="col-lg-6">
                        {uploadImage ? (
                            <img
                                src={URL.createObjectURL(uploadImage)}
                                onClick={handleCancelImage}
                                alt="image-path"
                                className='img-fluid'
                            />
                        ) : (
                            <div className="show--image">
                                {adData && adData.length > 0 && adData[0].images && Object.keys(adData[0].images).length > 0 && (
                                    <div>
                                        <img
                                            src={`${baseUrl}/${adData[0].images[0].afile_physical_path}`}
                                            onClick={handleCancelImage}
                                            alt="image-path"
                                            className='img-fluid'
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Default file input example</label>
                        <input className="form-control" type="file" id="formFile" ref={fileInputRef} onChange={(e) => setUploadImage(e.target.files[0])} />
                    </div>

                    <div className="col-lg-6">
                        <div className="from-group">
                            <label><strong>* Ad Title </strong><span>(30 characters max)</span></label>
                            <input type="text" className="form-control" name="title" value={formik.values.title} onChange={handleChange} maxLength="30" />
                        </div>
                        {formik.touched.title && formik.errors.title && (
                            <span style={{ color: '#b94a48' }}>{formik.errors.title}</span>
                        )}
                    </div>

                    <div className="col-lg-6">
                        <div className="from-group">
                            <label><strong>* Ad Link </strong><span>(Website or profile page)</span></label>
                            <input type="text" className="form-control" name="link" value={formik.values.link} onChange={handleChange} data-parsley-type="url" maxLength="500" />
                        </div>
                        {formik.touched.link && formik.errors.link && (
                            <span style={{ color: '#b94a48' }}>{formik.errors.link}</span>
                        )}
                    </div>

                    <div className="col-lg-12">
                        <div className="from-group">
                            <button type="submit" className="btn btn-default btn-sm btn-save btn-umber">save changes</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Ads
