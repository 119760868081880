import { FAQ_CATEGORY_LOADING, FAQ_CATEGORY_ERROR, FAQ_CATEGORY_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";

const handleLoadingFaqCategory = (payload) => ({
    type: FAQ_CATEGORY_LOADING,
    payload,
});

const handleErrorFaqCategory = () => ({
    type: FAQ_CATEGORY_ERROR,
});

const handleSuccessFaqCategory = (payload) => ({
    type: FAQ_CATEGORY_SUCCESS,
    payload,
});

const getDataFaqCategories = (data) => async (dispatch) => {
    dispatch(handleLoadingFaqCategory());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/faq/category/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessFaqCategory(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in faq category listing", error);
        dispatch(handleErrorFaqCategory());
    }
};

const getFaqCategorybyId = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios
            .get(`/faq/category/${id}`, { headers: headers() })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};


const addUpdateFaqCategory = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/faq/category/setup", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const deleteFaqCategory = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/faq/category/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateFaqCategoryStatus = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/faq/category/status-update", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export {
    getDataFaqCategories,
    getFaqCategorybyId,
    addUpdateFaqCategory,
    deleteFaqCategory,
    updateFaqCategoryStatus,
};