import { getLabel } from "../../common/getLabel";
import { LodingIndicater } from "../../common/common";
const SubNavigationListing = (props) => {
  const { subNavListing, loading, error, handleFormShow, deleteModalOpen, editNavigation } = props;
  return (
    <>
    {loading ? (
        <>
        <LodingIndicater />
        </>
      ) : error ? (
        <h3 style={{ textAlign: "center" }}>
          {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
        </h3>
      ) : subNavListing && subNavListing.length > 0 ? (
        <table
          className="table table-striped table-bordered"
          cellSpacing="0"
          width="100%"
        >
          <thead>
            <tr>

              <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
              <th>{getLabel("LBL_Title")}</th>
              {editNavigation && (<th className="table-action">{getLabel("LBL_Action")}</th>)}
            </tr>
          </thead>
          <tbody>
            {subNavListing && subNavListing.map((elem, index) => (
              <tr className="actions" key={index}>

                <td>{index + 1}</td>
                <td>
                  <div className="verified-wrap">
                    <span className="user-profile_title">{elem.nlink_title}</span>
                  </div>
                </td>
                {editNavigation && (
                  <td>
                    <span className="action">
                      <ul>
                        <li>
                          <a onClick={() => handleFormShow(elem.nlink_id)}>
                            <i className="bx bx-pencil" variant="primary"></i>
                          </a>
                        </li>
                        <li>
                          <a>
                            <i
                              className="bx bx-trash"
                              onClick={() => deleteModalOpen(elem.nlink_id)}
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h4 style={{ textAlign: "center" }}>
          {getLabel("LBL_No_result_found")}
        </h4>
      )}
    </>
  );
};

export default SubNavigationListing;
