import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { displayProcessing, errorMessage, successMessage } from "../../common/message";
const getProfileListing = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/search-profiles", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getProfileData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/profile-data", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getTrainingStatsData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/training-profile-data", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getEvaluationData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/evaluation-profile-data", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getAccountDetails = async () => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .get("/user/details", { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getBillingOrders = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/get-orders", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const setupAdsData = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/ads-setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const cancelSubscription = async (data) => {
    displayProcessing();
    return new Promise((resolve, reject) => {
        myAxios
            .post("/cancel-subscription", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateSubscription = async (data) => {
    displayProcessing();
    return new Promise((resolve, reject) => {
        myAxios
            .post("/update-subscription", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const udpateAccountDetails = async (data) => {
    displayProcessing();
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/update-account-details", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const setupProfileData = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user-profile/setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data);
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const setupUserProfileInfo = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user-profile-info/setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const setupTrainerProfileInfo = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user-training-info/setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteProfileImage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/delete-image", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
                return;
            });
    });
};

const uploadProfileImage = (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/profile-image", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data);
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const uploadProfileCoverImage = (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/profile-cover-image", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data);
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getEventsData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/event-search", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getProfileOffers = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/get-offers", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getCalendarEventsData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/calendar-event-search", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const setupEventsInfo = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/event-setup", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const setupTestimonials = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/add-testimonial", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const setupOffers = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/setup-offers", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    resolve(res.data.data);
                    return;
                }
                resolve(res.data.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteEvent = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/event-delete", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
                return;
            });
    });
};

const deleteTestimonial = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/user/delete-testimonial", data, { headers: headers() })
            .then(async (res) => {
                if (res.data.success === true) {
                    successMessage(resolve, res);
                    return;
                }
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
                return;
            });
    });
};

const getDataBulletinBoard = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/ads-search", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in Bulletin Board listing", error);
    }
};

const getTestimonials = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/testimonials", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in Testimonial listing", error);
    }
};

const checkUserHasPremium = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/check-premium-profile", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getOrderData = async (data) => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/user/order-data", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

export {
    getProfileListing,
    getProfileData,
    getTrainingStatsData,
    setupProfileData,
    setupUserProfileInfo,
    setupTrainerProfileInfo,
    getAccountDetails,
    udpateAccountDetails,
    deleteProfileImage,
    uploadProfileImage,
    uploadProfileCoverImage,
    setupAdsData,
    getEventsData,
    setupEventsInfo,
    deleteEvent,
    getDataBulletinBoard,
    getCalendarEventsData,
    getEvaluationData,
    getProfileOffers,
    setupOffers,
    getBillingOrders,
    checkUserHasPremium,
    getTestimonials,
    setupTestimonials,
    deleteTestimonial,
    cancelSubscription,
    updateSubscription,
    getOrderData
};