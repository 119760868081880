import { legacy_createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import loadLabelReducer from "./common-services/labels/reducer";
import loadOrderStatus from "./common-services/order-status/reducer";
import loadDefaultMetaTags from "./common-services/default-meta/reducer";

const rootReducer = combineReducers({
    loadLabel: loadLabelReducer,
    orderStatus: loadOrderStatus,
    defaultMetaTags: loadDefaultMetaTags,
})
const CommonStore = legacy_createStore(rootReducer, applyMiddleware(thunk))
export { CommonStore } 