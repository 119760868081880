import { BANNER_LOADING, BANNER_ERROR, BANNER_SUCCESS } from "./type";

const initState = { loading: true, error: false, banners: [] };

const bannerReducer = (state = initState, action) => {
    switch (action.type) {
        case BANNER_LOADING:
            return { ...state, loading: true };
        case BANNER_ERROR:
            return { ...state, loading: false, error: true };
        case BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                banners: action.payload.banners,
                attachments: action.payload.attachments,
                totalCount: action.payload.totalRecords,
            };
        default:
            return state;
    }
};

export { bannerReducer };
