import { GENERAL_SETTING_ERROR, GENERAL_SETTING_LOADING, GENERAL_SETTING_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";

const handleLoadingGeneralSetting = (payload) => ({
    type: GENERAL_SETTING_LOADING,
    payload,
});
const handleErrorGeneralSetting = () => ({
    type: GENERAL_SETTING_ERROR,
});
const handleSuccessGeneralSetting = (payload) => ({
    type: GENERAL_SETTING_SUCCESS,
    payload,
});
const getDataGeneralSettings = (data) => async (dispatch) => {
    dispatch(handleLoadingGeneralSetting());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/configuration/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessGeneralSetting(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        dispatch(handleErrorGeneralSetting());
    }
};
const updateGeneralSetting = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/configuration/setup", data, { headers: headers() })
            .then((res) => {
                console.log("responces in action" , res)
                resolve(res.data);

                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const deleteMediaImage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post("/configuration/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const uploadMediaImage = (data) =>{
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/configuration/upload-image", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data);
                    successMessage(resolve, res);
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
    }
};

const getConfigurationByKey = async(data)  => {
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/configuration/search", data, { headers: headers() })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    
                });
        });
    } catch (error) {
        
    }
};




export { getDataGeneralSettings, updateGeneralSetting , deleteMediaImage, uploadMediaImage, getConfigurationByKey }


