import Listing from "./listing";
import DeleteModal from "../../common/deleteModal";
import SearchBar from "./searchBar";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import {getDataOrderReturnReasons,getOrderReturnbyId,deleteReturOrderbyId} from "../../services/manage-order-return/action";
import AddUpdateOrderReturn from "./addUpdateReturnReason";
const ManageOrderReturnIndex = () => {
  const dispatch = useDispatch();
  const defineNames = {
    id: "orreason_id",
    title: "orreason_name",
  };
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const requestBody = {
    page: currentPage,
    limit: limit,
    [defineNames.title]: "",
  };
  const [filterdata, setFilterData] = useState(requestBody);
  const [deleteid, setDeleteId] = useState(null);
  const [deleteshow, setDeleteShow] = useState(false);
  const { orderReturnReasons, totalCount } = useSelector(
    (state) => state.orderReturnReason
  );
  const loading = useSelector((state) => state.orderReturnReason.loading);
  const error = useSelector((state) => state.orderReturnReason.error);
  const handlePaginate = (selectedPage) => {
    const updatedFilterData = {
      ...filterdata,
      page: selectedPage.selected + 1,
    };
    setCurrentPage(selectedPage.selected + 1);
    setFilterData(updatedFilterData);
    dispatch(getDataOrderReturnReasons(updatedFilterData));
  };
  const [formshow, setFormShow] = useState(false);
  const [initialValues, setInitialValues] = useState({
    [defineNames.title]: "",
  });
  const handleFormClose = () => {
    setFormShow(false);
    setInitialValues(() => ({
      ..."",
    }));
  };
  const handleFormShow = async (id) => {
    try {
      if (typeof id === "number") {
        const response = await getOrderReturnbyId(id);
        const result = response.data;
        setInitialValues({
          ...result,
        });
      }
    } catch (error) {}
    setFormShow(true);
  };
  const handleFilterUpdate = async (name, value, boolen) => {
    let updatedFilterData;
    updatedFilterData = {
      ...filterdata,
      [name]: value,
    };
    setFilterData(updatedFilterData);
    if (boolen === true) {
      dispatch(getDataOrderReturnReasons(updatedFilterData));
    }
  };
  const handleDeleteClose = () => {
    setDeleteShow(false);
  };
  const deleteModalOpen = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };
  const handleDelete = async () => {
    try {
      let data = [deleteid];
      await deleteReturOrderbyId({ [defineNames.id]: data });
      dispatch(getDataOrderReturnReasons(requestBody));
      handleDeleteClose();
    } catch (error) {}
  };
  return (
    <>
      <div className="bg-whites mt-4 pt-5">
        <div className="container-fluid inner-section">
          <SearchBar
            defineNames={defineNames}
            handleFilterUpdate={handleFilterUpdate}
            handleFormShow={handleFormShow}
          />
          <Listing
            orderReturnReasons={orderReturnReasons}
            totalCount={totalCount}
            loading={loading}
            error={error}
            handlePaginate={handlePaginate}
            currentPage={currentPage}
            limit={limit}
            handleFormShow={handleFormShow}
            deleteModalOpen={deleteModalOpen}
          />
        </div>
      </div>
      {formshow && (
        <AddUpdateOrderReturn
          defineNames={defineNames}
          initialValues={initialValues}
          handleFormClose={handleFormClose}
          formshow={formshow}
          requestBody={requestBody}
        />
      )}
      <DeleteModal
        show={deleteshow}
        deleteModalOpen={deleteModalOpen}
        handleDeleteClose={handleDeleteClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default ManageOrderReturnIndex;
