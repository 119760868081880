import { FAQ_LOADING, FAQ_ERROR, FAQ_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage, successMessage } from "../../common/message";

const handleLoadingFaq = (payload) => ({
    type: FAQ_LOADING,
    payload,
});

const handleErrorFaq = () => ({
    type: FAQ_ERROR,
});

const handleSuccessFaq = (payload) => ({
    type: FAQ_SUCCESS,
    payload,
});

const getDataFaqs = (data) => async (dispatch) => {
    dispatch(handleLoadingFaq());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/faq/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessFaq(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in tag listing", error);
        dispatch(handleErrorFaq());
    }
};

const getFaqbyId = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios
            .get(`/faq/${id}`, { headers: headers() })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};


const addUpdateFaq = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/faq/setup", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};
const deleteFaq = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/faq/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateFaqStatus = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/faq/update-status", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const faqCategoryListing = async () => {
    return new Promise((resolve, reject) => {
        myAxios
            .post(`/faq/category`, "data", { headers: headers() })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export {
    getDataFaqs,
    getFaqbyId,
    addUpdateFaq,
    deleteFaq,
    updateFaqStatus,
    faqCategoryListing
};
