import { myAxios } from "../../common/myAxios";
import { errorMessage } from "../../common/message";

const getContentPagebyId = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post(`/content-page`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

export { getContentPagebyId };