import Pagination from "../../common/reactPaginate";
import { getLabel } from "../../common/getLabel"
import TableSkeleton from "../../common/TableSkeleton";

const Listing = (props) => {
    const { loading, error, handlePaginate, limit, handleFormShow, calculateSerialNumber, labels, totalCount, editLabel, currentPage } = props;
    const totalPages = Math.ceil(totalCount / limit);
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    return (
        <>
            <form id="lavellisting">
                <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                            <th>{getLabel("LBL_Label_Key")}</th>
                            <th>{getLabel("LBL_Label_Caption")}</th>
                            {editLabel && (<th className="table-action">{getLabel("LBL_Action")}</th>)}
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? (
                            <>
                                <TableSkeleton cards={limit} count={4}/>
                            </>
                        ) : error ? (
                            <tr className="no--record">
                                <td colspan="6" className="text-center">{getLabel("LBL_Some_thing_wrong_Please_try_again_later")}</td>
                            </tr>
                        ) : (
                            <>
                                {labels && labels.length > 0 ? (
                                    labels.map((elem, index) => (
                                        <tr className="actions" key={elem.label_id}>
                                            <td>{calculateSerialNumber(index)}</td>
                                            <td>
                                                <div className="verified-wrap">
                                                    <span className="user-profile_title">
                                                        {elem.label_key}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="user-profile_title">
                                                    {elem.label_caption}
                                                </span>
                                            </td>
                                            {editLabel && (
                                                <td>
                                                    <span className="action">
                                                        <ul>
                                                            <li>
                                                                <a onClick={() => handleFormShow(elem.label_id)}
                                                                >
                                                                    <i className="bx bx-pencil"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="no--record">
                                        <td colspan="5" className="text-center">{getLabel("LBL_No_result_found")}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </form>
            {labels && labels.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    );
};
export default Listing;
