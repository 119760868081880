import { CONTENT_PAGE_LOADING, CONTENT_PAGE_ERROR, CONTENT_PAGE_SUCCESS } from "./type";
import { myAxios } from "../../common/myAxios";
import { headers } from "../../common/common";
import { errorMessage , successMessage} from "../../common/message";


const handleLoadingContentPage = (payload) => ({
    type: CONTENT_PAGE_LOADING,
    payload,
});

const handleErrorContentPage = () => ({
    type: CONTENT_PAGE_ERROR,
});

const handleSuccessContentPage = (payload) => ({
    type: CONTENT_PAGE_SUCCESS,
    payload,
});

const getDataContentPages = (data) => async (dispatch) => {
    dispatch(handleLoadingContentPage());
    try {
        return new Promise((resolve, reject) => {
            myAxios
                .post("/content-page/search", data, { headers: headers() })
                .then((res) => {
                    dispatch(handleSuccessContentPage(res.data.data));
                })
                .catch((error) => {
                    errorMessage(error, reject);
                });
        });
    } catch (error) {
        console.error("Error in tag listing", error);
        dispatch(handleErrorContentPage());
    }
};

const getContentPagebyId = async (id) => {
    return new Promise((resolve, reject) => {
        myAxios
            .get(`/content-page/${id}`, { headers: headers() })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateContentPage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/content-page/setup", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteContentPage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/content-page/delete", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const deleteContentPageImage = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post("/content/page/delete/image", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};

const updateBlockContentStatus = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios
            .post("/block/page/status", data, { headers: headers() })
            .then((res) => {
                successMessage(resolve, res);
            })
            .catch((error) => {
                errorMessage(error, reject);
            });
    });
};


export { getDataContentPages, getContentPagebyId, updateContentPage, deleteContentPage,  deleteContentPageImage, updateBlockContentStatus}


