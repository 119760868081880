import { getLabel } from "../../common/getLabel";
import TableSkeleton from "../../common/TableSkeleton";
import Pagination from "../../common/reactPaginate";
import SearchBar from "./SearchBar";
const MetaTagListing = (props) => {
    const { metaTagListing, currentPage, loading, error, handleFormShow, totalCount, limit, handlePaginate, activePage, handleFilterUpdate, handleFilter, filterdata } = props;
    const handlePageClick = (selectedPage) => {
        handlePaginate(selectedPage);
    };
    const totalPages = Math.ceil(totalCount / limit);
    return (
        <>
            <SearchBar
                handleFilterUpdate={handleFilterUpdate}
                handleFilter={handleFilter}
                filterdata={filterdata}
            />
            <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
                <thead>
                    <tr>
                        <th className="table-id">{getLabel("LBL_Sr._No.")}</th>
                        <th>{getLabel("LBL_Post_Title")}</th>
                        <th>{getLabel("LBL_Title")}</th>
                        <th className="thead-action">{getLabel("LBL_Action")}</th>
                    </tr>
                </thead>
                <tbody>

                    {loading ? (
                        <>
                            <TableSkeleton cards={10} count={4} />
                        </>
                    ) : error ? (
                        <tr className="no--record">
                            <td colspan="4" className="text-center">
                                <div className="verified-wrap">
                                    <span className="user-profile_title">
                                        {getLabel("LBL_Some_thing_wrong_Please_try_again_later")}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <>
                            {metaTagListing && metaTagListing.length > 0 ? (
                                metaTagListing.map((elem, index) => (
                                    <tr className="actions" key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className="verified-wrap">
                                                <span className="user-profile_title">
                                                    {elem[`post_meta_${activePage}.post_title`]}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="verified-wrap">
                                                <span className="user-profile_title">
                                                    {elem["meta_title"]}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="action">
                                                <ul>
                                                    <li>
                                                        <a>
                                                            <i className="bx bx-pencil" onClick={() => handleFormShow(elem["meta_id"])}></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="no--record">
                                    <td colspan="4" className="text-center">
                                        <div className="verified-wrap">
                                            <span className="user-profile_title">
                                                {getLabel("LBL_No_result_found")}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </table>
            {metaTagListing && metaTagListing.length > 0 && (
                <Pagination totalPages={totalPages} handlePageClick={handlePageClick} currentPage={currentPage} />
            )}
        </>
    );
};

export default MetaTagListing;
