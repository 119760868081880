import React, { useState, useEffect } from 'react'
import { getLabel } from '../../../../common/getLabel'
import { getEvaluationData } from '../../../services/account/action';

const Evaluation = (props) => {
    const { profileId, profileData } = props;
    const [evaluationData, setEvaluationData] = useState('');

    useEffect(() => {
        loadEvaluationData();
    }, []);

    const loadEvaluationData = async () => {
        const response = await getEvaluationData({ "profile_id": profileId });
        setEvaluationData(response);
    }

    let starsRating = 0;
    if (profileData && (profileData.uprofile_style == 3 || profileData.uprofile_style == 4)) {
        starsRating = (evaluationData.wr_te_stars) ? (evaluationData.wr_te_stars * 100) / 5 : 0;
    } else {
        starsRating = (evaluationData.stars) ? (evaluationData.stars * 100) / 5 : 0;
    }
    return (
        <>
            <h4>{getLabel('LBL_Evaluation')}</h4>
            <div className="row bg-white-tranier mb-5">
                {profileData && (profileData.uprofile_style == 3 || profileData.uprofile_style == 4) ? (
                    <>
                        <div className="col-lg-4">
                            <div className="bio">
                                <label className="d-block">{getLabel('LBL_Stars')}</label>
                                <div className="review__rating star-rating-menu">
                                    <div className="icn" style={{ width: starsRating + '%' }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="bio">
                                <label>{getLabel('LBL_FINAL_RATING')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_final} disabled />
                            </div>
                        </div>
                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_INDIVIDUAL_SCORES')}</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Size')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_size} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Speed')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_speed} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Release')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_release} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Catching')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_catching} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Catching_in_Traffic')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_catching_traffic} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Route_Running/Short')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_route_short} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Route_Running/Med')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_route_med} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Route_Running/Deep')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_route_deep} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Blocking')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_blocking} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Elusiveness')}</label>
                                <input type="text" className="form-control" value={evaluationData.wr_te_elusiveness} disabled />
                            </div>
                        </div>
                    </>

                ) : (
                    <>
                        <div className="col-lg-4">
                            <div className="bio">
                                <label className="d-block">{getLabel('LBL_Stars')}</label>
                                <div className="review__rating star-rating-menu">
                                    <div className="icn" style={{ width: starsRating + '%' }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="bio">
                                <label>{getLabel('LBL_FINAL_RATING')}</label>
                                <input type="text" className="form-control" value={evaluationData.final_rating} disabled />
                            </div>
                        </div>
                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_INDIVIDUAL_SCORES')}</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Size')}</label>
                                <input type="text" className="form-control" value={evaluationData.size} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Accuracy')}</label>
                                <input type="text" className="form-control" value={evaluationData.accuracy} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Arm_Strength')}</label>
                                <input type="text" className="form-control" value={evaluationData.arm_strength} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Release')}</label>
                                <input type="text" className="form-control" value={evaluationData.release} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Throw_on_the_Run')}</label>
                                <input type="text" className="form-control" value={evaluationData.throw_on_the_run} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Footwork')}</label>
                                <input type="text" className="form-control" value={evaluationData.footwork} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Poise')}</label>
                                <input type="text" className="form-control" value={evaluationData.poise} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Pocket_Presence')}</label>
                                <input type="text" className="form-control" value={evaluationData.pocket_presence} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Decision_Making')}</label>
                                <input type="text" className="form-control" value={evaluationData.decision_making} disabled />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="from-group">
                                <label>{getLabel('LBL_Touch')}</label>
                                <input type="text" className="form-control" value={evaluationData.touch} disabled />
                            </div>
                        </div>

                    </>
                )}
                <div className="col-lg-12 mt-4">
                    <div className="bio">
                        <label>{getLabel('LBL_SCOUTING_REPORT')}</label>
                        <textarea rows="4" cols="100" maxLength="50" value={evaluationData.scouting_report} disabled ></textarea>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Evaluation
