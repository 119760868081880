import { EVENTS_LOADING, EVENTS_ERROR, EVENTS_SUCCESS } from "./type";
const initState = {
    loading: true,
    error: false,
    profiles: [],
};

const eventsReducer = (state = initState, action) => {
    switch (action.type) {
        case EVENTS_LOADING:
            return { ...state, loading: true };
        case EVENTS_ERROR:
            return { ...state, loading: false, error: true };
        case EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                events: action.payload.events,
                totalCount: action.payload.totalRecords,
            };
        default:
            return state;
    }
};

export { eventsReducer };