import React, { useEffect, useState } from 'react'
import Listing from './listing'
import SearchBar from './SearchBar'
import AddUpdateCoupon from './addUpdateCoupon'
import { useDispatch, useSelector } from "react-redux"
import getPrivileges from '../../common/adminPrivilege'
import { deleteCoupon, getCouponbyId, getDataCoupons, updateCouponStatus } from '../../services/manage-coupons/action'
import DeleteModal from '../../common/deleteModal'
import { getAdminPaginationLimit } from '../../../common/common'

const CouponIndex = () => {
    const [couponData, setCouponData] = useState('');
    const [formshow, setFormShow] = useState(false);
    const [deleteid, setDeleteId] = useState(null);
    const [deleteshow, setDeleteShow] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const getPrivilege = getPrivileges();
    const editCoupons = getPrivilege.canEditCoupons();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = getAdminPaginationLimit();
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: ""
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch()
    const { coupons, totalCount } = useSelector((state) => state.coupons)
    const loading = useSelector((state) => state.coupons.loading)
    const error = useSelector((state) => state.coupons.error)

    useEffect(() => {
        dispatch(getDataCoupons(requestBody))
    }, []);

    const handleFormShow = async (id) => {
        try {
            if (typeof id === "number") {
                const response = await getCouponbyId(id);
                const result = response.data;
                setCouponData(result);
            }
        } catch (error) { }
        setFormShow(true);
    };
    const handleFormClose = () => {
        setCouponData('')
        setFormShow(false);
    }
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1,
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getDataCoupons(updatedFilterData))
    };
    const handleFilterUpdate = async (name, value, boolen) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
        if (boolen === true) {
            dispatch(getDataCoupons(updatedFilterData))
        }
    };

    const handleFilter = () => {
        dispatch(getDataCoupons(filterdata));
    };

    const handleClear = () => {
        setFilterData(requestBody);
        dispatch(getDataCoupons(requestBody));
    };

    const deleteModalOpen = (id) => {
        setDeleteShow(true);
        setDeleteId(id);
    };

    const handleDeleteClose = () => {
        setDeleteShow(false);
    };

    const handleDelete = async () => {
        try {
            let data;
            if (typeof deleteid === "number") {
                data = [deleteid];
            }
            await deleteCoupon({ "coupon_id": data });
            dispatch(getDataCoupons(requestBody));
            handleDeleteClose();
        } catch (error) { }
    };

    const handleStatus = async (e, id, status) => {
        if (editCoupons) {
            const newStatus = status === 1 ? 0 : 1;
            coupons.map((coupon) => {
                if (coupon.coupon_id === id) {
                    coupon.coupon_status = newStatus;
                }
                return coupon;
            });
            const data = {
                "coupon_status": newStatus,
                "coupon_id": [id],
            };
            try {
                await updateCouponStatus(data);
                dispatch(getDataCoupons(requestBody));
            } catch (error) { }
        }
    };

    const setCouponArray = () => {
        const checkedInputs = document.querySelectorAll(
            "input[name='coupon_array[]']:checked"
        );
        const newOrderIds = Array.from(checkedInputs, (input) => input.value);
        return newOrderIds;
    };

    const handleSelectedStatusUpdate = async (status) => {
        const data = {
            "coupon_id": setCouponArray(),
            "coupon_status": status,
        };
        try {
            await updateCouponStatus(data);
            dispatch(getDataCoupons(requestBody));
        } catch (error) { }
    };

    const handleCheckboxChange = (event, labelId) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedCheckboxes((prevSelected) => [...prevSelected, labelId]);
        } else {
            setSelectedCheckboxes((prevSelected) =>
                prevSelected.filter((id) => id !== labelId)
            );
        }
    };

    const userTypes = {
        "1": "PLAYER",
        "2": "TRAINER",
    }

    const subscriptionTypes = {
        "1": "MONTHLY",
        "2": "YEARLY",
    }

    return (
        <>
            <div className="bg-whites mt-4 pt-5">
                <div className="container-fluid inner-section">
                    <SearchBar
                        handleFormShow={handleFormShow}
                        handleSelectedStatusUpdate={handleSelectedStatusUpdate}
                        handleFilterUpdate={handleFilterUpdate}
                        filterdata={filterdata}
                        handleFilter={handleFilter}
                        handleClear={handleClear}
                        deleteModalOpen={deleteModalOpen}
                        selectedCheckboxes={selectedCheckboxes}
                        editCoupons={editCoupons}
                        userTypes={userTypes}
                        subscriptionTypes={subscriptionTypes}
                    />
                    <Listing
                        coupons={coupons}
                        totalCount={totalCount}
                        loading={loading}
                        error={error}
                        handlePaginate={handlePaginate}
                        currentPage={currentPage}
                        limit={limit}
                        handleFormShow={handleFormShow}
                        handleStatus={handleStatus}
                        deleteModalOpen={deleteModalOpen}
                        handleCheckboxChange={handleCheckboxChange}
                        setSelectedCheckboxes={setSelectedCheckboxes}
                        editCoupons={editCoupons}
                        userTypes={userTypes}
                        subscriptionTypes={subscriptionTypes}
                    />
                </div>
            </div>
            {formshow && (
                <AddUpdateCoupon
                    formshow={formshow}
                    handleFormClose={handleFormClose}
                    requestBody={requestBody}
                    couponData={couponData}
                    userTypes={userTypes}
                    subscriptionTypes={subscriptionTypes}
                    editCoupons={editCoupons}
                />
            )}

            <DeleteModal
                show={deleteshow}
                deleteModalOpen={deleteModalOpen}
                handleDeleteClose={handleDeleteClose}
                handleDelete={handleDelete}
            />
        </>
    )
}

export default CouponIndex
