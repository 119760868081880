import { getLabel } from "../../common/getLabel";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { couponSchema } from "../../common/validation";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { addUpdateCoupon, getDataCoupons } from "../../services/manage-coupons/action";

const AddUpdateBlogCategory = (props) => {
    const { formshow, handleFormClose, requestBody, couponData, userTypes, subscriptionTypes, editCoupons } = props;
    const dispatch = useDispatch();
    const initialValues = {
        coupon_id: (couponData) ? couponData.coupon_id : 0,
        coupon_code: (couponData) ? couponData.coupon_code : '',
        coupon_user_type: (couponData) ? couponData.coupon_user_type : '',
        coupon_subscription_type: (couponData) ? couponData.coupon_subscription_type : '',
        coupon_price: (couponData) ? couponData.coupon_price : '',
        coupon_status: (couponData) ? couponData.coupon_status : '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: couponSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                const response = await addUpdateCoupon(values);
                if (response === true) {
                    dispatch(getDataCoupons(requestBody));
                    handleFormClose();
                }
            } catch (error) { }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };
    return (
        <>
            <Modal
                show={formshow}
                onHide={handleFormClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">
                        {getLabel("LBL_Coupon_Form")}
                    </h5>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Coupon_code*")}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="coupon_code"
                                                name="coupon_code"
                                                onChange={handleChange}
                                                value={formik.values.coupon_code}
                                                placeholder={getLabel("LBL_Coupon_code")}
                                                disabled={!editCoupons}
                                            />
                                            <div>
                                                {formik.touched.coupon_code && formik.errors.coupon_code && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.coupon_code}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">
                                                {getLabel("LBL_Coupon_Price*")}
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                id="coupon_price"
                                                name="coupon_price"
                                                onChange={handleChange}
                                                value={formik.values.coupon_price}
                                                placeholder={getLabel("LBL_Coupon_price")}
                                                disabled={!editCoupons}
                                            />
                                            <div>
                                                {formik.touched.coupon_price && formik.errors.coupon_price && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.coupon_price}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputState">
                                            {getLabel("LBL_User_type*")}
                                        </label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="coupon_user_type"
                                            name="coupon_user_type"
                                            onChange={handleChange}
                                            value={formik.values.coupon_user_type}
                                            disabled={!editCoupons}
                                        >
                                            <option value="">{getLabel("LBL_Choose")}</option>
                                            {Object.keys(userTypes).map((key) => (
                                                <option key={key} value={key}>
                                                    {userTypes[key]}
                                                </option>
                                            ))}
                                        </select>
                                        <div>
                                            {formik.touched.coupon_user_type && formik.errors.coupon_user_type && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.coupon_user_type}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputState">
                                            {getLabel("LBL_Subscription_type*")}
                                        </label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="coupon_subscription_type"
                                            name="coupon_subscription_type"
                                            onChange={handleChange}
                                            value={formik.values.coupon_subscription_type}
                                            disabled={!editCoupons}
                                        >
                                            <option value="">{getLabel("LBL_Choose")}</option>
                                            {Object.keys(subscriptionTypes).map((key) => (
                                                <option key={key} value={key}>
                                                    {subscriptionTypes[key]}
                                                </option>
                                            ))}
                                        </select>
                                        <div>
                                            {formik.touched.coupon_subscription_type && formik.errors.coupon_subscription_type && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.coupon_subscription_type}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputState">
                                            {getLabel("LBL_Coupon_Status*")}
                                        </label>
                                        <select
                                            as="select"
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="coupon_status"
                                            name="coupon_status"
                                            onChange={handleChange}
                                            value={formik.values.coupon_status}
                                            disabled={!editCoupons}
                                        >
                                            <option value="">{getLabel("LBL_Choose")}</option>
                                            <option value="1">{getLabel("LBL_Active")}</option>
                                            <option value="0">{getLabel("LBL_InActive")}</option>
                                        </select>
                                        <div>
                                            {formik.touched.coupon_status && formik.errors.coupon_status && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.coupon_status}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFormClose}>
                            {getLabel("LBL_Close")}
                        </Button>
                        {editCoupons && (
                            <Button variant="primary" type="submit">
                                {getLabel("LBL_Submit")}
                            </Button>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};
export default AddUpdateBlogCategory;
