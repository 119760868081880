import { BLOG_TAG_LOADING, BLOG_TAG_ERROR, BLOG_TAG_SUCCESS } from "./type";

const initState = { loading: true, error: false, blogTags: [] };
const blogTagReducer = (state = initState, action) => {
  switch (action.type) {
    case BLOG_TAG_LOADING:
      return { ...state, loading: true };
    case BLOG_TAG_ERROR:
      return { ...state, loading: false, error: true };
    case BLOG_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        blogTags: action.payload.blogTags,
        totalCount: action.payload.totalRecords,
      };
    default:
      return state;
  }
};

export { blogTagReducer };
