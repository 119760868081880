import { getLabel } from "../../common/getLabel"
import { useDelayedSearch } from "../../common/common"
import { useState } from "react";
import { getUserProfileStyles } from "../../../common/common";
import { setUpRanking } from "../../services/manage-rankings/action";
import { useFormik } from "formik";
import { rankingProfileSchema } from "../../common/validation";
const SearchBar = (props) => {
    const { handleFilterUpdate, handleFilter, editRankings, filterdata, rankings } = props;
    const [searchItem, setSearchItem] = useState("")
    const [searchKey, setSearchKey] = useState("style")
    const [rankingMonth, setRankingMonth] = useState("")
    const [rankingStyle, setRankingStyle] = useState("")
    const [isRecordSearched, setIsRecordSearched] = useState(false)
    useDelayedSearch(searchKey, searchItem, handleFilterUpdate, isRecordSearched)
    const currentYear = new Date().getFullYear();
    let yearFilter = [];
    let rankingYearFilter = [];
    for (let year = (currentYear + 10); year >= (currentYear - 4); year--) {
        yearFilter.push(year);
    }
    for (let rankingYear = (currentYear - 1); rankingYear <= (currentYear + 1); rankingYear++) {
        rankingYearFilter.push(rankingYear);
    }
    const enabledRankingFilter = (rankings && filterdata.style && filterdata.grad_class) ? true : false;

    const handleRankingFilterData = (e) => {
        handleFilterUpdate(e.target.name, e.target.value, true);
        formik.handleChange(e)
    }

    const initialValues = {
        style: "",
        grad_class: "",
        month: "",
        year: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: rankingProfileSchema,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            const postData = {
                style: values.style,
                grad_class: values.grad_class,
                month: values.month,
                year: values.year,
                top_ranking: true,
                display_profile_info:true,
            }
            try {
                await setUpRanking(postData);
            } catch (error) { }
        }
    })

    const handleChange = (e) => {
        formik.handleChange(e)
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row search-bar-filter pb-5">
                    <div className="col leftspace">
                        <select name="style" className="form-select" id="style" style={{ width: "20%" }} onChange={(e) => handleRankingFilterData(e)}>
                            <option value="">All</option>
                            {Object.keys(getUserProfileStyles).map((key) => (
                                <option key={key} value={key}>
                                    {getUserProfileStyles[key]}
                                </option>
                            ))}
                        </select>
                        <select name="grad_class" className="form-select" id="grad_class" style={{ width: "20%" }} onChange={(e) => handleRankingFilterData(e)}>
                            <option value="">All</option>
                            {yearFilter.map((year) => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    {editRankings && (
                        <>
                            <div className="col leftspace publish-ranking">
                                <div className="row">
                                    <div className="col-md-12">
                                        <fieldset disabled={!enabledRankingFilter}>
                                            <label className="label">{getLabel("LBL_Publish_for")}</label>
                                            <select name="month" className="form-select" id="month" style={{ width: "20%" }} onChange={handleChange}>
                                                <option value="">--select--</option>
                                                <option value='1'>Janaury</option>
                                                <option value='2'>February</option>
                                                <option value='3'>March</option>
                                                <option value='4'>April</option>
                                                <option value='5'>May</option>
                                                <option value='6'>June</option>
                                                <option value='7'>July</option>
                                                <option value='8'>August</option>
                                                <option value='9'>September</option>
                                                <option value='10'>October</option>
                                                <option value='11'>November</option>
                                                <option value='12'>December</option>
                                            </select>

                                            <label className="label">{getLabel("LBL_Year")}</label>
                                            <select name="year" className="form-select" id="year" style={{ width: "20%" }} onChange={handleChange}>
                                                <option value="">--select--</option>
                                                {rankingYearFilter.map((year) => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </select>
                                            <button type="submit" name="btn_submit" className="btn btn-brand btn-wide btn-search">
                                                {getLabel("LBL_Publish")}
                                            </button>
                                        </fieldset>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="filter-error-msg">
                                            {formik.touched.month && formik.errors.month && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.month}</p>
                                            )}
                                            {formik.touched.year && formik.errors.year && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.year}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </>
    )
}
export default SearchBar