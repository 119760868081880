import axios from "axios";
import { url } from "./config";
export const getmyAxios = (conf) => {
    const myAxios = axios.create(conf);
    myAxios.interceptors.request.use(function (config) {
        if (config.adminURL == false || config.frontendURL == false) {
            config.baseURL = url;
        }
        if (config.customURL == true) {
            config.baseURL = config.url;
        }

        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    myAxios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response?.status === 498) {
            const key = conf.urlType === 'front' ? 'userDetails' : 'adminDetails';
            localStorage.removeItem(key);
            window.location.href = conf.urlType === 'front' ? '/' : '/admin';
        }        
        return Promise.reject(error);
    });
    return myAxios;
};

let conf = {
    baseURL: url,
    adminURL: true,
    customURL: false,
}
export const myAxios = getmyAxios(conf);