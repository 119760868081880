import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { baseUrl } from '../../common/config';
import { getLabel } from '../../../common/getLabel';

const ProfileImageModal = ({ imageData, profileImageModalShow, handleProfileImageModalClose, handleImageChange, imageUploadType }) => {
    const [profileImage, setProfileImage] = useState(null);

    const closePopup = () => {
        if (profileImage) {
            setProfileImage(null);
        }
        handleProfileImageModalClose();
    }
    return (
        <>
            <Modal className="profile-modal rightsidebar" show={profileImageModalShow} onHide={closePopup}  >
                <Modal.Header closeButton>
                    <Modal.Title>Profile Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-msg">
                        <p className="alert alert-info ">{getLabel('LBL_Upload_a_file_to_start_cropping')}</p>
                    </div>
                    <div className="image-upload">
                        <input type="file" name="" id="logo" onChange={(e) => setProfileImage(e.target.files[0])} />
                        <label htmlFor="logo" className="upload-field" id="file-label">
                            <div className="file-thumbnail">
                                {profileImage ? (
                                    <img src={URL.createObjectURL(profileImage)} alt="image-path" id="image-preview" />
                                ) : (
                                    <>
                                        {imageData && imageData.length > 0 ? (
                                            <img src={`${baseUrl}/${imageData[0].afile_physical_path}`} alt="Profile" />
                                        ) : (
                                            <img id="image-preview" src="/images/basic.png" alt="" />
                                        )}
                                    </>
                                )}
                                <h3>{getLabel('LBL_Drag_your_image_to_the_box_below')}</h3>
                                <p>{getLabel('LBL_Supports_JPG,_PNG,_SVG')}</p>
                                <p>
                                    {imageUploadType == 1 ? getLabel('LBL_Image_size_should_be_240x240') : getLabel('LBL_Image_size_should_be_1920x514')}
                                </p>
                            </div>
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={closePopup}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={() => handleImageChange(profileImage)}>Save Photo</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProfileImageModal
