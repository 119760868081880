import { getLabel } from "../../common/getLabel"
import { useState } from "react";
import { handleTimeZoneFormat } from "../../../common/common";
import CustomDatePicker from "../../../common/CustomDatePicker";
const SearchBar = (props) => {
    const { handleFilterUpdate, filterdata, handleFilter, handleClear } = props;
    const [searchItem, setSearchItem] = useState("")
    const handleInputChange = (event) => {
        setSearchItem(event.target.value);
        handleFilterUpdate('keyword', event.target.value, false);
    };
    const [startDate, setStartDate] = useState(filterdata.date_from ? new Date(filterdata.date_from) : null);
    const [endDate, setEndDate] = useState(filterdata.date_to ? new Date(filterdata.date_to) : null);
    const handleStartDateChange = date => {
        setStartDate(date);
        handleFilterUpdate('date_from', handleTimeZoneFormat(date), false);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
        handleFilterUpdate('date_to', handleTimeZoneFormat(date), false);
    };

    const handleFormClear = () => {
        setSearchItem("");
        setStartDate(null);
        setEndDate(null);
        handleClear();
    };
    return (
        <>
            <div className="row">
                <div className="col leftspace pb-5">
                    <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
                        <input className="search-input"
                            placeholder={getLabel("LBL_Search_by_Title")}
                            name="title"
                            value={searchItem}
                            onChange={(e) => {
                                handleInputChange(e)
                            }}
                        />
                        <a className="btn btn-primary" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample" href="#collapseExample">
                            <i className="bx bx-chevrons-down"></i>
                        </a>
                        <div className="card-button">
                            <ul>
                                <li>
                                    <button
                                        type="submit"
                                        name="btn_submit"
                                        className="btn btn-brand btn-wide btn-search"
                                        onClick={() => handleFilter}
                                    >
                                        {getLabel("LBL_Search")}{" "}
                                    </button>
                                    <button
                                        type="button"
                                        name="btn_clear"
                                        className="btn btn-link btn_clear"
                                        onClick={(e) => {
                                            handleFormClear();
                                        }}
                                    >
                                        {getLabel("LBL_Clear")}
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                        <div className="collapse" id="collapseExample">
                            <div className="card card-body">
                                <div className="container-fluid inner-section">
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-3 col-12">
                                            <div className="form-group">
                                                <label className="label">{getLabel("LBL_Date_from")}</label>
                                                <CustomDatePicker
                                                    selectedDate={startDate}
                                                    onDateChange={handleStartDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    id="birthday"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-sm-3 col-12">
                                            <div className="form-group">
                                                <label className="label">{getLabel("LBL_Date_to")}</label>
                                                <CustomDatePicker
                                                    selectedDate={endDate}
                                                    onDateChange={handleEndDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    id="birthday"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </div>
        </>
    )
}
export default SearchBar