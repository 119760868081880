import { ORDER_LOADING, ORDER_ERROR, ORDER_SUCCESS } from "./type";
const initState = { loading: true, error: false, orders: [] };
const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case ORDER_LOADING:
      return { ...state, loading: true };
    case ORDER_ERROR:
      return { ...state, loading: false, error: true };
    case ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        orders: action.payload.rows,
        totalCount: action.payload.count,
      };
    default:
      return state;
  }
};

export { orderReducer };
