import { store } from "../store"
import { getLabelByKey } from "../services/label/action";
import { loadLabels } from '../services/all-labels/action';

export const getLabel = (key) => {
    const loadedData = store.getState().loadLabel.loadedData;
    const upperKey = key.toUpperCase()
    let labelSize = loadedData && Object.keys(loadedData).length;    
    if (loadedData) {
        const foundLabel = loadedData[upperKey];
        if (foundLabel) {
            return foundLabel;
        } else {
            const formattedKey = key.replace('LBL_', '');
            const formattedValue = formattedKey.replace(/_/g, ' ');
            getKeyLabel(key);
            return formattedValue;   
        }
    }
    if(labelSize == null){
        const formattedKey = key.replace('LBL_', '');
        const formattedValue = formattedKey.replace(/_/g, ' ');
        return formattedValue;
    }
};
const getKeyLabel = async (key) => {
    const newLabel = { "key": key }
    try {
        const response = await getLabelByKey(newLabel);
        store.dispatch(loadLabels());
      } catch (error) {
    }
};




