import { getLabel } from "../../common/getLabel";


const SearchBar = (props) => {
  const { handleFormShow,editSubAdmin } = props;
  return (
    <>
      {editSubAdmin && (
        <div className="row">
          <div className="col leftspace pb-5">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleFormShow}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "#fff", transform: "", msFilter: "" }}
              >
                <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z"></path>
                <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
              </svg>
              {getLabel("LBL_Add_Admin_User")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBar;
