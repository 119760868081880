import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import SearchBar from './SearchBar'
import Listing from './listing'
import { getLabel } from '../../../common/getLabel'
import { getPremiumProfilesOffers } from '../../services/premium-offers/action'
import { formatDate } from '../../../common/common'
import { loadMetaTags, PREMIUM_OFFERS_PAGE_META_TYPE, SITE_TITLE } from '../../common/common'

const PremiumOffers = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 20;
    const requestBody = {
        page: currentPage,
        limit: limit,
        keyword: ""
    };
    const [filterdata, setFilterData] = useState(requestBody);
    const dispatch = useDispatch();
    const { premiumOffers, totalCount } = useSelector((state) => state.premiumOffers);
    const loading = useSelector((state) => state.premiumOffers.loading);
    const error = useSelector((state) => state.premiumOffers.error);
    const handlePaginate = (selectedPage) => {
        const updatedFilterData = {
            ...filterdata,
            page: selectedPage.selected + 1
        };
        setCurrentPage(selectedPage.selected + 1);
        setFilterData(updatedFilterData);
        dispatch(getPremiumProfilesOffers(updatedFilterData));
    };
    const handleFilter = () => {
        const updatedFilterData = {
            ...filterdata,
        };
        dispatch(getPremiumProfilesOffers(updatedFilterData));
    };

    const handleFilterUpdate = async (name, value) => {
        let updatedFilterData;
        updatedFilterData = {
            ...filterdata,
            [name]: value,
        };
        setFilterData(updatedFilterData);
    };

    const { headerFooter } = useSelector((state) => state.headerFooter);
    const site_title = headerFooter?.info?.CONF_SITE_TITLE || SITE_TITLE;
    const metaTags = {
        meta_type: PREMIUM_OFFERS_PAGE_META_TYPE,
        meta_title: `${site_title} | Premium College Offers And Commitments`,
        meta_record_id: 0,
        meta_advance_tag: 1
    }

    useEffect(() => {
        loadMetaTags(metaTags);
        dispatch(getPremiumProfilesOffers(requestBody));
        // const delaySearch = setTimeout(() => {
        // }, 1000);
        // return () => clearTimeout(delaySearch);
    }, []);
    return (
        <>
            <div className="player-list">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="player-heading">
                                <h2>{getLabel('LBL_PREMIUM_OFFERS/COMMITS')}</h2>
                                <p>Updated {formatDate(new Date())}</p>
                            </div>
                        </div>

                        <SearchBar
                            handleFilterUpdate={handleFilterUpdate}
                            handleFilter={handleFilter}
                            filterdata={filterdata}
                        />

                        <Listing
                            error={error}
                            premiumOffers={premiumOffers}
                            loading={loading}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            limit={limit}
                            handlePaginate={handlePaginate}
                        />

                    </div>
                </div>
            </div>
        </>
    )
}

export default PremiumOffers
