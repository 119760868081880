import React, { useRef, useState, useEffect } from 'react'
import { formatExpiryDate, sanitizeInput, stateListing } from '../../../common/common'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { checkCouponIsValid, getPlanDetails, membershipCheckout } from '../../services/membership/action'
import { useFormik } from "formik";
import { memberShipSchema } from '../../common/validation';
import { InputMask } from '@react-input/mask';
import { getLabel } from '../../../common/getLabel';
import { addOverlay } from '../../common/common';

const Checkout = () => {
    const search = useLocation().search;
    const choosePlan = new URLSearchParams(search).get('choose');
    const upgrade = new URLSearchParams(search).get('upgrade');
    const navigate = useNavigate();
    const [planDetail, setPlanDetail] = useState(null);
    useEffect(() => {
        handlePlanDetails();
    }, []);
    let payPer = '';
    let payDetails2 = '';
    let type = '';
    let showPromo = '';
    let planId = '';
    let paySubscriptionAmount = '';

    switch (choosePlan) {
        case 'yearly':
            payPer = 'year';
            paySubscriptionAmount = '499';
            payDetails2 = 'Automatically renews yearly.';
            type = 'yearly';
            showPromo = true;
            planId = 2;
            break;
        case 'monthly':
            payPer = 'month';
            paySubscriptionAmount = '49.99';
            payDetails2 = 'Automatically renews monthly.';
            type = 'monthly';
            showPromo = true;
            planId = 3;
            break;
        case 'tmonthly':
            payPer = 'month';
            paySubscriptionAmount = '59.99';
            payDetails2 = 'Automatically renews monthly.';
            type = 'tmonthly';
            showPromo = true;
            planId = 5;
            break;
        case 'tyearly':
            payPer = 'year';
            paySubscriptionAmount = '399';
            payDetails2 = 'Automatically renews yearly.';
            type = 'tyearly';
            showPromo = true;
            planId = 6;
            break;
        default:
            break;
    }

    const payAmount = planDetail ? planDetail.splan_price : paySubscriptionAmount;
    const payDetails = planDetail ? planDetail.splan_title : '';

    const [promoCode, setPromoCode] = useState("");
    const [promoCodeSuccessMsg, setPromoCodeSuccessMsg] = useState("");
    const [promoCodeErrorMsg, setPromoCodeErrorMsg] = useState("");
    const debounceTimeoutRef = useRef(null);

    const handlePlanDetails = async () => {
        const response = await getPlanDetails({ plan_id: planId });
        setPlanDetail(response);
    }

    const initialValues = {
        addressOne: "",
        addressTwo: "",
        city: "",
        state: "",
        zip: "",
        phoneNumber: "",
        cardName: "",
        cardNumber: "",
        expire: "",
        securityCode: "",
        promoCode: "",
        subscriptionType: type,
        planId: planId,
        amount: payAmount,
        profileId: upgrade !== null && !isNaN(upgrade) ? upgrade : 0
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: memberShipSchema,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            addOverlay();
            const postData = {
                addressOne: values.addressOne,
                addressTwo: values.addressTwo,
                city: values.city,
                state: values.state,
                zip: values.zip,
                phoneNumber: values.phoneNumber,
                cardName: values.cardName,
                cardNumber: sanitizeInput(values.cardNumber),
                expire: formatExpiryDate(values.expire),
                securityCode: values.securityCode,
                promoCode: promoCode,
                subscriptionType: values.subscriptionType,
                amount: payAmount,
                profileId: values.profileId,
                planId: values.planId,
            }
            try {
                const response = await membershipCheckout(postData);
                resetForm();
                if (response === true) {
                    navigate('/thank-you');
                }
            } catch (error) { }
        }
    })

    const handleChange = (e) => {
        formik.handleChange(e)
    };

    const handlePromoCode = (event) => {
        const { value } = event.target;
        setPromoCode(value);
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }
        debounceTimeoutRef.current = setTimeout(async () => {
            try {
                const response = await checkCouponIsValid({ coupon_code: value, coupon_subscription_type: type });
                if (response.success === false) {
                    setPromoCodeErrorMsg(response.message);
                    setPromoCodeSuccessMsg("");
                } else {
                    setPromoCodeErrorMsg("");
                    setPromoCodeSuccessMsg(response.message);
                }
            } catch (error) {
                console.error("Error checking coupon:", error);
            }
        }, 1000);
    };

    return (
        <div className="container payment">
            <div className="row">
                <div className="col-lg-7 mx-auto mb-5">
                    <div className="player-heading">
                        <h2>{getLabel('LBL_MEMBERSHIP_SUMMARY')}</h2>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="login-bg mb-5">
                            <div className="row">

                                <h6>${payAmount} <span> per {payPer} </span></h6>
                                <p>{payDetails}</p>

                                <div className="col-lg-12 sign-up-label">
                                    <label className="label-text">{getLabel('LBL_BILLING_ADDRESS')}</label>
                                </div>


                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_Address_1')}*</label>
                                        <input type="text" className="form-control" name="addressOne" value={formik.values.addressOne} onChange={handleChange} placeholder="Address 1" aria-label="Address" />
                                    </div>
                                    {formik.touched.addressOne && formik.errors.addressOne && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.addressOne}</span>
                                    )}
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_Address_2')}</label>
                                        <input type="text" className="form-control" name="addressTwo" value={formik.values.addressTwo} onChange={handleChange} placeholder="Address 2" aria-label="*Address1" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_City')}*</label>
                                        <input type="text" className="form-control" name="city" value={formik.values.city} onChange={handleChange} placeholder="City" aria-label="user name" />
                                    </div>
                                    {formik.touched.city && formik.errors.city && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.city}</span>
                                    )}
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_State')}*</label>
                                        <select className="form-select" name="state" value={formik.values.state} onChange={handleChange} aria-label="Default select example">
                                            <option value="">State</option>
                                            {stateListing && stateListing().map((state, index) => (
                                                <option key={index} value={state}>{state}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {formik.touched.state && formik.errors.state && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.state}</span>
                                    )}
                                </div>



                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_Zip')}*</label>
                                        <input type="text" className="form-control numberonly" name="zip" value={formik.values.zip} onChange={handleChange} placeholder="ZIP" aria-label="password" />
                                    </div>
                                    {formik.touched.zip && formik.errors.zip && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.zip}</span>
                                    )}
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_Phone_Number')}*</label>
                                        <InputMask type="text" className="form-control numberonly" mask="(___) ___-____" replacement={{ _: /\d/ }} name="phoneNumber" value={formik.values.phoneNumber} onChange={handleChange} placeholder="Phone Number" aria-label="phone number" />
                                    </div>
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.phoneNumber}</span>
                                    )}
                                </div>


                                <div className="col-lg-12 sign-up-label mb-4">
                                    <label className="label-text">{getLabel('LBL_PAYMENT')}</label>
                                    <figure>
                                        <img src="/images/cards.png" className="img-fluid" />
                                    </figure>
                                </div>


                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_Name_on_Card')}*</label>
                                        <input type="text" className="form-control" name="cardName" value={formik.values.cardName} onChange={handleChange} placeholder="Name on Card" aria-label="name on card" />
                                    </div>
                                    {formik.touched.cardName && formik.errors.cardName && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.cardName}</span>
                                    )}
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_Card_Number')}*</label>
                                        <InputMask type="text" className="form-control" mask="____ ____ ____ ____" replacement={{ _: /\d/ }} name="cardNumber" value={formik.values.cardNumber} onChange={handleChange} placeholder="Card Number" aria-label="*card number" />
                                    </div>
                                    {formik.touched.cardNumber && formik.errors.cardNumber && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.cardNumber}</span>
                                    )}
                                </div>

                                <div className="col-lg-12 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_Expiration_MM/YY')}*</label>
                                        <InputMask type="text" className="form-control" mask="__/__" replacement={{ _: /\d/ }} name="expire" value={formik.values.expire} onChange={handleChange} placeholder="Expiration MM/YY" aria-label="Expiration MM/YY" />
                                    </div>
                                    {formik.touched.expire && formik.errors.expire && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.expire}</span>
                                    )}
                                </div>


                                <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                    <div className="from-group">
                                        <label>{getLabel('LBL_Security_Code')}*</label>
                                        <InputMask type="text" className="form-control" mask="___" replacement={{ _: /\d/ }} name="securityCode" value={formik.values.securityCode} onChange={handleChange} placeholder="Security Code" aria-label="Security Code" />
                                    </div>
                                    {formik.touched.securityCode && formik.errors.securityCode && (
                                        <span style={{ color: '#b94a48' }}>{formik.errors.securityCode}</span>
                                    )}
                                </div>

                                {showPromo && (
                                    <>
                                        <div className="col-lg-6 col-sm-6 col-md-6 form--element">
                                            <div className="from-group">
                                                <label>{getLabel('LBL_Promo_Code')}</label>
                                                <input type="text" className="form-control" name="promoCode" value={promoCode} onChange={handlePromoCode} placeholder="Promo Code" aria-label="Promo Code" />
                                            </div>
                                        </div>
                                        {promoCodeSuccessMsg && (
                                            <span className="alert alert-success">{promoCodeSuccessMsg}</span>
                                        )}
                                        {promoCodeErrorMsg && (
                                            <span className="alert alert-danger">{promoCodeErrorMsg}</span>
                                        )}
                                    </>
                                )}
                                <p>{payDetails2} {getLabel('LBL_No_contracts._Cancel_anytime.')} <Link to="/terms-conditions" target="_blank">{getLabel('LBL_Membership_Terms')}</Link></p>
                                <div className="col-12 mt-4">
                                    <button type="submit" className="btn btn-primary"><i className='bx bx-lock' ></i> PAY ${payAmount}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Checkout
