import Modal from 'react-bootstrap/Modal';
import { getLabel } from './getLabel';


const DeleteModal = ({ show, handleDeleteClose, handleDelete }) => {

    return (
        <>
            <Modal
                show={show}
                onHide={handleDeleteClose}
                backdrop="static"
                keyboard={false}
                className="delete-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{getLabel("LBL_Delete_Record")} </Modal.Title>
                </Modal.Header>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="delete-icon"><i className='bx bx-trash bx-flip-horizontal' ></i></div>
                            <h2>{getLabel("LBL_Are_You_Sure?")}</h2>
                            <p>
                                {getLabel("LBL_Do_you_really_want_to_delete_these_records?_This_process_cannot_be_undone.")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">

                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleDeleteClose}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "#fff", transform: "", msFilter: "" }}  ><path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V7h16l.001 12H4z"></path><path d="m15.707 10.707-1.414-1.414L12 11.586 9.707 9.293l-1.414 1.414L10.586 13l-2.293 2.293 1.414 1.414L12 14.414l2.293 2.293 1.414-1.414L13.414 13z"></path></svg>{getLabel("LBL_Cancel")}</button>

                    <button type="button" className="btn btn-primary" onClick={handleDelete}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        style={{
                            fill: "#fff",
                            transform: "scaleX(-1)",
                            msFilter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)"
                        }} >
                        <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path><path d="M9 10h2v8H9zm4 0h2v8h-2z"></path></svg>{getLabel("LBL_Delete")}</button>
                </div>
            </Modal>
        </>
    )

}
export default DeleteModal